import gql from 'graphql-tag';

export const getProducts = gql`
  query getProductsB2B(
    $mainCategoryId: String
    $tenantId: String
    $productTypeId: Int
    $isIncludeTotalCount: Boolean
    $isIncludePurchaseHistory: Boolean
    $hasPrice: Boolean
    $categories: [Int]
    $buyerId: String
    $sortBy: String
    $sortType: String
    $isSample: Boolean
    $searchQueries: String
    $limit: Int
    $offset: Int
  ) {
    getProductsB2B(
      mainCategoryId: $mainCategoryId
      productTypeId: $productTypeId
      isIncludeTotalCount: $isIncludeTotalCount
      isIncludePurchaseHistory: $isIncludePurchaseHistory
      hasPrice: $hasPrice
      categories: $categories
      tenantId: $tenantId
      sortBy: $sortBy
      sortType: $sortType
      buyerId: $buyerId
      isSample: $isSample
      searchQueries: $searchQueries
      limit: $limit
      offset: $offset
    ) {
      totalCount
      buyerId
      items {
        categories {
          id
          main_categories {
            name
          }
          sub_categories {
            name
          }
        }
        active
        category_id
        id
        image
        name
        tenant_id
        voucherify_id
        tenant {
          tax_rate
          class_id
          email
          email_notification
          tenant {
            name
            persons {
              phone
            }
          }
        }
        min_price
        max_price
        min_weight
        max_weight
        min_amount
        max_amount
        min_market_price
        max_market_price
        quotation_statuses
        halal
        hasDirectPrice
        skuIdsByCategory
      }
    }
  }
`;

export const addFavorite = gql`
  mutation addFavouriteKus($item: FavouriteKusInput!) {
    addFavouriteKus(item: $item)
  }
`;

export const removeFavorite = gql`
  mutation REMOVE_FAVORITE_SKU($item: RemoveFavouriteSKUInput!) {
    removeFavouriteSKU(item: $item)
  }
`;

export const requestQuotation = gql`
  mutation requestQuotation($payload: RequestQuotationInputType!) {
    requestQuotation(payload: $payload) {
      id
    }
  }
`;

export const checkFavouriteSkus = gql`
  mutation checkFavouriteSkus($accountNumber: String!, $customerId: Int!, $listSKUs: [Int]!) {
    checkFavouriteSkus(accountNumber: $accountNumber, customerId: $customerId, listSKUs: $listSKUs)
  }
`;

export const GetCuisines = gql`
  query GetCuisines($customerId: Int!, $countryId: Int!) {
    getCuisines(customerId: $customerId, countryId: $countryId) {
      id
      name
      image
      active
      customerCuisine {
        note
      }
    }
  }
`;

export const getBuyerProductsRecommend = gql`
  query getProductRecommend(
    $mainCategoryId: String
    $isSingleVariant: Boolean
    $productId: Int!
    $tenantId: Int
    $buyerId: Int
    $limit: Int
    $offset: Int
  ) {
    getProductRecommend(
      mainCategoryId: $mainCategoryId
      isSingleVariant: $isSingleVariant
      productId: $productId
      tenantId: $tenantId
      buyerId: $buyerId
      limit: $limit
      offset: $offset
    ) {
      mainCategories
      items {
        active
        category_id
        id
        image
        name
        tenant_id
        voucherify_id
        categories {
          id
          main_categories {
            name
          }
          sub_categories {
            name
          }
        }
        tenant {
          tax_rate
          class_id
          email
          email_notification
          tenant {
            name
            persons {
              phone
            }
          }
        }
        min_price
        max_price
        min_weight
        max_weight
        min_amount
        max_amount
        min_market_price
        max_market_price
        halal
        hasDirectPrice
        skuIdsByCategory
        quotation_statuses
      }
      otherVariant {
        product_type_id
        quantity
        halal
        price
        unit_price
        unit_amount
        increment_qty
        sku_id
        weight
        oom
        uom
        origin_price
        tax_rate
        is_order_by_weight
        specs
        sku
        limit_price
        is_out_of_stock
        unit_per_item
        unit_per_item_uom
        is_favorite
        quotation_status
        is_catch_weight
        direct_price
        is_special_price
        is_back_order
        total_available_quantity
      }
    }
  }
`;

export const AddCuisines = gql`
  mutation AddCuisines($customerId: Int!, $cuisineIds: [Int!], $countryId: Int!, $note: String) {
    addCuisines(customerId: $customerId, cuisineIds: $cuisineIds, countryId: $countryId, note: $note)
  }
`;

export const slackNotifNewCustomer = gql`
  query slackNotifNewCustomer($customerId: Int!) {
    slackNotifNewCustomer(customerId: $customerId)
  }
`;
