import {
  getViewsUserNotifications,
  saleGetQuotations,
  saleGetQuotationsDetail,
  updateUserNotification,
  saleApproveQuotation,
  getBadgeNumbers,
  readAllUserNotificationsB2b
} from '@/modules/sale/services/graphql';
import { MUTATIONS } from './mutations';
import { apolloClient } from '@/main';

export const ACTIONS = {
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  LOAD_MORE_NOTIFICATION: 'LOAD_MORE_NOTIFICATION',

  GET_VIEWS_QUOTATIONS: 'GET_VIEWS_QUOTATIONS',
  LOAD_MORE_QUOTATIONS: 'LOAD_MORE_QUOTATIONS',

  GET_VIEWS_QUOTATIONS_DETAIL: 'GET_VIEWS_QUOTATIONS_DETAIL',

  UPDATE_SUB_BUYER: 'UPDATE_SUB_BUYER',
  SALE_APPROVE_QUOTATION: 'SALE_APPROVE_QUOTATION',
  GET_BADGE_NUMBERS: 'GET_BADGE_NUMBERS',

  GET_ALL_USER_NOTIFICATIONS_B2B: 'GET_ALL_USER_NOTIFICATIONS_B2B'
};

export default {
  async [ACTIONS.GET_NOTIFICATIONS]({ commit, state }, { params, isLoadMore = false, onFinish }) {
    commit(MUTATIONS.GET_NOTIFICATIONS_REQUEST, isLoadMore);
    try {
      const { data } = await apolloClient.query({
        query: getViewsUserNotifications,
        variables: {
          ...state.params,
          ...params
        }
      });

      // get total count
      if (state.params.isIncludeTotalCount) state.totalCount = data.saleGetViewsUserNotifications.totalCount;

      if (onFinish) onFinish(data.saleGetViewsUserNotifications);
      commit(MUTATIONS.GET_NOTIFICATIONS_SUCCESS, { list: data.saleGetViewsUserNotifications });
    } catch (error) {
      console.log(error);
      commit(MUTATIONS.GET_NOTIFICATIONS_FAILURE, error);
    }
  },

  async [ACTIONS.LOAD_MORE_NOTIFICATION]({ commit, dispatch }, { params, isLoadMore = true, onFinish }) {
    // update search params
    commit(MUTATIONS.UPDATE_QUERY_PARAMS, { params });
    dispatch(ACTIONS.GET_NOTIFICATIONS, { isLoadMore, onFinish });
  },

  async [ACTIONS.GET_VIEWS_QUOTATIONS]({ commit, state }, { paramsQuotation, isLoadMore = false, onFinish }) {
    commit(MUTATIONS.GET_VIEWS_QUOTATIONS_REQUEST, isLoadMore);
    try {
      const { data } = await apolloClient.query({
        query: saleGetQuotations,
        variables: {
          ...state.paramsQuotation,
          ...paramsQuotation
        }
      });

      // get total count
      if (state.paramsQuotation.isIncludeTotalCount)
        state.totalCountQuotation = data.saleGetQuotations.totalCount;

      if (onFinish) onFinish(data.saleGetQuotations);
      commit(MUTATIONS.GET_VIEWS_QUOTATIONS_SUCCESS, { listQuotation: data.saleGetQuotations });
    } catch (error) {
      console.log(error);
      commit(MUTATIONS.GET_VIEWS_QUOTATIONS_FAILURE, error);
    }
  },

  async [ACTIONS.LOAD_MORE_QUOTATIONS](
    { commit, dispatch },
    { paramsQuotation, isLoadMore = true, onFinish }
  ) {
    commit(MUTATIONS.UPDATE_QUERY_PARAMS_QUOTATIONS, { paramsQuotation });
    dispatch(ACTIONS.GET_VIEWS_QUOTATIONS, { isLoadMore, onFinish });
  },

  async [ACTIONS.UPDATE_SUB_BUYER]({ commit }, params) {
    commit(MUTATIONS.UPDATE_SUB_BUYER_REQUEST);
    try {
      await apolloClient.mutate({
        mutation: updateUserNotification,
        variables: params
      });
      commit(MUTATIONS.UPDATE_SUB_BUYER_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.UPDATE_SUB_BUYER_FAILURE, error);
    }
  },

  // Get detail quotation
  async [ACTIONS.GET_VIEWS_QUOTATIONS_DETAIL]({ commit }, params) {
    commit(MUTATIONS.GET_VIEWS_QUOTATIONS_DETAIL_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: saleGetQuotationsDetail,
        variables: params
      });

      commit(MUTATIONS.GET_VIEWS_QUOTATIONS_DETAIL_SUCCESS, { list: data.saleGetQuotationsDetail });
    } catch (error) {
      commit(MUTATIONS.GET_VIEWS_QUOTATIONS_DETAIL_FAILURE, error);
    }
  },

  // Repeat order
  async [ACTIONS.SALE_APPROVE_QUOTATION]({ commit }, params) {
    commit(MUTATIONS.SALE_APPROVE_QUOTATION_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: saleApproveQuotation,
        variables: params
      });

      commit(MUTATIONS.SALE_APPROVE_QUOTATION_SUCCESS, data.saleApproveQuotation);
    } catch (error) {
      commit(MUTATIONS.SALE_APPROVE_QUOTATION_FAILURE, error);
    }
  },
  // Get number notification & cart item

  async [ACTIONS.GET_BADGE_NUMBERS]({ commit }, params) {
    commit(MUTATIONS.GET_BADGE_NUMBERS_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getBadgeNumbers,
        variables: params
      });

      commit(MUTATIONS.GET_BADGE_NUMBERS_SUCCESS, {
        data: data.getBadgeNumbers
      });
    } catch (error) {
      commit(MUTATIONS.GET_BADGE_NUMBERS_FAILURE, error);
    }
  },

  //read All User Notification
  async [ACTIONS.GET_ALL_USER_NOTIFICATIONS_B2B]({ commit }) {
    try {
      await apolloClient.mutate({
        mutation: readAllUserNotificationsB2b,
        variables: {}
      });
      commit(MUTATIONS.GET_ALL_USER_NOTIFICATIONS_B2B_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.GET_ALL_USER_NOTIFICATIONS_B2B_FAILURE, error);
    }
  }
};
