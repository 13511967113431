import gql from 'graphql-tag';

export const getB2bAddresses = gql`
  query getB2bAddresses($supplierId: Int!, $active: Boolean) {
    getB2bAddresses(supplierId: $supplierId, active: $active) {
      id
      customer_id
      floor_number
      street_number
      road
      building
      unit
      stall
      city
      state
      postal_code
      latlng
      country_id
      address_type_id
      active
      is_default
    }
  }
`;

export const addAddress = gql`
  mutation addAddress(
    $supplierId: Int!
    $floorNumber: String
    $streetNumber: String
    $road: String
    $building: String
    $unit: String
    $stall: String
    $city: String
    $state: String
    $postalCode: String!
    $latlng: LatLngInput
    $countryId: Int!
    $addressTypeId: Int!
    $isDefault: Boolean
  ) {
    addAddress(
      supplierId: $supplierId
      floorNumber: $floorNumber
      streetNumber: $streetNumber
      road: $road
      building: $building
      unit: $unit
      stall: $stall
      city: $city
      state: $state
      postalCode: $postalCode
      latlng: $latlng
      countryId: $countryId
      addressTypeId: $addressTypeId
      isDefault: $isDefault
    )
  }
`;

export const updateAddress = gql`
  mutation updateAddressB2b($payload: AddressCustomType, $addressId: Int!) {
    updateAddressB2b(payload: $payload, addressId: $addressId) {
      id
      customer_id
      floor_number
      street_number
      road
      building
      unit
      stall
      city
      state
      postal_code
      latlng
      country_id
      address_type_id
      active
      is_default
    }
  }
`;

export const removeAddress = gql`
  mutation removeAddress($addressId: Int!) {
    removeAddress(address: { id: $addressId })
  }
`;
