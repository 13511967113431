import { FAILURE, REQUEST, SUCCESS } from '@/modules/sale/services/libs/state';

export const MUTATIONS = {
  // get list customer
  GET_LIST_CUSTOMER_CARD_REQUEST: 'GET_LIST_CUSTOMER_CARD_REQUEST',
  GET_LIST_CUSTOMER_CARD_SUCCESS: 'GET_LIST_CUSTOMER_CARD_SUCCESS',
  GET_LIST_CUSTOMER_CARD_FAILURE: 'GET_LIST_CUSTOMER_CARD_FAILURE',

  // get default card
  GET_DEFAULT_CARD_REQUEST: 'GET_DEFAULT_CARD_REQUEST',
  GET_DEFAULT_CARD_SUCCESS: 'GET_DEFAULT_CARD_SUCCESS',
  GET_DEFAULT_CARD_FAILURE: 'GET_DEFAULT_CARD_FAILURE',

  // remove card
  REMOVE_CARD_REQUEST: 'REMOVE_CARD_REQUEST',
  REMOVE_CARD_SUCCESS: 'REMOVE_CARD_SUCCESS',
  REMOVE_CARD_FAILURE: 'REMOVE_CARD_FAILURE',

  // add stripe card
  ADD_STRIPE_CARD_REQUEST: 'ADD_STRIPE_CARD_REQUEST',
  ADD_STRIPE_CARD_SUCCESS: 'ADD_STRIPE_CARD_SUCCESS',
  ADD_STRIPE_CARD_FAILURE: 'ADD_STRIPE_CARD_FAILURE',

  // set default card
  SET_DEFAULT_CARD_REQUEST: 'SET_DEFAULT_CARD_REQUEST',
  SET_DEFAULT_CARD_SUCCESS: 'SET_DEFAULT_CARD_SUCCESS',
  SET_DEFAULT_CARD_FAILURE: 'SET_DEFAULT_CARD_FAILURE',
  //sale list stripe card
  SALE_LIST_STRIPE_CARD_SUCCESS: 'SALE_LIST_STRIPE_CARD_SUCCESS',
  SALE_LIST_STRIPE_CARD_FAILURE: 'SALE_LIST_STRIPE_CARD_FAILURE',

  // action get invoices by customer
  GET_CUSTOMER_INVOICES_REQUEST: 'GET_CUSTOMER_INVOICES_REQUEST',
  GET_CUSTOMER_INVOICES_SUCCESS: 'GET_CUSTOMER_INVOICES_SUCCESS',
  GET_CUSTOMER_INVOICES_FAILURE: 'GET_CUSTOMER_INVOICES_FAILURE',
  UPDATE_QUERY_PARAMS_GET_CUSTOMER_INVOICE: 'UPDATE_QUERY_PARAMS_GET_CUSTOMER_INVOICE',

  // reset error and status back to default value
  RESET_STATUS_ERROR: 'RESET_STATUS_ERROR',

  // get xero customer info
  GET_XERO_CUSTOMER_REQUEST: 'GET_XERO_CUSTOMER_REQUEST',
  GET_XERO_CUSTOMER_SUCCESS: 'GET_XERO_CUSTOMER_SUCCESS',
  GET_XERO_CUSTOMER_FAILURE: 'GET_XERO_CUSTOMER_FAILURE',

  // get xero customer info
  GET_TOTAL_UNPAID_INVOICES_REQUEST: 'GET_TOTAL_UNPAID_INVOICES_REQUEST',
  GET_TOTAL_UNPAID_INVOICES_SUCCESS: 'GET_TOTAL_UNPAID_INVOICES_SUCCESS',
  GET_TOTAL_UNPAID_INVOICES_FAILURE: 'GET_TOTAL_UNPAID_INVOICES_FAILURE',

  // create payment
  CREATE_BANK_PAYMENT_REQUEST: 'CREATE_BANK_PAYMENT_REQUEST',
  CREATE_CASH_CHEQUE_PAYMENT_REQUEST: 'CREATE_CASH_CHEQUE_PAYMENT_REQUEST',
  CREATE_PAYMENT_SUCCESS: 'CREATE_PAYMENT_SUCCESS',
  CREATE_PAYMENT_FAILURE: 'CREATE_PAYMENT_FAILURE',

  CREATE_AUTOMATIC_BATCH_PAYMENTS_REQUEST: 'CREATE_AUTOMATIC_BATCH_PAYMENTS_REQUEST',
  CREATE_AUTOMATIC_BATCH_PAYMENTS_SUCCESS: 'CREATE_AUTOMATIC_BATCH_PAYMENTS_SUCCESS',
  CREATE_AUTOMATIC_BATCH_PAYMENTS_FAILURE: 'CREATE_AUTOMATIC_BATCH_PAYMENTS_FAILURE',

  SET_SELECTED_PAYMENT_INVOICES: 'SET_SELECTED_PAYMENT_INVOICES',
  REMOVE_SELECTED_PAYMENT_INVOICES: 'REMOVE_SELECTED_PAYMENT_INVOICES',

  // payment method
  SET_SELECTED_PAYMENT_METHOD: 'SET_SELECTED_PAYMENT_METHOD',
  REMOVE_SELECTED_PAYMENT_METHOD: 'REMOVE_SELECTED_PAYMENT_METHOD',
  CHECK_IS_FINANCE_MODULE_ENABLED: 'CHECK_IS_FINANCE_MODULE_ENABLED',

  GET_PAYMENT_HISTORY_REQUEST: 'GET_PAYMENT_HISTORY_REQUEST',
  GET_PAYMENT_HISTORY_SUCCESS: 'GET_PAYMENT_HISTORY_SUCCESS',
  GET_PAYMENT_HISTORY_FAILED: 'GET_PAYMENT_HISTORY_FAILED'
};

export default {
  // get list customer card
  [MUTATIONS.GET_LIST_CUSTOMER_CARD_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_LIST_CUSTOMER_CARD_SUCCESS](state, listCustomerCard) {
    SUCCESS(state);
    state.listCustomerCard = listCustomerCard;
  },
  [MUTATIONS.GET_LIST_CUSTOMER_CARD_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // get default card
  [MUTATIONS.GET_DEFAULT_CARD_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_DEFAULT_CARD_SUCCESS](state, defaultCardId) {
    SUCCESS(state);
    state.defaultCardId = defaultCardId;
  },
  [MUTATIONS.GET_DEFAULT_CARD_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // remove card
  [MUTATIONS.REMOVE_CARD_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.REMOVE_CARD_SUCCESS](state, removeStripeCard) {
    SUCCESS(state);
    state.removeStripeCard = removeStripeCard;
  },
  [MUTATIONS.REMOVE_CARD_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // add stripe card
  [MUTATIONS.ADD_STRIPE_CARD_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.ADD_STRIPE_CARD_SUCCESS](state, addStripeCard) {
    SUCCESS(state);
    state.addStripeCard = addStripeCard;
  },
  [MUTATIONS.ADD_STRIPE_CARD_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // set default card
  [MUTATIONS.SET_DEFAULT_CARD_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.SET_DEFAULT_CARD_SUCCESS](state, defaultCardId) {
    SUCCESS(state);
    state.defaultCardId = defaultCardId;
  },
  [MUTATIONS.SET_DEFAULT_CARD_FAILURE](state, error) {
    FAILURE(state, error);
  },
  [MUTATIONS.SALE_LIST_STRIPE_CARD_SUCCESS](state, payload) {
    SUCCESS(state);
    state.saleListStripeCard = payload;
  },
  [MUTATIONS.SALE_LIST_STRIPE_CARD_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // get invoices by customer
  [MUTATIONS.GET_CUSTOMER_INVOICES_REQUEST](state, isLoadMore) {
    REQUEST(state);
    state.isLoadMore = isLoadMore;
  },
  [MUTATIONS.GET_CUSTOMER_INVOICES_SUCCESS](state, { listInvoices }) {
    SUCCESS(state);
    if (state.isLoadMore) {
      const invoices = [...state.listInvoices.invoices, ...listInvoices.invoices];
      state.listInvoices = {
        ...listInvoices,
        invoices
      };
    } else {
      state.listInvoices = listInvoices;
    }
  },
  [MUTATIONS.GET_CUSTOMER_INVOICES_FAILURE](state, error) {
    FAILURE(state, error);
  },
  [MUTATIONS.UPDATE_QUERY_PARAMS_GET_CUSTOMER_INVOICE](state, { paramsListInvoices }) {
    state.paramsListInvoices = {
      ...state.paramsListInvoices,
      ...paramsListInvoices
    };
  },
  [MUTATIONS.RESET_STATUS_ERROR](state) {
    state.status = true;
    state.error = null;
  },
  [MUTATIONS.GET_XERO_CUSTOMER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_XERO_CUSTOMER_SUCCESS](state, payload) {
    SUCCESS(state);
    state.xeroCustomer = payload;
  },
  [MUTATIONS.GET_XERO_CUSTOMER_FAILURE](state, error) {
    FAILURE(state, error);
  },
  [MUTATIONS.GET_TOTAL_UNPAID_INVOICES_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_TOTAL_UNPAID_INVOICES_SUCCESS](state, payload) {
    SUCCESS(state);
    state.totalUnpaidInvoices = payload;
  },
  [MUTATIONS.GET_TOTAL_UNPAID_INVOICES_FAILURE](state, error) {
    FAILURE(state, error);
  },
  [MUTATIONS.CREATE_BANK_PAYMENT_REQUEST](state, { paramsCreateBankPayment }) {
    state.paramsCreateBankPayment = {
      ...state.paramsCreateBankPayment,
      ...paramsCreateBankPayment
    };
  },
  [MUTATIONS.CREATE_CASH_CHEQUE_PAYMENT_REQUEST](state, { paramsCreateCashChequePayment }) {
    state.paramsCreateCashChequePayment = {
      ...state.paramsCreateCashChequePayment,
      ...paramsCreateCashChequePayment
    };
  },
  [MUTATIONS.CREATE_PAYMENT_SUCCESS](state, { paymentSummary }) {
    SUCCESS(state);
    state.paymentSummary = paymentSummary;
  },
  [MUTATIONS.CREATE_PAYMENT_FAILURE](state, error) {
    FAILURE(state, error);
  },
  [MUTATIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS_REQUEST](state, { paramsCreatePayment }) {
    state.paramsCreatePayment = {
      ...state.paramsCreatePayment,
      ...paramsCreatePayment
    };
  },
  [MUTATIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS_SUCCESS](state, { paymentSummary }) {
    SUCCESS(state);
    state.paymentSummary = paymentSummary;
  },
  [MUTATIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS_FAILURE](state, error) {
    FAILURE(state, error);
  },
  [MUTATIONS.SET_SELECTED_PAYMENT_INVOICES](state, payload) {
    state.loading = true;
    state.selectedPaymentInvoices = payload;
    setTimeout(() => {
      state.loading = false;
    }, 500);
  },
  [MUTATIONS.REMOVE_SELECTED_PAYMENT_INVOICES](state) {
    state.selectedPaymentInvoices = null;
  },
  [MUTATIONS.SET_SELECTED_PAYMENT_METHOD](state, paymentMethodID) {
    state.selectedPaymentMethod = paymentMethodID;
  },
  [MUTATIONS.REMOVE_SELECTED_PAYMENT_MESET_SELECTED_PAYMENT_METHOD](state) {
    state.selectedPaymentMethod = null;
  },
  [MUTATIONS.CHECK_IS_FINANCE_MODULE_ENABLED](state, params) {
    state.isFinanceModuleEnabled = params;
  },

  [MUTATIONS.GET_PAYMENT_HISTORY_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_PAYMENT_HISTORY_SUCCESS](state, payload) {
    SUCCESS(state);
    state.paymentHistory = payload;
  },
  [MUTATIONS.GET_PAYMENT_HISTORY_FAILED](state, error) {
    FAILURE(state, error);
  }
};
