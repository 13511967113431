export const INVOICE_PAYMENT_TYPE = {
  BANK_TRANSFER: 4,
  CASH_DEPOSIT: 8,
  CHEQUE: 3,
  CASH_PAYMENT_COLLECTION: 2,
  PAYNOW: 6,
  CREDIT_CARD: 1, // MASTER CARD, VISA
  CUSTOMER_CREDIT: 7,
  NETS: 5
};

export const INVOICE_PAYMENT_METHOD = {
  DIGITAL: [6, 1, 7, 5],
  OTHER: [4, 8, 3, 2]
};

export const CARD_INVOICES_IMAGE = {
  BANK_IMAGE: '/assets/images/bank.svg',
  CASH_DEPOSIT_IMAGE: '/assets/images/cash-deposit.svg',
  CHEQUE_IMAGE: '/assets/images/cheque.svg',
  CASH_PAYMENT_IMAGE: '/assets/images/cash.svg'
};

export const PAYMENT_HISTORY_STATUS = {
  PAID: 'paid',
  UNPAID: 'unpaid',
  PARTIAL_PAID: 'partially paid'
};
