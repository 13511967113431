import gql from 'graphql-tag';

export const getViewsUserNotifications = gql`
  query saleGetViewsUserNotifications(
    $read: Boolean
    $isIncludeTotalCount: Boolean
    $limit: Int
    $offset: Int
  ) {
    saleGetViewsUserNotifications(
      read: $read
      isIncludeTotalCount: $isIncludeTotalCount
      limit: $limit
      offset: $offset
    ) {
      totalCount
      isAllNotifyRead
      data {
        id
        sender_user_id
        target_user_id
        message
        read
        created_at
        payload
        image_sender
        image_target
        title
        sub_type
      }
    }
  }
`;

export const saleGetQuotations = gql`
  query saleGetQuotations($tenantId: Int, $isIncludeTotalCount: Boolean, $limit: Int, $offset: Int) {
    saleGetQuotations(
      tenantId: $tenantId
      isIncludeTotalCount: $isIncludeTotalCount
      limit: $limit
      offset: $offset
    ) {
      totalCount
      data {
        id
        customer_id
        customer_name
        sku_name
        halal
        image
        quotation_status
        created_at
        price
        sku_id
        tenant_id
        status
        requestor {
          id
          image
          first_name
          last_name
        }
      }
    }
  }
`;

export const saleGetQuotationsDetail = gql`
  query saleGetQuotationsDetail($id: Int) {
    saleGetQuotationsDetail(id: $id) {
      id
      customer_id
      customer_name
      sku_name
      halal
      image
      quotation_status
      created_at
      unit_price
      price
      sku_id
      tenant_id
      description
      product_id
      tenant {
        tenant {
          name
        }
      }
      weight
      unit_per_oom
      uom
      origin_price
      status
      requestor {
        id
        image
        first_name
        last_name
      }
      quotedBy {
        image
        first_name
        last_name
      }
      approver {
        id
        first_name
        last_name
      }
      limit_price
      unit_per_item
      unit_per_item_uom
      direct_price
    }
  }
`;

export const updateUserNotification = gql`
  mutation updateUserNotification(
    $message: String
    $payload: String
    $title: String
    $notificationId: Int!
    $read: Boolean
  ) {
    updateUserNotification(
      message: $message
      payload: $payload
      title: $title
      notificationId: $notificationId
      read: $read
    ) {
      id
      read
    }
  }
`;
export const saleApproveQuotation = gql`
  mutation saleApproveQuotation(
    $actionType: Int!
    $quotationId: Int!
    $unitPrice: Float
    $price: Float!
    $approve: Int
  ) {
    saleApproveQuotation(
      actionType: $actionType
      quotationId: $quotationId
      unitPrice: $unitPrice
      price: $price
      approve: $approve
    ) {
      approveQuotation
      isNeedApprovedByAdmin
    }
  }
`;

export const getBadgeNumbers = gql`
  query getBadgeNumbers($customerId: Int!) {
    getBadgeNumbers(customerId: $customerId) {
      carts
      notifications
    }
  }
`;
export const readAllUserNotificationsB2b = gql`
  mutation readAllUserNotificationsB2b {
    readAllUserNotificationsB2b
  }
`;
export const getMaintenanceInfo = gql`
  query getMaintenanceInfo($timeNow: String!, $platform: String) {
    getMaintenanceInfo(timeNow: $timeNow, platform: $platform) {
      id
      description
      start_time
      end_time
      active
      platform
      time_remaining
      time_is_coming
      isMaintenance
      isIncoming
    }
  }
`;

export const rejectQuotation = gql`
  mutation rejectQuotation($quotationId: Int!) {
    rejectQuotation(quotationId: $quotationId)
  }
`;
