import {
  getViewsUserNotifications,
  getViewsQuotations,
  updateUserNotification,
  getBadgeNumbers,
  readAllUserNotificationsB2b
} from '@/modules/b2b/services/graphql';
import { MUTATIONS } from './mutations';
import { apolloClient } from '@/main';

export const ACTIONS = {
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  LOAD_MORE_NOTIFICATION: 'LOAD_MORE_NOTIFICATION',

  GET_VIEWS_QUOTATIONS: 'GET_VIEWS_QUOTATIONS',
  LOAD_MORE_QUOTATIONS: 'LOAD_MORE_QUOTATIONS',

  UPDATE_SUB_BUYER: 'UPDATE_SUB_BUYER',
  GET_VIEWS_QUOTATIONS_DETAIL: 'GET_VIEWS_QUOTATIONS_DETAIL',
  GET_BADGE_NUMBERS: 'GET_BADGE_NUMBERS',

  GET_ALL_USER_NOTIFICATIONS_B2B: 'GET_ALL_USER_NOTIFICATIONS_B2B'
};

export default {
  async [ACTIONS.GET_NOTIFICATIONS]({ commit, state }, { params, isLoadMore = false, onFinish }) {
    commit(MUTATIONS.GET_NOTIFICATIONS_REQUEST, isLoadMore);
    try {
      const { data } = await apolloClient.query({
        query: getViewsUserNotifications,
        variables: {
          ...state.params,
          ...params
        }
      });

      // get total count
      if (state.params.isIncludeTotalCount) state.totalCount = data.getViewsUserNotifications.totalCount;

      if (onFinish) onFinish(data.getViewsUserNotifications);
      commit(MUTATIONS.GET_NOTIFICATIONS_SUCCESS, { list: data.getViewsUserNotifications });
    } catch (error) {
      commit(MUTATIONS.GET_NOTIFICATIONS_FAILURE, error);
    }
  },

  async [ACTIONS.LOAD_MORE_NOTIFICATION]({ commit, dispatch }, { params, isLoadMore = true, onFinish }) {
    // update search params
    commit(MUTATIONS.UPDATE_QUERY_PARAMS, { params });
    dispatch(ACTIONS.GET_NOTIFICATIONS, { isLoadMore, onFinish });
  },

  async [ACTIONS.GET_VIEWS_QUOTATIONS]({ commit, state }, { paramsQuotation, isLoadMore = false, onFinish }) {
    commit(MUTATIONS.GET_VIEWS_QUOTATIONS_REQUEST, isLoadMore);
    try {
      const { data } = await apolloClient.query({
        query: getViewsQuotations,
        variables: {
          ...state.paramsQuotation,
          ...paramsQuotation
        }
      });

      // get total count
      if (state.paramsQuotation.isIncludeTotalCount)
        state.totalCountQuotation = data.getViewsQuotations.totalCount;

      if (onFinish) onFinish(data.getViewsQuotations);
      commit(MUTATIONS.GET_VIEWS_QUOTATIONS_SUCCESS, { listQuotation: data.getViewsQuotations });
    } catch (error) {
      console.log(error);
      commit(MUTATIONS.GET_VIEWS_QUOTATIONS_FAILURE, error);
    }
  },

  async [ACTIONS.LOAD_MORE_QUOTATIONS](
    { commit, dispatch },
    { paramsQuotation, isLoadMore = true, onFinish }
  ) {
    // update search params
    commit(MUTATIONS.UPDATE_QUERY_PARAMS_QUOTATIONS, { paramsQuotation });
    dispatch(ACTIONS.GET_VIEWS_QUOTATIONS, { isLoadMore, onFinish });
  },

  async [ACTIONS.UPDATE_SUB_BUYER]({ commit }, params) {
    commit(MUTATIONS.UPDATE_SUB_BUYER_REQUEST);
    try {
      await apolloClient.mutate({
        mutation: updateUserNotification,
        variables: params
      });
      commit(MUTATIONS.UPDATE_SUB_BUYER_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.UPDATE_SUB_BUYER_FAILURE, error);
    }
  },

  // Get detail quotation
  async [ACTIONS.GET_VIEWS_QUOTATIONS_DETAIL]({ commit }, params) {
    commit(MUTATIONS.GET_VIEWS_QUOTATIONS_DETAIL_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getViewsQuotations,
        variables: params
      });

      commit(MUTATIONS.GET_VIEWS_QUOTATIONS_DETAIL_SUCCESS, { list: data.getViewsQuotations });
    } catch (error) {
      commit(MUTATIONS.GET_VIEWS_QUOTATIONS_DETAIL_FAILURE, error);
    }
  },

  // Get number notification & cart item

  async [ACTIONS.GET_BADGE_NUMBERS]({ commit }, params) {
    commit(MUTATIONS.GET_BADGE_NUMBERS_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getBadgeNumbers,
        variables: params
      });

      commit(MUTATIONS.GET_BADGE_NUMBERS_SUCCESS, {
        data: data.getBadgeNumbers
      });
    } catch (error) {
      commit(MUTATIONS.GET_BADGE_NUMBERS_FAILURE, error);
    }
  },

  async [ACTIONS.GET_ALL_USER_NOTIFICATIONS_B2B]({ commit }) {
    try {
      await apolloClient.mutate({
        mutation: readAllUserNotificationsB2b,
        variables: {}
      });
      commit(MUTATIONS.GET_ALL_USER_NOTIFICATIONS_B2B_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.GET_ALL_USER_NOTIFICATIONS_B2B_FAILURE, error);
    }
  }
};
