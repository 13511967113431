import { apolloClient } from '@/main';
import { getMessage } from '@/services/shared/helper/services.js';
import { MUTATIONS } from './mutations';

import {
  addPreOrderB2b,
  cancelPreOrderB2b,
  getPreOrderItemDetailB2b,
  getPreOrdersB2b,
  updatePreOrderB2b,
  updatePreOrdersB2b
} from '@/modules/b2b/services/graphql';

export const ACTIONS = {
  GET_PREORDER: 'GET_PREORDER',
  LOAD_MORE_PREORDER: 'LOAD_MORE_PREORDER',
  RELOAD_PREORDER: 'RELOAD_PREORDER',

  GET_PREORDER_DETAIL: 'GET_PREORDER_DETAIL',
  GET_CUSTOMERS_AND_STATUS: 'GET_CUSTOMERS_AND_STATUS',

  UPDATE_PRE_ORDERS_B2B: 'UPDATE_PRE_ORDERS_B2B',
  ADD_PREORDER: 'ADD_PREORDER',

  UPDATE_PRE_ORDER_B2B: 'UPDATE_PRE_ORDER_B2B',
  CANCEL_PRE_ORDER_B2B: 'CANCEL_PRE_ORDER_B2B'
};

export default {
  // add PREORDER
  async [ACTIONS.ADD_PREORDER]({ commit }, params) {
    commit(MUTATIONS.ADD_PREORDER_REQUEST);
    try {
      await apolloClient.mutate({
        mutation: addPreOrderB2b,
        variables: params
      });
      commit(MUTATIONS.ADD_PREORDER_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.ADD_PREORDER_FAILURE, getMessage(error));
    }
  },

  async [ACTIONS.GET_PREORDER]({ commit, state }, { params, isLoadMore = false, onFinish }) {
    commit(MUTATIONS.GET_PREORDER_REQUEST, isLoadMore);

    try {
      const { data } = await apolloClient.query({
        query: getPreOrdersB2b,
        variables: { ...state.params, ...params }
      });

      // get total count
      if (state.params.isIncludeTotalCount) state.totalCount = data.getPreOrdersB2b.totalCount;

      if (onFinish) onFinish();

      commit(MUTATIONS.GET_PREORDER_SUCCESS, { list: data.getPreOrdersB2b });
    } catch (error) {
      commit(MUTATIONS.GET_PREORDER_FAILURE, error);
    }
  },
  async [ACTIONS.LOAD_MORE_PREORDER]({ commit, dispatch }, { params, isLoadMore = true, onFinish }) {
    // update search params
    commit(MUTATIONS.UPDATE_QUERY_PARAMS, { params });
    dispatch(ACTIONS.GET_PREORDER, { isLoadMore, onFinish });
  },

  async [ACTIONS.RELOAD_PREORDER]({ commit, dispatch }, variables) {
    // update search params
    commit(MUTATIONS.UPDATE_QUERY_PARAMS, {
      params: {
        isIncludeTotalCount: true,
        offset: 0,
        isLoadMore: false,
        ...variables
      }
    });

    dispatch(ACTIONS.GET_PREORDER, {});
  },

  // Get detail pre-order
  async [ACTIONS.GET_PREORDER_DETAIL]({ commit }, params) {
    commit(MUTATIONS.GET_PREORDER_DETAIL_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getPreOrderItemDetailB2b,
        variables: params
      });

      commit(MUTATIONS.GET_PREORDER_DETAIL_SUCCESS, { list: data.getPreOrderItemDetailB2b });
    } catch (error) {
      commit(MUTATIONS.GET_PREORDER_DETAIL_FAILURE, getMessage(error));
    }
  },

  // Sen invoice to pepol by order_id
  async [ACTIONS.UPDATE_PRE_ORDERS_B2B]({ commit }, params) {
    commit(MUTATIONS.UPDATE_PRE_ORDERS_B2B_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: updatePreOrdersB2b,
        variables: params
      });

      commit(MUTATIONS.UPDATE_PRE_ORDERS_B2B_SUCCESS, data.updatePreOrdersB2b);
    } catch (error) {
      commit(MUTATIONS.UPDATE_PRE_ORDERS_B2B_FAILURE, error);
    }
  },

  async [ACTIONS.UPDATE_PRE_ORDER_B2B]({ commit }, params) {
    commit(MUTATIONS.UPDATE_PRE_ORDER_B2B_REQUEST);
    try {
      await apolloClient.mutate({
        mutation: updatePreOrderB2b,
        variables: params
      });

      commit(MUTATIONS.UPDATE_PRE_ORDER_B2B_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.UPDATE_PRE_ORDER_B2B_FAILURE, error);
    }
  },

  async [ACTIONS.CANCEL_PRE_ORDER_B2B]({ commit }, params) {
    commit(MUTATIONS.CANCEL_PRE_ORDER_B2B_REQUEST);
    try {
      await apolloClient.mutate({
        mutation: cancelPreOrderB2b,
        variables: params
      });
      commit(MUTATIONS.CANCEL_PRE_ORDER_B2B_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.CANCEL_PRE_ORDER_B2B_FAILURE, error);
    }
  }
};
