import { REQUEST, SUCCESS, FAILURE } from '@/modules/b2b/services/libs/state';

export const MUTATIONS = {
  GET_PREORDER_REQUEST: 'GET_PREORDER_REQUEST',
  GET_PREORDER_SUCCESS: 'GET_PREORDER_SUCCESS',
  GET_PREORDER_FAILURE: 'GET_PREORDER_FAILURE',

  UPDATE_QUERY_PARAMS: 'UPDATE_QUERY_PARAMS',

  GET_PREORDER_DETAIL_REQUEST: 'GET_PREORDER_DETAIL_REQUEST',
  GET_PREORDER_DETAIL_SUCCESS: 'GET_PREORDER_DETAIL_SUCCESS',
  GET_PREORDER_DETAIL_FAILURE: 'GET_PREORDER_DETAIL_FAILURE',

  UPDATE_PRE_ORDERS_B2B_REQUEST: 'UPDATE_PRE_ORDERS_B2B_REQUEST',
  UPDATE_PRE_ORDERS_B2B_SUCCESS: 'UPDATE_PRE_ORDERS_B2B_SUCCESS',
  UPDATE_PRE_ORDERS_B2B_FAILURE: 'UPDATE_PRE_ORDERS_B2B_FAILURE',

  ADD_PREORDER_REQUEST: 'ADD_PREORDER_REQUEST',
  ADD_PREORDER_SUCCESS: 'ADD_PREORDER_SUCCESS',
  ADD_PREORDER_FAILURE: 'ADD_PREORDER_FAILURE',

  UPDATE_PRE_ORDER_B2B_REQUEST: 'UPDATE_PRE_ORDER_B2B_REQUEST',
  UPDATE_PRE_ORDER_B2B_SUCCESS: 'UPDATE_PRE_ORDER_B2B_SUCCESS',
  UPDATE_PRE_ORDER_B2B_FAILURE: 'UPDATE_PRE_ORDER_B2B_FAILURE',

  CANCEL_PRE_ORDER_B2B_REQUEST: 'CANCEL_PRE_ORDER_B2B_REQUEST',
  CANCEL_PRE_ORDER_B2B_SUCCESS: 'CANCEL_PRE_ORDER_B2B_SUCCESS',
  CANCEL_PRE_ORDER_B2B_FAILURE: 'CANCEL_PRE_ORDER_B2B_FAILURE'
};

export default {
  // update pre-order b2b
  [MUTATIONS.ADD_PREORDER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.ADD_PREORDER_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.ADD_PREORDER_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.GET_PREORDER_REQUEST](state, isLoadMore) {
    REQUEST(state);
    state.isLoadMore = isLoadMore;
  },
  [MUTATIONS.GET_PREORDER_SUCCESS](state, { list }) {
    SUCCESS(state);
    if (state.isLoadMore) {
      const data = [...state.list.data, ...list.data];
      state.list = {
        ...list,
        data
      };
    } else {
      state.list = list;
    }
  },
  [MUTATIONS.GET_PREORDER_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.UPDATE_QUERY_PARAMS](state, { params }) {
    state.params = {
      ...state.params,
      ...params
    };
  },

  // get pre-order detail
  [MUTATIONS.GET_PREORDER_DETAIL_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_PREORDER_DETAIL_SUCCESS](state, detailPreOrder) {
    SUCCESS(state);
    state.detailPreOrder = detailPreOrder;
  },
  [MUTATIONS.GET_PREORDER_DETAIL_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // update pre-order b2b
  [MUTATIONS.UPDATE_PRE_ORDERS_B2B_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.UPDATE_PRE_ORDERS_B2B_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.UPDATE_PRE_ORDERS_B2B_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // update pre-order b2b
  [MUTATIONS.UPDATE_PRE_ORDER_B2B_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.UPDATE_PRE_ORDER_B2B_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.UPDATE_PRE_ORDER_B2B_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // update pre-order b2b
  [MUTATIONS.CANCEL_PRE_ORDER_B2B_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.CANCEL_PRE_ORDER_B2B_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.CANCEL_PRE_ORDER_B2B_FAILURE](state, error) {
    FAILURE(state, error);
  }
};
