import { DEFAULT_GETTERS } from '@/modules/b2b/constants';

export default {
  ...DEFAULT_GETTERS,
  list: (state) => state.list,
  totalCount: (state) => state.totalCount,
  params: (state) => state.params,
  detailPreorder: (state) => state.detailPreorder?.list,
  customerAndStatus: (state) => state.customerAndStatus?.list,
  detailPreOrder: (state) => state.detailPreOrder?.list
};
