import { customerGuard } from './guards';

const b2bRoutes = [
  {
    path: '/b2b/select-customer',
    name: 'select-customer',
    component: () => import('./views/customer/SelectCustomerPage'),
    beforeEnter: customerGuard
  },
  {
    path: '/b2b',
    redirect: '/b2b/main',
    beforeEnter: customerGuard
  },
  {
    path: '/b2b/main',
    component: () => import('./views/Tabs'),
    beforeEnter: customerGuard,
    children: [
      {
        path: '',
        name: 'b2b/home',
        redirect: '/b2b/main/home'
      },
      {
        path: '/b2b/main/home',
        component: () => import('./views/home/Home'),
        name: 'home'
      }
    ]
  },
  {
    path: '/b2b/main/product-detail/error',
    component: () => import('./views/product-detail/components/ErrorPage.vue'),
    beforeEnter: customerGuard
  },
  {
    path: '/b2b/main/product-detail/:id',
    component: () => import('./views/product-detail'),
    beforeEnter: customerGuard
  },
  {
    path: '/b2b/order',
    component: () => import('./views/Tabs'),
    beforeEnter: customerGuard,
    children: [
      {
        path: '',
        redirect: '/b2b/order/purchase'
      },
      {
        path: '/b2b/order/purchase',
        component: () => import('./views/order/Purchase'),
        name: 'Purchase'
      }
    ]
  },
  {
    path: '/b2b/order/purchase/order-detail/:order_id',
    component: () => import('../shared/views/orders/order-detail/ViewOrder'),
    name: 'b2b_order_detail'
  },
  {
    path: '/b2b/order/purchase/track-order/:order_id',
    component: () => import('../shared/views/orders/order-detail/TrackOrder'),
    name: 'b2b_track_order'
  },
  {
    path: '/b2b/cart',
    component: () => import('./views/Tabs'),
    beforeEnter: customerGuard,
    children: [
      {
        path: '',
        component: () => import('./views/cart/Cart'),
        name: 'cart'
      }
    ]
  },
  {
    path: '/b2b/account',
    component: () => import('./views/Tabs'),
    beforeEnter: customerGuard,
    children: [
      {
        path: '',
        component: () => import('./views/account/Account'),
        name: 'account'
      }
    ]
  },
  {
    path: '/b2b/update-profile',
    component: () => import('./views/Tabs'),
    beforeEnter: customerGuard,
    children: [
      {
        path: '',
        component: () => import('./views/account/UpdateProfile')
      }
    ]
  },
  {
    path: '/b2b/connect-peppol',
    component: () => import('./views/Tabs'),
    beforeEnter: customerGuard,
    children: [
      {
        path: '',
        component: () => import('./views/account/ConnectPeppol')
      }
    ]
  },
  {
    path: '/b2b/request-product',
    component: () => import('./views/Tabs'),
    beforeEnter: customerGuard,
    children: [
      {
        path: '',
        component: () => import('./views/account/RequestProduct')
      }
    ]
  },
  {
    path: '/b2b/change-password',
    component: () => import('./views/Tabs'),
    beforeEnter: customerGuard,
    children: [
      {
        path: '',
        component: () => import('./views/account/ChangePassword')
      }
    ]
  },
  {
    path: '/b2b/customers',
    component: () => import('./views/customer/SelectCustomerPage'),
    beforeEnter: customerGuard
  },
  {
    path: '/b2b/customers/add',
    component: () => import('./views/Tabs'),
    beforeEnter: customerGuard,
    children: [
      {
        path: '',
        component: () => import('./views/customer/AddCustomer')
      }
    ]
  },
  {
    path: '/b2b/customers/add/no-companies',
    name: 'B2BNoCompanies',
    component: () => import('./views/customer/AddCustomer')
  },
  {
    path: '/b2b/customers/:id',
    component: () => import('./views/Tabs'),
    beforeEnter: customerGuard,
    children: [
      {
        path: '',
        component: () => import('./views/customer/CustomerDetail')
      }
    ]
  },
  {
    path: '/b2b/about',
    name: 'B2BAbout',
    component: () => import('@/modules/shared/views/AboutApp')
  },
  {
    path: '/b2b/add-company',
    name: 'B2BAddCompany',
    component: () => import('./views/customer/AddCustomer')
  },
  {
    path: '/b2b/invoices/select-invoices',
    name: 'select-invoices-b2b',
    component: () => import('./views/invoices/select-invoices/index.vue'),
    beforeEnter: customerGuard
  },
  {
    path: '/b2b/invoices/select-payment',
    name: 'select-payment-b2b',
    component: () => import('./views/invoices/select-payment/index.vue'),
    beforeEnter: customerGuard
  },
  {
    path: '/b2b/invoices/payment-details',
    name: 'payment-details-b2b',
    component: () => import('./views/invoices/payment-details/index.vue'),
    beforeEnter: customerGuard
  },
  {
    path: '/b2b/payment-transaction/completed',
    name: 'PaymentTransactionCompleted',
    component: () => import('./views/cart/components/PaymentTransactionCompleted')
  },
  {
    path: '/b2b/payment-transaction/:orderId',
    name: 'PaymentTransactionDetails',
    beforeEnter: customerGuard,
    component: () => import('./views/cart/components/PaymentTransactionDetails')
  },
  {
    path: '/b2b/notifications',
    beforeEnter: customerGuard,
    component: () => import('./views/Tabs'),
    children: [
      {
        path: '',
        redirect: '/b2b/notifications/all'
      },
      {
        path: '/b2b/notifications/all',
        component: () => import('./views/notifications'),
        name: 'NotificationsB2b'
      }
    ]
  },
  {
    path: '/b2b/search-products',
    name: 'b2bSearchProducts',
    component: () => import('./views/home/components/search-page/index')
  }
];

export default b2bRoutes;
