import gql from 'graphql-tag';

export const getOrderDetails = gql`
  query getOrderDetails($orderId: Int) {
    getOrderDetails(orderId: $orderId) {
      id
      order_status
      delivery_date
      delivery_time
      delivery_address
      delivery_name
      delivery_instruction
      billing_address
      billing_name
      payment_method
      po_number
      total_price
      total_tax
      description
      card_info {
        exp_month
        exp_year
        last4
        brand
      }
      orderStatus {
        id
        name
        description
      }
      last_order_log_info {
        status
        created_at
        reason
        changed_by
        images
        tracking_link
        app_name
      }
      customer_credit_term {
        displayName
        termPeriod
        gracePeriod
        customerCreditTermId
        tenantCreditTermId
      }
      orderItems {
        id
        invoice_id
        order_id
        sku_id
        user_id
        customer_buyer_id
        order_item_status_id
        order_item_status
        customer_seller_id
        amount_qty
        total_qty
        origin_unit_price
        sale_unit_price
        total_price
        tax
        catch_weight
        is_catch_weight
        active
        cancelled
        product
        sku_name
        oom
        image
        tenant_name
        customer_name
        account_number
        alias_name
        minimum_order
        tenant_id
        user_name
        mobile
        stripe_card_id
        delivery_status
        is_order_by_weight
        billing_address
        delivery_address
        dispute_status
        dispute_reason
        dispute_remedy
        increment_qty
        description
        halal
      }
    }
  }
`;

export const getOrderStatusLogs = gql`
  query getOrderStatusLogs($orderId: Int) {
    getOrderStatusLogs(orderId: $orderId) {
      status
      created_at
      reason
      changed_by
      images
      tracking_link
      app_name
    }
  }
`;
