import { FAILURE, REQUEST, SUCCESS } from '@/modules/b2b/services/libs/state';
export const MUTATIONS = {
  CHECK_FAVORITE_SKUS_REQUEST: 'CHECK_FAVORITE_SKUS_REQUEST',
  CHECK_FAVORITE_SKUS_SUCCESS: 'CHECK_FAVORITE_SKUS_SUCCESS',
  CHECK_FAVORITE_SKUS_FAILURE: 'CHECK_FAVORITE_SKUS_FAILURE',

  GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',

  GET_PRODUCTS_RECOMMEND_REQUEST: 'GET_PRODUCTS_RECOMMEND_REQUEST',
  GET_PRODUCTS_RECOMMEND_SUCCESS: 'GET_PRODUCTS_RECOMMEND_SUCCESS',
  GET_PRODUCTS_RECOMMEND_FAILURE: 'GET_PRODUCTS_RECOMMEND_FAILURE',
  RESET_PRODUCT_RECOMMEND: 'RESET_PRODUCT_RECOMMEND',

  GET_MORE_PRODUCTS_REQUEST: 'GET_MORE_PRODUCTS_REQUEST',
  GET_MORE_PRODUCTS_SUCCESS: 'GET_MORE_PRODUCTS_SUCCESS',
  GET_MORE_PRODUCTS_FAILURE: 'GET_MORE_PRODUCTS_FAILURE',

  UPDATE_SEARCH: 'UPDATE_SEARCH',
  CHOOSE_PRODUCT: 'CHOOSE_PRODUCT',

  ADD_FAVORITE_REQUEST: 'ADD_FAVORITE_REQUEST',
  ADD_FAVORITE_SUCCESS: 'ADD_FAVORITE_SUCCESS',
  ADD_FAVORITE_FAILURE: 'ADD_FAVORITE_FAILURE',

  REMOVE_FAVORITE_REQUEST: 'REMOVE_FAVORITE_REQUEST',
  REMOVE_FAVORITE_SUCCESS: 'REMOVE_FAVORITE_SUCCESS',
  REMOVE_FAVORITE_FAILURE: 'REMOVE_FAVORITE_FAILURE',

  REQUEST_QUOTATION_REQUEST: 'REQUEST_QUOTATION_REQUEST',
  REQUEST_QUOTATION_SUCCESS: 'REQUEST_QUOTATION_SUCCESS',
  REQUEST_QUOTATION_FAILURE: 'REQUEST_QUOTATION_FAILURE',

  RESET_CATEGORY: 'RESET_CATEGORY',

  CHANGE_ALL_CATEGORY: 'CHANGE_ALL_CATEGORY',
  GET_PRODUCTS_PRICES_SUCCESS: 'GET_PRODUCTS_PRICES_SUCCESS',

  GET_PRODUCT_DETAILS_SUCCESS: 'GET_PRODUCT_DETAILS_SUCCESS',
  GET_MORE_PRODUCT_DETAILS_SUCCESS: 'GET_MORE_PRODUCT_DETAILS_SUCCESS'
};

export default {
  // check favorite skus
  [MUTATIONS.CHECK_FAVORITE_SKUS_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.CHECK_FAVORITE_SKUS_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.CHECK_FAVORITE_SKUS_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // request quotation
  [MUTATIONS.REQUEST_QUOTATION_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.REQUEST_QUOTATION_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.REQUEST_QUOTATION_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // add favorite
  [MUTATIONS.ADD_FAVORITE_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.ADD_FAVORITE_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.ADD_FAVORITE_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // remove favorite
  [MUTATIONS.REMOVE_FAVORITE_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.REMOVE_FAVORITE_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.REMOVE_FAVORITE_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // get products
  [MUTATIONS.GET_PRODUCTS_REQUEST](state, isLoadMore) {
    state.isLoadMore = isLoadMore;

    // reset old product list
    REQUEST(state);
    if (!isLoadMore) {
      state.list.items = [];
    }
  },
  async [MUTATIONS.GET_PRODUCTS_SUCCESS](state, { list }) {
    SUCCESS(state);
    state.getProductStatus = 'Success';

    if (state.search.buyerId != list.buyerId) {
      return;
    }

    if (state.isLoadMore) {
      const items = [...state.list.items, ...list.items];
      state.list = {
        ...list,
        items
      };
      state.list.category = state.listCache;
    } else {
      state.list = list;
      state.listCache = state.list.category;
    }

    state.type = null;
  },
  [MUTATIONS.GET_PRODUCTS_FAILURE](state, error) {
    FAILURE(state, error);
    state.getProductStatus = error?.getProductStatus;
  },

  // get more products in search page
  [MUTATIONS.GET_MORE_PRODUCTS_REQUEST](state, isLoadMore) {
    state.isLoadMore = isLoadMore;

    // reset old product list
    REQUEST(state);
    if (!isLoadMore) {
      state.moreProductList.items = [];
    }
  },
  async [MUTATIONS.GET_MORE_PRODUCTS_SUCCESS](state, { moreProductList }) {
    SUCCESS(state);
    state.getProductStatus = 'Success';

    if (state.searchMore.buyerId != moreProductList.buyerId) {
      return;
    }

    if (state.isLoadMore) {
      const items = [...state.moreProductList.items, ...moreProductList.items];
      state.moreProductList = {
        ...moreProductList,
        items
      };
      state.moreProductList.category = state.listCache;
    } else {
      state.moreProductList = moreProductList;
      state.listCache = state.moreProductList.category;
    }

    state.type = null;
  },
  [MUTATIONS.GET_MORE_PRODUCTS_FAILURE](state, error) {
    FAILURE(state, error);
    state.getProductStatus = error?.getProductStatus;
  },

  // get products recommend
  [MUTATIONS.GET_PRODUCTS_RECOMMEND_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_PRODUCTS_RECOMMEND_SUCCESS](state, listOosProduct) {
    SUCCESS(state);
    state.listOosProduct = listOosProduct;
    state.type = null;
  },
  [MUTATIONS.RESET_PRODUCT_RECOMMEND](state) {
    SUCCESS(state);
    state.listOosProduct = null;
    state.type = null;
  },
  [MUTATIONS.GET_PRODUCTS_RECOMMEND_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // search
  [MUTATIONS.UPDATE_SEARCH](state, { search, isLoadMore, type }) {
    state.search = {
      ...state.search,
      ...search
    };
    state.isLoadMore = isLoadMore;
    if (type) {
      state.type = type;
    }
  },

  // choose product
  [MUTATIONS.CHOOSE_PRODUCT](state, item) {
    state.item = item;
  },
  [MUTATIONS.RESET_CATEGORY](state) {
    state.resetCategory = !state.resetCategory;
  },
  //change all categories
  [MUTATIONS.CHANGE_ALL_CATEGORY](state) {
    state.isChangeAllCategory += state.isChangeAllCategory;
  },

  // get products prices to update back to home page
  [MUTATIONS.GET_PRODUCTS_PRICES_SUCCESS](state, productPrices) {
    // update value for state Item
    state.list.items.forEach((item) => {
      const productPrice = productPrices.find((obj) => obj.id === item.id);
      if (productPrice) {
        const skusData = productPrice.skusData;
        Reflect.deleteProperty(productPrice, 'skusData');
        Object.assign(item, productPrice);

        item.skusData.forEach((data) => {
          const skuPrice = skusData?.find((obj) => obj.sku_id === data.sku_id);
          if (skuPrice) {
            Object.assign(data, skuPrice);
          }
        });
      }
    });
  },

  [MUTATIONS.GET_PRODUCT_DETAILS_SUCCESS](state, additionalInfos) {
    // update value for state Item
    if (additionalInfos) {
      state.list.items.forEach((item) => {
        const productAdditionalInfos = additionalInfos.find((obj) => obj.id === item.id);
        if (productAdditionalInfos) {
          item.productAdditionalInfos = productAdditionalInfos;
        }
      });
    }
  },

  [MUTATIONS.GET_MORE_PRODUCT_DETAILS_SUCCESS](state, additionalInfos) {
    // update value for state Item in More product list
    if (additionalInfos) {
      state.moreProductList.items.forEach((item) => {
        const productAdditionalInfos = additionalInfos.find((obj) => obj.id === item.id);
        if (productAdditionalInfos) {
          item.productAdditionalInfos = productAdditionalInfos;
        }
      });
    }
  }
};
