import { DEFAULT_GETTERS } from '@/modules/b2b/constants';

export default {
  ...DEFAULT_GETTERS,
  list: (state) => state.list || [],
  totalCount: (state) => state.totalCount,
  listQuotation: (state) => state.listQuotation || [],
  totalCountQuotation: (state) => state.totalCountQuotation,
  item: (state) => state.item.list?.data[0],
  numberBadge: (state) => state.numberBadge?.data
};
