import { MUTATIONS } from './mutations';
import { apolloClient } from '@/main';
import {
  getStatusCustomerJoinTenant,
  saleGetUserCustomerList,
  saleGetUserCustomerBranch,
  getUnconnectedTenant,
  getViewsCustomerTenants,
  addCustomerTenantJoinRequest,
  saleGetCustomerDetail,
  saleGetCustomerView,
  getCustomerImage,
  getSubBuyers,
  getGroupsAndCountriesAndDays,
  updateCustomer,
  getCustomerTenants,
  addPerson,
  addAddress,
  updateAddress,
  hoursAdd,
  addSubBuyer,
  updateSubBuyer,
  addCustomer,
  addBranch,
  addCustomerImage,
  updateCustomerImage,
  deletePersonB2b,
  getCustomerDetails
} from '@/modules/sale/services/graphql';

export const ACTIONS = {
  GET_STATUS_CUSTOMER: 'GET_STATUS_CUSTOMER',
  RELOAD_CUSTOMERS: 'RELOAD_CUSTOMERS',
  LOAD_MORE_CUSTOMERS: 'LOAD_MORE_CUSTOMERS',
  GET_CUSTOMERS: 'GET_CUSTOMERS',
  GET_CUSTOMERS_BRANCH: 'GET_CUSTOMERS_BRANCH',
  GET_UNCONNECTED_TENANT: 'GET_UNCONNECTED_TENANT',
  GET_VIEWS_CUSTOMER_TENANTS: 'GET_VIEWS_CUSTOMER_TENANTS',
  ADD_CUSTOMER_TENANT_JOIN_REQUEST: 'ADD_CUSTOMER_TENANT_JOIN_REQUEST',
  GET_CUSTOMER_VIEW: 'GET_CUSTOMER_VIEW',
  GET_CUSTOMER_DETAIL_SALE: 'GET_CUSTOMER_DETAIL_SALE',
  GET_CUSTOMER_IMAGE: 'GET_CUSTOMER_IMAGE',
  GET_SUB_BUYERS: 'GET_SUB_BUYERS',
  GET_GROUPS_AND_COUNTRIES_AND_DAYS: 'GET_GROUPS_AND_COUNTRIES_AND_DAYS',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  GET_CUSTOMER_TENANTS: 'GET_CUSTOMER_TENANTS',
  ADD_PERSON: 'ADD_PERSON',
  ADD_ADDRESS: 'ADD_ADDRESS',
  UPDATE_ADDRESS: 'UPDATE_ADDRESS',
  HOURS_ADD: 'HOURS_ADD',
  ADD_SUB_BUYER: 'ADD_SUB_BUYER',
  UPDATE_SUB_BUYER: 'UPDATE_SUB_BUYER',
  ADD_CUSTOMER: 'ADD_CUSTOMER',
  UPDATE_QUERY_PARAMS: 'UPDATE_QUERY_PARAMS',
  GET_HEAD_COMPANY: 'GET_HEAD_COMPANY',
  ADD_BRANCH: 'ADD_BRANCH',
  ADD_CUSTOMER_IMAGE: 'ADD_CUSTOMER_IMAGE',
  UPDATE_CUSTOMER_IMAGE: 'UPDATE_CUSTOMER_IMAGE',

  RESET_CUSTOMER_IMAGE: 'RESET_CUSTOMER_IMAGE',
  // Reset error and status back to default value
  RESET_STATUS_ERROR: 'RESET_STATUS_ERROR',
  UPDATE_SELECTED_CUSTOMER: 'UPDATE_SELECTED_CUSTOMER',
  DELETE_PERSON: 'DELETE_PERSON',
  GET_CUSTOMER_DETAILS: 'GET_CUSTOMER_DETAILS'
};

export default {
  [ACTIONS.UPDATE_SELECTED_CUSTOMER]({ commit }, selectedCustomer) {
    commit(MUTATIONS.UPDATE_SELECTED_CUSTOMER, selectedCustomer);
  },

  // GET CUSTOMER VIEW
  async [ACTIONS.GET_CUSTOMER_VIEW]({ commit }, params) {
    commit(MUTATIONS.GET_CUSTOMER_VIEW_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: saleGetCustomerView,
        variables: params
      });
      commit(MUTATIONS.GET_CUSTOMER_VIEW_SUCCESS, data.saleGetCustomerView);
    } catch (error) {
      commit(MUTATIONS.GET_CUSTOMER_VIEW_FAILURE, error);
    }
  },

  async [ACTIONS.GET_STATUS_CUSTOMER]({ commit }, params) {
    commit(MUTATIONS.GET_STATUS_CUSTOMER_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getStatusCustomerJoinTenant,
        variables: params
      });
      commit(MUTATIONS.GET_STATUS_CUSTOMER_SUCCESS, data.getStatusCustomerJoinTenant);
    } catch (error) {
      commit(MUTATIONS.GET_STATUS_CUSTOMER_FAILURE, error);
    }
  },

  async [ACTIONS.GET_CUSTOMERS]({ commit, state }, { params, isLoadMore = false, onFinish }) {
    commit(MUTATIONS.GET_CUSTOMERS_REQUEST, isLoadMore);
    try {
      const { data } = await apolloClient.query({
        query: saleGetUserCustomerList,
        variables: {
          ...state.params,
          ...params
        }
      });

      // get total count
      if (state.params.isIncludeTotalCount) state.totalCount = data.saleGetUserCustomerList.totalCount;

      if (onFinish) onFinish();
      commit(MUTATIONS.GET_CUSTOMERS_SUCCESS, { list: data.saleGetUserCustomerList });
    } catch (error) {
      commit(MUTATIONS.GET_CUSTOMERS_FAILURE, error);
    }
  },

  // get user customer branch
  async [ACTIONS.GET_CUSTOMERS_BRANCH]({ commit }, params) {
    commit(MUTATIONS.GET_CUSTOMERS_BRANCH_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: saleGetUserCustomerBranch,
        variables: params
      });
      commit(MUTATIONS.GET_CUSTOMERS_BRANCH_SUCCESS, data.saleGetUserCustomerBranch);
    } catch (error) {
      commit(MUTATIONS.GET_CUSTOMERS_BRANCH_FAILURE, error);
    }
  },

  async [ACTIONS.LOAD_MORE_CUSTOMERS]({ commit, dispatch }, { params, isLoadMore = true, onFinish }) {
    // update search params
    commit(MUTATIONS.UPDATE_QUERY_PARAMS, { params });
    dispatch(ACTIONS.GET_CUSTOMERS, { isLoadMore, onFinish });
  },
  async [ACTIONS.RELOAD_CUSTOMERS]({ commit, dispatch }, keySearch) {
    // update search params
    commit(MUTATIONS.UPDATE_QUERY_PARAMS, {
      params: {
        isIncludeTotalCount: true,
        offset: 0,
        isLoadMore: false,
        searchQueries: keySearch
      }
    });
    dispatch(ACTIONS.GET_CUSTOMERS, {});
  },
  // get view customer tenant
  async [ACTIONS.GET_VIEWS_CUSTOMER_TENANTS]({ commit }, params) {
    commit(MUTATIONS.GET_VIEWS_CUSTOMER_TENANTS_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getViewsCustomerTenants,
        variables: params
      });
      commit(MUTATIONS.GET_VIEWS_CUSTOMER_TENANTS_SUCCESS, data.getViewsCustomerTenants);
    } catch (error) {
      commit(MUTATIONS.GET_VIEWS_CUSTOMER_TENANTS_FAILURE, error);
    }
  },
  // get un-connected tenant
  async [ACTIONS.GET_UNCONNECTED_TENANT]({ commit }, params) {
    commit(MUTATIONS.GET_UNCONNECTED_TENANT_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getUnconnectedTenant,
        variables: params
      });

      commit(MUTATIONS.GET_UNCONNECTED_TENANT_SUCCESS, data.getUnconnectedTenant);
    } catch (error) {
      commit(MUTATIONS.GET_UNCONNECTED_TENANT_FAILURE, error);
    }
  },
  // get un-connected tenant
  async [ACTIONS.ADD_CUSTOMER_TENANT_JOIN_REQUEST]({ commit }, params) {
    commit(MUTATIONS.ADD_CUSTOMER_TENANT_JOIN_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: addCustomerTenantJoinRequest,
        variables: params
      });
      commit(MUTATIONS.ADD_CUSTOMER_TENANT_JOIN_SUCCESS, data.addCustomerTenantJoinRequest);
    } catch (error) {
      commit(MUTATIONS.ADD_CUSTOMER_TENANT_JOIN_FAILURE, error);
    }
  },

  // get customer detail buyer
  async [ACTIONS.GET_CUSTOMER_DETAIL_SALE]({ commit }, params) {
    commit(MUTATIONS.GET_CUSTOMER_DETAIL_SALE_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: saleGetCustomerDetail,
        variables: params
      });
      commit(MUTATIONS.GET_CUSTOMER_DETAIL_SALE_SUCCESS, data.saleGetCustomerDetail);
    } catch (error) {
      commit(MUTATIONS.GET_CUSTOMER_DETAIL_SALE_FAILURE, error);
    }
  },

  // get customer image
  async [ACTIONS.GET_CUSTOMER_IMAGE]({ commit }, params) {
    commit(MUTATIONS.GET_CUSTOMER_IMAGE_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: getCustomerImage,
        variables: params
      });
      commit(MUTATIONS.GET_CUSTOMER_IMAGE_SUCCESS, data.saleGetCustomerImages);
    } catch (error) {
      commit(MUTATIONS.GET_CUSTOMER_IMAGE_FAILURE, error);
    }
  },

  // get sub buyers
  async [ACTIONS.GET_SUB_BUYERS]({ commit }, params) {
    commit(MUTATIONS.GET_SUB_BUYERS_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: getSubBuyers,
        variables: params
      });
      commit(MUTATIONS.GET_SUB_BUYERS_SUCCESS, data.getSubBuyers);
    } catch (error) {
      commit(MUTATIONS.GET_SUB_BUYERS_FAILURE, error);
    }
  },

  // get groups and countries and days
  async [ACTIONS.GET_GROUPS_AND_COUNTRIES_AND_DAYS]({ commit }) {
    commit(MUTATIONS.GET_GROUPS_AND_COUNTRIES_AND_DAYS_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: getGroupsAndCountriesAndDays
      });
      commit(MUTATIONS.GET_GROUPS_AND_COUNTRIES_AND_DAYS_SUCCESS, data.getGroupsAndCountriesAndDays);
    } catch (error) {
      commit(MUTATIONS.GET_GROUPS_AND_COUNTRIES_AND_DAYS_FAILURE, error);
    }
  },

  // update customer
  async [ACTIONS.UPDATE_CUSTOMER]({ commit }, params) {
    commit(MUTATIONS.UPDATE_CUSTOMER_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: updateCustomer,
        variables: params
      });
      if (data.updateCustomer) {
        commit(MUTATIONS.UPDATE_CUSTOMER_SUCCESS, data.updateCustomer);
      }
    } catch (error) {
      commit(MUTATIONS.UPDATE_CUSTOMER_FAILURE, error);
    }
  },

  // get customer tenants
  async [ACTIONS.GET_CUSTOMER_TENANTS]({ commit }, params) {
    commit(MUTATIONS.GET_CUSTOMER_TENANTS_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: getCustomerTenants,
        variables: params
      });
      commit(MUTATIONS.GET_CUSTOMER_TENANTS_SUCCESS, data.getCustomerTenants);
    } catch (error) {
      commit(MUTATIONS.GET_CUSTOMER_TENANTS_FAILURE, error);
    }
  },

  // add person
  async [ACTIONS.ADD_PERSON]({ commit }, params) {
    commit(MUTATIONS.ADD_PERSON_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: addPerson,
        variables: params
      });
      if (!errors) {
        commit(MUTATIONS.ADD_PERSON_SUCCESS, data.addPerson);
      } else {
        commit(MUTATIONS.ADD_PERSON_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.ADD_PERSON_FAILURE, error);
    }
  },

  // add address
  async [ACTIONS.ADD_ADDRESS]({ commit }, params) {
    commit(MUTATIONS.ADD_ADDRESS_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: addAddress,
        variables: params
      });
      if (!errors) {
        commit(MUTATIONS.ADD_ADDRESS_SUCCESS, data.addPerson);
      } else {
        commit(MUTATIONS.ADD_ADDRESS_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.ADD_PERSON_FAILURE, error);
    }
  },

  // update address
  async [ACTIONS.UPDATE_ADDRESS]({ commit }, params) {
    commit(MUTATIONS.UPDATE_ADDRESS_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: updateAddress,
        variables: params
      });
      if (!errors) {
        commit(MUTATIONS.UPDATE_ADDRESS_SUCCESS, data.updateAddressB2b);
      } else {
        commit(MUTATIONS.UPDATE_ADDRESS_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.UPDATE_ADDRESS_FAILURE, error);
    }
  },

  // hours add
  async [ACTIONS.HOURS_ADD]({ commit }, { variables }) {
    commit(MUTATIONS.HOURS_ADD_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: hoursAdd,
        variables
      });
      if (!errors) {
        commit(MUTATIONS.HOURS_ADD_SUCCESS, data.updateAddressB2b);
      } else {
        commit(MUTATIONS.HOURS_ADD_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.HOURS_ADD_FAILURE, error);
    }
  },

  // add sub buyer
  async [ACTIONS.ADD_SUB_BUYER]({ commit, dispatch }, { variables, supplierId }) {
    commit(MUTATIONS.ADD_SUB_BUYER_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: addSubBuyer,
        variables
      });
      if (!errors) {
        commit(MUTATIONS.ADD_SUB_BUYER_SUCCESS, data.updateAddressB2b);
        dispatch(ACTIONS.GET_SUB_BUYERS, { supplierId });
      } else {
        commit(MUTATIONS.ADD_SUB_BUYER_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.ADD_SUB_BUYER_FAILURE, error);
    }
  },

  // update sub buyer
  async [ACTIONS.UPDATE_SUB_BUYER]({ commit }, params) {
    commit(MUTATIONS.UPDATE_SUB_BUYER_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: updateSubBuyer,
        variables: params
      });
      if (!errors) {
        commit(MUTATIONS.UPDATE_SUB_BUYER_SUCCESS, data.updateAddressB2b);
      } else {
        commit(MUTATIONS.UPDATE_SUB_BUYER_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.UPDATE_SUB_BUYER_FAILURE, error);
    }
  },

  // add customer
  async [ACTIONS.ADD_CUSTOMER]({ commit }, params) {
    commit(MUTATIONS.ADD_CUSTOMER_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: addCustomer,
        variables: params
      });
      if (!errors) {
        commit(MUTATIONS.ADD_CUSTOMER_SUCCESS, data.saleAddCustomer);
      } else {
        commit(MUTATIONS.ADD_CUSTOMER_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.ADD_CUSTOMER_FAILURE, error);
    }
  },

  // get head company
  async [ACTIONS.GET_HEAD_COMPANY]({ commit }, params) {
    commit(MUTATIONS.GET_HEAD_COMPANY_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: saleGetCustomerDetail,
        variables: params
      });
      commit(MUTATIONS.GET_HEAD_COMPANY_SUCCESS, data.saleGetCustomerDetail);
    } catch (error) {
      commit(MUTATIONS.GET_HEAD_COMPANY_FAILURE, error);
    }
  },

  // add branch
  async [ACTIONS.ADD_BRANCH]({ commit }, params) {
    commit(MUTATIONS.ADD_BRANCH_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: addBranch,
        variables: params
      });
      commit(MUTATIONS.ADD_BRANCH_SUCCESS, data.addBranch);
    } catch (error) {
      commit(MUTATIONS.ADD_BRANCH_FAILURE, error);
    }
  },

  // add customer image
  async [ACTIONS.ADD_CUSTOMER_IMAGE]({ commit }, params) {
    commit(MUTATIONS.ADD_CUSTOMER_IMAGE_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: addCustomerImage,
        variables: params
      });
      commit(MUTATIONS.ADD_CUSTOMER_IMAGE_SUCCESS, data.saleAddCustomerImage);
    } catch (error) {
      commit(MUTATIONS.ADD_CUSTOMER_IMAGE_FAILURE, error);
    }
  },

  // update customer image
  async [ACTIONS.UPDATE_CUSTOMER_IMAGE]({ commit }, params) {
    commit(MUTATIONS.UPDATE_CUSTOMER_IMAGE_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: updateCustomerImage,
        variables: params
      });
      commit(MUTATIONS.UPDATE_CUSTOMER_IMAGE_SUCCESS, data.saleUpdateCustomerImage);
    } catch (error) {
      commit(MUTATIONS.UPDATE_CUSTOMER_IMAGE_FAILURE, error);
    }
  },

  // delete person
  async [ACTIONS.DELETE_PERSON]({ commit }, params) {
    commit(MUTATIONS.DELETE_PERSON_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: deletePersonB2b,
        variables: params
      });
      commit(MUTATIONS.DELETE_PERSON_SUCCESS, data.deletePersonB2b);
    } catch (error) {
      commit(MUTATIONS.DELETE_PERSON_FAILURE, error);
    }
  },

  // update query params
  async [ACTIONS.UPDATE_QUERY_PARAMS]({ commit }, params) {
    // update search params
    commit(MUTATIONS.UPDATE_QUERY_PARAMS, { params });
  },
  // reset customer image
  async [ACTIONS.RESET_CUSTOMER_IMAGE]({ commit }) {
    commit(MUTATIONS.RESET_CUSTOMER_IMAGE);
  },
  // reset status error back to default value
  async [ACTIONS.RESET_STATUS_ERROR]({ commit }) {
    commit(MUTATIONS.RESET_STATUS_ERROR);
  },
  async [ACTIONS.GET_CUSTOMER_DETAILS]({ commit }, params) {
    try {
      const { data } = await apolloClient.query({
        query: getCustomerDetails,
        variables: params
      });
      commit(MUTATIONS.GET_CUSTOMER_DETAILS_SUCCESS, { customerDetails: data.getCustomerDetails });
    } catch (error) {
      commit(MUTATIONS.GET_CUSTOMER_DETAILS_FAILURE, error);
    }
  }
};
