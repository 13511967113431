import { i18n } from '@/main.js';
import { displayPrice } from '@/modules/b2b/services/libs/helper';
import { ref } from 'vue';
import { MAX_INPUT, USER_TYPE } from '../../shared/constants/common';

export const useLoadingSpinner = () => {
  const isLoading = ref(false);
  const changeLoadingStatus = () => {
    isLoading.value = !isLoading.value;
  };
  const loadingProgress = async (callback) => {
    changeLoadingStatus();
    callback().finally(() => {
      changeLoadingStatus();
    });
  };

  return {
    isLoading,
    loadingProgress,
    changeLoadingStatus
  };
};

export const useCheck = () => {
  const checkWeightItem = (quantity, unit) => {
    if (quantity) {
      return quantity + ' ' + unit;
    }
    return '';
  };
  const combineTwoUnit = (first, second) => first + (second && ' x ' + second);

  const isItemKGAndNoneUnit = (itemType, unit) => {
    return itemType === 'KG' && !unit;
  };
  return { checkWeightItem, combineTwoUnit, isItemKGAndNoneUnit };
};

export const isQuantityAMultipleOfIncrement = ({ quantity, increment }) => {
  if (increment === 1) return true;
  return quantity && Number(quantity) % Number(increment) === 0;
};

export const isLargerMaxInput = (input) => {
  return input >= MAX_INPUT;
};

export const handleCheckHalal = (params) => {
  const { t } = i18n.global;
  return params === true
    ? ['halal', t('sync_state.halal')]
    : params === false
    ? ['non-halal', t('sync_state.non_halal')]
    : ['', ''];
  // return array[v-bind class css, text multilanguages]
};

export const handlePricePerWeight = (currencySymbol, price, weight, oom) => {
  return `(${currencySymbol}${displayPrice(price / weight)}/${oom})`;
};

export const handleCaculateCuisineShow = (payload) => {
  let cuisine = '';
  const item = payload.filter((item) => item.active === true);
  if (item) {
    for (const i in item) {
      //display 3 item
      if (i == 3) {
        cuisine = cuisine + ',' + (item.length - 3) + '+';
        break;
      } else {
        cuisine.length > 0 ? (cuisine = cuisine.concat(',', item[i].name)) : (cuisine = item[i].name);
      }
    }
  }
  return cuisine;
};

export const showPriceWithCurrencySymbol = (currencySymbol) => (price) => {
  return `${currencySymbol} ${price.toFixed(2)}`;
};

export const isMerchant = (userId) => USER_TYPE.B2B.includes(userId);
export const isSale = (userId) => USER_TYPE.SALE.includes(userId);
export const getUserType = (userId) => {
  return isMerchant(userId) ? 'b2b' : 'sale';
};
