import gql from 'graphql-tag';

export const getListCustomerCard = gql`
  query getListStripeCard($customerId: Int!) {
    listCardByCustomerId(customerId: $customerId) {
      __typename
      ... on ListCardByCustomerId {
        data {
          id
          brand
          exp_month
          exp_year
          country
          funding
          last4
          fingerprint
        }
        has_more
        url
      }
      ... on CardErrorResponse {
        type
        message
      }
    }
  }
`;

export const removeStripeCard = gql`
  mutation removeCardByCustomer($cardId: String!, $customerId: Int!) {
    removeCardByCustomer(cardId: $cardId, customerId: $customerId) {
      ... on DefaultCardByCustomerResponse {
        cardId
        stripeCustomerId
      }
      ... on CardErrorResponse {
        type
        message
      }
    }
  }
`;

export const addStripeCard = gql`
  mutation addCardByCustomer(
    $cardNumber: String!
    $expYear: Int!
    $expMonth: Int!
    $cvc: String!
    $customerId: Int!
  ) {
    addCardByCustomer(
      cardNumber: $cardNumber
      expYear: $expYear
      expMonth: $expMonth
      cvc: $cvc
      customerId: $customerId
    ) {
      ... on DefaultCardByCustomerResponse {
        cardId
        stripeCustomerId
      }
      ... on CardErrorResponse {
        type
        message
      }
    }
  }
`;

export const setDefaultCard = gql`
  mutation setDefaultCardByCustomer($cardId: String!, $customerId: Int!) {
    setDefaultCardByCustomer(cardId: $cardId, customerId: $customerId) {
      ... on DefaultCardByCustomerResponse {
        cardId
        stripeCustomerId
      }
      ... on CardErrorResponse {
        type
        message
      }
    }
  }
`;
export const saleListStripeCard = gql`
  query saleListStripeCard($customerId: Int!) {
    saleListCardByCustomerId(customerId: $customerId) {
      __typename
      ... on ListCardByCustomerId {
        data {
          id
          brand
          exp_month
          exp_year
          country
          funding
          last4
          fingerprint
        }
        has_more
        url
      }
      ... on CardErrorResponse {
        type
        message
      }
    }
  }
`;

export const saleGetInvoicesByCustomer = gql`
  query saleGetInvoicesByCustomer(
    $tenantId: Int!
    $customerId: Int!
    $limit: Int
    $offset: Int
    $isIncludeTotalCount: Boolean
  ) {
    saleGetInvoicesByCustomer(
      tenantId: $tenantId
      customerId: $customerId
      limit: $limit
      offset: $offset
      isIncludeTotalCount: $isIncludeTotalCount
    ) {
      invoices {
        id
        invoice_number
        actual_delivery_date
        description
        currency_code
        currency_symbol
        amount
        cod_paid_amount
        payment_type
        due_date
        status_id
        resync_status
        status_id
        created_at
        company_name
        credit_term
        is_pending_approval
        credit_term_display_name
        outstanding_amount
        payment_type_id
      }
      totalCount
    }
  }
`;

export const saleGetXeroCustomer = gql`
  query saleGetXeroCustomer($customerId: Int!, $tenantId: Int!) {
    saleGetXeroCustomer(customerId: $customerId, tenantId: $tenantId) {
      id
      customer_id
      outstanding_amount
      overdue_amount
      overpayment_amount
      credit_notes_amount
      active
      last_xero_sync
      created_at
      updated_at
    }
  }
`;

export const saleGetTotalUnpaidInvoices = gql`
  query saleGetTotalUnpaidInvoices($tenantId: Int!, $customerId: Int!) {
    saleGetTotalUnpaidInvoices(tenantId: $tenantId, customerId: $customerId) {
      total_unpaid_invoices
      total_overdue_invoices
    }
  }
`;

export const createSaleAutomaticBatchPayments = gql`
  mutation createSaleAutomaticBatchPayments(
    $paymentTypeId: Int!
    $invoiceIDs: [Int]!
    $totalPaidAmount: Float!
    $balanceAllocationAmount: Float!
    $stripeCardId: String
    $stripeCustomerId: String
  ) {
    createSaleAutomaticBatchPayments(
      paymentTypeId: $paymentTypeId
      invoiceIDs: $invoiceIDs
      totalPaidAmount: $totalPaidAmount
      balanceAllocationAmount: $balanceAllocationAmount
      stripeCardId: $stripeCardId
      stripeCustomerId: $stripeCustomerId
    ) {
      id
      url
      expires_at
      payment_status
      status
      cancel_url
      totalData
      transactionDate
      valueDate
      notes
      details {
        batchPayment {
          id
          status
        }
        customerCreditBatchPayment {
          id
        }
      }
    }
  }
`;

export const createPaymentB2b = gql`
  mutation createPaymentB2b(
    $transactionDate: String!
    $valueDate: String!
    $paymentTypeId: Int!
    $paymentProof: [String]!
    $referenceCode: String!
    $invoiceIDs: [Int]!
    $notes: String
    $totalPaidAmount: Float!
    $balanceAllocationAmount: Float!
  ) {
    createPaymentB2b(
      transactionDate: $transactionDate
      valueDate: $valueDate
      paymentTypeId: $paymentTypeId
      paymentProof: $paymentProof
      referenceCode: $referenceCode
      invoiceIDs: $invoiceIDs
      notes: $notes
      totalPaidAmount: $totalPaidAmount
      balanceAllocationAmount: $balanceAllocationAmount
    ) {
      totalData
      transactionDate
      totalPaidAmount
      totalCustomerBalanceUsed
      valueDate
      notes
      details {
        id
        batchPaymentInvoices {
          id
        }
      }
    }
  }
`;

export const createPaymentChequeCash = gql`
  mutation createPaymentChequeCash(
    $depositDate: String!
    $paymentTypeId: Int!
    $paymentProof: [String]!
    $referenceCode: String!
    $invoiceIDs: [Int]!
    $notes: String
    $totalPaidAmount: Float!
    $balanceAllocationAmount: Float!
  ) {
    createPaymentChequeCash(
      depositDate: $depositDate
      paymentTypeId: $paymentTypeId
      paymentProof: $paymentProof
      referenceCode: $referenceCode
      invoiceIDs: $invoiceIDs
      notes: $notes
      totalPaidAmount: $totalPaidAmount
      balanceAllocationAmount: $balanceAllocationAmount
    ) {
      totalData
      transactionDate
      totalPaidAmount
      totalCustomerBalanceUsed
      notes
      details {
        id
        reference_number
        batchPaymentInvoices {
          id
        }
      }
    }
  }
`;

export const saleGetIsFinanceModuleEnabled = gql`
  query saleGetIsFinanceModuleEnabled($tenantId: Int!, $customerId: Int!) {
    saleGetIsFinanceModuleEnabled(tenantId: $tenantId, customerId: $customerId)
  }
`;
