import { REQUEST, SUCCESS, FAILURE } from '@/modules/sale/services/libs/state';

export const MUTATIONS = {
  // get list transaction
  GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATIONS_REQUEST',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILURE: 'GET_NOTIFICATIONS_FAILURE',

  UPDATE_QUERY_PARAMS: 'UPDATE_QUERY_PARAMS',

  // get list customer
  GET_VIEWS_QUOTATIONS_REQUEST: 'GET_VIEWS_QUOTATIONS_REQUEST',
  GET_VIEWS_QUOTATIONS_SUCCESS: 'GET_VIEWS_QUOTATIONS_SUCCESS',
  GET_VIEWS_QUOTATIONS_FAILURE: 'GET_VIEWS_QUOTATIONS_FAILURE',
  UPDATE_QUERY_PARAMS_QUOTATIONS: 'UPDATE_QUERY_PARAMS_QUOTATIONS',

  UPDATE_SUB_BUYER_REQUEST: 'UPDATE_SUB_BUYER_REQUEST',
  UPDATE_SUB_BUYER_SUCCESS: 'UPDATE_SUB_BUYER_SUCCESS',
  UPDATE_SUB_BUYER_FAILURE: 'UPDATE_SUB_BUYER_FAILURE',

  GET_VIEWS_QUOTATIONS_DETAIL_REQUEST: 'GET_VIEWS_QUOTATIONS_DETAIL_REQUEST',
  GET_VIEWS_QUOTATIONS_DETAIL_SUCCESS: 'GET_VIEWS_QUOTATIONS_DETAIL_SUCCESS',
  GET_VIEWS_QUOTATIONS_DETAIL_FAILURE: 'GET_VIEWS_QUOTATIONS_DETAIL_FAILURE',

  SALE_APPROVE_QUOTATION_REQUEST: 'SALE_APPROVE_QUOTATION_REQUEST',
  SALE_APPROVE_QUOTATION_SUCCESS: 'SALE_APPROVE_QUOTATION_SUCCESS',
  SALE_APPROVE_QUOTATION_FAILURE: 'SALE_APPROVE_QUOTATION_FAILURE',

  GET_BADGE_NUMBERS_REQUEST: 'GET_BADGE_NUMBERS_REQUEST',
  GET_BADGE_NUMBERS_SUCCESS: 'GET_BADGE_NUMBERS_SUCCESS',
  GET_BADGE_NUMBERS_FAILURE: 'GET_BADGE_NUMBERS_FAILURE',

  OPEN_MODAL_DETAILS: 'OPEN_MODAL_DETAILS',

  GET_ALL_USER_NOTIFICATIONS_B2B_SUCCESS: 'GET_ALL_USER_NOTIFICATIONS_B2B_SUCCESS',
  GET_ALL_USER_NOTIFICATIONS_B2B_FAILURE: 'GET_ALL_USER_NOTIFICATIONS_B2B_FAILURE'
};

export default {
  // get list notification

  [MUTATIONS.GET_NOTIFICATIONS_REQUEST](state, isLoadMore) {
    REQUEST(state);
    state.isLoadMore = isLoadMore;
  },
  [MUTATIONS.GET_NOTIFICATIONS_SUCCESS](state, { list }) {
    SUCCESS(state);
    if (state.isLoadMore) {
      const data = [...state.list.data, ...list.data];
      state.list = {
        ...list,
        data
      };
    } else {
      state.list = list;
    }
  },
  [MUTATIONS.GET_NOTIFICATIONS_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.UPDATE_QUERY_PARAMS](state, { params }) {
    state.params = {
      ...state.params,
      ...params
    };
  },

  // Quotation
  [MUTATIONS.GET_VIEWS_QUOTATIONS_REQUEST](state, isLoadMore) {
    REQUEST(state);
    state.isLoadMore = isLoadMore;
  },
  [MUTATIONS.GET_VIEWS_QUOTATIONS_SUCCESS](state, { listQuotation }) {
    SUCCESS(state);
    if (state.isLoadMore) {
      const data = [...state.listQuotation.data, ...listQuotation.data];
      state.listQuotation = {
        ...listQuotation,
        data
      };
    } else {
      state.listQuotation = listQuotation;
    }
  },
  [MUTATIONS.GET_VIEWS_QUOTATIONS_FAILURE](state, error) {
    FAILURE(state, error);
  },

  [MUTATIONS.UPDATE_QUERY_PARAMS_QUOTATIONS](state, { paramsQuotation }) {
    state.paramsQuotation = {
      ...state.paramsQuotation,
      ...paramsQuotation
    };
  },

  [MUTATIONS.UPDATE_SUB_BUYER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.UPDATE_SUB_BUYER_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.UPDATE_SUB_BUYER_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // get purchase detail
  [MUTATIONS.GET_VIEWS_QUOTATIONS_DETAIL_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_VIEWS_QUOTATIONS_DETAIL_SUCCESS](state, item) {
    SUCCESS(state);
    state.item = item;
  },
  [MUTATIONS.GET_VIEWS_QUOTATIONS_DETAIL_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // get purchase detail
  [MUTATIONS.SALE_APPROVE_QUOTATION_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.SALE_APPROVE_QUOTATION_SUCCESS](state, payload) {
    SUCCESS(state);
    state.saleApproveQuotation = payload;
  },
  [MUTATIONS.SALE_APPROVE_QUOTATION_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // get badget number
  [MUTATIONS.GET_BADGE_NUMBERS_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_BADGE_NUMBERS_SUCCESS](state, numberBadge) {
    SUCCESS(state);
    state.numberBadge = numberBadge;
  },
  [MUTATIONS.GET_BADGE_NUMBERS_FAILURE](state, error) {
    FAILURE(state, error);
  },
  [MUTATIONS.OPEN_MODAL_DETAILS](state, payload) {
    state.orderId = payload.id;
  },
  [MUTATIONS.GET_ALL_USER_NOTIFICATIONS_B2B_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.GET_ALL_USER_NOTIFICATIONS_B2B_FAILURE](state, error) {
    FAILURE(state, error);
  }
};
