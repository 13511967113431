import { DEFAULT_STATE } from '@/modules/b2b/constants';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const state = {
  ...DEFAULT_STATE,
  cart: {
    items: []
  },
  checkDuplicateOrder: [],
  finishOrder: false
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
