import { apolloClient } from '@/main';
import {
  checkDuplicateOrder,
  saleAddItemToCart,
  saleChangeCartItem,
  saleCheckClosingTime,
  saleGetCartItems,
  saleRemoveCartItem,
  saleUpdateLatestPrice
} from '@/modules/sale/services/graphql';
import { MUTATIONS } from './mutations';

export const ACTIONS = {
  ADD_ITEM_TO_CART: 'ADD_ITEM_TO_CART',
  GET_CART_ITEMS: 'GET_CART_ITEMS',
  CHANGE_CART_ITEMS: 'CHANGE_CART_ITEMS',
  REMOVE_CART_ITEM: 'REMOVE_CART_ITEM',
  CHECK_CLOSING_TIME: 'CHECK_CLOSING_TIME',
  CHECK_DUPLICATE_ORDER: 'CHECK_DUPLICATE_ORDER',
  SALE_UPDATE_LATEST_PRICE: 'SALE_UPDATE_LATEST_PRICE',
  FINISH_ORDER: 'FINISH_ORDER'
};

export default {
  async [ACTIONS.FINISH_ORDER]({ commit }) {
    commit(MUTATIONS.FINISH_ORDER);
  },
  // check delivery time
  async [ACTIONS.CHECK_CLOSING_TIME]({ commit }, params) {
    commit(MUTATIONS.CHECK_CLOSING_TIME_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: saleCheckClosingTime,
        variables: params
      });
      commit(MUTATIONS.CHECK_CLOSING_TIME_SUCCESS);
      return data.saleCheckClosingTime;
    } catch (error) {
      commit(MUTATIONS.CHECK_CLOSING_TIME_FAILURE, error);
    }

    return null;
  },
  // change quantity item
  async [ACTIONS.CHANGE_CART_ITEMS]({ commit }, params) {
    commit(MUTATIONS.CHANGE_CART_ITEMS_REQUEST);
    try {
      await apolloClient.mutate({
        mutation: saleChangeCartItem,
        variables: params
      });
      commit(MUTATIONS.CHANGE_CART_ITEMS_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.CHANGE_CART_ITEMS_FAILURE, error);
    }
  },

  // update latest price
  async [ACTIONS.SALE_UPDATE_LATEST_PRICE]({ commit }, params) {
    commit(MUTATIONS.SALE_UPDATE_LATEST_PRICE_REQUEST);
    try {
      await apolloClient.mutate({
        mutation: saleUpdateLatestPrice,
        variables: params
      });
      commit(MUTATIONS.SALE_UPDATE_LATEST_PRICE_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.SALE_UPDATE_LATEST_PRICE_FAILURE, error);
    }
  },

  // remove cart item
  async [ACTIONS.REMOVE_CART_ITEM]({ commit }, params) {
    commit(MUTATIONS.REMOVE_CART_ITEM_REQUEST);
    try {
      await apolloClient.mutate({
        mutation: saleRemoveCartItem,
        variables: params
      });
      commit(MUTATIONS.REMOVE_CART_ITEM_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.REMOVE_CART_ITEM_FAILURE, error);
    }
  },

  // add item to cart
  async [ACTIONS.ADD_ITEM_TO_CART]({ commit }, params) {
    commit(MUTATIONS.ADD_ITEM_TO_CART_REQUEST);
    try {
      await apolloClient.mutate({
        mutation: saleAddItemToCart,
        variables: params
      });
      commit(MUTATIONS.ADD_ITEM_TO_CART_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.ADD_ITEM_TO_CART_FAILURE, error);
    }
  },

  // get cart items
  async [ACTIONS.GET_CART_ITEMS]({ commit }, params) {
    commit(MUTATIONS.GET_CART_ITEMS_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: saleGetCartItems,
        variables: params
      });
      commit(MUTATIONS.GET_CART_ITEMS_SUCCESS, data.saleGetCartItems);
    } catch (error) {
      commit(MUTATIONS.GET_CART_ITEMS_FAILURE, error);
    }
  },
  async [ACTIONS.CHECK_DUPLICATE_ORDER]({ commit }, params) {
    try {
      const { data } = await apolloClient.mutate({
        mutation: checkDuplicateOrder,
        variables: {
          customerId: params.customerId,
          deliveryAddressId: params.deliveryAddressId,
          items: params.items
        }
      });
      commit(MUTATIONS.CHECK_DUPLICATE_ORDER_SUCCESS, data.checkDuplicateOrder);
    } catch (error) {
      commit(MUTATIONS.CHECK_DUPLICATE_ORDER_FAILURE, error);
    }
  }
};
