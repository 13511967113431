import { isPlatform } from '@ionic/vue';

const isAllow = (currentUser) => {
  return (
    ((currentUser.user_type_id === 6 || currentUser.user_type_id === 12) && currentUser.buyer_type === 1) ||
    currentUser.user_type_id === 7
  );
};

export const authGuard = async (to, from, next) => {
  // close browser if it's redirected from stripe and platform is android
  // workaround for stripe payment. Because android can't close opener window
  if (to.fullPath.indexOf('/b2b/payment-transaction/completed') > -1) {
    next();
  } else if (
    to.fullPath.indexOf('/b2b/payment-transaction/') > -1 &&
    isPlatform('android') &&
    to.fullPath.indexOf('close=true') > -1
  ) {
    next('/b2b/payment-transaction/completed');
  }
  const currentUser = await from.meta.$storage.getUser();
  const hasSkippedOnboarding = await from.meta.$storage.getSkippedOnBoarding();

  if (currentUser) {
    if (!isAllow) return next('/home');
    if (hasSkippedOnboarding && hasSkippedOnboarding.includes(currentUser.id)) {
      if (
        (currentUser.user_type_id === 6 || currentUser.user_type_id === 12) &&
        currentUser.buyer_type === 1 &&
        to.fullPath.indexOf('/b2b') === -1
      ) {
        next('/b2b');
      } else if (
        currentUser.user_type_id === 6 &&
        currentUser.buyer_type === 2 &&
        to.fullPath.indexOf('/b2c') === -1
      ) {
        next('/b2c');
      } else if (
        (currentUser.user_type_id === 7 || currentUser.user_type_id === 2) &&
        to.fullPath.indexOf('/sale') === -1
      ) {
        next('/sale');
      } else {
        next();
      }
    } else if (to.fullPath !== '/shared/auth/onboarding') {
      next('/shared/auth/onboarding');
    } else {
      next();
    }
  } else if (
    !currentUser &&
    to.fullPath.indexOf('/shared/auth') === -1 &&
    to.fullPath !== '/home' &&
    to.fullPath !== '/'
  ) {
    next('/home');
  } else {
    next();
  }
};
