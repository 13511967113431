import { DEFAULT_STATE } from '@/modules/sale/constants';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const state = {
  ...DEFAULT_STATE,
  list: {
    data: []
  },
  totalCount: 0,
  params: {
    isIncludeTotalCount: true,
    limit: 20,
    offset: 0,
    isLoadMore: false
  },

  listQuotation: {
    data: []
  },
  totalCountQuotation: 0,
  paramsQuotation: {
    isIncludeTotalCount: true,
    limit: 20,
    offset: 0,
    isLoadMore: false
  },
  item: null,
  numberBadge: null,
  saleApproveQuotation: []
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
