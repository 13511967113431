import { DEFAULT_GETTERS } from '@/modules/b2b/constants';

export default {
  ...DEFAULT_GETTERS,
  cardList: (state) => state.listCustomerCard?.data || [],
  defaultCardId: (state) => state.defaultCardId,
  removeStripeCard: (state) => state.removeStripeCard,
  listNetsCard: (state) => state.listNetsCard,
  stripeId: (state) => state.stripeId,
  listCardByStripeId: (state) => state.listCardByStripeId,
  listNetsMerchant: (state) => state.listNetsMerchant,
  listInvoices: (state) => state.listInvoices || [],
  totalCountInvoices: (state) => state.totalCountInvoices,
  paymentSummary: (state) => state.paymentSummary,
  xeroCustomer: (state) => state.xeroCustomer,
  totalUnpaidInvoices: (state) => state.totalUnpaidInvoices,
  selectedPaymentInvoices: (state) => state.selectedPaymentInvoices,
  isFinanceModuleEnabled: (state) => state.isFinanceModuleEnabled,
  paymentHistory: (state) => state.paymentHistory
};
