import { FAILURE, REQUEST, SUCCESS } from '@/modules/sale/services/libs/state';

export const MUTATIONS = {
  ADD_ITEM_TO_CART_REQUEST: 'ADD_ITEM_TO_CART_REQUEST',
  ADD_ITEM_TO_CART_SUCCESS: 'ADD_ITEM_TO_CART_SUCCESS',
  ADD_ITEM_TO_CART_FAILURE: 'ADD_ITEM_TO_CART_FAILURE',

  GET_CART_ITEMS_REQUEST: 'GET_CART_ITEMS_REQUEST',
  GET_CART_ITEMS_SUCCESS: 'GET_CART_ITEMS_SUCCESS',
  GET_CART_ITEMS_FAILURE: 'GET_CART_ITEMS_FAILURE',

  CHANGE_CART_ITEMS_REQUEST: 'CHANGE_CART_ITEMS_REQUEST',
  CHANGE_CART_ITEMS_SUCCESS: 'CHANGE_CART_ITEMS_SUCCESS',
  CHANGE_CART_ITEMS_FAILURE: 'CHANGE_CART_ITEMS_FAILURE',

  CHECK_CLOSING_TIME_REQUEST: 'CHECK_CLOSING_TIME_REQUEST',
  CHECK_CLOSING_TIME_SUCCESS: 'CHECK_CLOSING_TIME_SUCCESS',
  CHECK_CLOSING_TIME_FAILURE: 'CHECK_CLOSING_TIME_FAILURE',

  REMOVE_CART_ITEM_REQUEST: 'REMOVE_CART_ITEM_REQUEST',
  REMOVE_CART_ITEM_SUCCESS: 'REMOVE_CART_ITEM_SUCCESS',
  REMOVE_CART_ITEM_FAILURE: 'REMOVE_CART_ITEM_FAILURE',

  CHECK_DUPLICATE_ORDER_SUCCESS: 'CHECK_DUPLICATE_ORDER_SUCCESS',
  CHECK_DUPLICATE_ORDER_FAILURE: 'CHECK_DUPLICATE_ORDER_FAILURE',

  SALE_UPDATE_LATEST_PRICE_REQUEST: 'SALE_UPDATE_LATEST_PRICE_REQUEST',
  SALE_UPDATE_LATEST_PRICE_SUCCESS: 'SALE_UPDATE_LATEST_PRICE_SUCCESS',
  SALE_UPDATE_LATEST_PRICE_FAILURE: 'SALE_UPDATE_LATEST_PRICE_FAILURE',

  FINISH_ORDER: 'FINISH_ORDER'
};

export default {
  // CHECK_CLOSING_TIME
  [MUTATIONS.CHECK_CLOSING_TIME_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.CHECK_CLOSING_TIME_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.CHECK_CLOSING_TIME_FAILURE](state, error) {
    FAILURE(state, error);
  },
  // CHANGE_CART_ITEMS
  [MUTATIONS.CHANGE_CART_ITEMS_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.CHANGE_CART_ITEMS_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.CHANGE_CART_ITEMS_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // UPDATE_LATES_PRICE
  [MUTATIONS.SALE_UPDATE_LATEST_PRICE_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.SALE_UPDATE_LATEST_PRICE_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.SALE_UPDATE_LATEST_PRICE_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // REMOVE_CART_ITEM
  [MUTATIONS.REMOVE_CART_ITEM_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.REMOVE_CART_ITEM_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.REMOVE_CART_ITEM_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // ADD_ITEM_TO_CART
  [MUTATIONS.ADD_ITEM_TO_CART_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.ADD_ITEM_TO_CART_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.ADD_ITEM_TO_CART_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // GET_CART_ITEMS
  [MUTATIONS.GET_CART_ITEMS_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_CART_ITEMS_SUCCESS](state, cart) {
    SUCCESS(state);
    state.cart = cart;
  },
  [MUTATIONS.GET_CART_ITEMS_FAILURE](state, error) {
    FAILURE(state, error);
  },
  [MUTATIONS.CHECK_DUPLICATE_ORDER_SUCCESS](state, payload) {
    SUCCESS(state);
    state.checkDuplicateOrder = payload;
  },
  [MUTATIONS.CHECK_DUPLICATE_ORDER_FAILURE](state, error) {
    FAILURE(state, error);
  },
  [MUTATIONS.FINISH_ORDER](state, payload) {
    state.finishOrder = payload;
  }
};
