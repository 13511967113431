const en = {
  Available: 'Tersedia',
  NPS_text: 'Seberapa besar kemungkinan Anda akan merekomendasikan aplikasi kami kepada teman-teman Anda?',
  NPS_thank_you: 'Terima kasih atas masukan Anda!',
  OK: 'Oke',
  Person_type_cannot_be_empty: 'Tipe orang tidak boleh kosong',
  Weights: 'Bobot',
  about_app: 'Tentang Aplikasi',
  about_this_product: 'Tentang Produk Ini',
  about_treedots: 'Tentang TreeDots',
  about_us: 'Tentang Kami',
  access_denied: 'Akses ditolak!',
  access_signup_des1: 'Sepertinya Anda tidak memiliki akun kami.',
  access_signup_des2: 'Harap buat akun atau ',
  access_signup_des3: 'untuk Ditambahkan ke Keranjang.',
  access_signup_terms: 'Saya telah membaca dan menyetujui ',
  account: 'Akun',
  accountPage: {
    add_card: 'Tambahkan Kartu',
    add_nets_card_type: 'Tambahkan Kartu Bank NETS',
    added_card_successfully: 'Berhasil Menambahkan Kartu!',
    are_you_sure_delete_card: 'Apakah Anda yakin ingin menghapus kartu kredit ini?',
    are_you_sure_delete_card_nets: 'Apakah Anda yakin ingin menghapus kartu bank NETS ini?',
    cannot_be_empty: 'Tidak boleh kosong',
    card_has_been_removed: 'Kartu telah dihapus',
    card_number: 'Nomor Kartu',
    change: 'Ubah',
    configure_peppol_identifier: 'Konfigurasikan Pengenal Peppol',
    confirm_new_password: 'Konfirmasikan kata sandi baru',
    confirm_new_password_cannot_be_empty: 'Konfirmasikan kata sandi baru tidak boleh kosong',
    confirm_password_does_not_match: 'Konfirmasikan kata sandi tidak cocok',
    cvc_cvv: 'CVC/CVV',
    cvv: 'CVV',
    default: 'Standar',
    default_card_has_been_changed: 'Kartu standar telah diubah',
    delete_card: 'Hapus Kartu',
    dont_have_card:
      'Tidak punya kartu? Klik di sini untuk mendaftar dan dapatkan potongan tunai sebesar $200!',
    enter_current_password: 'Masukkan kata sandi saat ini',
    enter_new_password: 'Masukkan kata sandi baru',
    expiry_date: 'Tanggal Kedaluwarsa',
    manage_companines: 'Kelola Perusahaan',
    must_be_a_peppol_identifier: 'Harus berupa pengenal peppol',
    must_be_a_valid_number: 'Harus berupa nomor yang valid',
    nets_card_error_instruction:
      'Kartu bank NETS tidak tersedia untuk perangkat ini, kami akan menghapus semua kartu bank NETS pada perangkat ini, silakan tambahkan kartu baru lagi',
    nets_card_error_title: 'Kesalahan kartu bank NETS',
    nets_card_type: '(Kartu Bank NETS)',
    new_password_cannot_be_empty: 'Kata sandi baru tidak boleh kosong',
    old_password_cannot_be_empty: 'Kata sandi lama tidak boleh kosong',
    old_password_doesnt_match: 'Kata sandi lama tidak cocok',
    password: 'Kata Sandi',
    password_must_be_at_least_6_characters: 'Kata sandi harus setidaknya 6 karakter',
    peppol_id_configuration: 'Konfigurasi ID Peppol',
    peppol_id_configured_successfully: 'ID Peppol berhasil dikonfigurasi',
    peppol_identifier: 'Pengenal Peppol',
    peppol_scheme: 'Skema Peppol',
    peppol_scheme_description: 'Deskripsi Skema Peppol',
    please_choose_something: 'Silakan pilih sesuatu',
    product_sku_name: 'Nama Sku Produk',
    remarks: 'Keterangan',
    remove: 'Hapus',
    request_product: 'Minta Produk',
    request_product_success: 'Berhasil meminta produk',
    set_as_default: 'Tetapkan sebagai standar',
    supplier_name: 'Nama Pemasok',
    validity_period: 'Masa Berlaku',
    your_password_has_been_saved: 'Kata sandi Anda telah disimpan'
  },
  account_type: 'Jenis Akun',
  add: 'Tambah',
  addCompany: {
    accept: 'Terima',
    accept_company_request: 'Terima Permintaan Perusahaan',
    companies_that_assigned:
      'perusahaan yang menugaskan Anda sebagai narahubung utama. Silakan tinjau dan terima atau tolak.',
    have_been_notified:
      'telah diberitahu tentang tindakan Anda dan anggota tim operasi kami akan segera menghubungi Anda untuk mengonfirmasi tindakan ini sebelum menghapus Anda sebagai',
    key_contact_person: 'narahubung utama.',
    my_companies: 'Perusahaan Saya',
    pending_your_action: 'Menunggu tindakan Anda',
    reject_company_request: 'Tolak Permintaan Perusahaan',
    success_assigned: 'Berhasil Ditugaskan',
    success_rejected: 'Berhasil Ditolak',
    there_are: 'Terdapat',
    would_you_like: 'Apakah Anda ingin mengonfirmasi diri Anda sebagai narahubung utama untuk',
    would_you_like_to_reject_being: 'Apakah Anda ingin menolak menjadi',
    you_are_now: 'Anda sekarang',
    you_can_now_make_order: 'Anda sekarang dapat membuat pesanan untuk'
  },
  add_address: 'Tambahkan Alamat',
  add_another_card: 'Tambahkan Kartu Lain',
  add_card_failed: {
    expired_card: 'Kartu ini telah kedaluwarsa. Silakan coba lagi dengan kartu lain.',
    general:
      'Tampaknya kartu Anda bermasalah. Silakan coba lagi dengan kartu lain atau hubungi bank Anda untuk mendapatkan bantuan lebih lanjut.',
    invalid_account:
      'Kartu ini tidak valid. Silakan tambahkan kartu yang berbeda atau hubungi bank Anda untuk mendapatkan bantuan lebih lanjut.',
    stolen_card:
      'Tampaknya kartu Anda bermasalah. Silakan hubungi bank Anda untuk mendapatkan bantuan lebih lanjut.'
  },
  add_card_or_credit_card: 'Tambahkan Kartu Debit/Kredit',
  add_card_success: 'Berhasil menambahkan kartu baru!',
  add_card_validation: {
    card_number: 'Masukkan 16 digit nomor kartu yang valid',
    cvv: 'CVV 3 digit tidak valid',
    expiry_date: 'BB/TT',
    invalid_month: 'Bulan Tidak Valid'
  },
  add_file: 'Tambahkan File',
  add_item_cart: 'Tambahkan Item ke Keranjang',
  add_items_to_favorite_tab:
    'Tambahkan ke Favorit untuk pemesanan dan checkout lebih cepat di masa mendatang.',
  add_new_address: 'Tambah Alamat Baru',
  add_new_card: 'Tambahkan Kartu Baru',
  add_delivery_instructions_or_directions: 'Enter delivery instructions or directions here',
  add_product_to_favorite: 'Tambahkan produk ke Favorit?',
  add_to_cart: '+Keranjang',
  adding_card_failed: 'Gagal menambahkan kartu',
  additional_options: 'Opsi tambahan',
  address: 'Alamat',
  address_btn_remove: '',
  address_remove_confirm: '',
  address_unable_delete_default: '',
  address_form: {
    city: {
      required: 'Kota tidak boleh kosong'
    },
    country: {
      required: 'Negara tidak boleh kosong'
    },
    postal_code: {
      required: 'Kode pos tidak boleh kosong'
    },
    road: {
      required: 'Jalan tidak boleh kosong'
    },
    state: {
      required: 'Negara bagian tidak boleh kosong'
    }
  },
  alert_blacklist_order: 'waspada-daftar-hitam-pesanan',
  alert_dulicate_order: 'waspada-pesanan-ganda',
  all: 'Semua',
  all_collection_point: 'Semua Poin Koleksi',
  all_is_un_selected: 'Semua tidak dipilih',
  all_products: 'Semua Produk',
  allow_location_access: 'Izinkan Akses Lokasi',
  already_have_an_account: 'Sudah memiliki akun?',
  and: 'dan',
  apply: 'Gunakan',
  apply_filter: 'Terapkan filter',
  apply_treedots_voucher: 'Gunakan Voucer TreeDots',
  apply_voucher: 'Gunakan voucer',
  approve: 'Setujui',
  approvedBy: 'Disetujui Oleh',
  approvedPrice: 'Harga yang Disetujui',
  are_you_sure: 'Apakah Anda yakin?',
  available_vouchers: 'voucer tersedia',
  available_vouchers_for_you: 'Voucer yang Tersedia untuk Anda',
  back: 'Kembali',
  back_order: 'Pesanan Kembali',
  back_to_top: 'Kembali ke Atas',
  back_to_try_again: 'Kembali untuk Mencoba Lagi',
  back_try_again: 'Kembali untuk Mencoba Lagi',
  began: 'Bagaimana semuanya dimulai',
  billing_address: 'Alamat Penagihan',
  birth_date: 'Tanggal Lahir',
  birth_date_failed: 'Pembaruan Tanggal Lahir gagal!',
  birth_date_updated: 'Pembaruan Tanggal Lahir berhasil!',
  branches: 'Cabang',
  browse: 'Jelajahi',
  browse_message_if_cart_empty: 'makanan kami untuk ditambahkan ke item Anda di sini!',
  browse_more: 'Telusuri lebih banyak item di ',
  building_name: 'Nama Bangunan',
  cache_cleared: 'Tembolok dibersihkan.',
  camera: 'Kamera',
  cancel: 'Batalkan',
  cancelOrder: 'Batalkan Pesanan',
  cancel_button: 'tombol-batalkan',
  cancel_leave_community_chat: 'BATALKAN',
  cancel_order_item_hint: 'Anda tidak dapat membatalkan item saat pembelian grup tercapai',
  cancel_pre_order: 'Batalkan Pra-Pesanan',
  cancel_remove_product_form_cart: 'Tidak, Simpan Item Ini',
  cancelled_item: 'Anda membatalkan item.',
  order_item: 'Item Pesanan:',
  select_reason: 'Pilih Alasan',
  let_us_know: 'Beritahu kami mengapa anda membatalkan pesanan.',
  additional_info: 'Tambahan Informasi',
  input_your_reasons: 'Masukkan alasan anda',
  input_editional_info: 'Informasi tambahan',
  minimum_5_char: 'Minimal 5 karakter!',
  confirm_cancel: 'Konfirmasi Pembatalan',
  cannot_connect: 'Tidak Dapat Terhubung',
  cannot_create_problem: 'Tidak dapat membuat permintaan Anda saat ini, silakan coba lagi',
  cant_ordered: 'Maaf, beberapa item tidak dapat dipesan lagi. Kami telah menghapusnya dari pesanan Anda.',
  card: 'Kartu',
  card_default_failed: 'Gagal menetapkan kartu sebagai standar!',
  card_default_success: 'Berhasil menetapkan kartu sebagai standar!',
  card_list: 'Daftar Kartu',
  card_not_selected: 'Tidak Dipilih',
  card_popover: 'kartu-popover',
  card_unset_default_failed: 'Gagal membatalkan penetapan kartu sebagai standar!',
  card_unset_default_success: 'Berhasil membatalkan penetapan kartu sebagai standar!',
  cart: 'Keranjang',
  cart_error_title: 'Kesalahan Kartu!',
  carton: 'KARTON',
  carton_deal: 'PENAWARAN KARTON',
  cash_on_delivery: 'Bayar di Tempat',
  catch_weight: 'Bobot tangkapan',
  categories: 'Kategori',
  category_sub: 'Kategori/Sub Kategori',
  cc_add_card: 'Tambahkan Kartu Baru',
  cc_card_number: 'Nomor Kartu',
  cc_cvv: 'CVV',
  cc_expiry_date: 'Tanggal Kedaluwarsa',
  cc_name_on_card: 'Nama pada Kartu',
  change: 'Ubah',
  change_address: 'Ubah Alamat',
  change_order_item_status_confirmation: 'Apakah Anda ingin memperbarui Status Pesanan Item ?',
  change_password: 'Ubah Kata Sandi',
  change_profile_photo: 'Ubah foto profil',
  change_the_address: 'Ubah alamat',
  change_time: 'Ubah waktu',
  channel_admin: 'Admin Saluran',
  chat: 'Obrolan',
  chat_mute_notification: 'Bisukan Pemberitahuan',
  chat_muted: 'Obrolan dibisukan',
  chat_no_description: 'Host titik pengambilan ini belum menambahkan deskripsi grup. Pantau terus!',
  chat_participants: 'Anggota',
  chat_unmuted: 'Obrolan dibunyikan',
  chats: 'Obrolan',
  check_birtday_val: 'updateProfile.tanggal_lahir_tidak_boleh_kosong',
  check_email_val: 'updateProfile.email_tidak_boleh_kosong',
  check_fname_length: 'nama_depan_tidak_boleh_kosong',
  check_fname_length_limit: 'nama_depan_harus_terdiri_dari_setidaknya_2_karakter',
  check_gender_val: 'updateProfile.jenis_kelamin_tidak_boleh_kosong',
  check_phone_number: 'Nomor telepon sudah ada.',
  check_phone_number_error: 'Nomor Telepon Tidak Valid.',
  check_phone_number_length_val: 'updateProfile.nomor_telepon_harus_minimal_5_karakter',
  check_phone_number_val: 'nomor_telepon_tidak_boleh_kosong',
  check_search: 'Silakan periksa',
  check_valid_close_time: 'Bidang tidak boleh kosong',
  check_valid_email: 'bidang_ini_harus_berupa_email_yang_valid',
  check_valid_open_time: 'Bidang tidak boleh kosong',
  checkout: 'Checkout',
  checkout_loader: {
    back_to_checkout: 'Kembali ke Checkout',
    cancel_transaction: 'Anda telah membatalkan transaksi.',
    change_payment: 'Ubah Metode Pembayaran',
    contact_support: 'Hubungi Dukungan',
    continue: 'Lanjutkan',
    fail: 'Pembayaran Anda tidak berhasil.<br>Silakan coba lagi!',
    failure: {
      card_not_supported:
        'Kartu tidak didukung untuk transaksi ini. Silakan checkout lagi dengan kartu lain.',
      card_velocity_exceeded:
        'Batas kartu terlampaui. Silakan coba lagi dengan kartu lain untuk menyelesaikan checkout.',
      error: 'Terjadi kesalahan... Kami mengalami masalah dalam memuat halaman ini. Silakan coba lagi.',
      expired_card: 'Kartu Anda telah kedaluwarsa. Silakan coba lagi dengan kartu lain.',
      general:
        'Tampaknya kartu Anda mengalami masalah. Silakan coba lagi dengan kartu lain untuk menyelesaikan transaksi atau hubungi bank Anda untuk mendapatkan bantuan lebih lanjut.',
      insufficient_e_wallet_balance:
        'Saldo Anda tidak cukup untuk membayar. Pastikan Anda memiliki dana yang cukup di TreeDots Pay Anda lalu coba lagi',
      insufficient_funds:
        'Saldo Anda tidak cukup untuk membayar. Pastikan Anda memiliki dana yang cukup pada metode pembayaran Anda atau pilih metode lain.',
      invalid_account: 'Kartu Anda sudah tidak berlaku. Silakan coba lagi dengan kartu lain.',
      processing_error: 'Terjadi kesalahan saat memproses kartu. Silakan coba untuk checkout lagi.'
    },
    insufficient: 'Dana tidak mencukupi.',
    loading: 'Harap tunggu!<br>Kami sedang memproses pembayaran Anda...',
    not_you_1: 'Ini bukan Anda. Ini kami.',
    not_you_2: 'Silakan hubungi',
    not_you_3: 'Tim Dukungan',
    not_you_4: 'untuk mendapatkan bantuan!',
    or_reach_1: 'Atau hubungi',
    or_reach_2: 'Tim Dukungan',
    or_reach_3: 'untuk mendapatkan bantuan',
    payment_canceled: 'Pembayaran dibatalkan',
    payment_failed: 'Pembayaran gagal!',
    review_checkout: 'Tinjau Item Checkout',
    review_payment: 'Tinjau Metode Pembayaran',
    success: 'Pembayaran Anda berhasil!',
    try_another_method: 'Coba metode pembayaran lain?'
  },
  checkout_pre_order: 'Checkout Pesanan di Muka',
  chinese_simplified: 'Bahasa Cina (Disederhanakan)',
  chinese_traditional: 'Bahasa China (Tradisional)',
  choose_card: 'Harus Memilih Kartua',
  choose_hub: 'Cari Titik Pengumpulan',
  choose_hub_btn: 'Pilih Titik Pengumpulan',
  choose_product: 'Harus Memilih Produk',
  choose_server: 'Pilih Server',
  choose_sign_in_method: 'Pilih metode masuk',
  city: 'Kota',
  city_cannot_be_empty: 'Kota tidak boleh kosong',
  clear_cache: 'Hapus Tembolok',
  clear_cache_confirm: 'Apakah Anda yakin untuk menghapus tembolok?',
  close: 'TUTUP',
  code: 'Kode',
  collect_at: 'Ambil Di',
  collection_date: 'Perkiraan Tanggal Pengambilan: %{date}',
  collection_details: 'Detail Pengambilan',
  collection_note:
    '*Tanggal Pengambilan akan dikonfirmasi setelah Titik Pengambilan Anda mencapai%{currency_symbol}%{minimum_order}.',
  collection_point_description:
    'Titik Pengambilan adalah tempat di dalam komunitas Anda dan lokasi lain tempat Anda dapat melakukan hal berikut:',
  collection_point_not_supported:
    'Mohon maaf. Pemasok ini tidak dapat mengirim ke Titik Pengambilan yang Anda pilih.',
  collection_point_unsupported: 'Titik Pengambilan tidak didukung',
  collection_points_near_you: 'Titik Pengambilan di Dekat Anda',
  coming_soon: 'segera hadir!',
  community: 'Komunitas',
  community_description_label: 'Deskripsi Komunitas',
  companies_with_key_contact: 'Perusahaan dengan Narahubung Utama',
  companies_with_postal_code: 'Perusahaan dengan kode pos',
  company: 'Perusahaan',
  companyDoesNotExit: 'Perusahaan tidak ada',
  competitor_price_feedback: 'umpan balik harga pesaing',
  confirm: 'Konfirmasikan',
  confirm_CP_modal:
    'Bergabunglah dengan Komunitas Pusat kami untuk menerima pembaruan pesanan dan pengiriman!',
  confirm_CP_title: 'Selamat Datang di Pusat Ang Mo Kio',
  confirm_button: 'tombol-konfirmasi',
  confirm_cancel_order: 'Yakin untuk membatalkan pesanan ini?',
  confirm_email: 'Konfirmasikan Email Baru',
  confirm_increased_quantity: 'Konfirmasikan Peningkatan Kuantitas',
  confirm_order_5_mins: 'dibuat dalam 5 menit yang lalu, apakah Anda ingin mengirimkan pesanan baru ini?',
  confirm_password: 'Konfirmasikan Kata Sandi Baru',
  confirm_password_cannot_be_empty: 'Konfirmasi kata sandi tidak boleh kosong',
  confirm_password_does_not_match: 'Konfirmasikan kata sandi tidak cocok',
  confirm_password_new: 'Konfirmasikan Kata Sandi',
  confirm_remove_product_form_cart: 'Ya, Hapus Item Ini',
  confirm_send_inv_email: 'Kami akan mengirimkan faktur ke email Anda, lanjutkan?',
  confirm_submission: 'Konfirmasikan Pengiriman',
  confirm_update_order: 'Yakin ingin memperbarui pra pesanan ini?',
  confirm_update_order_mess: 'Yakin ingin memperbarui pesanan ini?',
  confirmation: 'Konfirmasi',
  connect: 'Sambungkan',
  connectSupplier: {
    connected: 'Terhubung',
    payment: 'Pembayaran',
    supplier: 'pemasok',
    supplier_not_found: 'Pemasok Tidak Ditemukan'
  },
  connectToOtherSuppliers: 'Hubungkan ke pemasok lain',
  connected: 'Terhubung',
  contact: 'Hubungi',
  contact_p1: 'Untuk pelanggan di Indonesia',
  contact_p2: "Email Layanan Pelanggan: hello.id{'@'}thetreedots.id",
  contact_p3: 'WhatsApp: +62 821 10001 778',
  contact_p4: 'Layanan Pengaduan Konsumen',
  contact_p5: 'Direktorat Jenderal Perlindungan Konsumen dan Tertib Niaga',
  contact_p6: 'Kementerian Perdagangan Republik Indonesia',
  contact_p7: 'WhatsApp: 0853-1111-10101',
  contact_us: 'Hubungi Kami',
  continue_order: 'Anda akan melakukan pemesanan ini, lanjutkan?',
  cooking: 'Memasak',
  copy: 'Salin',
  country: 'Negara',
  country_code: 'Kode Negara',
  country_of_origin: 'Negara Asal',
  cp_description_point_1: 'Pembelian grup dengan pembeli lain yang nyaman',
  cp_description_point_2: 'Pilih produk dari Pemasok yang berbeda',
  cp_description_point_3: 'Ambil barang kebutuhan sehari-hari Anda pada saat pengiriman',
  credit_term: 'Jangka Waktu Kredit',
  payment_duedate: 'Tanggal Jatuh Tempo:',
  crop_image: 'Pangkas Gambar',
  customerPage: {
    account_number: 'Nomor Akun',
    add_address: 'Tambahkan Alamat',
    add_company: 'Tambahkan Perusahaan Baru',
    address: 'Alamat',
    address_has_been_added: 'Alamat telah ditambahkan!',
    address_has_been_updated: 'Alamat telah diperbarui!',
    address_saved: 'Alamat disimpan!',
    bank_transfer: 'Transfer Bank',
    billing_address: 'Alamat Penagihan',
    branch: 'Cabang',
    business_information: 'Informasi Bisnis',
    cannot_load_image: 'Tidak dapat memuat gambar',
    cod: 'COD',
    end_week: 'End of Week',
    days_lower: 'days',
    days: 'Days',
    thirty_days: '(30 Days)',
    end_month: 'End of Month',
    collection: 'Pengambilan',
    company: 'Perusahaan',
    company_has_been_updated: 'Perusahaan telah diperbarui!',
    company_information: 'Informasi Perusahaan',
    company_information_saved: 'Informasi perusahaan disimpan!',
    company_name: 'Nama Perusahaan',
    company_name_cannot_be_empty: 'Nama perusahaan tidak boleh kosong',
    company_name_must_be_at_least_4_characters: 'Nama perusahaan harus setidaknya 4 karakter',
    credit_term: 'Jangka Waktu Kredit',
    credit_term_cannot_be_empty: 'Jangka Waktu Kredit tidak boleh kosong',
    customer: 'Pelanggan',
    customer_email_optional: 'Email Pelanggan (Opsional)',
    customers: 'Pelanggan',
    delete_person: 'Hapus Orang',
    delivery_charge: ' Biaya pengiriman',
    delivery_hours: 'Jam Pengiriman',
    delivery_hours_has_been_updated: 'Jam Pengiriman telah diperbarui!',
    delivery_hours_saved: 'Jam pengiriman disimpan!',
    delivery_instruction: 'Petunjuk Pengiriman',
    details: 'Rincian',
    edit_information: 'Edit Informasi',
    enter_a_location: 'Masukkan lokasi',
    enter_building_name: 'Masukkan nama gedung',
    enter_city: 'Masukkan kota',
    enter_floor_no: 'Masukkan nomor lantai',
    enter_new_company_name: 'Masukkan nama perusahaan baru',
    enter_postal_code: 'Masukkan kode pos',
    enter_stall_no: 'Masukkan nomor kios',
    enter_street_address: 'Masukkan alamat jalan',
    enter_unit_no: 'Masukkan nomor unit',
    enter_your_place: 'Masukkan tempat Anda',
    existing_account: 'Akun yang Sudah Ada',
    find_customer: 'Temukan Pelanggan',
    image: 'Gambar',
    image_has_been_added: 'Gambar telah ditambahkan',
    image_has_been_updated: 'Gambar telah diperbarui',
    image_optional: 'Gambar (Opsional)',
    images: 'Gambar',
    minimum_order: 'Pesanan Minimum',
    minimum_order_quantity: 'Jumlah Pesanan Minimum',
    new_account: 'Akun Baru',
    no_branches: 'Tidak Ada Cabang',
    no_cash_no_delivery: 'Pembayaran di Tempat Tidak Tersedia',
    person_has_been_added: 'Orang telah ditambahkan!',
    person_has_been_deleted: 'Orang telah dihapus!',
    person_has_been_updated: 'Orang telah diperbarui!',
    person_information: 'Informasi Orang',
    person_information_saved: 'Informasi orang disimpan!',
    position_cannot_be_empty: 'Posisi tidak boleh kosong',
    profile_cannot_be_empty: 'Profil tidak boleh kosong',
    select_a_profile: 'Pilih profil',
    statement: 'Pernyataan',
    sub_buyer: 'Sub Pembeli',
    sub_buyer_has_been_added: 'Sub Pembeli telah ditambahkan!',
    sub_buyers: 'Sub Pembeli',
    upload_company_logo: 'Unggah Logo Perusahaan'
  },
  dashboard: 'Dasbor',
  date_purchased_new_to_old: 'Tanggal dibeli (Baru ke Lama)',
  date_purchased_old_to_new: 'Tanggal dibeli (Lama ke Baru)',
  days: '{day} hari',
  default: 'Default',
  default_group_description: 'Host titik pengumpulan ini belum menambahkan deskripsi grup. Pantau terus!',
  delete: 'Hapus',
  delete_account: 'Hapus Akun',
  delete_account_text1: 'Kami sedih melihat Anda pergi',
  delete_account_text2: 'Apakah Anda yakin ingin menghapus akun Anda secara permanen?',
  delete_account_text3: 'Harap akui, setelah Anda menghapus:',
  delete_account_text4:
    'Kami hanya akan menyimpan akun Anda selama 30 hari, setelah itu akun Anda akan dihapus secara permanen. Jika kapan saja dalam 30 hari Anda berubah pikiran, Anda dapat mengaktifkan kembali akun Anda cukup dengan masuk kembali.',
  delete_account_text5:
    'Semua data, transaksi sebelumnya, voucer tersisa, dan dana e-wallet Anda akan dihapus secara permanen dan Anda tidak akan dapat lagi mengakses akun Anda.',
  delete_card: 'Hapus Kartu?',
  delete_chat_confirm: 'Yakin ingin meninggalkan obrolan ini?',
  delete_items: 'Hapus Item',
  price_changes: 'Oh, harganya telah berubah',
  delete_message: 'Pesan ini telah dihapus',
  delete_message_confirm: 'Yakin ingin menghapus pesan ini?',
  delivery: 'Pengiriman',
  deliveryTimeOptional: 'Waktu Pengiriman (Opsional)',
  delivery_address: 'Alamat Pengiriman',
  delivery_date_time: 'Tanggal & Waktu Pengiriman',
  delivery_time: 'Waktu Pengiriman',
  desc_unavailable_product: 'Produk-produk ini tidak tersedia di Titik Pengambilan yang Anda pilih.',
  description: 'Deskripsi',
  desired_solution: 'Solusi yang diinginkan',
  developer_menu: 'Menu Pengembang',
  diary: 'Diari',
  did_not_receive_code: 'Tidak menerima kode?',
  direct_price: 'Harga Langsung',
  discount: 'Diskon',
  discount_voucher: 'Voucer Diskon',
  dispute_select_reason: 'Pilih alasan masalah Anda',
  dispute_select_remedy: 'Pilih tindakan pemulihan',
  dispute_selected_reason: 'Alasan yang dipilih untuk masalah Anda',
  dispute_selected_remedy: 'Pemulihan yang dipilih',
  dispute_status_declined: 'Ditolak',
  dispute_status_processing: 'Memproses',
  dispute_status_resolved: 'Terselesaikan',
  dispute_status_unknown: 'Tidak Diketahui',
  distance: 'Jarak',
  document: 'Dokumen',
  done: 'Selesai',
  dont_have_account: 'Tidak punya akun',
  dont_have_an_account: 'Tidak punya Akun?',
  downloading: 'Mengunduh',
  drinks: 'Minuman',
  drn_delayed_delivery: 'Pengiriman Tertunda',
  drn_goods_spoild: 'Barang Rusak',
  drn_missing_item: 'Barang yang Hilang',
  dry_no_remedy: 'Menerima produk tanpa pemulihan apapun',
  dry_request_credit: 'Meminta Nota Kredit',
  dry_request_exchange: 'Meminta pertukaran 1:1',
  dry_request_pick_item: 'Meminta untuk mengambil item yang salah',
  e_wallet: 'E-Wallet',
  edit: 'Edit',
  edit_card: 'Edit Kartu',
  edit_profile: 'Edit Profil',
  email: 'Email',
  email_address: 'Alamat Email',
  email_already_registered: 'Email ini telah terdaftar',
  email_alt_img: 'email',
  email_cannot_be_empty: 'Email tidak boleh kosong',
  email_failed: 'Pembaruan email gagal',
  email_link_to_share:
    'mailto:?subject=TreeDots%20App%20Download%20Link&amp;body=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%0Ahttps%3A%2F%2Fthetreedots.com%2Fdownload',
  email_logo_url: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/mail-256.png',
  email_share_link: 'email ke:hello@thetreedots.com',
  email_updated: 'Pembaruan email berhasil!',
  email_username: 'Email / Nama Pengguna',
  empty_cart_1: 'Keranjang Anda kosong!',
  empty_cart_2: 'Jelajahi',
  empty_cart_3: 'makanan kami dan mulai memesan dengan kami!',
  encouraging: {
    content_1:
      'Pada tahun 2021, platform pembelian grup kami diperluas, memberikan solusi bagi para pelopor komunitas untuk melayani konsumen akhir.',
    content_2: 'Kami meluncurkan',
    content_2a:
      'di mana host Titik Pengambilan dapat mengelola pesanan dari lingkungan mereka, menerima barang dalam jumlah banyak dari pemasok dan mendapatkan komisi. Anda juga dapat menjadi host dengan mendaftar ke aplikasi Pusat Penjual kami!',
    seller_app: ' Aplikasi Pusat Penjual, ',
    title: 'Mendorong Kepemilikan Bisnis'
  },
  english: 'Bahasa Inggris',
  enter_address: 'Masukkan alamat',
  enter_card_number: 'Masukkan Nomor Kartu',
  enter_code: 'Masukkan Kode',
  enter_country: 'Masukkan negara',
  enter_description: 'Masukkan deskripsi (Opsional)',
  enter_state: 'Masukkan negara bagian',
  enter_voucher_code: 'Masukkan kode voucer Anda',
  enter_your_mobile_number:
    'Masukkan nomor ponsel Anda dan kami akan mengirimkan kode verifikasi untuk kembali ke akun Anda.',
  err_msg_color: 'bahaya',
  err_query: 'Ups! Terjadi kesalahan, coba lagi nanti.',
  err_query_payment: 'Ups! terdapat kesalahan dalam pembayaran Anda, silakan coba lagi nanti.',
  err_searh: 'Tidak ada hasil pencarian!',
  err_voucher_invalid: 'Kode voucer tidak valid!',
  errorContent: 'Maaf, kami tidak dapat memprosesnya, silakan coba lagi.',
  errorDefaultTime: 'Waktu tutup harus lebih besar dari atau sama dengan Waktu buka',
  errorTitle: 'Ups! Terjadi kesalahan',
  estimated_collection_date: 'Perkiraan Tanggal Pengambilan',
  expire_soon: 'Segera Kedaluwarsa',
  expired_date: 'Tanggal Kedaluwarsa',
  expires: 'Kedaluwarsa',
  facebook: 'Facebook',
  facebook_alt_img: 'facebook',
  facebook_link_to_share: 'https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fthetreedots.com%2Fdownload',
  facebook_logo_url: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/facebook-256.png',
  facing_an_issue_chat_with_us_for_real_time_support:
    'Mengalami masalah? Diskusikan dengan kami untuk mendapatkan dukungan waktu nyata!',
  failed: 'Gagal',
  failed_cancel: 'Gagal membatalkan item.',
  faq: 'Pertanyaan Umum',
  faq_page: {
    collections: 'Koleksi',
    more: 'Lainnya',
    orders: 'Pesanan',
    p1_1:
      'Untuk membeli dengan kami, Anda harus terlebih dahulu mengunduh aplikasi Group Buy kami untuk seluler lalu daftar untuk akun pribadi. Aplikasi kami saat ini tersedia untuk iOS dan Android. Kemudian mulai telusuri penawaran platform kami!',
    p1_2:
      'Melihat sesuatu yang Anda sukai? Pilih Titik Pengumpulan di dekat Anda di mana Anda ingin membeli dan mengumpulkan item. Tambahkan item ke keranjang belanja Anda, pastikan Anda telah memilih metode pembayaran, dan lakukan pemesanan - selesai! Pesanan Anda akan muncul di bawah bagian Pesanan, di mana Anda dapat memantau status pembelian Anda dan melihatnya setelah siap untuk diambil. Ketika Pembelian Grup atas pesanan Anda & pembeli lain secara keseluruhan mencapai batas minimum bagi pemasok untuk mengatur pengiriman - Anda akan mendapatkan email konfirmasi kapan harus mengambil barang Anda di Titik Pengambilan yang Anda pilih!',
    p1_3:
      'Jangan lupa untuk mengakses obrolan dalam aplikasi kami dan ucapkan “hai” ke host titik pengambilan Anda - host mungkin tetangga Anda atau seseorang dari area yang Anda kenal! Host akan menerima barang Anda dari pemasok dan akan mendapatkan informasi terbaru tentang keberadaan pesanan Anda. Pastikan untuk bergabung dengan obrolan komunitas Titik Pengambilan! Ada pembeli-pembeli seperti Anda, teman dan tetangga, yang berbagi ide dan resep!',
    p2_1:
      'Platform kami menawarkan pengalaman berbelanja Pembelian Grup di mana teman dan tetangga berbelanja bersama, menghemat uang dan makanan bersama-sama! Ketika Anda membeli produk dengan kami, Anda secara otomatis menjadi bagian dari Pembelian Grup dan berkontribusi untuk memecahkan masalah kehilangan makanan pada setiap pembelian Anda!',
    p2_2:
      'Bagaimana cara kerjanya? Semua produk yang dicantumkan oleh pemasok kami dijual secara borongan. Tapi kabar baiknya adalah - Anda tidak perlu membeli borongan sendiri! Kami mengizinkan pembeli individu untuk membeli barang dalam jumlah yang mereka inginkan yang kami gabungkan dengan pembelian dari pembeli lain di Pembelian Grup. Ketika produk yang dibeli oleh pembeli mencapai batas minimum yang disyaratkan, pemasok akan mengatur pengiriman semua pesanan ke Titik Pengambilan sekaligus.',
    p2_3:
      'Dengan cara ini kami dapat menjaga harga kami tetap rendah, sehingga Anda dapat menikmati diskon hingga 90% dari harga eceran untuk bahan makanan! Dan pemasok tidak harus memecah volume mereka atau menyia-nyiakan persediaan sehingga memungkinkan redistribusi dan pengurangan emisi karbon yang lebih efektif ke atmosfer. Apa lagi? Kami menyatukan komunitas! Bergabunglah dengan obrolan komunitas dalam aplikasi kami dan ucapkan “hai” ke Host Titik Pengambilan dan sesama pembeli!',
    p3_1:
      'Untuk bergabung dengan Titik Pengambilan, mulai dari mencari di peta titik pengumpulan yang ada di dekat tempat Anda. Anda dapat mengakses peta dengan mengklik ikon lokasi. Setelah Anda menemukan Titik Pengumpulan di mana Anda ingin membeli dan mengambil barang Anda, klik konfirmasi dan nikmati menelusuri produk yang tersedia di sana!',
    p3_2:
      'Ketika Anda bergabung dengan Titik Pengambilan, secara otomatis Anda menjadi bagian dari obrolan komunitas Titik Pengambilan, di mana Anda dapat berbincang dengan Host mengenai pesanan dan pengambilan atau mengobrol dengan sesama pembeli! Ingin berbincang dengan Host Anda secara pribadi? Obrolan 1:1 juga tersedia! Untuk mengakses semua obrolan, pilih ikon obrolan.',
    p4_1:
      'Anda dapat memantau status pesanan melalui aplikasi pada bagian Pesanan. Item yang siap untuk diambil akan muncul di bawah status “Untuk Diambil” dengan tanggal pengambilan yang dikonfirmasi. Khawatir Anda mungkin melewatkannya? Tenang, kami akan mengirimkan email dan pemberitahuan setelah tanggal pengambilan untuk item Anda dikonfirmasi. Dan ketika harinya telah tiba, kami akan mengingatkan Anda kembali!',
    p4_2:
      'Harap diperhatikan, bahwa item dari pemasok yang berbeda dalam pesanan Anda mungkin tiba pada waktu yang berbeda tergantung pada kemajuan Pembelian Grup. Jika Anda tidak dapat mengambil barang Anda pada tanggal tertentu, silakan berbincang dengan Host titik pengambilan Anda melalui obrolan dalam aplikasi jika Anda ingin membuat perubahan pada pesanan Anda.',
    p5_1:
      'Anda dapat membatalkan item dalam pesanan Anda jika minimum Pembelian Grup untuk pemasok tidak tercapai. Anda dapat memeriksa perkembangan Pembelian Grup produk Anda di bagian Pesanan. Item dengan status “Untuk Diambil” dengan tanggal pengambilan yang dikonfirmasi tidak dapat dibatalkan karena alasan perubahan item. Kami memproses pengembalian uang untuk pembatalan segera setelah pembatalan. Harap diperhatikan bahwa mungkin diperlukan 7-10 hari kerja agar pengembalian dana muncul di rekening bank Anda. Untuk informasi lengkap tentang kebijakan pembatalan dan pengembalian dana, lihat bagian Pengembalian Dana kami.',
    p6_1:
      'Jika Anda memiliki item dalam pesanan yang Pembelian Grup-nya tidak tercapai, kami secara otomatis memindahkan item Anda ke tanggal Pembelian Grup berikutnya yang tersedia, sehingga item dapat mencapai jumlah minimum dengan grup pembeli lain. Anda akan mendapatkan pemberitahuan melalui email tentang perkiraan tanggal pengambilan baru untuk item yang Anda maksud.',
    p6_2:
      'Jika Anda tidak ingin menunggu item Anda lebih lama lagi, Anda dapat membatalkan item jika minimum Pembelian Grup untuk item tersebut tidak tercapai dan dapatkan pengembalian dana. Jika Anda ingin membuat perubahan pada pesanan Anda, silakan hubungi Host Titik Pengumpulan Anda melalui obrolan dalam aplikasi. Host Anda dapat sepenuhnya mengelola pesanan Anda, melakukan pembatalan, memindahkan pesanan Anda ke tanggal pengambilan lain atau ke tempat pengambilan lain atas nama Anda.',
    p7_1:
      'Dalam kasus yang jarang terjadi ketika produk yang Anda beli kehabisan stok pada saat Anda membeli, Host Titik Pengumpulan Anda akan memproses pengembalian dana untuk Anda. Jika Anda menerima produk yang rusak dari pemasok (kualitas rendah, kedaluwarsa, dll.), silakan hubungi Host Titik Pengambilan Anda melalui obrolan dalam aplikasi untuk meminta pengembalian dana. Pengembalian dana mungkin memerlukan waktu 7-10 hari kerja untuk masuk di rekening bank Anda. Untuk informasi lengkap tentang kebijakan pembatalan dan pengembalian uang, lihat bagian Pengembalian Dana kami.',
    p7_2: 'bagian.',
    p8_1:
      'Beberapa produk di Keranjang Anda mungkin ditandai sebagai “tidak tersedia” ketika pemasok produk ini tidak dapat mengirim ke Titik Pengumpulan yang Anda pilih. Anda dapat menentukan untuk memilih Titik Pengambilan lain di mana pemasok saat ini dapat mengirimkan produk, dan checkout dengan Titik Pengambilan baru. Ingin pemasok tertentu mengirimkan ke Titik Pengambilan favorit Anda? Bicaralah dengan Host Titik Pengambilan Anda melalui obrolan dalam aplikasi kami! Host Anda adalah orang yang terhubung dan berkomunikasi dengan pemasok, dan dapat meminta pengiriman dari mereka!',
    p9_1:
      'Platform kami hanya menawarkan pengambilan pesanan sendiri dari Host Titik Pengambilan oleh pembeli. Kami sangat menginginkan keberlanjutan dan membangun komunitas. Host Titik Pengambilan Anda menerima semua pesanan komunitas sekaligus untuk lingkungan tersebut, memungkinkan pemasok untuk mendistribusikan kembali inventaris mereka lebih efisien dan mengurangi emisi karbon ke atmosfer dengan pengiriman massal. Bergabunglah dengan kami dalam perjalanan menuju belanja kebutuhan sehari-hari yang berkelanjutan!',
    privacy: 'Privasi',
    q1: 'Bagaimana cara memesan?',
    q2: 'Apa itu Pembelian Grup dan bagaimana cara membeli dalam grup?',
    q3: 'Bagaimana cara bergabung dengan Titik Pengambilan?',
    q4: 'Bagaimana saya tahu kapan harus mengambil pesanan saya? ',
    q5: 'Dapatkah saya membatalkan pesanan saya?',
    q6: 'Bagaimana jika Pembelian Grup untuk pesanan saya tidak tercapai?',
    q7: 'Bagaimana jika saya menerima produk yang tidak lengkap atau rusak? ',
    q8: 'Mengapa beberapa produk di Keranjang saya "tidak tersedia" untuk checkout?',
    q9: 'Apakah Anda menyediakan pengiriman ke rumah? ',
    refunds: 'Pengembalian dana',
    related: 'Pertanyaan Terkait',
    title: 'PYSD',
    top_qustions: 'Pertanyaan Teratas',
    treedots: 'TreeDots'
  },
  favorite: 'Favorit',
  feedback: 'Umpan balik terkirim!',
  female: 'Wanita',
  file_saved: 'File Disimpan',
  finance_label: 'Keuangan',
  find_company: 'Temukan Perusahaan',
  find_out: 'Temukan lebih banyak di sini',
  find_products: 'Temukan Produk',
  first_name: 'Nama Depan',
  first_name_cannot_be_empty: 'Nama depan tidak boleh kosong',
  first_name_failed: 'Pembaruan nama depan gagal!',
  first_name_must_be_at_least_2_characters: 'Nama depan setidaknya harus 2 karakter',
  first_name_updated: 'Pembaruan nama depan berhasil!',
  floor: 'Lantai',
  floorNo: 'Nomor Lantai (opsional)',
  follow_us: 'Ikuti Kami',
  for_next_delivery: 'untuk pengiriman hari berikutnya.',
  forgot_password: 'Lupa Kata Sandi?',
  forgot_password_1: 'Lupa Kata Sandi?',
  forgot_your_password: 'Lupa kata sandi Anda?',
  forward: 'Teruskan',
  fpx: 'FPX',
  friends_join: 'Teman-teman Bergabung',
  frozen_batang_steak: 'Steak Batang Beku',
  fruits: 'Buah-buahan',
  gender: 'Jenis kelamin',
  gender_failed: 'Pembaruan jenis kelamin gagal!',
  gender_updated: 'Pembaruan jenis kelamin berhasil!',
  generic: 'umum',
  get_code: 'Tidak mendapatkan kode?',
  get_help: 'Hubungi untuk mendapatkan bantuan!',
  go_to_login: 'Lanjutkan masuk',
  got_a_voucher: 'Punya voucer?',
  grabpay: 'GrabPay',
  greeting: 'Bagus',
  group_buy: {
    how_group_buy_works: 'Bagaimana cara kerja Pembelian Grup?',
    slide_1: 'Bergabunglah dengan titik pengambilan terdekat!',
    slide_2: 'Berbelanjalah di titik pengambilan Anda dengan sesama pembeli!',
    slide_3: 'Capai Pembelian Grup minimum untuk mengambil pesanan bersama-sama!',
    slide_4:
      'Mengobrol dengan Host & komunitas titik pengambilan Anda untuk mendapatkan pembaruan, berbagi resep, dan banyak lagi!'
  },
  group_buy_available: 'Pembelian Grup Tersedia',
  group_by_item: 'GRUP BERDASARKAN ITEM',
  group_description: 'Deskripsi Grup',
  group_spend_total:
    'Pemasok ini membutuhkan pembelian grup Anda untuk membelanjakan total <strong>{currencyCode}%{minimum_order}</strong> untuk mengirimkan pesanan Anda ke Titik Pengumpulan.',
  gst_included: 'Termasuk GST, jika berlaku.',
  halal: 'Halal',
  halal_off:
    'Aktifkan jika perusahaan ini adalah perusahaan yang menyediakan produk halal dan tidak ingin melihat produk non-halal.',
  halal_on:
    'Nonaktifkan jika perusahaan ini bukan perusahaan yang menyediakan produk halal. Produk non-halal akan ditampilkan.',
  halal_product: 'Produk Halal',
  halal_products: 'Produk Halal',
  halal_type: 'Jenis Halal',
  handle_not_connect_mess:
    'bukan cabang utama dan tidak dapat dihubungkan. Harap hubungi kantor Anda untuk meminta koneksi ke perusahaan utama.',
  health: 'Kesehatan',
  hello: 'Halo',
  hello_new_string: 'Halo',
  help: 'Bantuan',
  hi: 'Hai',
  history: 'Riwayat',
  hmm: 'Hmm...',
  home: 'Beranda',
  host: 'Host',
  host_account: 'Akun Host',
  hot_deals: 'Penawaran Menarik',
  hot_discounts: 'Diskon Menarik',
  how_help: 'Apa yang dapat kami bantu?',
  image: 'Gambar',
  image_lager_than_5mb: 'Gambar tersebut lebih besar dari batas 5 MB!',
  in: 'dalam',
  inactive_supplier:
    'Pemesanan untuk pemasok ini belum dimulai. Anda dapat memilih Titik Pengambilan lain untuk membeli.',
  incorrect_card_number: 'Penambahan kartu baru gagal! Nomor kartu Anda salah.',
  incorrect_email_or_password_entered: 'Email atau kata sandi yang dimasukkan salah',
  incorrect_phone_number: 'Nomor telepon yang dimasukkan salah',
  incorrect_role: 'Anda bukan pelanggan atau penjual',
  incorrect_verification_code: 'Kode verifikasi yang dimasukkan salah',
  inquiries: 'Pertanyaan',
  instagram: 'Instagram',
  invalid_card_year: 'Penambahan kartu baru gagal! Tahun kedaluwarsa kartu Anda tidak valid.',
  invalid_phone_number: 'Nomor Telepon Tidak Valid',
  invalid_price: 'Harga tidak valid',
  invite_friends: 'Undang Teman',
  invite_friends_description:
    'Undang teman untuk mendaftar dan dapatkan diskon $5 untuk Pembelian berikutnya untuk Anda dan teman Anda',
  invite_friends_get_5: 'Undang Teman dan dapatkan $5',
  ios: 'ios',
  is_the_company_you_want: 'Apakah perusahaan yang ingin Anda tambahkan tercantum di atas?',
  item_unavailable_after: 'Item tidak tersedia setelah',
  items_note: 'Catatan item',
  johor: 'Johor',
  join_now: 'GABUNG SEKARANG!',
  join_to_ordering: 'Bergabunglah dengan penyewa ini untuk mulai memesan',
  key_contact: {
    company_with_key_contact: 'Perusahaan dengan kontak utama',
    does_not_exits: 'tidak ada',
    enter_first_name: 'Masukkan nama depan',
    enter_last_name: 'Masukkan nama belakang',
    key_contact_information: 'Informasi Kontak Utama',
    select_position: 'Pilih posisi'
  },
  key_contact_label: 'Kontak Utama',
  kg: 'KG',
  kitchen: 'Dapur',
  klang_valley: 'Lembah Klang',
  language: 'Bahasa',
  language_preference: 'Preferensi Bahasa',
  last_name: 'Nama Belakang',
  last_name_cannot_be_empty: 'Nama belakang tidak boleh kosong',
  last_name_failed: 'Pembaruan nama belakang gagal!',
  last_name_must_be_at_least_2_characters: 'Nama belakang harus setidaknya 2 karakter',
  last_name_updated: 'Pembaruan nama belakang berhasil!',
  last_order: 'Pesanan terakhir',
  last_quoted_price: 'Harga Penawaran Terakhir',
  later: 'Nanti',
  learn_more: 'Pelajari Lebih Lanjut',
  leave: 'Tinggalkan',
  leave_channel_confirm_header: 'Tinggalkan obrolan ini?',
  leave_channel_confirm_message:
    'Anda tidak akan dapat melihat percakapan dalam obrolan ini. Anda dapat kembali lagi dengan bergabung dengan titik pengambilan ini.',
  leave_channel_toast_message: 'Anda baru saja meninggalkan saluran ini',
  leave_community_channel_confirm_header: 'Tinggalkan Obrolan Komunitas?',
  leave_community_channel_confirm_message: 'Yakin ingin meninggalkan obrolan komunitas ini?',
  leave_private_channel_confirm_header: 'Tinggalkan Obrolan Pribadi?',
  leave_private_channel_confirm_message: 'Yakin ingin meninggalkan obrolan pribadi ini?',
  limit_price: 'Batas Harga',
  linkedin: 'Linkedin',
  list_of_referrees_signed_up: 'Daftar perujuk yang telah mendaftar ke TreeDots',
  live_chat: 'Obrolan Langsung',
  live_chat_support_only_available_in_android_and_ios_app:
    'Dukungan obrolan langsung hanya tersedia di Aplikasi Android dan iOS',
  loading: 'Memuat…',
  loading_infinity_scroll: 'Memuat lebih banyak data...',
  location_permission_text1: 'Halo! Masukkan kode pos Anda atau izinkan akses lokasi untuk melanjutkan',
  location_permission_text2:
    'Untuk menikmati layanan geolokasi kami, izinkan akses lokasi untuk TreeDots GroupBuy.',
  location_permission_text3: 'Anda juga dapat mengizinkan akses lokasi di ponsel Anda dengan membuka:',
  location_permission_text4: 'Pengaturan > TreeDots GroupBuy > Lokasi > Izinkan Akses Lokasi',
  location_permission_text5: 'Pengaturan > Lokasi > Izin Lokasi > TreeDots GroupBuy > Izinkan',
  log_in: 'Masuk',
  login: 'Masuk',
  login_header_tagline: 'Selamat datang di TreeDots, tempatnya produk makanan yang murah dan berkualitas.',
  login_now: 'Masuk Sekarang',
  login_sticky: 'Masuk untuk melanjutkan berbelanja!',
  login_with_app_instead:
    'Nomor ponsel ini terdaftar di Aplikasi Pembelian Grup kami. Silakan coba masuk ke Aplikasi Pembelian Grup.',
  login_with_email: 'Masuk dengan Email',
  login_with_mobile: 'Masuk dengan Nomor Ponsel',
  logout: 'Keluar',
  logout_confirm: 'Yakin ingin keluar?',
  looking_to_browse1: 'Ingin menelusuri',
  looking_to_browse2: 'Pemasok',
  looking_to_browse3: '? Ketuk di sini!',
  main_company: 'Perusahaan Utama',
  make_as_default_address: 'Jadikan sebagai alamat default',
  malaysia: 'Malaysia',
  male: 'Pria',
  manage_account: 'Kelola Akun',
  map: {
    active_only: 'Hanya yang Aktif',
    cancel: 'Batalkan',
    collection_point_is_inactive: 'Titik Pengambilan tidak aktif',
    collection_point_near_me: 'Titik Pengambilan Terdekat',
    confirm: 'Konfirmasikan',
    continue: 'Lanjutkan',
    delelect: 'Batalkan pilihan',
    dismiss: 'Tolak',
    enter_an_address_to_view_collection_points: 'Masukkan alamat untuk melihat titik pengambilan',
    enter_password: 'Masukkan kata sandi',
    enter_your_address: 'Masukkan alamat Anda',
    find_a_collection_point: 'Temukan Titik Pengambilan',
    friends_only: 'Hanya Teman',
    get_your_pass: 'Dapatkan kata sandi Anda dari host Titik Pengambilan ini',
    halal: 'Halal',
    halal_only: 'Halal saja',
    km: 'km',
    no_collection_point_nearby: 'Tidak ada Titik Pengambilan terdekat',
    not_connected_to_cp: 'Anda tidak terhubung ke Titik Pengambilan mana pun!',
    not_open_for_orders_right_now: 'Saat ini tidak terbuka untuk pemesanan',
    pass_incorrect: 'Kata sandi salah. Silakan coba lagi.',
    popup_message: 'Saat ini, Titik Pengambilan ini tidak menerima pemesanan. Lanjutkan?',
    prefer_buyers_know:
      'Host Titik Pengambilan ini lebih memilih pembeli yang mereka kenal secara pribadi. Jika Anda mengenal Host, tambahkan kata sandi di sini atau pilih titik pengambilan publik.',
    search_results: 'Hasil Pencarian',
    search_this_area: 'Cari area ini',
    show_inactive: 'Tampilkan yang tidak aktif',
    welcome_close: 'Tutup',
    welcome_join_now: 'Gabung sekarang!',
    welcome_message:
      'Bergabunglah dengan Titik Pengambilan kami untuk menerima pembaruan tentang pemesanan dan pengiriman!',
    welcome_title: 'Selamat datang di Titik Pengambilan %{cp_name}'
  },
  markAsReadAll: 'Tandai semua sebagai sudah dibaca',
  mark_all: 'Tandai semua sebagai sudah dibaca',
  mark_all_as_read: 'tandai semua sebagai sudah dibaca',
  mark_as_read: 'Tandai sebagai Sudah Dibaca',
  mark_as_received: 'Tandai Sebagai Sudah Diterima',
  mark_as_unread: 'Tandai sebagai Belum Dibaca',
  market_price: 'Harga Pesaing',
  maximum_quantity_exceeded: 'Jumlah maksimum terlampaui.',
  meals: 'Makanan',
  meat: 'Daging',
  medium_color: 'sedang',
  melaka: 'Melaka',
  member: 'Anggota',
  members: 'Anggota',
  menu: 'Menu',
  menu_items: {
    category: 'Kategori',
    home: 'Beranda',
    notifications: 'Pemberitahuan'
  },
  merchandise_subtotal: 'Subtotal Barang Dagangan',
  merchant: 'Penjual',
  message_cart_empty: 'Keranjang Anda kosong.',
  message_forwaded: 'Pesan diteruskan',
  message_join_to_tenant: 'Anda telah meminta untuk bergabung dengan penyewa ini',
  method_send_invoice: 'Silakan pilih metode untuk mengirim faktur ini.',
  minimum_order_malaysia: 'Nilai Pesanan Minimum adalah RM3,00',
  minimum_order_singapore: 'Untuk pembayaran non-ewallet, nilai pesanan minimum adalah S$0,50',
  mission:
    'Memimpikan dunia tanpa kehilangan pangan, kami bertujuan menjadi katalisator rantai pasokan makanan modern dan saluran distribusi makanan yang efisien di kawasan guna mencapai keberlanjutan dengan lebih mudah dan lebih dapat diakses oleh semua orang. Kami memiliki visi bahwa makanan, yang dengan susah payah dipelihara dan disemai, tidak akan sia-sia.',
  mobile_already_registered: 'Nomor ponsel ini sudah terdaftar',
  mobile_not_registered: 'Anda belum mendaftar dengan kami, silakan mendaftar agar akun dapat memulai!',
  mobile_number: 'Nomor Seluler',
  mobile_phone: 'Telepon Seluler',
  more_credit_term: 'Lebih banyak opsi jangka waktu kredit tersedia di dasbor pemasok Anda',
  most_popular: 'Terpopuler',
  most_purchased: 'Paling banyak dibeli',
  msg_delete: 'Pesan Dihapus',
  mute: 'Bisukan',
  my_account: 'Akun Saya',
  my_cards: 'Kartu Saya',
  my_company: 'Perusahaan Saya',
  my_custom_class: 'kelas-kustom-saya',
  my_orders: 'Pesanan Saya',
  name: 'Nama',
  name_az: 'Nama, A-Z',
  name_za: 'Nama, Z-A',
  nevermind_keep_my_account: 'Tidak apa-apa, simpan akun saya',
  new_email: 'Email Baru',
  new_password: 'Kata Sandi Baru',
  new_phone: 'Telepon Baru',
  newcustomer: {
    active: 'Aktif',
    add_brand: 'Tambahkan Cabang',
    add_debit: 'Tambahkan Kartu Debit/Kredit',
    add_nets: 'Tambahkan Kartu Bank NETS',
    add_person_in_charge: 'Tambahkan Penanggung Jawab',
    address: 'alamat',
    close_time: 'Waktu Tutup',
    company_name: 'Nama perusahaan',
    days: 'Hari',
    default_delivery_time: 'Waktu Pengiriman Default',
    delivery_details: 'Rincian Pengiriman',
    edit_business_information: 'Edit Informasi Bisnis',
    edit_delivery_time: 'Edit Waktu Pengiriman',
    edit_image: 'Edit Gambar',
    edit_payment_card: 'Edit Kartu Pembayaran',
    edit_person_in_charge: 'Edit Penanggung Jawab',
    enter_delivery_interval: 'Petunjuk Pengiriman',
    language: 'Bahasa',
    main_brand: 'Cabang Utama',
    notifications: 'Pemberitahuan akan dikirimkan dalam bahasa ini.',
    open_time: 'Jam Buka',
    payment_card_details: 'Rincian Kartu Pembayaran',
    person_in_charge: 'Penanggung Jawab',
    profile: 'profil',
    same_as_parent_company: 'Sama seperti Perusahaan Induk',
    set_as_default: 'Tetapkan sebagai Default',
    set_default_time: 'Tetapkan Waktu Default',
    set_delivery_days: 'Tetapkan Hari Pengiriman',
    update_address: 'Perbarui Alamat',
    update_person_in_charge: 'Perbarui Penanggung Jawab',
    upload_company_logo: 'Unggah Logo Perusahaan',
    upload_image: 'Unggah Gambar'
  },
  newest: 'Terbaru',
  next: 'Berikutnya',
  next_day: 'Pengiriman Hari Berikutnya tidak tersedia',
  no: 'Tidak',
  noData: 'Tidak Ada Data',
  no_alert_confirm: 'Tidak, buat pesanan baru.',
  yes_proceed_order: 'Ya, lakukan pemesanan ini',
  no_cash_off:
    'Aktifkan jika Anda mengharuskan perusahaan ini untuk melakukan pembayaran sebelum atau pada saat pengiriman untuk menerima item mereka. Pemasok akan memiliki hak untuk mengambil kembali item jika syarat pengiriman ini tidak terpenuhi.',
  no_cash_on:
    'Nonaktifkan jika Anda tidak mengharuskan perusahaan ini untuk melakukan pembayaran sebelum atau pada saat pengiriman untuk memenuhi pesanan.',
  no_cc: 'Belum ada kartu kredit yang ditambahkan',
  no_chat_text1: 'Maaf, tidak ada hasil yang ditemukan.',
  no_chat_text2: 'Kami tidak dapat menemukan kata kunci yang Anda cari.',
  no_chat_text3: 'Silakan coba kata kunci lain.',
  no_continue_adding_new_company: 'Tidak, lanjutkan menambahkan perusahaan baru',
  no_credit_card_selected: 'Harap pilih metode pembayaran',
  no_expiry_date: 'Tidak ada tanggal kedaluwarsa',
  no_name: 'Tidak ada nama',
  no_notification: 'Tidak ada Pemberitahuan',
  no_notification_text1: 'Pemberitahuan Anda akan muncul di sini!',
  no_notification_text2: 'Kami akan menggunakan ruang ini untuk mengingatkan Anda tentang',
  no_notification_text3: 'pesanan dan promo! :)',
  no_product_found: 'Tidak Ada Produk yang Ditemukan',
  no_product_in_category: 'Belum ada produk di sini,',
  no_quantity: 'Pemasok tidak memiliki jumlah yang cukup untuk item ini.',
  no_referred_text: 'Belum ada teman yang dirujuk',
  no_results_found: 'Tidak ada hasil yang ditemukan',
  no_search_result: 'Kami tidak dapat menemukan produk apa pun yang cocok dengan pencarian Anda.',
  no_search_text1: 'Kami tidak dapat menemukan produk yang',
  no_search_text2: 'cocok dengan pencarian Anda!',
  no_subcategory1: 'Kategori ini tidak memiliki item untuk saat ini.',
  no_subcategory2: 'Kembalilah lain waktu!',
  no_supcategory: 'Pemasok ini tidak memiliki item untuk saat ini.',
  no_supplier_text1:
    'Kami berkomitmen untuk menghadirkan pemasok ke kota Anda. Kami harap Anda akan segera menikmati berbelanja produk di platform kami!',
  no_supplier_text2: 'Tidak yakin apa yang harus dilakukan selanjutnya?',
  no_supplier_text3: 'Silakan',
  no_supplier_text4: 'hubungi kami',
  no_transaction_made: 'Tidak ada transaksi yang dilakukan.',
  no_update_available: 'Tidak ada pembaruan yang tersedia',
  no_vouchers_text: 'Tidak ada voucer yang ditemukan',
  non_halal_product: 'Non-Halal',
  non_pooling: 'Non-Pengumpulan',
  notif_no_spree_open:
    'Titik Pengambilan ini tidak terbuka untuk pesanan saat ini. Kembali lagi nanti atau pilih yang lain!',
  notification: 'Pemberitahuan',
  notifications: 'Pemberitahuan',
  off: 'NONAKTIF',
  new_onboarding_1: 'Bahan makanan berkualitas tinggi dan murah untuk bisnis Anda',
  new_onboarding_2: 'Kelola beberapa entitas dalam satu aplikasi sederhana',
  onboarding1_text:
    'Platform Pembelian Grup No. 1 tempat teman dan tetangga menikmati diskon besar bersama-sama!',
  onboarding1_title: 'Selamat Datang di Treedots',
  onboarding2_text:
    'Kami bekerja sama dengan pemasok terpercaya yang memasok makanan ke pasar dan restoran yang Anda sukai.',
  onboarding2_title_1: 'Makanan Berkualitas Tinggi.',
  onboarding2_title_2: 'Murah.',
  onboarding3_text:
    'Belanja kebutuhan sehari-hari yang berkelanjutan dan ambil di Titik Pengambilan pilihan Anda!',
  onboarding3_title: 'Temukan',
  onboarding4_text: 'Mengobrol dengan host titik pengambilan Anda dan sesama pembeli!',
  onboarding4_title: 'Bergabunglah dengan komunitas yang berkembang!',
  onboarding_button: 'Belanja Kebutuhan Sehari-hari',
  one_or_more_company:
    'Satu atau lebih perusahaan dalam database kami memiliki kode pos yang sama dengan yang Anda masukkan. Jika perusahaan yang Anda coba tambahkan sudah ada, silakan sambungkan ke perusahaan tersebut',
  one_or_more_company1:
    'Satu atau lebih perusahaan dalam database kami memiliki nomor telepon yang sama dengan yang Anda masukkan. Jika perusahaan yang Anda coba tambahkan sudah ada, silakan sambungkan ke perusahaan tersebut',
  open_alert_reject: 'Apakah Anda ingin menolak Pre-Pesanan?',
  open_document: 'Buka Dokumen',
  operation_label: 'Operasi',
  optional: 'Opsional',
  Optional: '(Opsional)',
  or_sign_in_with: 'atau masuk dengan',
  or_use: 'atau gunakan',
  order: {
    accept_rejection: 'Terima Penolakan',
    accept_rejection_confirm_description:
      'Menerima penolakan berarti Anda setuju untuk mengakhiri perselisihan ini. Anda tidak akan menerima pengembalian dana.',
    accept_rejection_confirm_title: 'Yakin untuk menerima penolakan?',
    action_not_be_reversed: 'Tindakan ini tidak dapat dikembalikan',
    allow_refund_default: 'Selalu kembalikan dana ke TreeDots Pay',
    amount_refunded: 'Jumlah Dana yang Dikembalikan:',
    appeal_description:
      'Dengan menolak penawaran tersebut, masalah ini sekarang akan dinaikkan ke Pusat Penyelesaian TreeDots. Peninjauan akan membutuhkan waktu sekitar 12-24 jam. Anda akan menerima pembaruan segera setelah peninjauan selesai.',
    appeal_reason_input_placeholder:
      'Jelaskan alasan Anda mengenai masalah ini sehingga kami dapat menyelidikinya dengan cepat.',
    appeal_submitted: 'Banding Diajukan',
    appeal_submitted_description:
      'Masalah ini sekarang telah dinaikkan ke Pusat Penyelesaian TreeDots. Peninjauan akan membutuhkan waktu sekitar 1 hari. Anda akan menerima pembaruan segera setelah peninjauan selesai.',
    appeal_to_treedots: 'Banding ke TreeDots',
    attachment: 'Lampiran',
    back: 'KEMBALI',
    browse: 'Jelajahi',
    buy_again: 'Beli Lagi',
    cancel: 'Batalkan',
    cancel_Item_modal: 'BATALKAN ITEM',
    cancel_item: 'Batalkan Item?',
    cancel_modal_text: 'Yakin ingin membatalkan item ini?',
    cancel_order_confirm: 'Yakin untuk membatalkan pesanan ini?',
    cancel_order_succeed: 'Berhasil dibatalkan',
    cancel_refund_default: 'Tidak sekarang',
    cancelled: 'Dibatalkan',
    cashback: 'potongan tunai',
    change_company: 'Ganti Perusahaan',
    chart_with_hub: 'Mengobrol dengan Host',
    chat_not_ready: 'Obrolan komunitas ini belum siap!',
    collected: 'Diambil',
    collection_date: 'Tanggal pengambilan:',
    collection_point: 'Titik Pengambilan',
    completed: 'Diselesaikan',
    date_of_request: 'Tanggal permintaan',
    date_resolved: 'Tanggal diselesaikan',
    dispute: 'Perselisihan',
    dispute_attach_description:
      'Lampirkan bukti pendukung untuk masalah Anda. Anda dapat melampirkan foto (JPG, JPEG dan PNG) atau video (MP4) hingga 5 file. Maksimal 10MB',
    dispute_attach_photo_video: 'Lampirkan foto atau video',
    dispute_detail: 'Rincian Perselisihan',
    dispute_history: 'Riwayat Perselisihan',
    dispute_id: 'ID Perselisihan',
    dispute_review_description:
      'Masalah ini sekarang telah dinaikkan ke Pusat Penyelesaian TreeDots. Peninjauan akan membutuhkan waktu sekitar 1 hari. Anda akan menerima pembaruan segera setelah peninjauan selesai.',
    draft: 'Draf',
    estimated: 'Diperkirakan',
    estimated_collection_date: 'Perkiraan Tanggal Pengambilan',
    group_buy_progress: 'Kemajuan Pembelian Grup',
    have_been_rewarded: 'Anda memperoleh hadiah Potongan Tunai!',
    have_no_cancelled: 'Anda tidak memiliki item "Dibatalkan".',
    have_no_collected: 'Anda tidak memiliki item “Diambil”.',
    have_no_overview: 'Anda tidak memiliki item "Gambaran Umum".',
    have_no_preparing: 'Anda tidak memiliki item “Sedang disiapkan”.',
    have_no_to_collect: 'Anda tidak memiliki item “Untuk Diambil”.',
    manage_account: 'Kelola Akun',
    my_account: 'Akun Saya',
    my_orders: 'Pesanan Saya',
    need_help: 'Butuh bantuan?',
    ongoing: 'Sedang berlangsung',
    opening_chat: 'Membuka obrolan...',
    order_No: 'Nomor Pesanan',
    order_amount: 'Jumlah Pesanan:',
    order_id: 'ID Pesanan',
    order_title: 'Pesanan',
    order_total: 'Total Pesanan:',
    our_food: 'makanan kami untuk mulai memesan dengan kami!',
    overview: 'Ringkasan',
    package_no: 'Nomor Paket',
    polling_item: 'Pengumpulan item',
    preparing: 'Mempersiapkan',
    qty: 'Jumlah:',
    reason_for_appeal: 'Alasan banding',
    refund: 'Pengembalian dana:',
    refund_modal_bold_text: 'Akun > TreeDots Pay',
    refund_modal_header: 'Ingin menerima pengembalian dana cepat di lain waktu?',
    refund_modal_text:
      'Pilih e-wallet TreeDots Pay sebagai opsi utama untuk semua pengembalian dana Anda. Anda selalu dapat mengubahnya di',
    refund_total: 'Jumlah yang dikembalikan:',
    refunded: 'Dana dikembalikan',
    rejoin_chat_message: 'Anda telah meninggalkan obrolan ini sebelumnya. Ingin bergabung kembali?',
    rejoin_chat_no: 'TIDAK, TERIMA KASIH',
    rejoin_chat_title: 'Bergabung kembali dengan obrolan ini?',
    rejoin_chat_yes: 'GABUNG OBROLAN',
    resolution: 'Penyelesaian',
    resolve_dispute: 'Selesaikan Masalah',
    resolve_dispute_description: 'Masalah ini akan diselesaikan secara otomatis dalam waktu 24 jam.',
    submit_appeal: 'Ajukan Banding',
    supplier_rejection_description1: 'Pemasok menolak permintaan pengembalian dana',
    supplier_rejection_description2:
      ' untuk produk ini. Silakan periksa Riwayat Perselisihan untuk melihat detailnya.',
    supplier_rejection_reason: 'Alasan Penolakan Pemasok',
    text_item_required:
      'Item ini membutuhkan jumlah minimum agar dapat dikonfirmasi untuk pengambilan. Saat ini sedang menunggu pembeli lain dari lingkungan Anda untuk mengumpulkan item ini! Anda dapat membatalkan item Anda jika tidak ingin menunggu (Anda tidak akan dikenakan biaya untuk item ini).',
    text_missing_items: 'Mengapa saya kehilangan item?',
    text_pooling_items:
      'Pengumpulan item memerlukan jumlah minimum untuk dikonfirmasi untuk pengambilan. Anda telah memesan sedikit di atas batas minimum untuk kumpulan ini. Tapi jangan khawatir! Item Anda yang tertunda dapat dikonfirmasi di kumpulan pembeli berikutnya dalam waktu dekat!',
    to_collect: 'Untuk Mengambil',
    total_paid: 'Total yang Dibayar:',
    track_issue: 'Lacak Masalah',
    view_detail: 'Lihat Detail',
    view_history: 'Lihat Riwayat',
    volume_deal: 'PENAWARAN VOLUME',
    voucher_savings: 'Penghematan dengan voucer:',
    want_to_cancel_issue: 'Yakin ingin membatalkan masalah?',
    you_will_receive: 'Anda akan menerima pengembalian dana Anda dalam 7-10 hari kerja.'
  },
  orderB2b: {
    acceptingRemedy: 'Menerima produk tanpa pemulihan apapun',
    accountNumber: 'Nomor Akun',
    addPICMessage: 'Harap tambahkan PIC ke akun penjual sebelum mengirimkan pesanan',
    attention: 'Perhatian',
    billTo: 'Tagih Ke',
    companies: 'Perusahaan',
    created: 'Dibuat',
    deliverTo: 'Kirim Ke',
    delivery: 'Pengiriman',
    deliveryInstruction: 'Petunjuk Pengiriman',
    delivery_address: 'Alamat Pengiriman',
    deplayDelivery: 'Tunda Pengiriman',
    detail: 'Rincian',
    discount: 'Diskon',
    downloadInvoice: 'Unduh Faktur',
    dueDate: 'Tanggal Jatuh Tempo',
    filter: 'Filter',
    from: 'Dari',
    goodsSpoild: 'Barang Rusak',
    grandTotal: 'Total Keseluruhan',
    gst: 'GST',
    invoice: 'Faktur',
    invoiceDate: 'Tanggal Faktur',
    invoiceNumber: 'Nomor Faktur',
    item: 'Item',
    items: 'Item',
    missingItem: 'Barang yang Hilang',
    order: 'Pesanan',
    orderItemDetail: 'Detail Item Pesanan',
    orderNow: 'Pesan Sekarang',
    orders: 'Pesanan',
    placeOrder: 'Buat Pesanan',
    poNumber: 'Nomor PO',
    preOrder: 'Pesan di Muka',
    price: 'Harga',
    purchase: 'Beli',
    qty: 'Jumlah',
    remark: 'Catatan',
    repeat: 'Ulangi',
    repeatOrder: 'Ulangi Pesanan',
    reportProblem: 'Laporkan Masalah',
    requestingCreditNote: 'Meminta Nota Kredit',
    requestingExchange: 'Meminta pertukaran 1:1',
    requestingItem: 'Meminta untuk mengambil barang yang salah',
    reset: 'Atur ulang',
    selectDate: 'Pilihan tanggal akhir harus lebih besar dari tanggal mulai',
    selectDeliveryDate: 'Pilih Tanggal Pengiriman',
    selectProblem: 'Pilih alasan masalah Anda',
    selectRemedy: 'Pemulihan yang dipilih',
    sendInvoice: 'Kirim Faktur',
    separateInvoice: 'Pisahkan Faktur',
    showInvoice: 'Tampilkan Faktur',
    sku: 'SKU',
    status: 'Status',
    subTotal: 'Sub Total',
    submitPreOrder: 'Kirim Pemesanan di Muka',
    tax: 'Pajak',
    taxInvoice: 'Faktur Pajak',
    taxRegistrationNo: 'Nomor Registrasi Pajak',
    to: 'Ke',
    total: 'Total',
    totalPrice: 'Total Harga',
    unitPrice: 'Harga Satuan',
    updateOrderSuccess: 'Berhasil diperbarui',
    viewDispute: 'Lihat Perselisihan',
    viewDisputeStatus: 'Lihat Status Perselisihan',
    weight: 'Berat',
    paymentFail: 'Pembayaran Gagal',
    paymentFailMessage:
      'Maaf! Kami telah membatalkan pesanan Anda karena kami tidak dapat menerima pembayaran untuk pesanan tersebut',
    paymentPending: 'Pembayaran Tertunda',
    paymentPendingMessage: 'Menunggu konfirmasi dari mitra pembayaran untuk mengonfirmasi pesanan Anda!',
    paymentPendingHeadline: 'Selesaikan pembayaran Anda dengan:',
    paymentExpireIn: 'Kedaluwarsa dalam:',
    backToCart: 'Kembali ke Keranjang',
    pendingPayment: 'PEMBAYARAN TERTUNDA',
    paymentInstruction: 'Petunjuk Pembayaran',
    viewQRCode: 'Lihat Kode QR',
    cancelPaymentTransaction: 'Batalkan Pembayaran & Pesanan',
    paymentTransactionExpired: 'Pembayaran telah kedaluwarsa!',
    paynowInstruction: 'Your PayNow QR code will be valid for 1 hour, kindly make payment before it expires!',
    cancelPaymentTitle: 'Cancel Payment and Order?',
    cancelPaymentHint: 'Your payment and order will be cancelled and you will not be charged.',
    cancelStayButton: 'Stay',
    cancelConfirmButton: 'Confirm',
    paymentStatusUpdated: 'Payment status has been updated!',
    paymentStatusUpdatedHint: 'Please close this page to continue'
  },
  order_added: 'Pesanan ditambahkan!',
  order_amount: 'Jumlah Pesanan',
  order_created: 'Kami telah menerima pesanan Anda!',
  order_failed: 'Pesanan Gagal',
  order_now: 'Pesan Sekarang',
  order_of_measure: 'Pesanan Ukuran',
  order_received_date: 'Tanggal Diterima',
  note_to_driver: 'Note to Driver',
  order_submitted: 'Pesanan Dikirimkan',
  order_total: 'Total Pesanan',
  ordering: 'Pemesanan & Pengambilan',
  ordering_collection: {
    find_out_more: 'Cari tahu lebih lanjut tentang Pemesanan dan Pengambilan di sini!',
    p1: 'Saat berbelanja dengan kami, Anda menjadi bagian dari Pembelian Grup.',
    p2: 'Telusuri berbagai pilihan produk berkualitas dan nikmati penawaran yang bagus bersama-sama!',
    p3: 'Checkout kebutuhan sehari-hari di Titik Pengambilan pilihan Anda!',
    p4: 'Capai minimum Pembelian Grup dengan sesama pembeli dan ambil pesanan Anda!',
    p5: 'Mengobrol dengan Host titik pengambilan jika Anda memiliki pertanyaan tentang pesanan!',
    take_a_tour: 'Ikuti Tur',
    take_step: 'Ikuti panduan langkah demi langkah kami tentang Cara Pembelian Grup!'
  },
  orders: 'Pesanan',
  orders_temporarily_closed: 'Pesanan Sementara Ditutup',
  originalPrice: 'Harga Asli',
  other: 'Lainnya',
  other_information: 'Informasi Lainnya (Opsional)',
  other_methods: 'metode lainnya',
  our_mission: 'Misi & Visi Kami',
  our_research: {
    content_1:
      'Kami menemukan lebih dari 810.000 Ton makanan terbuang sia-sia pada tahun 2017. DI SINGAPURA SAJA.',
    content_2:
      'Yang lebih buruk lagi adalah membuang begitu banyak makanan ke tempat pembuangan sampah dapat menghasilkan gas metana dalam jumlah tinggi, yang bahkan memiliki dampak lebih besar pada kesehatan Planet kita.',
    title: 'Kami telah melakukan penelitian'
  },
  our_solution: {
    content_1:
      'Pada pertengahan tahun 2019, kami membuat konsep versi pertama aplikasi kami. Kami menciptakan platform online yang memungkinkan pemasok untuk mendistribusikan kembali stok makanan mereka yang berlebih melalui manajemen pesanan dan layanan logistik kami.',
    content_2:
      'Dengan menjaga biaya platform tetap rendah, kami menunjukkan kepada pemasok cara alternatif yang hemat biaya dalam menangani inventaris mereka yang tidak terjual dan untuk terhubung dengan pelanggan mereka.',
    title: 'Solusi Kami'
  },
  our_team_will_reply_to_your_inquires_in_2_3_working_days:
    'Tim kami akan menjawab pertanyaan Anda dalam 2-3 hari kerja.',
  out_of_stock: 'Stok Habis',
  select_other_variant: 'Pilih Variasi Lainnya',
  overview: 'Ringkasan',
  pack_of: 'PAKET',
  packaging_size: 'Ukuran Kemasan',
  paid: 'DIBAYAR',
  parent_address: 'Alamat orang tua',
  password: 'Kata sandi',
  password_cannot_be_empty: 'Kata sandi tidak boleh kosong',
  password_failed: 'Pembaruan kata sandi gagal!',
  password_must_be_at_least_6_characters: 'Kata sandi harus setidaknya 6 karakter',
  password_updated: 'Pembaruan kata sandi berhasil!',
  pay_now: 'Bayar Sekarang',
  payment: 'Pembayaran',
  paymentStatus: 'Status Pembayaran',
  paymentSuccess: 'Pembayaran Berhasil',
  paymentSuccessMessage: 'Order has been successfully placed',
  paymentMethod: 'Metode Pembayaran',
  paymentTime: 'Waktu Pembayaran',
  orderNumber: 'Nomor Pesanan',
  totalAmount: 'TOTAL',
  viewOrderDetails: 'Lihat Perincian Pesanan',
  backToHome: 'Kembali ke Beranda',
  paymentFail: 'Pembayaran Gagal',
  paymentFailMessage:
    'Maaf! Kami telah membatalkan pesanan Anda karena kami tidak dapat menerima pembayaran untuk pesanan tersebut.',
  backToCart: 'Kembali ke Keranjang',
  payment_method: 'Metode Pembayaran',
  payment_option: 'Opsi Pembayaran',
  payment_option_page: {
    other_payment_method: 'Metode Pembayaran Lainnya',
    treedots_balance_modal_page: {
      amount: 'JUMLAH',
      change_payment_method: 'Ubah Metode Pembayaran',
      oops: 'Ups, saldo anda tidak mencukupi',
      to_complete:
        'Untuk menyelesaikan transaksi Anda, silakan isi ulang dompet TreeDots Anda atau pilih opsi pembayaran lain',
      top_up_treedots: 'Isi Ulang Dompet TreeDots'
    },
    treedots_pay: 'TreeDots Pay'
  },
  penang: 'Penang',
  per: 'Per',
  per_unit_price: 'Harga Per Satuan',
  perak: 'Perak',
  person: 'Orang',
  person_type: 'Tipe Orang',
  pet: 'Binatang Peliharaan',
  phone: 'Telepon',
  phone_alt_img: 'telepon',
  phone_failed: 'Pembaruan telepon gagal!',
  phone_logo_url: 'https://cdn2.iconfinder.com/data/icons/circle-icons-1/64/phone-256.png',
  phone_number: 'Nomor Telepon',
  phone_number_cannot_be_empty: 'Nomor telepon tidak boleh kosong',
  phone_share_link: 'telp:+65 3138 5464',
  phone_updated: 'Berhasil memperbarui telepon!',
  place_order: 'Buat Pesanan',
  please_check_your_email_password: 'Silakan periksa email & kata sandi Anda',
  please_enter_valid_mobile_number: 'Harap masukkan nomor ponsel yang valid.',
  please_enter_valid_username_password: 'Harap masukkan nama pengguna dan kata sandi yang valid',
  please_enter_verification_code:
    'Silakan masukkan kode verifikasi yang telah dikirim ke nomor ponsel Anda<br><strong>{countryCode} {number}</strong>',
  please_provide_your_password: 'Mohon berikan kata sandi Anda',
  please_provide_your_username: 'Silakan berikan nama pengguna Anda',
  please_select_language: 'Pilih bahasa.',
  please_try_again: 'Silakan coba lagi',
  please_wait: 'Mohon tunggu',
  po_number: 'Nomor PO',
  po_number_optional: 'Nomor PO (Opsional)',
  pool_item: 'Kunpulkan Item',
  pool_together: 'Kumpulkan Semuanya',
  pooling: 'Pengumpulan',
  pooling_item: 'PENGUMPULAN ITEM',
  position: 'Posisi',
  postal_code: 'Kode Pos',
  postal_code_cannot_be_empty: 'Kode pos tidak boleh kosong',
  postal_code_not_found: 'Perusahaan dengan kode pos tidak ditemukan.',
  postal_code_placeholder: 'misalnya 456123',
  price: 'Harga',
  priceUpdatedSuccessfully: 'Harga berhasil diperbarui',
  price_cannot_be_empty: 'Harga tidak boleh kosong',
  price_change_log: 'Log perubahan harga',
  price_is_quoted: 'Harga dikutip',
  price_normal: 'harga',
  price_per: 'Harga /',
  price_update: 'Harga Diperbarui',
  price_updated: 'Harga Diperbarui',
  privacy: 'Kebijakan Privasi',
  privacy_policy: {
    change_privacy: '7. Perubahan Kebijakan Privasi',
    children: '3. Privasi Anak',
    collection: '2. Pengumpulan Data Pribadi',
    cookies: '6. Kuki dan Teknologi Seluler',
    effective: 'Berlaku per 14 Mei 2020.',
    intro: '1. Pendahuluan',
    p1:
      'Kebijakan Privasi ini berlaku untuk produk, layanan, situs web yang disediakan, aplikasi seluler yang disediakan dan/atau bisnis yang dijalankan, oleh TreeDots Enterprise (Private Limited), TreeDots International (Private Limited) (secara bersama dan individual disebut sebagai "TreeDots", "kami", "milik kami", "kita") serta menjelaskan cara kami menangani Data Pribadi dan mematuhi persyaratan Undang-Undang Perlindungan Data Pribadi Singapura ("PDPA"). TreeDots menganggap serius keamanan dan privasi Data Pribadi pelanggan dan pengguna situs webnya (seperti www.thetreedots.com) dan aplikasi seluler TreDots (situs web dan aplikasi seluler tersebut dapat secara kolektif atau individual disebut sebagai “Situs”; aplikasi seluler kami dapat disebut sebagai “Aplikasi”).',
    p1_1:
      '1.1 Dengan menggunakan Layanan, Situs, atau Aplikasi kami, Anda mengakui dan menyetujui pengumpulan dan penggunaan Data Pribadi Anda sehubungan dengan kebijakan ini. Kami tidak menggunakan atau membagikan Data Pribadi Anda dengan cara selain yang dijelaskan dalam Kebijakan Privasi ini.',
    p1_2:
      '2.1 Kebijakan Privasi ini akan membantu Anda dalam memahami cara kami mengumpulkan, menggunakan, mengungkapkan dan/atau memproses Data Pribadi yang telah Anda berikan kepada kami atau yang kami miliki tentang Anda, serta untuk membantu Anda dalam membuat keputusan yang tepat sebelum memberikan Data Pribadi Anda kepada kami. Istilah "Data Pribadi" mengacu pada informasi yang berkaitan dengan Anda sebagai individu yang dapat diidentifikasi, yang didefinisikan berdasarkan PDPA yang berarti data, baik benar atau tidak, tentang individu yang dapat diidentifikasi dari data tersebut, atau dari data dan informasi lainnya di mana organisasi memiliki atau kemungkinan besar memiliki akses (“Data Pribadi”). Data ini mungkin termasuk nama, alamat, nomor telepon, alamat email, tanggal lahir, jenis kelamin, rincian kartu kredit, ID perangkat, sistem operasi atau versi, informasi tentang pembelian atau preferensi Anda, dan informasi lainnya yang dikumpulkan oleh kami di mana Anda diidentifikasi atau dapat diidentifikasi.',
    p1_3: 'Kebijakan Privasi ini tunduk kepada hukum Singapura.',
    p2_1:
      '2.1 Kami dapat mengumpulkan Data Pribadi Anda melalui berbagai cara, termasuk namun tidak terbatas dalam hal ketika Anda:',
    p2_1a:
      '• berikan Data Pribadi Anda melalui Situs atau Aplikasi kami untuk tujuan mendaftar dan membuat akun;',
    p2_1b: '• unduh atau akses Situs atau Aplikasi;',
    p2_1c: '• setujui Situs atau Aplikasi mengakses Negara/Wilayah Anda;',
    p2_1d: '• ikut serta dalam promosi atau fitur Aplikasi lainnya;',
    p2_1e: '• proses pembayaran atau transaksi kredit;',
    p2_1f:
      '• minta informasi produk atau layanan atau terima pemasaran, promosi, atau jenis komunikasi lainnya;',
    p2_1g:
      '• minta penyediaan barang dan/atau jasa dari kami dan/atau kami menyediakan tujuan bisnis tak terduga lainnya terkait atau sehubungan dengan Situs atau Aplikasi kami • lakukan pembelian melalui Aplikasi kami;',
    p2_1h: '• ajukan pertanyaan melalui departemen Kesuksesan Pelanggan kami dan/atau;',
    p2_1i: '• berinteraksi dengan staf penjualan kami atau dengan kami.',
    p2_2:
      '2.2 Saat menggunakan Layanan, Situs atau Aplikasi kami, atau ketika meminta bantuan kami, kami dapat secara otomatis mengumpulkan atau meminta Anda untuk memberikan informasi pengenal pribadi Anda kepada kami, yang mungkin termasuk namun tidak terbatas pada:',
    p2_2a: '• nama;',
    p2_2b: '• alamat email;',
    p2_2c: '• nomor telepon;',
    p2_2d: '• informasi pembayaran;',
    p2_2e: '• data lokasi;',
    p2_2f: '• file media;',
    p2_2g: '• data riwayat komunikasi;',
    p2_2h:
      '• data penggunaan dan transaksi, termasuk rincian tentang pencarian Anda, pesanan, iklan dan konten yang berinteraksi dengan Anda di Situs atau Aplikasi, serta produk dan layanan lain yang terkait dengan Anda.',
    p2_2i:
      'Anda tidak berkewajiban untuk memberikan Data Pribadi apa pun yang kami minta. Namun, tergantung pada keadaan, kami mungkin tidak dapat menyediakan produk dan layanan tertentu kepada Anda, atau bertransaksi dengan Anda, dalam beberapa hal yang bergantung pada pengumpulan, penggunaan, atau pengungkapan Data Pribadi Anda.',
    p3_1:
      '3.1 Kami tidak akan dan tidak bermaksud untuk mengumpulkan, menggunakan, membagikan Data Pribadi apa pun atau bertransaksi melalui platform kami secara langsung dengan siapa pun yang kami ketahui berusia di bawah 18 tahun. Jika Anda berusia di bawah 18 tahun, Anda harus menggunakan Situs, Aplikasi, atau Layanan kami hanya dengan melibatkan orang tua atau wali dan tidak boleh mengirimkan Data Pribadi apa pun kepada kami. Dengan memberikan Data Pribadi apa pun kepada kami, Anda menyatakan bahwa Anda berusia di atas 18 tahun.',
    p4_1:
      '4.1 Dalam menjalankan bisnis kami, TreeDots mungkin/akan mengungkapkan atau membagikan Data Pribadi Anda kepada penyedia layanan pihak ketiga, agen dan/atau afiliasi kami atau perusahaan terkait, dan/atau pihak ketiga lainnya untuk berbagai Tujuan, sehingga pihak ketiga tersebut, akan memproses Data Pribadi Anda untuk Tujuan tersebut.',
    p4_2:
      '4.2 Penyedia layanan pihak ketiga kami dapat mengumpulkan dan memproses Data Pribadi Anda atas nama kami atau sebaliknya untuk satu atau lebih Tujuan berikut, termasuk namun tidak terbatas pada tujuan untuk menyediakan layanan analitis, administratif, periklanan, logistik, dan teknologi informasi.',
    p5_1:
      '5.1 Kami akan berusaha untuk mengambil semua langkah yang wajar guna memastikan Data Pribadi Anda tetap rahasia dan aman, serta mengambil langkah-langkah teknis dan organisatoris yang tepat untuk mencegah perusakan yang melanggar hukum atau tidak disengaja, kehilangan yang tidak disengaja, pengungkapan atau akses yang tidak sah atau bentuk pemrosesan yang melanggar hukum lainnya. Data pribadi pengguna tersimpan dalam jaringan yang aman dan hanya dapat diakses oleh karyawan tertentu yang memiliki hak akses khusus ke sistem tersebut.',
    p5_2:
      '5.2 Kami menerapkan pengaturan keamanan yang wajar untuk memastikan bahwa Data Pribadi Anda dilindungi dan diamankan secara memadai. Namun, kami tidak bertanggung jawab atas penggunaan Data Pribadi Anda yang tidak sah oleh pihak ketiga yang sepenuhnya disebabkan oleh faktor-faktor di luar kendali kami.',
    p6_1:
      '6.1 Selain hal-hal di atas, kami dapat menggunakan teknologi berikut (diuraikan di bawah) untuk secara otomatis mengumpulkan informasi tentang aktivitas Anda di Aplikasi atau Situs, tergantung pada kasusnya:',
    p6_1a: '• Kuki • Suar web • Tag analitis • Log server web • Teknologi geo-lokasi',
    p6_2:
      '6.2 Kuki. Untuk pengguna Situs, harap dicatat bahwa TreeDots dapat menyimpan "kuki" di komputer atau perangkat seluler Anda untuk mengidentifikasi Anda. Kuki adalah file teks data kecil yang dikirim dari komputer server selama sesi penelusuran. Kuki biasanya disimpan di hard drive komputer dan digunakan oleh situs web untuk mensimulasikan koneksi berkelanjutan ke situs. Langkah-langkah keamanan telah diterapkan untuk mencegah akses tidak sah ke data pengunjung. Namun, pengunjung mengakui bahwa TreeDots tidak mengontrol transfer data melalui fasilitas telekomunikasi termasuk Internet. Oleh karena itu, sejauh diizinkan oleh undang-undang, TreeDots tidak akan bertanggung jawab atas pelanggaran keamanan atau pengungkapan atau penggunaan yang tidak sah atas data tersebut di Internet, yang bukan karena kesalahan TreeDots. Tidak semua kuki mengumpulkan Data Pribadi dan Anda dapat mengonfigurasi browser Anda untuk menolak kuki. Namun, ini mungkin berarti Anda mungkin tidak dapat memanfaatkan sepenuhnya layanan atau fitur di Situs.',
    p6_3:
      '6.3 Suar Web. Suar web adalah gambar grafis kecil pada halaman web atau dalam email yang dapat digunakan untuk hal-hal seperti merekam halaman dan iklan yang diklik oleh pengguna, atau melacak kinerja kampanye pemasaran email.',
    p6_4:
      '6.4 Tag Analitik. Kami menggunakan tag analitik untuk menganalisis apa yang klien kami suka lakukan serta efektivitas fitur dan iklan kami. Kami dapat menggunakan informasi yang dikumpulkan melalui tag analitik atau tautan terlacak yang digabungkan dengan Data Pribadi Anda.',
    p6_5:
      '6.5 Log Server Web. Log server web adalah catatan aktivitas yang dibuat oleh perangkat seluler atau komputer yang menyediakan halaman web yang Anda minta ke browser. Misalnya, log server web dapat merekam istilah pencarian yang Anda masukkan atau tautan yang Anda klik untuk membuka halaman web, informasi tentang browser Anda, seperti alamat IP dan kuki yang disetel pada browser Anda oleh server.',
    p6_6:
      '6.6 Teknologi Geo-Lokasi. Teknologi geo-lokasi mengacu pada teknologi yang memungkinkan kami untuk menentukan Negara/Wilayah Anda. Kami mungkin meminta Anda untuk memberikan informasi Negara/Wilayah secara manual (seperti kode pos Anda), atau untuk mengizinkan perangkat seluler Anda mengirimkan informasi Negara/Wilayah Anda kepada kami.',
    p7_1:
      '7.1 TreeDots berhak untuk mengubah Kebijakan Privasinya kapan saja dan memberi tahu Anda tentang pembaruan kebijakan tersebut di Platform kami. Sejauh yang diizinkan berdasarkan hukum yang berlaku, penggunaan Layanan, Situs, atau Aplikasi kami yang berkelanjutan, termasuk setiap pembuatan pesanan, merupakan pengakuan dan penerimaan Anda atas perubahan yang dibuat pada Kebijakan Privasi ini.',
    p7_2:
      '7.2 Kebijakan ini berlaku untuk pembeli dan penjual yang menggunakan Layanan, Situs, atau Aplikasi kami kecuali jika dinyatakan lain.',
    protecting: '5. Melindungi Data Pribadi Anda',
    sharing: '4. Membagikan dan Pengungkapan Informasi Pribadi',
    title: 'Kebijakan Privasi TreeDots'
  },
  private: 'Pribadi',
  processing: 'Pemrosesan',
  product: 'Produk',
  productInfo: 'Info Produk',
  product_availability: 'Ketersediaan Produk',
  product_detail: {
    achieve_min: 'Capai nilai pembelian minimal bersama-sama',
    add_to_cart: 'Tambah ke Keranjang',
    add_to_cart_failed: 'Gagal Menambahkan ke Keranjang',
    add_to_cart_success: 'Berhasil ditambahkan ke keranjang',
    almost_stock: 'Hampir Habis!',
    buy_together: 'Beli bersama-sama',
    buy_with_others: 'Beli dengan orang lain',
    country_of_origin: 'NEGARA ASAL',
    discounted_price: 'Harga Diskon',
    halal: 'HALAL',
    how_group_buy_works: 'Bagaimana Cara Kerja Pembelian Grup',
    item_weight: 'BERAT ITEM',
    join_collection_point: 'Bergabung dengan Titik Pengambilan',
    packaging: 'PENGEMASAN',
    per_unit_price: 'HARGA PER SATUAN',
    pooling: 'pembeli dibutuhkan',
    product_description: 'DESKRIPSI PRODUK',
    quantity: 'JUMLAH',
    see_less: 'Lihat Lebih Sedikit',
    see_more: 'Lihat Selengkapnya',
    total_price: 'TOTAL HARGA',
    uom_default: 'Satuan',
    variation: 'VARIASI',
    view_supplier: 'Lihat Pemasok',
    volume_deal: 'PENAWARAN VOLUME',
    volume_deal_description:
      'Nikmati harga diskon dan dapatkan potongan tunai untuk setiap item yang dibeli saat volume Titik Pengambilan tercapai!',
    volume_deal_details: 'Detail Penawaran Volume',
    similar_product: 'Produk Serupa',
    explore_other_product: 'Jelajahi Produk lainnya',
    sharing_title: 'Anda mungkin menyukai %{product_name} dari TreeDots untuk Pedagang!',
    sharing_dialog_title: 'Bagikan Produk',
    sharing_text: 'Lihat %{product_name} di TreeDots untuk Pedagang sekarang!\n',
    sold_out: 'Sold Out',
    buy_again: 'Buy Again',
    stock: 'Stock',
    out_of_stock: 'Out of Stock'
  },
  product_found: 'Produk Ditemukan',
  product_in_other_collection_point: 'Produk tersedia di <b>%{collection_point_name}</b>',
  product_info: 'Info Produk',
  product_information: 'INFORMASI PRODUK',
  product_name: 'Nama Produk',
  product_name_a_z: 'Nama Produk A-Z',
  product_name_z_a: 'Nama Produk Z-A',
  product_price_update: 'Pembaruan Harga Produk',
  product_variation: 'VARIASI PRODUK',
  product_weight: 'Berat Produk',
  products: 'Produk',
  products_found: 'Produk Ditemukan',
  profile: 'Profil',
  profile_updated: 'Profil Diperbarui',
  promos: 'Promo',
  prompt_select_collection_point: 'Silakan pilih Titik Pengambilan Anda!',
  providing: {
    content_1:
      'Pada tahun 2020, di tengah pandemi Covid-19, kami menyempurnakan platform kami dengan menghubungkan pemasok secara langsung ke konsumen akhir. Kami memperkenalkan',
    content_1a:
      'Sekarang pembeli rumah tangga dan pembeli individu dapat menikmati produk dari pemasok kami dengan harga lebih rendah dan berperan aktif dalam menyelesaikan masalah limbah makanan bersama kami.',
    content_2:
      'Konsumen akhir membeli produk bersama tetangga dan teman mereka bersama-sama sebagai pembelian kolektif. Sehingga pemasok tidak perlu memecah barang limbak mereka, pemasok dapat mengirimkan pesanan ke area komunitas sekaligus sehingga mengurangi emisi karbon dan memungkinkan cara redistribusi yang lebih efektif.',
    groupbuy_app: 'Aplikasi Pembelian Grup',
    title: 'Menyediakan untuk Rumah Tangga'
  },
  quantity: 'Jumlah',
  quotation: 'Penawaran',
  quotationApproved: 'Penawaran telah disetujui',
  quotationPendingContent:
    'Karena harga penawaran Anda di bawah batas yang ditetapkan, permintaan telah diteruskan ke pemasok Anda untuk mendapatkan persetujuan lebih lanjut.',
  quotationPendingTitle: 'Penawaran Anda sedang menunggu persetujuan',
  quotation_has_been_sent: 'Penawaran telah dikirim!',
  quotation_price: 'Harga Penawaran',
  quote_price: 'Penawaran',
  quote_price_great_direct_price_title: 'Kutip di atas harga langsung?',
  quote_price_great_direct_price_body:
    'Pelanggan akan menerima harga kuotasi yang lebih tinggi dari harga langsung produk. Anda yakin?',
  quote_price_great_direct_price_agree: 'Ya, Lanjutkan',
  quoted: 'Ditawarkan',
  quotedBy: 'Ditawarkan Oleh',
  re_enter_password: 'Masukkan ulang kata sandi',
  read_more: 'Baca selengkapnya',
  received_your_problem:
    'Kami menerima permintaan Anda. Harap tunggu hingga salah satu staf kami menanggapi permintaan Anda.',
  recent_searches: 'Pencarian Terbaru',
  referral: 'Perujuk',
  referral_code_label: 'Perujuk (Opsional)',
  referral_code_placeholder: 'Kode Perujuk',
  refund_breakdown: 'Rincian Pengembalian Dana',
  refund_description1:
    'Untuk item yang dibayar dengan voucer, jumlah pengembalian dana tidak termasuk jumlah voucer.',
  refund_description2: 'Anda dapat meninjau rincian pengembalian dana di sini.',
  refund_tooltip1: 'Nilai voucher yang diterapkan saat pengguna melakukan check-out',
  refund_tooltip2: 'Jumlah item yang dibatalkan pada pesanan yang dananya kami kembalikan',
  refund_tooltip3: 'Jumlah total yang dananya dikembalikan',
  refunded_amount: 'Jumlah Dana yang Dikembalikan',
  refunded_qty: 'JML yang Dikembalikan',
  refunds: 'Pengembalian dana',
  refunds_policy: {
    or: 'atau',
    p1:
      'TreeDots mengizinkan pembatalan dan pengembalian dana jika Anda berubah pikiran setelah memesan produk. Ini hanya berlaku untuk produk di mana Pembelian Grup belum mencapai batas minimum yang disyaratkan.',
    p2:
      'Produk yang memenuhi syarat untuk pembatalan harus memiliki status "Sedang Disiapkan" dalam pesanan Anda. Produk dengan status “Untuk Diambil” tidak dapat dibatalkan karena alasan berubah pikiran.',
    p3: 'Semua pengembalian uang karena alasan berubah pikiran tunduk pada syarat dan ketentuan berikut:',
    p3_1:
      'Barang harus memiliki status "Sedang disiapkan" dalam pesanan Anda agar memenuhi syarat untuk pembatalan dan pengembalian dana',
    p3_2: 'Barang harus berada dalam Pembelian Grup yang belum mencapai batas minimum yang disyaratkan',
    p3_3:
      'Kebijakan pengembalian dana ini tidak berlaku untuk barang yang telah dinyatakan sebagai “Untuk Diambil” dan batas minimum Pembelian Grup-nya telah tercapai',
    p3_4: 'Untuk barang dengan voucer, jumlah pengembalian dana tidak termasuk jumlah voucer',
    p4a:
      'Pengembalian dana untuk barang yang dibatalkan diproses segera setelah pembatalan ke metode pembayaran yang sama dengan yang Anda gunakan untuk memeriksa barang-barang ini.',
    p4b:
      'Anda dapat menemukan informasi tentang pengembalian dana atas barang Anda di bagian "Dibatalkan" dari pesanan Anda.',
    p5:
      'Anda dapat meminta Host titik pengambilan untuk melakukan pembatalan atas nama Anda melalui obrolan dalam aplikasi jika Anda ingin Host mengelola pembatalan sepenuhnya untuk Anda. Dalam hal produk yang diterima rusak atau tidak lengkap,  harap hubungi Host titik pengambilan untuk meminta pengembalian dana untuk Anda.',
    p6: 'Jika Anda mengalami kesulitan dalam membatalkan pesanan Anda karena masalah teknis, silakan hubungi',
    refunds_take: 'Pengembalian dana membutuhkan waktu 7-10 hari kerja agar muncul di rekening bank Anda.',
    tech_call: 'Dukungan Teknis melalui panggilan ',
    title: 'Kebijakan Pengembalian Dana TreeDots',
    whatsapp: 'WhatsApp di +65 31384846.',
    whatsapp_my: 'WhatsApp di +65 89145600.',
    whatsapp_sg: 'WhatsApp di +65 31384846.'
  },
  register_today: 'Daftar Hari Ini!',
  registered_mobile_go_to_login:
    'Nomor telepon Anda sudah terdaftar di platform kami, apakah Anda ingin masuk dengan nomor ponsel ini?',
  reject: 'Tolak',
  reject_pre_success: 'Berhasil Menolak Pra-pesanan',
  rejectedBy: 'Ditolak Oleh',
  reminder_spree_close_date_and_collection_date:
    'Beli sebelum <b>%{close_date}</b> untuk diambil sebelum <b>%{collection_date}!</b>',
  remove_card: 'Hapus Kartu',
  remove_confirm: 'Yakin ingin menghapus kartu ini?',
  remove_mess: 'Hapus barang ini?',
  remove_product_from_cart: 'Hapus Barang Dari Keranjang?',
  removedSuccessfully: 'Berhasil Dihapus.',
  reply: 'Balas',
  report_your_problem: 'Laporkan Masalah Anda',
  requestAt: 'Minta Di',
  requestQuotationDetails: 'Minta Rincian Penawaran',
  request_again: 'Minta lagi',
  request_approval: 'Minta Persetujuan',
  request_approve: 'Minta Persetujuan',
  request_at: 'Minta Di',
  request_quotation: 'Permintaan Penawaran',
  request_quotation_details: 'Minta rincian penawaran',
  request_quotation_failed: 'Permintaan Penawaran Gagal',
  request_reject: 'Permintaan Ditolak',
  request_transaction: 'Minta Rincian Transaksi',
  requested: 'Diminta',
  resend_code: 'Kirim Ulang Kode',
  resend_code_in: 'Kirim Ulang Kode dalam {duration}',
  reset_filter: 'Reset filter',
  reset_password: 'Atur Ulang Kata Sandi',
  salesperson: 'Petugas penjualan',
  sample: 'Contoh',
  sampleProduct: 'Produk Contoh',
  save: 'Simpan',
  save_address: 'Simpan Alamat',
  save_changes: 'Simpan perubahan',
  saved_successfully: 'Berhasil Disimpan',
  seafood: 'Makanan laut',
  search: 'Cari',
  search_collection_point_via_map: 'Cari Titik Pengambilan melalui peta',
  search_for: 'Pencarian untuk',
  search_for_food: 'Cari makanan',
  search_in: 'Cari di ',
  search_in_ang_mo_kio: 'Cari di Ang Mo Kio',
  search_items: 'Cari Item...',
  search_location: 'Masukkan Lokasi di Sini',
  search_results_for: 'Hasil Pencarian untuk',
  search_suppliers: 'Cari pemasok...',
  search_term: 'istilah pencarian.',
  see_all: 'Lihat semua',
  see_more: 'Lihat Lebih Banyak',
  select: 'Pilih',
  selectCompany: {
    branches: 'Cabang',
    change: 'Ubah',
    change_company: 'Ganti Perusahaan',
    main_company: 'Perusahaan Utama',
    products: 'Produk',
    select_company: 'Pilih Perusahaan'
  },
  select_all: 'Pilih Semua',
  select_another_option: 'Pilih opsi lain',
  select_collection_point: 'Pilih Titik Pengambilan',
  select_date: 'Pilih Tanggal',
  select_delivery_date: 'Atur Tanggal Pengiriman',
  select_delivery_time: 'Pilih Waktu Pengiriman',
  select_invoice: 'Pilih Faktur',
  select_payment_method: 'Pilih Metode Pembayaran',
  select_person: 'Pilih orang',
  select_preferred_language: 'Pilih Bahasa Pilihan',
  select_state: 'Pilih area belanja yang Anda sukai sekarang untuk menikmati pilihan produk di sana.',
  select_valid_action: 'Silakan pilih alasan yang valid untuk masalah Anda',
  select_valid_remedy: 'Silakan pilih tindakan pemulihan yang valid untuk masalah Anda',
  selected_collection_point: 'Titik Pengambilan yang Dipilih',
  seller: 'Penjual',
  selling_price: 'Harga Jual',
  send: 'Kirim',
  send_document: 'Kirim dokumen',
  send_feedback: 'Kirim Masukan',
  send_photo: 'Kirim foto',
  send_verification_code: 'Kirim Kode Verifikasi',
  send_via_email: 'Kirim melalui Email',
  send_via_peppol: 'Kirim melalui Jaringan Peppol',
  separate_invoice: 'Pisahkan Faktur',
  set_birth_date: 'Atur Tanggal lahir',
  set_default: 'Atur Kartu sebagai Default',
  set_main_card: 'Tetapkan Kartu Utama',
  set_new_price: 'Tetapkan Harga Baru',
  set_password: 'Atur Kata Sandi',
  set_password_for_sub_buyer: 'Atur kata sandi untuk sub-pembeli',
  set_time: 'Atur Tanggal dan Waktu Pengiriman',
  settings: 'Pengaturan',
  share: 'Bagikan',
  share_via: 'Bagikan melalui',
  show_less: 'Tampilkan lebih sedikit',
  show_more: 'Tampilkan lebih banyak',
  sign_in: 'Masuk',
  sign_in_failed:
    'Sepertinya ini bukan kata sandi atau email yang benar. Silakan coba lagi atau gunakan Lupa Kata Sandi.',
  sign_in_with_mobile_phone: 'Masuk dengan Ponsel',
  sign_in_with_password: 'Masuk dengan Kata Sandi',
  sign_out: 'Keluar',
  sign_out_confirmation: 'Konfirmasi Keluar',
  delete_account_button: 'Hapus akun saya',
  delete_account_title: 'Apakah Anda yakin?',
  delete_account_confirmation:
    'Akun Anda akan dihapus dan Anda akan kehilangan semua akses ke akun tersebut. Tindakan ini tidak dapat dibatalkan.',
  delete_account_agree: 'Setuju dan hapus akun saya',
  delete_account_go_back: 'Kembali',
  sign_up: 'Mendaftar',
  singapore: 'Singapura',
  skip: 'Lewati',
  sku: 'SKU',
  snacks: 'Makanan Ringan',
  social_shared_mess:
    'Hai, TreeDots menyediakan persediaan berkualitas tinggi dan murah.\n\nUnduh sekarang di ',
  soldInUnits: 'Dijual dalam satuan {number}',
  out_of_max_quantity: 'Kuantitas maksimum adalah {quantity}!',
  out_of_max_weight: 'Berat maksimum adalah {weight} KG!',
  last_remaming_item: 'Sisa {remainingQuantity} terakhir',
  last_remaming_weight_item: 'Sisa {remainingQuantity} KG terakhir',
  some_items_not_favorite: 'Beberapa item tidak ada dalam Favorit.',
  something_went_wrong_please_try_again: 'Terjadi kesalahan. Silakan coba lagi!',
  sorry: 'Maaf!',
  sort: 'Urutkan',
  spelling: 'pengejaan',
  stall: 'Kios',
  stallNo: 'Nomor Kios (opsional)',
  staples: 'Staples',
  start_buying_choose_cp: 'Untuk memulai pembelian dalam grup, pilih titik pengambilan Anda.',
  start_purchasing_today: 'Mulai Membeli Hari Ini',
  started_with_avocados: {
    content_1:
      'Salah satu pendiri telah bekerja di perusahaan perdagangan komoditas dan pengiriman sebelumnya, dan suatu hari, karena keadaan yang tidak terduga, 24 Ton Alpukat busuk di pelabuhan pengiriman di Cina.',
    content_2:
      'Para pendiri tidak dapat menerima hal itu. Hal inilah yang memicu ide TreeDots. Kami ingin menjadi perusahaan yang menyelamatkan produk segar berkualitas tinggi dari dibuang ke limbah karena praktik peraturan yang usang.',
    title: 'Semuanya berawal dari Alpukat'
  },
  state: 'Negara Bagian',
  stay_tuned: 'Pantau Terus!',
  stock_locations: 'lokasi stok',
  street: 'Jalan',
  street_number_cannot_be_empty: 'Nomor jalan tidak boleh kosong',
  sub_total: 'Sub Total',
  submit: 'Kirim',
  submit_information_about_other_supplier: 'Kirimkan informasi tentang pemasok lain',
  submit_report_mess:
    'Kami menerima permintaan Anda. Harap tunggu hingga salah satu staf kami menanggapi permintaan Anda.',
  subtotal: 'Subtotal',
  success: 'Berhasil',
  successfully_added_to_cart: 'Berhasil ditambahkan ke keranjang.',
  successfully_connected_to_company: 'Berhasil terhubung ke perusahaan',
  sucess_msg_color: 'utama',
  supplier: 'Pemasok',
  support: 'Dukungan',
  sweets: 'Permen',
  switch_collection_point: 'Alihkan Titik Pengambilan',
  sync_state: {
    halal: 'Halal',
    non_halal: 'Non-Halal',
    not_applicable: 'Tidak bersertifikat Halal'
  },
  system_outage_head_text: 'Kami mengalami gangguan untuk sementara waktu 😫',
  system_outage_sub_text:
    'Tim kami sedang bekerja untuk memulihkan platform kembali, beri kami waktu untuk memperbaikinya',
  tab_1: 'Tab 1',
  tabs: {
    account: 'Akun',
    cart: 'Keranjang',
    categories: 'Kategori',
    home: 'Beranda',
    orders: 'Pesanan'
  },
  takeAction: 'Ambil Tindakan',
  take_photo: 'Ambil foto',
  tap_here_to_view_description: 'Ketuk di sini untuk melihat deskripsi',
  tax: 'Pajak',
  tax_amount: 'Semua harga sudah termasuk GST',
  tech: 'Dukungan Teknis',
  telegr: 'Telegram',
  telegr_alt_img: 'telegram',
  telegr_link_to_share:
    'https://telegram.me/share/url?text=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%0Ahttps%3A%2F%2Fthetreedots.com%2Fdownload&amp;url=',
  telegr_logo_url: 'https://cdn3.iconfinder.com/data/icons/social-icons-33/512/Telegram-256.png',
  telegr_share_link: 'https://t.me/TreeDotsBot',
  tell_a_friend: 'Beri Tahu Teman',
  terms: 'Ketentuan Penggunaan',
  terms_of_use: {
    collection: '5. Pengambilan',
    effective: 'Berlaku per 14 Mei 2020.',
    eligibility: '3. Kelayakan',
    governing: '10. Hukum dan Yurisdiksi yang Mengatur',
    inability: '6. Ketidakmampuan untuk Mengambil',
    intelectual: '2. Kekayaan Intelektual',
    intro: '1. Pendahuluan',
    intro_1:
      'Ini adalah ketentuan penggunaan dan penjualan (“Ketentuan”) untuk penggunaan Anda atas situs yang dimiliki, dikendalikan dan dikelola oleh TreeDots Enterprise (Private Limited) dan TreeDots International (Private Limited) (secara bersama disebut “TreeDots”), termasuk www.thetreedots.com dan aplikasi seluler TreeDots ("Situs"). Kami dapat menambahkan situs tambahan dari waktu ke waktu bersamaan dengan perluasan penawaran kami dan Ketentuan ini akan mengatur situs-situs baru tersebut saat ditambahkan.',
    intro_2:
      'Ketentuan ini adalah antara Anda dan TreeDots termasuk namun tidak terbatas pada pengguna yang merupakan penjual, pembeli, pedagang atau kontributor konten, informasi, dan materi lainnya. Jika Anda menandatangani perjanjian ini untuk dan atas nama entitas bisnis, istilah "Anda" dalam perjanjian ini berarti entitas bisnis yang atas namanya Anda menggunakan Situs, kecuali konteksnya tidak mengizinkan.',
    limitations: '9. Batasan Tanggung Jawab',
    miscell: '11. Lain-lain',
    p10_1:
      'Anda dengan ini menyetujui bahwa hukum Singapura mengatur Ketentuan ini dan bahwa pengadilan Singapura memiliki yurisdiksi non-eksklusif atas setiap perselisihan yang timbul dari atau sehubungan dengan Ketentuan ini.',
    p11_1:
      'Anda dan TreeDots adalah kontraktor independen, dan tidak ada keagenan, kemitraan, usaha patungan, karyawan-perusahaan, atau pemberi waralaba-pewaralaba yang dimaksudkan atau dibuat oleh Ketentuan ini.',
    p11_2:
      'Jika terdapat ketetapan dari Ketentuan ini dianggap tidak sah atau tidak dapat diberlakukan, legalitas, validitas, dan keberlakuan ketetapan lainnya tidak akan terpengaruh atau terganggu.',
    p11_3:
      'Kegagalan apa pun oleh TreeDots untuk menjalankan hak atau mewajibkan pelaksanaan kewajiban dalam Ketentuan ini tidak akan memengaruhi kemampuan TreeDots untuk melaksanakan hak tersebut atau mengharuskan kinerja tersebut setiap saat setelahnya, dan pengabaian atas pelanggaran Ketentuan ini tidak akan merupakan pengabaian atas setiap pelanggaran selanjutnya.',
    p11_4:
      'Anda tidak boleh mengalihkan hak atau melimpahkan kewajiban apa pun yang ada dalam Ketentuan ini tanpa persetujuan tertulis sebelumnya dari kami dan upaya pengalihan atau pelimpahan apa pun yang bertentangan dengan ketentuan ini akan batal demi hukum dan tidak memiliki kekuatan atau efek apa pun.',
    p11_5:
      'Ketentuan ini tidak memberikan hak kepada pihak ketiga mana pun yang bukan merupakan pihak dalam Ketentuan ini.',
    p1_1:
      '1.1 Anda dapat mengakses Situs kami dari komputer atau perangkat seluler (misalnya melalui aplikasi seluler TreeDots). Ketentuan ini mengatur penggunaan Anda atas Situs kami dan perilaku Anda, terlepas dari sarana aksesnya.',
    p1_2:
      '1.2 Kami mungkin melibatkan Anda di situs pihak ketiga termasuk halaman web media sosial tempat kami memposting konten, seperti http://www.facebook.com/thetreedots/ dan lainnya. TreeDots tidak mengontrol Situs Sosial tersebut dan Ketentuan ini tidak berlaku untuk perusahaan yang tidak dimiliki atau dikendalikan oleh TreeDots, atau untuk tindakan orang yang tidak dipekerjakan atau dikelola oleh TreeDots. Anda harus selalu memeriksa ketentuan penggunaan yang diposting di Situs Sosial.',
    p1_3:
      '1.3 Kami meminta Anda membaca Ketentuan ini dan Kebijakan Privasi kami dengan saksama sebelum mengakses atau menggunakan Situs kami, atau membeli produk apa pun melalui Situs. Jika Anda memiliki pertanyaan terkait penelusuran dan belanja online dengan kami, silakan lihat bagian Pertanyaan yang Sering Diajukan (“FAQ”), Pemesanan & Pengambilan atau hubungi kami.',
    p1_4:
      '1.4 Dengan menggunakan Situs, Anda menyetujui Ketentuan ini, dan ketentuan Kebijakan Privasi, FAQ, Pemesanan & Pengumpulan, dan Kebijakan Pengembalian Dana kami. TreeDots berhak untuk mengubah atau memodifikasi syarat dan ketentuan mana pun yang terkandung dalam Ketentuan ini setiap saat, atau dengan pemberitahuan sebagaimana diwajibkan oleh hukum yang berlaku. Perubahan atau modifikasi apa pun pada Ketentuan Penggunaan TreeDots akan berlaku setelah revisi diposting. Ketentuan Penggunaan TreeDots yang akan berlaku untuk setiap pesanan yang dilakukan melalui Situs kami adalah Ketentuan Penggunaan TreeDots yang berlaku pada saat Anda melakukan pemesanan. Jika Anda terus menggunakan Situs setelah perubahan atau modifikasi diposting, berarti Anda menerima perubahan atau modifikasi tersebut. Jika Anda tidak setuju dengan Ketentuan Penggunaan TreeDots atau perubahan atau modifikasinya, Anda harus segera berhenti menggunakan Situs.',
    p2_1:
      '2.1 Anda dapat menggunakan Situs hanya dengan cara yang diizinkan berdasarkan Ketentuan Penggunaan TreeDots.',
    p2_2: '2.2. Anda tidak boleh:',
    p2_2a:
      '2.2.1.Menyalin, menguraikan, merekayasa balik, membongkar, mencoba mendapatkan kode sumber, mendekripsi, mencampuri, atau mengganggu integritas atau kinerja Situs;',
    p2_2b:
      '2.2.2.Membuat atau mencoba membuat modifikasi, penyesuaian, perbaikan, peningkatan, penerjemahan atau karya turunan dari Situs;',
    p2_2c:
      '2.2.3.Melanggar hukum, aturan, atau regulasi yang berlaku sehubungan dengan akses atau penggunaan Situs oleh Anda;',
    p2_2d: '2.2.4.Menggunakan Situs untuk tujuan apa pun yang tidak ditujukan atau dimaksudkan;',
    p2_2e:
      '2.2.5.Menggunakan Situs untuk mengirim, memposting, atau mengomunikasikan konten apa pun yang menyinggung, tidak senonoh, mengancam, kasar, menghina, melecehkan, memfitnah, mencemarkan nama baik, menipu, curang, menyiksa, cabul, tidak sopan, mengganggu privasi orang lain, atau secara ras, etnis atau lainnya tidak pantas;',
    p2_2f:
      '2.2.6.Menggunakan Situs untuk mengirim pesan, materi iklan atau promosi atau surat sampah, spam, atau surat berantai secara otomatis, yang tidak diminta atau tidak sah.',
    p2_2g:
      '2.2.7.Menggunakan atau mencoba menggunakan scraper, robot, bot, spider, crawler, atau perangkat atau sarana otomatis lainnya untuk mengakses, memperoleh, menyalin, atau memantau bagian mana pun dari Situs, atau data atau konten apa pun yang ditemukan atau diakses melalui Situs;',
    p2_2h: '2.2.8.Mengumpulkan informasi apa pun sehubungan dengan pengguna lain tanpa persetujuan mereka;',
    p2_2i:
      '2.2.9.Melakukan tindakan apa pun yang bertujuan untuk menghindari pembayaran biaya dan/atau ongkos apa pun yang berlaku;',
    p2_2j:
      '2.2.10. Mencoba dan/atau terlibat dalam aktivitas atau tindakan apa pun yang dimaksudkan untuk menyalahgunakan, menyalahgunakan, atau memanipulasi secara tidak tepat kode promosi, kampanye, dan/atau diskon apa pun yang ditawarkan melalui Situs. Tindakan dan aktivitas tersebut termasuk namun tidak terbatas pada: membuat akun palsu atau duplikat;',
    p2_2k: 'membuat pesanan palsu;',
    p2_2l:
      '2.2.11. Memberi wewenang atau mendorong siapa pun untuk melakukan salah satu dari hal-hal yang disebutkan di atas;',
    p2_3:
      '2.3. Semua hak kekayaan intelektual (termasuk, tanpa batasan, hak cipta, merek dagang, paten, hak desain, dan semua bentuk hak kekayaan intelektual lainnya) pada Situs dimiliki oleh TreeDots. Lebih lanjut lagi, Anda mengakui dan menyetujui bahwa sumber dan kode objek Situs serta format, direktori, kueri, algoritme, struktur dan organisasi Situs adalah kekayaan intelektual dan informasi rahasia milik TreeDots.',
    p2_4:
      '2.4. Situs dapat menggunakan atau menyertakan perangkat lunak pihak ketiga yang tunduk pada sumber terbuka dan persyaratan lisensi pihak ketiga (“Perangkat Lunak Pihak Ketiga”). Anda mengakui dan setuju bahwa hak Anda untuk menggunakan Perangkat Lunak Pihak Ketiga tersebut sebagai bagian dari Situs tunduk dan diatur oleh syarat dan ketentuan dari sumber terbuka dan lisensi pihak ketiga yang berlaku untuk Perangkat Lunak Pihak Ketiga tersebut, termasuk, tanpa batasan, setiap pengakuan yang berlaku, persyaratan lisensi dan penafian yang terkandung di dalamnya. Jika terjadi perselisihan antara Ketentuan ini dan ketentuan dari sumber terbuka atau lisensi pihak ketiga tersebut, ketentuan sumber terbuka atau lisensi pihak ketiga akan berlaku sehubungan dengan penggunaan Anda atas Perangkat Lunak Pihak Ketiga yang relevan. Dalam keadaan apa pun, Situs atau bagiannya tidak boleh dianggap sebagai perangkat lunak sumber terbuka atau tersedia untuk umum.',
    p3_1:
      '3.1 Untuk dapat melakukan pembelian melalui Situs kami, Anda akan diminta untuk memberikan rincian data pribadi Anda kepada kami. Secara khusus, Anda akan diminta untuk memberikan kepada kami nama, alamat email, nomor telepon, dan/atau rincian kontak lainnya sebagaimana yang ditunjukkan.',
    p3_2:
      '3.2 Informasi atau rincian data pribadi yang Anda berikan kepada kami akan diproses sesuai dengan Kebijakan Privasi kami dalam konteks pesanan Anda. Saat Anda menggunakan Situs kami, Anda menyetujui pemrosesan informasi dan rincian data Anda, dan Anda mengonfirmasi bahwa semua informasi dan rincian data yang Anda berikan adalah benar dan tepat.',
    p3_3:
      '3.3 Saat Anda melakukan pemesanan melalui Situs kami, Anda mengonfirmasi bahwa Anda berusia di atas 18 tahun dan secara hukum memenuhi syarat untuk menandatangani kontrak yang mengikat. Jika Anda berusia di bawah 18 tahun, Anda hanya dapat melakukan pemesanan dengan persetujuan orang tua atau wali Anda. Jika Anda tidak mendapat persetujuan dari orang tua atau wali, Anda harus berhenti menggunakan Situs.',
    p3_4:
      '3.4 Harap dicatat bahwa jika Anda tidak memberikan semua informasi yang kami butuhkan, Anda tidak dapat membuat atau memroses pesanan Anda.',
    p4_1:
      '4.1 Informasi dan deskripsi produk di Situs telah diberikan kepada kami oleh Pemasok terkait yang menjual di platform kami. Dalam kasus tersebut, TreeDots tidak secara independen memverifikasi informasi produk yang diklaim oleh Pemasok dan tidak dapat bertanggung jawab atas klaim tersebut.',
    p4_2:
      '4.2 Meskipun kami berusaha memberikan deskripsi produk yang tepat, kami tidak menjamin bahwa deskripsi, informasi, harga, promosi, atau konten lain yang tersedia di Situs adalah akurat, terkini, atau bebas dari kesalahan. TreeDots dapat memperbaiki kesalahan, ketidaktepatan atau kelalaian apa pun dan mengubah atau memperbarui informasi atau membatalkan pesanan jika terdapat informasi di Situs yang tidak tepat kapan pun tanpa pemberitahuan sebelumnya, termasuk setelah Anda memasukkan pesanan Anda.',
    p4_3:
      '4.3 Dalam hal produk yang Anda terima salah, tidak lengkap, atau cacat, berlaku Kebijakan Pengembalian Dana TreeDots.',
    p4_4:
      '4.4 Harga yang ditampilkan di Situs adalah dalam mata uang lokal (Dolar Singapura (SGD) untuk wilayah Singapura dan Ringgit Malaysia (RM) untuk wilayah Malaysia), sudah termasuk GST dan termasuk biaya pengiriman Pemasok ke Titik Pengambilan.',
    p4_5:
      '4.5 Harga produk akan seperti yang ditetapkan setiap saat di Situs kami, kecuali jika terjadi kesalahan yang nyata. Meskipun kami melakukan segala upaya untuk memastikan bahwa harga yang ditampilkan di Situs adalah benar, kesalahan mungkin saja terjadi. Jika kami menemukan kesalahan dalam harga salah satu produk yang Anda pesan, kami akan memberi tahu Anda sesegera mungkin. Jika TreeDots tidak dapat menghubungi Anda, pesanan Anda dapat dibatalkan dan jumlah yang Anda bayarkan akan dikembalikan sepenuhnya melalui metode pembayaran asli yang Anda gunakan.',
    p4_6:
      '4.6 Untuk informasi tentang cara pembayaran yang tersedia di TreeDots, silakan lihat bagian FAQ dan Pemesanan & Pengambilan.',
    p5_1:
      '5.1 Produk yang ditampilkan di Situs didasarkan pada kode negara tempat Anda mendaftar. Produk hanya dapat dipesan di Singapura dan Malaysia dan hanya tersedia untuk diambil sendiri. Lihat FAQ atau bagian Pemesanan & Pengambilan kami jika Anda memerlukan informasi lebih lanjut tentang ketentuan pengambilan TreeDots.',
    p6_1:
      '6.1 Lihat bagian FAQ dan Pengembalian Dana kami untuk mengetahui opsi yang tersedia jika Anda tidak dapat menerima paket.',
    p7_1:
      '7.1 Semua pengembalian harus dilakukan sesuai dengan petunjuk yang ditetapkan di bagian FAQ dan Pengembalian Dana kami.',
    p8_1: '8.1 Anda harus berusia minimal 18 tahun agar dapat menjadi pengguna terdaftar.',
    p8_2:
      '8.2 Anda menyetujui bahwa Anda hanya akan mengirimkan atau memberikan informasi yang akurat dan benar, dan bahwa Anda akan selalu memperbarui informasi yang diberikan.',
    p8_3:
      '8.3 Pada saat pendaftaran akun TreeDots di Situs kami, Anda memerlukan kata sandi untuk Akun tersebut. Anda bertanggung jawab untuk memastikan detail Akun Anda termasuk kata sandi tetap rahasia, terkini, lengkap dan akurat. Anda bertanggung jawab atas semua aktivitas yang terjadi di dalam Akun dan/atau kata sandi Anda (diizinkan atau tidak) seolah-olah aktivitas tersebut dilakukan oleh Anda. Anda harus segera memberi tahu TreeDots jika mengetahui atau memiliki alasan untuk mencurigai bahwa kerahasiaan detail Akun Anda termasuk kata sandi telah disusupi atau jika ada penggunaan Akun Anda yang tidak sah atau jika Data Pribadi Anda perlu diperbarui.',
    p8_4:
      '8.4 Anda dapat menyimpan informasi kartu kredit Anda sebagai bagian dari Akun Anda untuk memungkinkan proses checkout yang lebih cepat. Pengambilan dan penanganan informasi ini diatur oleh Kebijakan Privasi kami.',
    p8_5:
      '8.5 Jika Anda mengakses dan menggunakan Situs atas nama orang lain, perusahaan, organisasi, atau badan hukum (“Entitas”), Anda menyatakan bahwa Anda memiliki wewenang untuk mengikat Entitas tersebut sebagai prinsipal terhadap Ketentuan TreeDots. Sejauh Anda tidak memiliki wewenang tersebut, Anda setuju untuk terikat pada Ketentuan ini dan Anda menerima tanggung jawab atas kerugian yang disebabkan oleh setiap penggunaan Situs.',
    p8_6:
      '8.6 TreeDots berhak untuk menangguhkan atau mengakhiri Akun Anda tanpa pemberitahuan sebelumnya untuk alasan apa pun termasuk jika Ketentuan TreeDots dilanggar.',
    p8_7: '8.7 Anda dapat menonaktifkan Akun Anda kapan saja melalui Situs.',
    p8_8:
      '8.8 Anda mengakui dan menyetujui bahwa jika Anda menonaktifkan Akun atau jika TreeDots menangguhkan atau menghentikan Akun, Anda akan kehilangan informasi apa pun yang terkait dengan Akun. Setelah pengakhiran, semua hak yang diberikan kepada Anda dalam Ketentuan ini akan segera dihentikan.',
    p9_1:
      'Anda mengakui dan menyetujui bahwa satu-satunya hak Anda sehubungan dengan masalah atau ketidakpuasan terhadap Situs adalah untuk meminta penghentian akun dan/atau tidak melanjutkan penggunaan layanan apa pun.',
    p9_2:
      'Dalam keadaan apa pun TreeDots atau anak perusahaan, afiliasi, mitra, pemasok, atau pemberi lisensinya tidak bertanggung jawab atas kerusakan tidak langsung, insidental, konsekuensial, khusus, atau umum yang timbul dari atau sehubungan dengan akses atau penggunaan atau ketidakmampuan Anda untuk mengakses atau menggunakan layanan atau konten dan layanan pihak ketiga mana pun, atau yang timbul dari atau sehubungan dengan perilaku Anda atau perilaku pengguna lain sehubungan dengan penggunaan Situs, baik kerugian tersebut dapat diperkirakan atau tidak dan baik TreeDots diberitahu tentang kemungkinan dari kerusakan tersebut atau tidak. Tanpa membatasi hal umum di atas, kewajiban keseluruhan TreeDots kepada Anda (baik berdasarkan kontrak, gugatan, undang-undang atau lainnya) tidak akan melebihi jumlah biaya yang diterima oleh TreeDots sehubungan dengan layanan yang menimbulkan klaim tersebut. Batasan di atas akan berlaku bahkan jika pemulihan yang disebutkan di atas berbeda dari tujuan dasarnya.',
    p9_3:
      'TreeDots tidak menjamin atau menyatakan bahwa TreeDots menilai atau memantau kesesuaian, legalitas, kemampuan, pergerakan atau lokasi dari setiap pengguna atau penyedia pihak ketiga dan Anda secara tegas mengesampingkan dan melepaskan TreeDots dari setiap dan semua kewajiban, klaim, atau kerugian yang timbul dari atau dengan cara apa pun terkait dengan pengguna atau penyedia pihak ketiga.',
    p9_4:
      'Tidak ada dalam Ketentuan ini yang berlaku untuk atau dengan cara apa pun membatasi atau mengecualikan tanggung jawab TreeDots atas: (a) kematian atau cedera pribadi yang disebabkan oleh kelalaiannya; (b) ketidakjujuran, penipuan atau kesalahan pernyataan; atau (c) kewajiban yang mungkin tidak dibatasi atau dikecualikan berdasarkan peraturan perundang-undangan yang berlaku.',
    product: '4. Informasi Produk dan Harga',
    refunds: '7. Pengembalian dana',
    title: 'Ketentuan Penggunaan TreeDots',
    your_account: '8. Akun Anda'
  },
  test_enterprise_branch: 'TEST-TreeDots Enterprise-Cabang',
  text_copied_to_clipboard: 'Teks disalin ke papan klip',
  text_remove_item_in_cart: 'Item ini akan dihapus dari keranjang Anda?',
  this_field_must_be_a_valid_email: 'Bidang ini harus diisi dengan email yang valid',
  title_check_duplicate: 'Gabungkan pesanan?',
  duplicate_order: 'Duplikasi Pesanan',
  order_merged: 'Pesanan digabung - Item Anda telah ditambahkan ke pesanan',
  new_order_placed: 'Pesanan baru telah dibuat',
  already_has: 'sudah memiliki',
  an_order_with: 'pesanan dengan',
  for_delivery_on: 'untuk pengiriman pada:',
  do_you_want_merge: 'Ingin menggabungkan item ini ke pesanan yang sudah ada?',
  do_you_want_proceed: 'Masih ingin melanjutkan?',
  toast_color: 'utama',
  toast_color_error: 'bahaya',
  tomorrow: 'Besok',
  tooltip_message_end: ' dari apa yang tercantum.',
  tooltip_message_four: 'produk ini mungkin berbeda',
  tooltip_message_second: 'bervariasi dalam ukuran. Final',
  tooltip_message_start: 'Produk ini secara alami',
  tooltip_message_third: 'dari',
  top: 'Teratas',
  top_picks: 'Pilihan Teratas',
  topup: 'Isi Ulang',
  total: 'Total',
  total_amount: 'Total Jumlah',
  total_bill: 'Total Tagihan',
  total_new_price: 'Total Harga Baru',
  total_order: 'Total Pesanan',
  total_payment: 'Total Pembayaran',
  total_price: 'Total Harga',
  total_weight: 'Total Berat',
  transaction: 'Transaksi',
  treedots_groupbuy: 'Groupbuy TreeDots',
  treedots_story: 'Kisah TreeDots',
  treedotspay: 'TreeDots Pay',
  try_again: 'Coba Lagi',
  twitter: 'Twitter',
  twitter_alt_img: 'twitter',
  twitter_link_to_share:
    'https://twitter.com/intent/tweet/?text=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%0Ahttps%3A%2F%2Fthetreedots.com%2Fdownload&amp;url=',
  twitter_logo_url: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/twitter-256.png',
  type_message: 'Ketik pesan di sini',
  un_paid: 'BELUM DIBAYAR',
  pending_payment: 'PENDING PAYMENT',
  paid_payment: 'FAILED',
  unable_to_connect: 'Tidak dapat menyambungkan',
  unable_to_place_order: 'Tidak dapat memesan',
  unavailable: 'Tidak tersedia',
  unavailable_product: 'Produk yang Tidak Tersedia',
  understood_delete_account: 'Saya mengerti, hapus akun saya',
  undo: 'URUNGKAN',
  per_unit: '[Satuan]',
  unit: 'Satuan',
  unitNo: 'Nomor Satuan (opsional)',
  unitPriceCannotBeEmpty: 'Harga satuan tidak boleh kosong',
  unmute: 'Nyalakan',
  update: 'Perbarui',
  updateOrder: 'Perbarui Pesanan',
  confirm_order_update: 'Konfirmasi Pembaruan Pesanan',
  confirm_order_cancel: 'Konfirmasi Pembatalan Pesanan',
  updateProfile: {
    birth_date_cannot_be_empty: 'Tanggal lahir tidak boleh kosong',
    email_cannot_be_empty: 'Email tidak boleh kosong',
    gender_cannot_be_empty: 'Jenis kelamin tidak boleh kosong',
    phone_number_must_be_at_least_5_characters: 'Nomor telepon harus setidaknya 5 karakter'
  },
  update_address: 'Perbarui Alamat',
  update_information: 'Perbarui Informasi',
  update_item_success_mess: 'Berhasil memperbarui status barang!',
  update_modal_header: 'Pembaruan terbaru tersedia!',
  update_modal_message: 'Kami menyempurnakan aplikasi GroupBuy kami. Harap perbarui untuk melanjutkan.',
  update_pre_order: 'Perbarui Pra-Pesanan',
  update_price: 'Perbarui Harga',
  update_profile: 'Perbarui Profil',
  use_this_postal_code: 'Gunakan Kode Pos ini',
  user_not_found_for_mobile:
    'Anda tidak memiliki akun kami. Silakan mendaftar untuk mengakses platform kami.',
  username: 'Nama Pengguna',
  valid_till: 'Berlaku hingga',
  validation: {
    confirm_password: 'Kata sandi yang dimasukkan tidak cocok',
    country_code: 'Pilih kode negara',
    email: 'Harap masukkan alamat email yang valid',
    first_name: 'Nama depan harus lebih dari 2 karakter',
    password: 'Kata sandi harus lebih dari 6 karakter',
    phone: 'Silahkan masukkan nomor telepon yang valid'
  },
  variations: 'VARIASI',
  vegetables: 'Sayur-sayuran',
  veggies: 'Sayuran',
  vendor_connect: 'Vendor Terhubung!',
  verify_phone_number: 'Verifikasi Nomor Telepon',
  verify_your_number: 'Verifikasi nomor Anda',
  view: 'Lihat',
  view_all_collection_points: 'Lihat Semua Titik Pengambilan',
  view_dispute_status: 'Lihat Status Perselisihan',
  view_order: 'Lihat Pesanan',
  vision:
    'Pada tahun 2025, TreeDots berharap dapat menghemat 2 juta ton makanan dari pemborosan, sehingga mengurangi emisi karbon sebesar 18 juta ton.',
  volume_deal: 'PENAWARAN VOLUME',
  volume_deal_s: 'Penawaran Volume',
  voucher: 'Voucer',
  voucher_amount: 'Total Voucer',
  voucher_applied: 'Voucer yang Digunakan',
  voucher_deduction: 'Potongan Voucer',
  vouchers: 'Voucer',
  vouchers_expiring_soon: 'voucer segera kedaluwarsa',
  wa: 'WhatsApp',
  wa_alt_img: 'whatsapp',
  wa_link_to_share:
    'whatsapp://send?text=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%0Ahttps%3A%2F%2Fthetreedots.com%2Fdownload',
  wa_logo_url: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/whatsapp-256.png',
  wa_share_link: 'https://wa.me/6531384846',
  waiting_for_approval: 'Menunggu persetujuan dari penyewa',
  wallet: {
    add_bank_account: 'Tambahkan Rekening Bank',
    add_bank_fail: 'Gagal menambahkan rekening bank yang baru!',
    add_bank_success: 'Berhasil menambahkan rekening bank yang baru!',
    add_new_bank_account: 'Tambahkan Rekening Bank Baru',
    amount_exceeds:
      'Jumlah ini melebihi jumlah maksimum yang dapat ditransfer. Jumlah yang dapat ditransfer:',
    available_withdraw: 'Saldo Tersedia yang Dapat Ditarik',
    balance: 'Saldo',
    completed_withdraw: 'Anda telah menyelesaikan penarikan!',
    info_withdraw: 'Apa yang dimaksud dengan dana yang tersedia?',
    is_required: 'disyaratkan!',
    item_refund: 'Pengembalian Dana Item',
    link_bank: 'Tautkan rekening bank Anda',
    load_withdraw: 'Penarikan Anda sedang diproses',
    main_info_withdraw:
      'Saldo yang diterima dari donasi tidak boleh ditarik. Anda masih dapat menggunakannya sebagai pembayaran untuk pembelian apa pun dalam aplikasi.',
    minimum_withdraw: 'Nilai penarikan minimum adalah $0,11',
    no_transaction: 'Anda belum memiliki daftar transaksi.',
    order_id: 'ID Pesanan',
    order_payment: 'Pembayaran Pesanan',
    order_refund: 'Pengembalian Dana Pesanan',
    primary_refunds: 'Tetapkan sebagai tujuan pengembalian dana utama',
    savings: 'TABUNGAN:',
    select_bank: 'Pilih Bank',
    something_wrong:
      'Terjadi kesalahan... Sepertinya ada masalah dengan transaksi ini. Silakan coba lagi nanti.',
    to: 'kepada',
    top_fail_info:
      'Transaksi Isi Ulang Anda gagal, sepertinya terdapat masalah dengan metode pembayaran yang Anda gunakan. Silakan coba lagi atau pilih metode yang lain.',
    top_up: 'Isi Ulang',
    top_up_page: {
      add_new_bank_account: 'Tambahkan Rekening Bank Baru',
      bank_transfer: 'Transfer Bank',
      bank_transfer_page: {
        add_bank_page: {
          account_name: 'Nama Rekening',
          account_number: 'Nomor Rekening',
          account_type: 'Jenis Rekening',
          add_dbs_ocbc: 'Tambahkan rekening DBS / OCBC',
          bank_code: 'Kode Bank',
          branch_code: 'Kode Cabang',
          current_account: 'Rekening Saat Ini',
          instruction_1: 'Masuk ke aplikasi perbankan Anda',
          instruction_2:
            'Pilih opsi Transfer ke <strong>rekening bank lokal</strong>, lalu pilih <strong>Tambahkan Penerima Pembayaran</strong>',
          instruction_3:
            'Pilih opsi untuk menambahkan penerima pembayaran menggunakan rincian <strong>Rekening Bank</strong>',
          instruction_4: 'Di bawah Nama Bank, pilih <strong>TREEDOTS ENTERPRISE PTE LTD</strong>',
          instruction_5: 'Masukkan nomor rekening Anda',
          instruction_5_1: '6591118888',
          instruction_5_2: '<strong>Salin</strong>',
          instruction_6: 'Pastikan nomor rekening benar',
          instruction_7:
            'Masukkan <strong>Nama penerima pembayaran</strong> Anda dan <strong>Konfirmasi</strong>, lalu pilih <strong>Transfer ke Penerima Pembayaran</strong>',
          instruction_8:
            'Masukkan jumlah isi ulang Anda, tambahkan catatan apa pun untuk penerima lalu Konfirmasi',
          instruction_9: 'TreeDots akan menerima isi ulang Anda dalam waktu 30 detik',
          saving_account: 'Rekening Tabungan',
          success: 'Anda sekarang dapat menggunakan Rekening Bank Anda dengan TreeDots',
          top_up_via: 'Isi Ulang melalui Transfer Bank',
          verified: 'Diverifikasi!',
          we_have_transferred:
            'Kami telah mentransfer sejumlah uang ke rekening di atas. Jumlah yang diterima:'
        },
        amount: 'Jumlah',
        amount_tip: 'Pastikan Anda mengirim jumlah yang tepat seperti yang ditunjukkan.',
        copy: 'Salin',
        instruction_1:
          'Masuk ke halaman ibanking Anda, dan ikuti informasi di bawah ini untuk melakukan transfer.',
        instruction_2: 'Tambahkan penerima baru dengan mengikuti informasi di bawah ini.',
        instruction_3:
          'Setelah pembayaran berhasil diterima, faktur ini akan diperbarui secara otomatis dalam waktu 5 menit. (Anda mungkin perlu menyegarkan halaman).',
        instruction_title: 'Petunjuk Transfer:',
        recipient_account_number: 'Nomor Rekening Penerima',
        recipient_account_number_placeholder: '6591118888',
        recipient_account_number_tip:
          'Kiat: Periksa apakah nomor rekening yang Anda masukkan di halaman ibanking sudah tepat.',
        recipient_bank: 'Bank Penerima',
        recipient_bank_placeholder: 'CIMB',
        recipient_name: 'Nama Penerima',
        recipient_name_placeholder: 'TreeDots',
        top_up_via: 'Isi Ulang melalui Transfer Bank'
      },
      card_page: {
        maximum_top_up: 'Nilai isi ulang maksimum adalah',
        minimum_top_up: 'Nilai isi ulang minimum adalah',
        top_up_amount: 'Jumlah isi ulang:',
        top_up_via: 'Isi Ulang melalui Kartu Kredit / Kartu Debit'
      },
      complete_page: {
        paid_total: 'Total yang dibayarkan ',
        the_top_up_amount:
          'Jumlah isi ulang akan diperbarui di dompet Anda yang mungkin memakan waktu beberapa menit',
        title: 'Isi ulang selesai!'
      },
      credit_card_debit_card: 'Kartu Kredit/Kartu Debit',
      fpx: 'FPX',
      grabpay: 'GrabPay',
      grabpay_page: {
        top_up_via: 'Isi Ulang GrabPay'
      },
      modal_page: {
        bank_transfer: 'Transfer Bank',
        fee: 'Biaya',
        grabpay: 'GrabPay',
        netslink: 'NETSLink',
        no_fee: 'Tanpa Biaya',
        ok: 'Oke',
        payment_method: 'Metode Pembayaran',
        paynow: 'PayNow',
        please_make_sure: 'Pastikan saldo di rekening Anda cukup atau pilih metode pembayaran lain.',
        review_and_confirm: 'Tinjau dan Konfirmasi',
        top_up_amount: 'Jumlah isi ulang',
        top_up_failed: 'Isi ulang gagal!',
        top_up_successful: 'Isi ulang berhasil!',
        total: 'Total',
        your_balance: 'Saldo Anda akan diperbarui dalam beberapa menit'
      },
      nets: 'NETSLink',
      netslink_page: {
        top_up_via: 'Isi ulang NETSLink'
      },
      paynow: 'PayNow',
      paynow_old_page: {
        add_your: 'Tambahkan Nomor PayNow Anda',
        instruction_1: 'Masuk ke aplikasi perbankan Anda dan pilih PayNow',
        instruction_2: 'Pilih <strong>PayNow ke VPA</strong>',
        instruction_3: 'Masukkan VPA lengkap Anda seperti yang ditunjukkan di bawah ini:',
        instruction_3_1: '+6591118888#TREEDOTS',
        instruction_3_2: '<strong>Salin</strong>',
        instruction_4: 'Pastikan <strong>Nama Panggilan Penerima</strong> sudah benar.',
        instruction_5: 'Masukkan jumlah isi ulang yang Anda inginkan',
        instruction_6: 'Isi ulang Anda akan terlihat di aplikasi ini dalam waktu 30 detik.',
        top_up_via: 'Isi Ulang melalui PayNow'
      },
      paynow_page: {
        account_name: 'Nama Akun',
        account_name_placeholder: 'TreeDots',
        click_to_download: 'Klik untuk Mengunduh kode QR',
        copy: 'Salin',
        instruction_1_1: 'Pastikan untuk hanya menggunakan',
        instruction_1_2: 'PayNow ',
        instruction_1_3: 'aplikasi untuk melakukan pembayaran.',
        instruction_2_1: 'Pastikan Anda melakukan pembayaran ke XFERS PTE. LTD. - REKENING KLIEN',
        instruction_title: 'PETUNJUK TRANSFER',
        the_qr_code:
          'Kode QR hanya berlaku untuk transaksi khusus ini. Saat memindai kode qr, jumlah dan nomor referensi akan terisi secara otomatis.',
        top_up_via: 'Isi Ulang melalui PayNow',
        uen: 'UEN (Nomor Entitas Unik)',
        uen_placeholder: '4318123452R13'
      }
    },
    total_treedotspay: 'Total Saldo TreeDots Pay:',
    transaction_history: 'Riwayat Transaksi',
    transfer_bank: 'Transfer ke Bank',
    transfer_to: 'Transfer ke:',
    tree_wallet: 'Tree-Wallet',
    turn_off_refunds_bottom:
      'Pengembalian dana akan memakan waktu 7-10 hari kerja hingga muncul di bank Anda. Untuk menerima pengembalian dana secara waktu nyata, pilih opsi TreeDots Pay sebagai default.',
    turn_off_refunds_title: 'Nonaktifkan pengembalian dana ke TreeDots Pay?',
    turn_off_refunds_top:
      'Jika dinonaktifkan, Anda akan menerima semua pengembalian dana di masa mendatang untuk pesanan yang dibatalkan ke rekening bank atau metode pembayaran yang Anda gunakan saat checkout.',
    turn_on_refunds:
      'Menetapkan TreeDots Pay sebagai tujuan utama pengembalian dana akan memungkinkan Anda menerima semua pengembalian dana di masa mendatang dari pesanan yang dibatalkan secara waktu nyata. Anda dapat mengubah pengaturan ini kapan saja.',
    turn_on_refunds_title: 'Aktifkan pengembalian dana ke TreeDots Pay?',
    understood: 'Saya Mengerti',
    view_all: 'Lihat Semua',
    volume_deal_refund: 'Pengembalian Dana Penawaran Volume',
    withdraw: 'Tarik',
    withdraw_amount: 'Jumlah penarikan:',
    withdraw_appear: 'Jumlah penarikan akan muncul di rekening bank Anda dalam waktu beberapa menit',
    withdraw_fail_info:
      'Transaksi Penarikan Anda gagal, sepertinya ada masalah dengan rekening bank yang Anda coba gunakan. Silakan coba lagi atau pilih bank lain.',
    withdrawal: 'Penarikan',
    withdrawal_failed: 'Penarikan gagal',
    your_wallet_is_protected: 'Dompet Anda terlindungi.'
  },
  we_are_sorry: 'Kami mohon maaf!',
  we_will_proceed_to_clear_your_cart: 'Kami akan melanjutkan untuk mengosongkan keranjang Anda',
  weight: 'Berat',
  weight_size: 'berat, ukuran',
  welcome_again: 'Selamat Datang Kembali!',
  what_is_collection_point: 'Apa itu Titik Pengambilan?',
  whatis_next: {
    content:
      '2021 adalah tahun yang menyenangkan bagi kami! Kami menghadirkan lebih banyak pemasok dan memperluas layanan kami agar menjadi solusi satu atap bagi manajemen pesanan pemasok, manajemen inventaris, logistik pihak ketiga, dan layanan Keuangan.',
    title: 'Apa Selanjutnya?'
  },
  which_state: 'Di negara bagian mana Anda tinggal?',
  yes: 'Ya',
  yes_confirm: 'Ya, Konfirmasi',
  yes_connect_to_existing_company: 'Ya, hubungkan ke perusahaan yang ada',
  yes_leave_community_chat: 'YA, KELUAR',
  yes_reject: 'Ya, Tolak',
  yesterday: 'Kemarin',
  your_information_is_protected: 'Informasi Anda dilindungi.',
  your_order: 'Pesanan Anda',
  your_referral: 'Perujuk Anda',
  your_referral_code: 'Kode perujuk Anda',
  your_referrees: 'Penerima rujukan Anda',
  your_reward: 'Hadiah Anda',
  please_select_cuisine:
    'Silakan pilih masakan yang terkait dengan bisnis Anda. Anda dapat memilih lebih dari satu!',
  what_type_of_your_business: 'Apa jenis masakan bisnis Anda?',
  selection: 'Pilihan',
  cuisine_preference: 'PREFERENSI MASAKAN',
  edit_cusine: 'Edit Masakan',
  add_cuisine: 'Tambahkan Masakan',
  cuisine_preference_optional: 'Preferensi Masakan (Opsional)',
  tell_us_about_your_business: 'Beritahu kami tentang bisnis Anda',
  cuisine: 'Masakan',
  do_you_have_any_feedback: 'Ada Masukan? (Opsional)',
  input_feedback: 'Masukkan Umpan Balik',
  have_not_set_pwd: 'Belum mengatur kata sandi?',
  click_here: 'Klik Disini',
  dont_have_pwd: 'Belum punya kata sandi?',
  enter_your_mobile_number_to_set_pwd:
    'Masukkan nomor ponsel Anda dan kami akan mengirimkan kode verifikasi untuk mengatur kata sandi baru.',
  set_pwd: 'Atur Kata Sandi',
  phone_verified: 'Nomor telepon berhasil diverifikasi!',
  password_successfully_set: 'Kata sandi berhasil diatur!',
  all_companies: 'Semua Perusahaan',
  company_request: 'Permintaan Perusahaan',
  company_in_review: 'Perusahaan dalam Tinjauan',
  you_have_company: 'Anda memiliki perusahaan yang menunggu tindakan Anda',
  we_are_still_process: 'Kami masih dalam proses meninjau perusahaan Anda',
  select_company_pic_name: 'Pilih Perusahaan atau Nama PIC',
  let_us_get_started: 'Ayo Mulai!',
  we_are_reviewing_company: 'Kami sedang meninjau perusahaan Anda',
  step1_add_company: 'Langkah 1: Tambahkan perusahaan',
  step2_we_will_review: 'Langkah 2: Kami akan meninjau dan menyetujui perusahaan Anda',
  step3_start_ordering: 'Langkah 3: Mulai Memesan!',
  add_new_company: 'Tambah Perusahaan Baru',
  search_company: 'Cari Perusahaan atau Nomor Rekening',
  search_company_admin: 'Cari Perusahaan, PIC atau Nomor Akun',
  thank_you_first_company: 'Terima kasih telah menambahkan perusahaan pertama Anda!',
  we_received_registration:
    'Kami telah menerima pendaftaran Anda dan sedang dalam proses meninjau perusahaan Anda.',
  get_started: 'Ayo Mulai!',
  other_info_error: 'Jumlah maksimum 255 karakter terlampaui',
  file_upload_error: 'Ukuran maksimum file sebesar 50 MB terlampaui',
  select_variation: 'Pilih Variasi',
  you_have_overdue: 'Anda memiliki ({totalInvoices}) faktur yang belum dibayar',
  kindly_make_payment: 'Mohon lakukan pembayaran sekarang ',
  my_invoices: 'Faktur Saya',
  customer_balance: 'Saldo Pelanggan',
  unpaid: 'Belum Dibayar ({unpaidAmount})',
  unpaid_invoices: 'Faktur yang belum Dibayar ({unpaidInvoiceAmount})',
  overdue: 'Jatuh Tempo',
  overdue_copy: 'Jatuh Tempo',
  all_invoices: 'Semua Faktur',
  pay_invoices: 'Bayar Faktur',
  total_invoice_amount: 'Jumlah Total Faktur',
  download_invoice: 'Unduh Faktur',
  invoice_date: 'Tanggal Faktur: {invoiceDate} ',
  due: 'Jatuh Tempo: {dueDate}',
  awaiting_payment: 'Menunggu Pembayaran',
  pending_payment_approval: 'Persetujuan Pembayaran Tertunda',
  paid_copy: 'Dibayar',
  select_payment: 'Pilih Pembayaran',
  use_customer_balance: 'Gunakan Saldo Pelanggan',
  digital_payment_method: 'Metode Pembayaran Digital',
  other_payment_method: 'Pembayaran Lainnya',
  summary: 'Ringkasan',
  pay: 'Bayar',
  credit_note: 'Catatan Kredit ({creditCode})',
  overpayment: 'Kelebihan Pembayaran',
  bank_transfer_detail: 'Perincian Transfer Bank',
  transaction_date: 'Tanggal Transaksi',
  payment_value_date: 'Tanggal Nilai Pembayaran',
  the_date_that_effective: 'Tanggal berlakunya transaksi.',
  payment_proof: 'Bukti Pembayaran',
  reference_code: 'Kode Referensi (Opsional)',
  input_reference_provided:
    'Masukkan nomor referensi yang disediakan bank Anda untuk transaksi pembayaran ini.',
  notes: 'Catatan (Opsional)',
  payment_overdue_invoice: 'Pembayaran untuk faktur jatuh tempo untuk {userName}',
  enter_desc_transaction: 'Masukkan deskripsi tentang transaksi ini.',
  upload_file: 'Unggah File',
  max_file_size: 'Maks. ukuran file 50 mb',
  payment_summary: 'Ringkasan Pembayaran',
  payment_pending: 'Pembayaran Tertunda',
  payment_success: 'Pembayaran Berhasil',
  payment_failed: 'Pembayaran Gagal',
  back_to_invoice: 'Kembali ke Daftar Faktur',
  pending: 'Tertunda',
  invoice_list: 'Faktur',
  reference_number: 'Nomor Referensi',
  payment_time: 'Waktu Pembayaran',
  recipient: 'Penerima',
  payment_details: 'Perincian Pembayaran',
  image_success_transaction: 'Gambar transaksi Anda yang berhasil',
  use_available_credits: 'Gunakan Kredit yang tersedia',
  remaining_credits: 'Sisa Kredit:',
  request_detail_placeholder:
    'Contoh: Saya ingin menerapkan semua kredit pelanggan yang tersedia ke Faktur A dan tidak ada yang ke Faktur B',
  request_detail_let_us_know:
    'Beri tahu kami di sini jika Anda memiliki permintaan tentang alokasi kredit pelanggan.',
  request_detail_optional: 'Perincian Permintaan (Opsional)',
  apply_available_credit: 'Ajukan Kredit yang Tersedia',
  input_amount: 'Masukkan Jumlah',
  note_only: 'Catatan',
  request_details_only: 'Perincian Permintaan',
  payment_pending_info:
    'Kami sedang memproses pembayaran Anda dan akan memakan waktu beberapa lama agar status pembayaran yang diperbarui terlihat pada sistem',
  grand_total: 'Total Keseluruhan',
  end_of_week: 'Akhir Pekan',
  available_credits: 'Kredit yang Tersedia',
  payment_creation_failed: 'Pembuatan Pembayaran Gagal',
  missing_payment_proof: 'Silakan unggah bukti pembayaran',
  applied_credits: 'Kredit Terapan',
  bank_transfer: 'Transfer Bank',
  type_your_company_cuisine: 'Ketik masakan perusahaan Anda disini',
  what_type_cuisine_company_sell: 'Apa jenis masakan yang dijual perusahaan Anda?',
  bargain_price: 'Harga Tawar',
  special_price: 'Harga Spesial',
  support_information: 'Informasi pendukung / alasan',
  input_proposed: 'Masukkan harga yang diusulkan atau jumlah pesanan mingguan',
  pending_approve: 'Menunggu Persetujuan',
  request_sent: 'Permintaan Terkirim!',
  refresh: 'Segarkan',
  all_rights_reserved: 'Hak Cipta Dilindungi',
  only_allowed_symbol: 'Simbol yang diperbolehkan hanya',
  cannot_find_product: 'Ups, kami tidak dapat menemukan produk ini.',
  select_another_one: 'Silakan pilih yang lain!',
  back_to_home: 'Kembali ke Beranda',
  manage_companies: 'Kelola Perusahaan',
  cheque: 'Cek',
  cash: 'Tunai',
  edit_payment_amount: 'Edit Jumlah Pembayaran',
  amount_due: 'Jumlah Terutang',
  update_payment: 'Perbarui Pembayaran',
  update_amount: 'Perbarui Jumlah',
  deposit_date: 'Tanggal Setoran Tunai',
  cheque_date: 'Tanggal Penyetoran Cek',
  transaction_reference_number: 'No. Referensi Transaksi',
  cash_detail: 'Perincian Uang Tunai',
  cheque_detail: 'Perincian Cek',
  payment_amount_warning: 'Total Pembayaran harus lebih dari',
  not_sure_avail_credit: 'Tidak yakin apa itu Kredit yang Tersedia?',
  not_sure_credit_desc:
    'Jika Anda memiliki pertanyaan lebih lanjut tentang kredit yang tersedia dan cara menggunakannya, silakan hubungi kami di bawah ini',
  verify_mobile_num: 'Verifikasi Nomor Ponsel',
  sorry_went_wrong: 'Maaf, terjadi kesalahan',
  we_detected_unusual_activity:
    'Kami telah mendeteksi aktivitas yang tidak wajar pada alamat IP Anda dan telah menangguhkan sementara fitur ini. Silakan coba lagi dalam:',
  invalid_amount_negative: 'Jumlah tidak valid. Jumlah masukan tidak boleh negatif',
  to: 'Ke',
  future_date_error: 'Pilih tanggal yang sesuai dengan atau sebelumnya',
  past_date_error: 'Pilih tanggal yang sesuai dengan atau setelahnya',
  make_payment: 'Lakukan Pembayaran',
  cash_payment_collection: 'Pembayaran Tunai (Penagihan)',
  cash_payment_collection_detail: 'Perincian Pembayaran Tunai (Penagihan)',
  cash_collection_date: 'Tanggal Pengambilan Tunai',
  cash_deposit: 'Setoran Tunai',
  cash_deposit_detail: 'Perincian Setoran Tunai',
  input_amount_error: 'Jumlah masukan tidak boleh lebih besar dari kredit tersisa',
  input_amount_error_2: 'Jumlah input tidak boleh lebih besar dari jumlah total yang harus dibayar',
  image_error_size: 'Ukuran gambar terlalu besar. Silakan unggah gambar dengan ukuran kurang dari 5 MB',
  image_error_type: 'Hanya file gambar yang diperbolehkan',
  amount_due_error: 'Jumlah yang harus dibayar tidak boleh lebih besar dari',
  invoice_cn: {
    invoice: 'Faktur',
    credit_note: 'Nota Kredit',
    invoice_cn: 'Faktur dan Nota Kredit',
    bill_to: 'TAGIHAN UNTUK',
    grand_total: 'TOTAL KESELURUHAN',
    sub_total: 'Sub Total',
    tax: 'Pajak',
    download: 'Unduh untuk melihat Faktur asli',
    download_pdf: 'Unduh Faktur (pdf)',
    view_invoice_cn: 'Lihat Faktur dan Nota Kredit',
    invoices: 'FAKTUR',
    cn: 'NOTA KREDIT',
    all_file: 'Unduh Semua File',
    cn_amount: 'JUMLAH NOTA KREDIT',
    download_cn: 'Unduh untuk melihat Nota Kredit asli',
    download_cn_pdf: 'Unduh Nota Kredit (pdf)',
    download_all: 'Unduh Semua File',
    view: 'Lihat',
    download_file: 'Berhasil mengunduh file',
    download_files: 'Berhasil mengunduh file',
    download_file_fail: 'File gagal diunduh',
    download_files_fail: 'File gagal diunduh',
    view_document: 'View Document',
    preview: 'Preview',
    share_invoice: 'Share Invoice',
    share_credit_note: 'Share Credit Note'
  },
  search_company_not_found: 'Ups, perusahaan tidak ditemukan',
  try_different_name: 'Coba Nama Perusahaan atau Nomor Akun yang berbeda',
  try_different_name_admin: 'Coba Nama Perusahaan, PIC, atau Nomor Akun yang berbeda',
  sorry_could_not_find: 'Maaf, tidak dapat menemukan',
  sorry_not_any_products: 'Sorry, we couldn’t find any products!',
  sorry_not_found: 'Maaf, kami tidak dapat menemukan "{search_keyword}"',
  try_different_keyword: 'Silakan coba kata kunci yang berbeda',
  today: 'Hari ini',
  companies: 'Perusahaan',
  select_other_company: 'Pilih Perusahaan Lain',
  selected_count: '{selected_count} Dipilih',
  customer_instruction: 'Petunjuk Pelanggan',
  delivery_instruction_hint: 'Contoh: Masukkan petunjuk tetap untuk semua pesanan dari pelanggan ini',
  ex_delivery_instruction: 'Contoh: "Masukkan petunjuk tetap untuk semua pesanan dari pelanggan ini"',
  no_invoices: 'Belum ada faktur!',
  browse_your_business: 'Mulai jelajahi bisnis Anda',
  browse_product: 'Jelajahi Produk',
  update_app: 'Memperbarui Versi Aplikasi',
  better_experience: 'Kami memperbarui aplikasi agar Anda mendapatkan pengalaman yang lebih baik',
  wait_update: 'Mohon tunggu hingga pembaruan ini selesai.',
  default_billing_address: 'Alamat Penagihan Default',
  set_as_default_address: 'Tetapkan sebagai alamat penagihan default',
  select_address: 'Pilih Alamat',
  you_have_no_company: 'Anda tidak memiliki perusahaan.',
  register_your_company: 'Daftarkan perusahaan Anda sekarang untuk membeli produk',
  previous: 'Sebelumnya',
  updated: 'Diperbarui',
  removed: 'Dihapus',
  new_request: 'Permintaan Baru',
  dismiss: 'Tolak',
  view_details: 'Lihat Perincian',
  master_card_debit: 'Master Card (debit)',
  visa_card_debit: 'Visa Card (debit)',
  nets_payment_error: 'NETs payment error',
  on_registration: 'Saat Pendaftaran',
  alert_error_header: 'Kesalahan',
  select_business_profile: 'Pilih Profil Bisnis',
  connection_issue_message: 'Tidak dapat memuat - Pastikan Anda terhubung ke Internet lalu coba lagi',
  unable_load: 'Tidak dapat memuat',
  please_make_sure_connected: 'Pastikan Anda terhubung ke Internet lalu coba lagi',
  page_taking_longer:
    'Proses memuat halaman ini membutuhkan waktu yang lebih lama dari biasanya. Sebaiknya periksa koneksi internet Anda',
  pull_to_refresh: 'Tarik ke bawah untuk menyegarkan',
  black_listed: 'Diblokir',
  search_all_products: 'Cari Semua Produk',
  product_category: 'Kategori Produk',
  relevant: 'Relevan',
  filter: 'Filter',
  favorites: 'Favorit',
  result_for: 'Hasil untuk <strong>"<i>{search_key}</i>"</strong>',
  result_from: 'Hasil dari <strong>Kategori Produk: {category_name}</strong>',
  product_have_purchased: 'Produk yang telah Anda beli',
  more_products: 'Produk Lainnya',
  with_price: 'Dengan harga',
  in_stock: 'Tersedia',
  apply_search_filter: 'Terapkan Filter',
  reset_search_filter: 'Reset Filter',
  no_preview_docs: 'Tidak Tersedia Pratinjau',
  cancel_payment: 'Batalkan Pembayaran',
  cancel_payment_desc: 'Pembayaran Anda akan dibatalkan',
  pending_verification: 'Verifikasi Tertunda',
  view_invoice: 'Lihat Faktur',
  view_payment_history: 'Lihat Riwayat Pembayaran',
  payment_history: 'Riwayat Pembayaran',
  total_paid_amount: 'Jumlah Total yang dibayarkan',
  partially_paid: 'Dibayar Sebagian',
  order_processed: 'Pesanan Diproses',
  order_processing: 'Pesanan Ditempatkan',
  order_cancel_by_supplier: 'Pesanan Dibatalkan oleh Pemasok',
  order_cancelled: 'Pesanan Dibatalkan',
  delivery_started: 'Pengiriman Dimulai',
  delivered: 'Terkirim',
  failed_delivery: 'Pengiriman Gagal',
  order_completed: 'Pesanan Selesai',
  receive_your_order: 'Kami telah menerima pesanan Anda',
  is_preparing_order: 'sedang menyiapkan pesanan Anda',
  cancelled_by_supplier: 'Pesanan Anda telah dibatalkan oleh {supplierName}',
  has_been_cancelled: 'Pesanan Anda telah dibatalkan.',
  has_pickup_order: 'Mitra logistik kami telah mengambil barang pesanan Anda',
  delivered_success: 'Barang pesanan Anda berhasil dikirim!',
  delivery_failed: 'Maaf, upaya pengiriman kami gagal',
  order_has_been_completed: 'Pesanan Anda telah selesai!',
  track_order: 'Lacak Pesanan',
  catch_weight_cap: 'BOBOT TANGKAPAN',
  cap_grand_total: 'TOTAL KESELURUHAN',
  delivery_information: 'INFORMASI PENGIRIMAN',
  billing_information: 'INFORMASI TAGIHAN',
  bill_name_and_address: 'Nama dan Alamat Penagihan',
  order_delivery_instruction: 'Instruksi Pengiriman',
  view_driver_location: 'Lihat Lokasi pengemudi di Peta',
  not_found_order: 'Maaf, kami tidak dapat menemukan pesanan yang Anda cari!',
  view_all_order: 'Lihat Semua Pesanan',
  cancellation_reason: 'Alasan Pembatalan',
  failure_reason: 'Alasan Kegagalan',
  note: 'Catatan',
  track_driver_location: 'Lihat Lokasi Pengemudi',
  view_image: 'Lihat Gambar',
  payment_date: 'Tanggal Pembayaran',
  invoice_total: 'Invoice Total',
  items: 'Items'
};
export default en;
