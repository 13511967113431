import gql from 'graphql-tag';

export const getProductsOosStatus = gql`
  query getProductsOosStatus($productIds: [Int], $tenantId: String, $buyerId: String) {
    getProductsOosStatus(productIds: $productIds, tenantId: $tenantId, buyerId: $buyerId) {
      id
      is_oos
    }
  }
`;

export const getProductDetails = gql`
  query getProductDetails($productId: Int!, $tenantId: String, $buyerId: String) {
    getProductDetails(productId: $productId, tenantId: $tenantId, buyerId: $buyerId) {
      id
      name
      halal
      description
      is_single_variant
      tenant_id
      image
      date_quoted
      tenant {
        tenant {
          name
          persons {
            phone
          }
        }
        email_notification
        class_id
        email
      }
      categories {
        main_categories {
          id
          name
        }
        sub_categories {
          name
        }
      }
      skusData {
        sku_id
        quotation_status
        limit_price
        is_favorite
        increment_qty
        specs
        weight
        unit_amount
        uom
        unit_per_item
        unit_per_item_uom
        is_out_of_stock
        price
        is_order_by_weight
        is_catch_weight
        sku
        origin_price
        is_back_order
        total_stock_location
        total_available_quantity
        unit_price
        halal
        inven_id
        product_type_id
        quantity
        tax_rate
        oom
        direct_price
        is_special_price
        is_purchased
      }
    }
  }
`;
