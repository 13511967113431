import { alertController } from '@ionic/core';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
export const useAlert = () => {
  const alert = ref();
  const { t } = useI18n();
  const createAlert = async (header, message, functionClose) => {
    alert.value = await alertController.create({
      header: header,
      message: message,
      buttons: [
        {
          text: t('close'),
          role: 'close',
          handler: functionClose
        }
      ]
    });
    return alert.value.present();
  };

  return {
    createAlert,
    alert
  };
};
export const presentAlert = async (payload) => {
  const { mode, cssClass, header, subHeader, message, buttons } = payload;
  const alert = await alertController.create({
    mode: mode,
    cssClass: cssClass,
    header: header,
    subHeader: subHeader,
    message: message,
    buttons: buttons
  });
  await alert.present();
};
