import { DEFAULT_STATE } from '@/modules/b2b/constants';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const state = {
  ...DEFAULT_STATE,
  list: []
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
