import { checkmarkCircleOutline, closeOutline, paperPlaneOutline, repeatOutline } from 'ionicons/icons';

export const OFFSET_LIST_DEFAULT = 0;

export const QUOTATION_STATUS_ICON = {
  received: {
    icon: checkmarkCircleOutline,
    color: 'primary'
  },
  rejected: {
    icon: closeOutline,
    color: 'danger'
  },
  requested: {
    icon: paperPlaneOutline,
    color: 'light'
  },
  'pending approval': {
    icon: repeatOutline,
    color: 'primary'
  }
};

export const UNIT_DEFAULT = 'Kg';
export const MAX_INPUT = 9999;
export const INPUT_QUANTITY_BY_WEIGHT = 1;
export const TOAST_COLOR = {
  PRIMARY: 'primary',
  DANGER: 'danger',
  BLACK: 'tertiary'
};

export const NUMBER_OF_DECIMAL_PLACES = 2;

export const USER_TYPE_ID = {
  ADMIN: 2,
  SALE: 7
};
export const NUMBER_OF_NOTIFICATION = {
  NEW_PRODUCT: [1],
  PRICE_UPDATE: [2, 6, 13, 16, 22, 23],
  QUOTATION: [3, 4, 5],
  ORDER: [7, 8, 9, 10, 11, 12],
  PAYMENT: [30],
  ORDER_JOURNEY: [31, 32, 33]
};

export const USER_TYPE = {
  SALE: [2, 7],
  B2B: [1, 6, 12]
};

export const USER_COUNTRY = {
  SG: 193,
  MY: 127,
  INA: 96
};

export const DATE_RELEASE_CUISINE_MERCHANT_APP = new Date('2022-06-27');
export const LOAD_THRESHOLD_PX = 300;

export const CATEGORY_ENABLE_OOS = ['history', 'favourite', 'quoted'];
export const MERCHANT_APP_NAME = 'TreeDots for Merchants';
export const ACTION_BY_MERCHANT = 'Action by merchant user';

export const ORDER_STATUS_NAME = {
  PROCESSING: 'Processing',
  PROCESSED: 'Processed',
  UPDATED: 'Updated',
  CANCELLED: 'Cancelled',
  DELIVERING: 'Delivering',
  DELIVERED: 'Delivered',
  FAILED_DELIVERY: 'Failed Delivery',
  DELIVERY_SUCCEED: 'Delivery Succeed',
  DELIVERY_STARTED: 'Delivery Started',
  ARRIVED: 'Arrived',
  COLLECTED: 'Collected',
  COMPLETED: 'Completed'
};
