import { apolloClient } from '@/main';
import {
  addStripeCard,
  createPaymentB2b,
  createPaymentChequeCash,
  createSaleAutomaticBatchPayments,
  getListCustomerCard,
  removeStripeCard,
  saleGetInvoicesByCustomer,
  saleGetIsFinanceModuleEnabled,
  saleGetTotalUnpaidInvoices,
  saleGetXeroCustomer,
  saleListStripeCard,
  setDefaultCard
} from '@/modules/sale/services/graphql';
import { getDefaultCard, getInvoicePaymentHistory } from '@/modules/shared/services/graphql';
import { MUTATIONS } from './mutations';

export const ACTIONS = {
  GET_LIST_CUSTOMER_CARD: 'GET_LIST_CUSTOMER_CARD',
  GET_DEFAULT_CARD: 'GET_DEFAULT_CARD',
  REMOVE_CARD: 'REMOVE_CARD',
  ADD_STRIPE_CARD: 'ADD_STRIPE_CARD ',
  SET_DEFAULT_CARD: 'SET_DEFAULT_CARD',
  SALE_LIST_STRIPE_CARD: 'SALE_LIST_STRIPE_CARD',
  GET_CUSTOMER_INVOICES: 'GET_CUSTOMER_INVOICES',
  LOAD_MORE_CUSTOMER_INVOICES: 'LOAD_MORE_CUSTOMER_INVOICES',
  RESET_STATUS_ERROR: 'RESET_STATUS_ERROR',
  GET_XERO_CUSTOMER: 'GET_XERO_CUSTOMER',
  GET_TOTAL_UNPAID_INVOICES: 'GET_TOTAL_UNPAID_INVOICES',
  CREATE_BANK_PAYMENT: 'CREATE_BANK_PAYMENT',
  CREATE_CASH_CHEQUE_PAYMENT: 'CREATE_CASH_CHEQUE_PAYMENT',
  SET_SELECTED_PAYMENT_INVOICES: 'SET_SELECTED_PAYMENT_INVOICES',
  REMOVE_SELECTED_PAYMENT_INVOICES: 'REMOVE_SELECTED_PAYMENT_INVOICES',
  SET_SELECTED_PAYMENT_METHOD: 'SET_SELECTED_PAYMENT_METHOD',
  REMOVE_SELECTED_PAYMENT_METHOD: 'REMOVE_SELECTED_PAYMENT_METHOD',
  CHECK_IS_FINANCE_MODULE_ENABLED: 'CHECK_IS_FINANCE_MODULE_ENABLED',
  CREATE_AUTOMATIC_BATCH_PAYMENTS: 'CREATE_AUTOMATIC_BATCH_PAYMENTS',
  GET_PAYMENT_HISTORY: 'GET_PAYMENT_HISTORY'
};

export default {
  // get list customer card
  async [ACTIONS.GET_LIST_CUSTOMER_CARD]({ commit }) {
    commit(MUTATIONS.GET_LIST_CUSTOMER_CARD_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getListCustomerCard
      });
      commit(MUTATIONS.GET_LIST_CUSTOMER_CARD_SUCCESS, data.listCardByCustomerId);
    } catch (error) {
      commit(MUTATIONS.GET_LIST_CUSTOMER_CARD_FAILURE, error);
    }
  },

  // get default card
  async [ACTIONS.GET_DEFAULT_CARD]({ commit }) {
    commit(MUTATIONS.GET_DEFAULT_CARD_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getDefaultCard
      });
      commit(MUTATIONS.GET_DEFAULT_CARD_SUCCESS, data.getDefaultCardByCustomer.cardId);
    } catch (error) {
      commit(MUTATIONS.GET_DEFAULT_CARD_FAILURE, error);
    }
  },

  // remove card
  async [ACTIONS.REMOVE_CARD]({ commit, dispatch }, cardId) {
    commit(MUTATIONS.REMOVE_CARD_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: removeStripeCard,
        variables: {
          cardId
        }
      });
      if (data.removeCardByCustomer.error) {
        commit(MUTATIONS.REMOVE_CARD_FAILURE, data.removeCardByCustomer);
      } else {
        commit(MUTATIONS.REMOVE_CARD_SUCCESS, data.removeCardByCustomer);
        dispatch(ACTIONS.GET_LIST_CUSTOMER_CARD);
        dispatch(ACTIONS.GET_DEFAULT_CARD);
      }
    } catch (error) {
      commit(MUTATIONS.REMOVE_CARD_FAILURE, error);
    }
  },

  // add stripe card
  async [ACTIONS.ADD_STRIPE_CARD]({ commit, dispatch }, card) {
    commit(MUTATIONS.ADD_STRIPE_CARD_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: addStripeCard,
        variables: card
      });
      if (data.addCardByCustomer.error) {
        commit(MUTATIONS.ADD_STRIPE_CARD_FAILURE, data.addCardByCustomer);
      } else {
        commit(MUTATIONS.ADD_STRIPE_CARD_SUCCESS, data.addCardByCustomer);
        dispatch(ACTIONS.GET_LIST_CUSTOMER_CARD);
        dispatch(ACTIONS.GET_DEFAULT_CARD);
      }
    } catch (error) {
      commit(MUTATIONS.ADD_STRIPE_CARD_FAILURE, error);
    }
  },

  // set default card
  async [ACTIONS.SET_DEFAULT_CARD]({ commit }, cardId) {
    commit(MUTATIONS.SET_DEFAULT_CARD_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: setDefaultCard,
        variables: {
          cardId
        }
      });
      if (data.setDefaultCardByCustomer.error) {
        commit(MUTATIONS.SET_DEFAULT_CARD_FAILURE, data.setDefaultCardByCustomer);
      } else {
        commit(MUTATIONS.SET_DEFAULT_CARD_SUCCESS, data.setDefaultCardByCustomer.cardId);
      }
    } catch (error) {
      commit(MUTATIONS.SET_DEFAULT_CARD_FAILURE, error);
    }
  },
  async [ACTIONS.SALE_LIST_STRIPE_CARD]({ commit }, params) {
    try {
      const { data } = await apolloClient.query({
        query: saleListStripeCard,
        variables: {
          customerId: params
        }
      });
      commit(MUTATIONS.SALE_LIST_STRIPE_CARD_SUCCESS, data.saleListCardByCustomerId);
    } catch (error) {
      commit(MUTATIONS.SALE_LIST_STRIPE_CARD_FAILURE, error);
    }
  },
  async [ACTIONS.GET_CUSTOMER_INVOICES](
    { commit, state },
    { paramsListInvoices, isLoadMore = false, onFinish }
  ) {
    commit(MUTATIONS.GET_CUSTOMER_INVOICES_REQUEST, isLoadMore);
    try {
      const { data } = await apolloClient.query({
        query: saleGetInvoicesByCustomer,
        variables: {
          ...state.paramsListInvoices,
          ...paramsListInvoices
        }
      });

      if (state.paramsListInvoices.isIncludeTotalCount)
        state.totalCountInvoices = data.saleGetInvoicesByCustomer.totalCount;

      if (onFinish) onFinish();
      commit(MUTATIONS.GET_CUSTOMER_INVOICES_SUCCESS, { listInvoices: data.saleGetInvoicesByCustomer });
    } catch (error) {
      commit(MUTATIONS.GET_CUSTOMER_INVOICES_FAILURE, error);
    }
  },
  async [ACTIONS.LOAD_MORE_CUSTOMER_INVOICES](
    { commit, dispatch },
    { paramsListInvoices, isLoadMore = true, onFinish }
  ) {
    commit(MUTATIONS.UPDATE_QUERY_PARAMS_GET_CUSTOMER_INVOICE, { paramsListInvoices });
    dispatch(ACTIONS.GET_CUSTOMER_INVOICES, { isLoadMore, onFinish });
  },
  async [ACTIONS.RESET_STATUS_ERROR]({ commit }) {
    commit(MUTATIONS.RESET_STATUS_ERROR);
  },
  async [ACTIONS.GET_XERO_CUSTOMER]({ commit }, params) {
    try {
      const { data } = await apolloClient.query({
        query: saleGetXeroCustomer,
        variables: {
          customerId: params.customerId,
          tenantId: params.tenantId
        }
      });
      commit(MUTATIONS.GET_XERO_CUSTOMER_SUCCESS, data.saleGetXeroCustomer);
    } catch (error) {
      commit(MUTATIONS.GET_XERO_CUSTOMER_FAILURE, error);
    }
  },
  async [ACTIONS.GET_TOTAL_UNPAID_INVOICES]({ commit }, params) {
    try {
      const { data } = await apolloClient.query({
        query: saleGetTotalUnpaidInvoices,
        variables: {
          customerId: params.customerId,
          tenantId: params.tenantId
        }
      });
      commit(
        MUTATIONS.GET_TOTAL_UNPAID_INVOICES_SUCCESS,
        data.saleGetTotalUnpaidInvoices?.total_unpaid_invoices
      );
    } catch (error) {
      commit(MUTATIONS.GET_TOTAL_UNPAID_INVOICES_FAILURE, error);
    }
  },
  async [ACTIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS]({ commit }, { paramsCreatePayment }) {
    commit(MUTATIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS_REQUEST, paramsCreatePayment);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: createSaleAutomaticBatchPayments,
        variables: {
          ...paramsCreatePayment
        }
      });
      if (!errors) {
        commit(MUTATIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS_SUCCESS, {
          paymentSummary: data.createSaleAutomaticBatchPayments
        });
      } else {
        commit(MUTATIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS_FAILURE, error);
    }
  },
  async [ACTIONS.CREATE_BANK_PAYMENT]({ commit }, { paramsCreateBankPayment }) {
    commit(MUTATIONS.CREATE_BANK_PAYMENT_REQUEST, paramsCreateBankPayment);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: createPaymentB2b,
        variables: {
          ...paramsCreateBankPayment
        }
      });
      if (!errors) {
        commit(MUTATIONS.CREATE_PAYMENT_SUCCESS, { paymentSummary: data.createPaymentB2b });
      } else {
        commit(MUTATIONS.CREATE_PAYMENT_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.CREATE_PAYMENT_FAILURE, error);
    }
  },
  async [ACTIONS.CREATE_CASH_CHEQUE_PAYMENT]({ commit }, { paramsCreateCashChequePayment }) {
    commit(MUTATIONS.CREATE_CASH_CHEQUE_PAYMENT_REQUEST, paramsCreateCashChequePayment);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: createPaymentChequeCash,
        variables: {
          ...paramsCreateCashChequePayment
        }
      });
      if (!errors) {
        commit(MUTATIONS.CREATE_PAYMENT_SUCCESS, { paymentSummary: data.createPaymentChequeCash });
      } else {
        commit(MUTATIONS.CREATE_PAYMENT_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.CREATE_PAYMENT_FAILURE, error);
    }
  },
  async [ACTIONS.SET_SELECTED_PAYMENT_INVOICES]({ commit }, data) {
    commit(MUTATIONS.SET_SELECTED_PAYMENT_INVOICES, data);
  },
  async [ACTIONS.REMOVE_SELECTED_PAYMENT_INVOICES]({ commit }) {
    commit(MUTATIONS.REMOVE_SELECTED_PAYMENT_INVOICES);
  },
  async [ACTIONS.SET_SELECTED_PAYMENT_METHOD]({ commit }, data) {
    commit(MUTATIONS.SET_SELECTED_PAYMENT_METHOD, data);
  },
  async [ACTIONS.REMOVE_SELECTED_PAYMENT_METHOD]({ commit }) {
    commit(MUTATIONS.REMOVE_SELECTED_PAYMENT_METHOD);
  },
  async [ACTIONS.CHECK_IS_FINANCE_MODULE_ENABLED]({ commit }, params) {
    const { data } = await apolloClient.query({
      query: saleGetIsFinanceModuleEnabled,
      variables: {
        customerId: params.customerId,
        tenantId: params.tenantId
      }
    });
    commit(MUTATIONS.CHECK_IS_FINANCE_MODULE_ENABLED, data.saleGetIsFinanceModuleEnabled);
  },

  async [ACTIONS.GET_PAYMENT_HISTORY]({ commit }, params) {
    commit(MUTATIONS.GET_PAYMENT_HISTORY_REQUEST);
    try {
      const { data, errors } = await apolloClient.query({
        query: getInvoicePaymentHistory,
        variables: {
          tenantId: params.tenantId,
          invoiceId: params.invoiceId
        }
      });
      if (!errors) {
        commit(MUTATIONS.GET_PAYMENT_HISTORY_SUCCESS, {
          paymentHistory: data.getInvoicePaymentHistory
        });
      } else {
        commit(MUTATIONS.GET_PAYMENT_HISTORY_FAILED, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.GET_PAYMENT_HISTORY_FAILED, error);
    }
  }
};
