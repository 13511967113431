import { DEFAULT_STATE } from '@/modules/b2b/constants';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const state = {
  ...DEFAULT_STATE,
  list: {
    data: []
  },
  item: null,
  totalCount: 0,
  params: {
    isIncludeTotalCount: true,
    isLoadMore: false,
    limit: 5,
    offset: 0
  },
  detailPreOrder: null
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
