import gql from 'graphql-tag';

export const getViewsUserNotifications = gql`
  query getViewsUserNotifications($read: Boolean, $isIncludeTotalCount: Boolean, $limit: Int, $offset: Int) {
    getViewsUserNotifications(
      read: $read
      isIncludeTotalCount: $isIncludeTotalCount
      limit: $limit
      offset: $offset
    ) {
      totalCount
      isAllNotifyRead
      data {
        id
        sender_user_id
        target_user_id
        message
        read
        created_at
        payload
        image_sender
        image_target
        title
        sub_type
      }
    }
  }
`;

export const getViewsQuotations = gql`
  query getViewsQuotations(
    $id: Int
    $customerId: Int
    $isIncludeTotalCount: Boolean
    $limit: Int
    $offset: Int
  ) {
    getViewsQuotations(
      id: $id
      customerId: $customerId
      isIncludeTotalCount: $isIncludeTotalCount
      limit: $limit
      offset: $offset
    ) {
      totalCount
      data {
        id
        customer_id
        customer_name
        sku_name
        halal
        image
        quotation_status
        created_at
        unit_price
        price
        sku_id
        tenant_id
        weight
        unit_per_oom
        product_id
        uom
        origin_price
        status
        requestor {
          id
          image
          first_name
          last_name
        }
        quotedBy {
          image
          first_name
          last_name
        }
        approver {
          id
          first_name
          last_name
        }
        limit_price
        unit_per_item
        unit_per_item_uom
        direct_price
      }
    }
  }
`;

export const updateUserNotification = gql`
  mutation updateUserNotification(
    $message: String
    $payload: String
    $title: String
    $notificationId: Int!
    $read: Boolean
  ) {
    updateUserNotification(
      message: $message
      payload: $payload
      title: $title
      notificationId: $notificationId
      read: $read
    ) {
      id
      read
    }
  }
`;

export const getBadgeNumbers = gql`
  query getBadgeNumbers($customerId: Int!) {
    getBadgeNumbers(customerId: $customerId) {
      carts
      notifications
    }
  }
`;
export const readAllUserNotificationsB2b = gql`
  mutation readAllUserNotificationsB2b {
    readAllUserNotificationsB2b
  }
`;
export const getMaintenanceInfo = gql`
  query getMaintenanceInfo($timeNow: String!, $platform: String) {
    getMaintenanceInfo(timeNow: $timeNow, platform: $platform) {
      id
      description
      start_time
      end_time
      active
      platform
      time_remaining
      time_is_coming
      isMaintenance
      isIncoming
    }
  }
`;
