import { NUMBER_OF_NOTIFICATION } from '@/modules/shared/constants/';
import { FCM } from '@capacitor-community/fcm';
import { Capacitor } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
import { isPlatform } from '@ionic/vue';
import { getUserType, isSale } from '../../../modules/shared/utils/common';
import clevertap from './clevertap';

const init = async (router, storage) => {
  if (Capacitor.isNativePlatform()) {
    try {
      await registerForPushNotifications(router, storage);
      console.log('echo: FCM Registration succeed!');
    } catch (e) {
      console.log('echo: FCM Registration Error: ' + JSON.stringify(e));
    }
  }
};
const registerForPushNotifications = async (router, storage) => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!');
  }

  await PushNotifications.register();

  // only do this for android
  if (isPlatform('android')) {
    await PushNotifications.createChannel({
      id: 'push-notification',
      name: 'Push notification firebase',
      importance: 4
    });
  }

  PushNotifications.addListener('registration', (token) => {
    console.log('echo: FCM Token: ' + JSON.stringify(token));
    clevertap.setPushToken(token.value);
  });

  PushNotifications.addListener('registrationError', (error) => {
    console.log('echo: FCM Registration Error: ' + JSON.stringify(error));
  });

  PushNotifications.addListener('pushNotificationReceived', async (notification) => {
    if (Capacitor.getPlatform() === 'android') {
      try {
        if (notification.id.indexOf(':') > -1) {
          const deliveriedMessages = await PushNotifications.getDeliveredNotifications();
          const currentNotificationId = notification.id.split(':')[0];
          const currentNotification = deliveriedMessages.notifications.find(
            (noti) => noti.id == currentNotificationId
          );
          if (currentNotification)
            await PushNotifications.removeDeliveredNotifications({ notifications: [currentNotification] });
        }
      } catch (e) {
        console.log('echo: Push notification error', e);
      }
      const notifications = [
        {
          id: new Date().getTime() / 1000,
          title: notification.title || notification.data?.nt,
          body: notification.body || notification.data?.nm,
          data: notification.data || notification.data?.dt
        }
      ];
      LocalNotifications.schedule({
        notifications
      });
    }
  });

  PushNotifications.addListener('pushNotificationActionPerformed', async (notification) => {
    if (notification.actionId === 'tap') {
      const subType = Number(notification.notification.data.sub_type);
      const payload = notification.notification.data.payload
        ? JSON.parse(notification.notification.data.payload)
        : undefined;
      const { user_type_id } = await storage.getUser();
      const userType = getUserType(user_type_id);
      const sale = isSale(user_type_id) ? 'sale_' : '';

      if (NUMBER_OF_NOTIFICATION.PRICE_UPDATE.includes(subType)) {
        router.push(`/${userType}/notifications/all`);
      } else if (NUMBER_OF_NOTIFICATION.NEW_PRODUCT.includes(subType)) {
        router.push({ name: `${sale}home`, params: { isNewest: true } });
      } else if (NUMBER_OF_NOTIFICATION.QUOTATION.includes(subType)) {
        router.push({ path: `/${userType}/notifications/all`, query: { segment: 'quotations' } });
      } else if (NUMBER_OF_NOTIFICATION.ORDER.includes(subType)) {
        router.push(`/${userType}/order/purchase`);
      } else if (NUMBER_OF_NOTIFICATION.PAYMENT.includes(subType) && payload) {
        router.push(`/${userType}/payment-transaction/${payload.order_id}`);
      } else if (NUMBER_OF_NOTIFICATION.ORDER_JOURNEY.includes(subType) && payload) {
        router.push(`/${userType}/order/purchase/order-detail/${payload.order_id}`);
      } else {
        router.push(`/${userType}/notifications/all`);
      }
    }
  });
  LocalNotifications.addListener('localNotificationActionPerformed', async (notification) => {
    if (notification.actionId === 'tap') {
      const subType = Number(notification.notification.data.sub_type);
      const payload = notification.notification.data.payload
        ? JSON.parse(notification.notification.data.payload)
        : undefined;
      const { user_type_id } = await storage.getUser();
      const userType = getUserType(user_type_id);
      const sale = isSale(user_type_id) ? 'sale_' : '';

      if (NUMBER_OF_NOTIFICATION.PRICE_UPDATE.includes(subType)) {
        router.push(`/${userType}/notifications/all`);
      } else if (NUMBER_OF_NOTIFICATION.NEW_PRODUCT.includes(subType)) {
        router.push({ name: `${sale}home`, params: { isNewest: true } });
      } else if (NUMBER_OF_NOTIFICATION.QUOTATION.includes(subType)) {
        router.push({ path: `/${userType}/notifications/all`, query: { segment: 'quotations' } });
      } else if (NUMBER_OF_NOTIFICATION.ORDER.includes(subType)) {
        router.push(`/${userType}/order/purchase`);
      } else if (NUMBER_OF_NOTIFICATION.PAYMENT.includes(subType) && payload) {
        router.push(`/${userType}/payment-transaction/${payload.order_id}`);
      } else if (NUMBER_OF_NOTIFICATION.ORDER_JOURNEY.includes(subType) && payload) {
        router.push(`/${userType}/order/purchase/order-detail/${payload.order_id}`);
      } else {
        router.push(`/${userType}/notifications/all`);
      }
    }
  });
};

const getFormatTopic = (topic) => {
  const env = process.env.VUE_APP_ENV || 'development';
  return `${topic}-${env}`;
};
const subscribeTopic = (topic) => {
  if (!Capacitor.isNativePlatform()) return;
  if (!topic) return console.log('Topic cannot be empty');

  const formatedTopic = getFormatTopic(topic);
  FCM.subscribeTo({ topic: formatedTopic })
    .then(() => console.log(`FCM: subscribed to topic ${formatedTopic}`))
    .catch((err) => console.error('FCM: error', err));
};

const deleteInstanceId = async () => {
  if (!Capacitor.isNativePlatform()) return;
  FCM.deleteInstance()
    .then(() => {
      console.log(`Token deleted`);
      getToken();
    })
    .catch((err) => console.error(err));
};

const getToken = async () => {
  FCM.getToken()
    .then((r) => {
      console.log(`FCM Token: ${r.token}`);
    })
    .catch((err) => console.error(err));
};

const removeAllListenerAndSubscribe = (topic) => {
  return Promise.allSettled([
    FCM.unsubscribeFrom({ topic: getFormatTopic(topic) }),
    PushNotifications.removeAllListeners()
  ]);
};

const PushNotification = {
  init,
  subscribeTopic,
  deleteInstanceId,
  removeAllListenerAndSubscribe
};

export default PushNotification;
