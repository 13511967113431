export const IMAGE_PATH = {
  USER: 'profile-pictures',
  CUSTOMER: 'customer-pictures',
  PRODUCT: 'products',
  CATEGORY: 'category-images/new',
  CATEGORY_ICON: 'category-images/category-icons'
};

export const DEFAULT_PRODUCT_IMAGE = '/assets/images/new-default-product-img.svg';

export const DEFAULT_AVATAR = '/assets/images/no-avatar.png';

export const DEFAULT_USER_AVARTAR = '/assets/images/user.svg';

export const LAT_LNG_SINGAPORE = { lat: 1.29027, lng: 103.851959 };
export const LAT_LNG_MALAYSIA = { lat: 3.168153, lng: 101.6695613 };
export const LAT_LNG_INDONESIA = { lat: -6.218363, lng: 106.836848 };
export const DEFAULT_ZOOM_GGMAP_FIRST_TIME = 13;
export const DEFAULT_ZOOM_GGMAP = 17;
