import { REQUEST, SUCCESS, FAILURE } from '@/modules/b2b/services/libs/state';

export const MUTATIONS = {
  ADD_ADDRESS_REQUEST: 'ADD_ADDRESS_REQUEST',
  ADD_ADDRESS_SUCCESS: 'ADD_ADDRESS_SUCCESS',
  ADD_ADDRESS_FAILURE: 'ADD_ADDRESS_FAILURE',

  GET_ADDRESSES_REQUEST: 'GET_ADDRESSES_REQUEST',
  GET_ADDRESSES_SUCCESS: 'GET_ADDRESSES_SUCCESS',
  GET_ADDRESSES_FAILURE: 'GET_ADDRESSES_FAILURE',

  UPDATE_ADDRESS_REQUEST: 'UPDATE_ADDRESS_REQUEST',
  UPDATE_ADDRESS_SUCCESS: 'UPDATE_ADDRESS_SUCCESS',
  UPDATE_ADDRESS_FAILURE: 'UPDATE_ADDRESS_FAILURE'
};

export default {
  // UPDATE_ADDRESS
  [MUTATIONS.UPDATE_ADDRESS_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.UPDATE_ADDRESS_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.UPDATE_ADDRESS_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // ADD_ADDRESS
  [MUTATIONS.ADD_ADDRESS_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.ADD_ADDRESS_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.ADD_ADDRESS_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // GET_ADDRESSES
  [MUTATIONS.GET_ADDRESSES_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.GET_ADDRESSES_SUCCESS](state, list) {
    SUCCESS(state);
    state.list = list;
  },
  [MUTATIONS.GET_ADDRESSES_FAILURE](state, error) {
    FAILURE(state, error);
  }
};
