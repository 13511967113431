export const getImage = async (imageName, imagepath, imageurl = process.env.VUE_APP_AWS_S3_IMAGE) => {
  const noImage = '/assets/images/new-default-product-img.png';

  if (!imageName) return noImage;

  const detaultExtension = imagepath.indexOf('category-images') > -1 ? '.svg' : '.png';
  imageName = imageName.indexOf('.') > -1 ? imageName : `${imageName}${detaultExtension}`;
  const path = `${imageurl}/${imagepath}/${imageName}`;

  return path;
};
