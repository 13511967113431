import { FAILURE, REQUEST, SUCCESS } from '@/modules/b2b/services/libs/state';

export const MUTATIONS = {
  ADD_ORDER_REQUEST: 'ADD_ORDER_REQUEST',
  ADD_ORDER_SUCCESS: 'ADD_ORDER_SUCCESS',
  ADD_ORDER_FAILURE: 'ADD_ORDER_FAILURE',

  ADD_PRE_ORDER_REQUEST: 'ADD_PRE_ORDER_REQUEST',
  ADD_PRE_ORDER_SUCCESS: 'ADD_PRE_ORDER_SUCCESS',
  ADD_PRE_ORDER_FAILURE: 'ADD_PRE_ORDER_FAILURE',

  CHECK_DOUBLE_ORDER_REQUEST: 'CHECK_DOUBLE_ORDER_REQUEST',
  CHECK_DOUBLE_ORDER_SUCCESS: 'CHECK_DOUBLE_ORDER_SUCCESS',
  CHECK_DOUBLE_ORDER_FAILURE: 'CHECK_DOUBLE_ORDER_FAILURE',

  NETS_PAYMENT_PIN_REQUEST: 'NETS_PAYMENT_PIN_REQUEST',
  NETS_PAYMENT_PIN_SUCCESS: 'NETS_PAYMENT_PIN_SUCCESS',
  NETS_PAYMENT_PIN_FAILURE: 'NETS_PAYMENT_PIN_FAILURE'
};

export default {
  // ADD pre-ORDER
  [MUTATIONS.ADD_PRE_ORDER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.ADD_PRE_ORDER_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.ADD_PRE_ORDER_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // ADD ORDER
  [MUTATIONS.ADD_ORDER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.ADD_ORDER_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.ADD_ORDER_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // CHECK DOUBLE ORDER
  [MUTATIONS.CHECK_DOUBLE_ORDER_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.CHECK_DOUBLE_ORDER_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.CHECK_DOUBLE_ORDER_FAILURE](state, error) {
    FAILURE(state, error);
  },

  // ADD NET CARD
  [MUTATIONS.NETS_PAYMENT_PIN_REQUEST](state) {
    REQUEST(state);
  },
  [MUTATIONS.NETS_PAYMENT_PIN_SUCCESS](state) {
    SUCCESS(state);
  },
  [MUTATIONS.NETS_PAYMENT_PIN_FAILURE](state, error) {
    FAILURE(state, error);
  }
};
