const en = {
  Available: 'Tersedia',
  NPS_text: 'Adakah anda akan mengesyorkan aplikasi kami kepada rakan-rakan anda?',
  NPS_thank_you: 'Terima kasih atas maklum balas anda!',
  OK: 'Okey',
  Person_type_cannot_be_empty: 'Jenis individu tidak boleh kosong',
  Weights: 'Berat',
  about_app: 'Mengenai Aplikasi',
  about_this_product: 'Mengenai Produk Ini',
  about_treedots: 'Mengenai TreeDots',
  about_us: 'Mengenai Kami',
  access_denied: 'Akses ditolak!',
  access_signup_des1: 'Nampaknya anda tidak mempunyai akaun dengan kami.',
  access_signup_des2: 'Sila cipta akaun atau',
  access_signup_des3: 'Tambah ke Troli.',
  access_signup_terms: 'Saya sudah membaca dan bersetuju dengan',
  account: 'Akaun',
  accountPage: {
    add_card: 'Tambah Kad',
    add_nets_card_type: 'Tambah Kad Bank NETS',
    added_card_successfully: 'Kad Berjaya Ditambah!',
    are_you_sure_delete_card: 'Adakah anda pasti ingin memadamkan kad kredit ini?',
    are_you_sure_delete_card_nets: 'Adakah anda pasti ingin memadamkan kad bank NETS ini?',
    cannot_be_empty: 'Tidak boleh kosong',
    card_has_been_removed: 'Kad telah dialih keluar',
    card_number: 'Nombor Kad',
    change: 'Ubah',
    configure_peppol_identifier: 'Konfigurasikan Pengecam Peppol',
    confirm_new_password: 'Sahkan kata laluan baharu',
    confirm_new_password_cannot_be_empty: 'Sahkan kata laluan tidak boleh kosong',
    confirm_password_does_not_match: 'Sahkan kata laluan tidak sepadan',
    cvc_cvv: 'CVC/CVV',
    cvv: 'CVV',
    default: 'Lalai',
    default_card_has_been_changed: 'Kad lalai telah diubah',
    delete_card: 'Padamkan Kad',
    dont_have_card: 'Anda tidak mempunyai kad? Klik di sini untuk memohon dan mendapat pulangan tunai $200!',
    enter_current_password: 'Masukkan kata laluan semasa',
    enter_new_password: 'Masukkan kata laluan baharu',
    expiry_date: 'Tarikh Tamat Tempoh',
    manage_companines: 'Urus Syarikat',
    must_be_a_peppol_identifier: 'Mestilah pengecam peppol',
    must_be_a_valid_number: 'Mestilah nombor yang sah',
    nets_card_error_instruction:
      'Bank kad NETS tidak tersedia untuk peranti ini, kami akan mengosongkan semua kad bank NETS di dalam peranti ini. Sila tambah kad baharu sekali lagi',
    nets_card_error_title: 'Ralat kad bank NETS',
    nets_card_type: '(Kad Bank NETS)',
    new_password_cannot_be_empty: 'Kata laluan baharu tidak boleh kosong',
    old_password_cannot_be_empty: 'Kata laluan lama tidak boleh kosong',
    old_password_doesnt_match: 'Kata laluan lama tidak sepadan',
    password: 'Kata Laluan',
    password_must_be_at_least_6_characters: 'Kata laluan mestilah sekurang-kurangnya 6 aksara',
    peppol_id_configuration: 'Konfigurasi ID Peppol',
    peppol_id_configured_successfully: 'ID Peppol berjaya dikonfigurasikan',
    peppol_identifier: 'Pengecam Peppol',
    peppol_scheme: 'Skim Peppol',
    peppol_scheme_description: 'Penerangan Skim Peppol',
    please_choose_something: 'Sila pilih sesuatu',
    product_sku_name: 'Nama SKU Produk',
    remarks: 'Catatan',
    remove: 'Alih keluar',
    request_product: 'Minta Produk',
    request_product_success: 'Meminta kejayaan produk',
    set_as_default: 'Tetapkan sebagai lalai',
    supplier_name: 'Nama Pembekal',
    validity_period: 'Tempoh Sah',
    your_password_has_been_saved: 'Kata laluan anda telah disimpan'
  },
  account_type: 'Jenis Akaun',
  add: 'Tambah',
  addCompany: {
    accept: 'Terima',
    accept_company_request: 'Terima Permintaan Syarikat',
    companies_that_assigned:
      'syarikat yang telah memilih anda sebagai kenalan utama. Sila semak dan terima atau tolak.',
    have_been_notified:
      'telah dimaklumkan tentang tindakan anda dan ahli pasukan operasi kami akan menghubungi anda tidak lama lagi untuk mengesahkan tindakan ini sebelum anda dikeluarkan sebagai',
    key_contact_person: 'individu bagi kenalan utama.',
    my_companies: 'Syarikat Saya',
    pending_your_action: 'Menunggu tindakan anda',
    reject_company_request: 'Tolak Permintaan Syarikat',
    success_assigned: 'Berjaya Ditugaskan',
    success_rejected: 'Berjaya Ditolak',
    there_are: 'Terdapat',
    would_you_like: 'Adakah anda ingin mengesahkan diri anda sebagai kenalan utama untuk',
    would_you_like_to_reject_being: 'Adakah anda ingin menolak daripada menjadi',
    you_are_now: 'Anda sekarang',
    you_can_now_make_order: 'Anda sudah boleh membuat pesanan bagi'
  },
  add_address: 'Tambah Alamat',
  add_another_card: 'Tambah Kad Lain',
  add_card_failed: {
    expired_card: 'Kad ini telah tamat tempoh. Sila cuba lagi dengan menggunakan kad lain.',
    general:
      'Nampaknya terdapat masalah dengan kad anda. Sila cuba lagi dengan kad yang lain atau hubungi bank anda untuk mendapatkan bantuan lanjut.',
    invalid_account:
      'Kad ini tidak sah. Sila tambah kad yang lain atau hubungi bank anda untuk mendapatkan bantuan lanjut.',
    stolen_card:
      'Nampaknya terdapat masalah dengan kad anda. Sila hubungi bank anda untuk mendapatkan bantuan lanjut.'
  },
  add_card_or_credit_card: 'Tambah Kad Debit/Kredit',
  add_card_success: 'Tambah kad baharu berjaya!',
  add_card_validation: {
    card_number: 'Masukkan nombor kad 16 digit yang sah',
    cvv: 'CVV 3 digit tidak sah',
    expiry_date: 'BB/TT',
    invalid_month: 'Bulan Tidak Sah'
  },
  add_file: 'Tambah Fail',
  add_item_cart: 'Tambah Item Ke Troli',
  add_items_to_favorite_tab:
    'Tambah kepada Kegemaran untuk membuat pesanan dan daftar keluar dengan cepat pada masa hadapan.',
  add_new_address: 'Tambah Alamat Baharu',
  add_new_card: 'Tambah Kad Baharu',
  add_delivery_instructions_or_directions: 'Enter delivery instructions or directions here',
  add_product_to_favorite: 'Tambah produk kepada Kegemaran?',
  add_to_cart: 'Tambah ke Troli',
  adding_card_failed: 'Tambah kad tidak berjaya',
  additional_options: 'Pilihan Tambahan',
  address: 'Alamat',
  address_btn_remove: '',
  address_remove_confirm: '',
  address_unable_delete_default: '',
  address_form: {
    city: {
      required: 'Bandar tidak boleh kosong'
    },
    country: {
      required: 'Negara tidak boleh kosong'
    },
    postal_code: {
      required: 'Poskod tidak boleh kosong'
    },
    road: {
      required: 'Jalan tidak boleh kosong'
    },
    state: {
      required: 'Negeri tidak boleh kosong'
    }
  },
  alert_blacklist_order: 'alert-blacklist-order',
  alert_dulicate_order: 'alert-duplicate-order',
  all: 'Semua',
  all_collection_point: 'Semua Pusat Pengambilan',
  all_is_un_selected: 'Semua tidak dipilih',
  all_products: 'Semua Produk',
  allow_location_access: 'Benarkan Akses Lokasi',
  already_have_an_account: 'Sudah mempunyai akaun?',
  and: 'dan',
  apply: 'Memohon',
  apply_filter: 'Guna penapis',
  apply_treedots_voucher: 'Memohon Baucar TreeDots',
  apply_voucher: 'Memohon baucar',
  approve: 'Luluskan',
  approvedBy: 'Diluluskan Oleh',
  approvedPrice: 'Harga yang Diluluskan',
  are_you_sure: 'Adakah anda pasti?',
  available_vouchers: 'baucar tersedia',
  available_vouchers_for_you: 'Baucar tersedia untuk anda',
  back: 'Kembali',
  back_order: 'Pesanan Tertangguh',
  back_to_top: 'Kembali ke Atas',
  back_to_try_again: 'Kembali ke Cuba Lagi',
  back_try_again: 'Kembali ke Cuba Lagi',
  began: 'Bagaimana ia bermula',
  billing_address: 'Alamat Pembayaran',
  birth_date: 'Tarikh Lahir',
  birth_date_failed: 'Kemas kini Tarikh Lahir tidak berjaya!',
  birth_date_updated: 'Maklumat Tarikh Lahir berjaya dikemas kini!',
  branches: 'Cawangan',
  browse: 'Layari',
  browse_message_if_cart_empty: 'makanan kami untuk menambah item anda di sini!',
  browse_more: 'Layari lebih banyak item ',
  building_name: 'Nama Bangunan',
  cache_cleared: 'Cache dikosongkan.',
  camera: 'Kamera',
  cancel: 'Batal',
  cancelOrder: 'Batalkan Pesanan',
  cancel_button: 'cancel-button',
  cancel_leave_community_chat: 'BATAL',
  cancel_order_item_hint: 'Anda tidak boleh membatalkan item apabila pembelian kumpulan telah dicapai',
  cancel_pre_order: 'Batalkan Prapesanan',
  cancel_remove_product_form_cart: 'Tidak, Simpan Item Ini',
  cancelled_item: 'Anda telah membatalkan item.',
  order_item: 'Item Pesanan:',
  select_reason: 'Pilih Sebab',
  let_us_know: 'Sila maklumkan kepada kami sebab anda menukar pesanan anda.',
  additional_info: 'Alasan',
  input_your_reasons: 'Sila masukkan alasan anda',
  input_editional_info: 'Maklumat Tambahan',
  minimum_5_char: 'Minimum 5 aksara!',
  confirm_cancel: 'Sahkan Pembatalan',
  cannot_connect: 'Tidak Boleh Disambungkan',
  cannot_create_problem: 'Tidak dapat mencipta permintaan anda pada masa ini. Sila cuba lagi',
  cant_ordered:
    'Maaf, beberapa item sudah tidak boleh dipesan. Kami telah mengalih keluar daripada pesanan anda.',
  card: 'Kad',
  card_default_failed: 'Tetapkan kad sebagai lalai tidak berjaya!',
  card_default_success: 'Tetapkan kad sebagai lalai berjaya!',
  card_list: 'Senarai Kad',
  card_not_selected: 'Tidak Dipilih',
  card_popover: 'card-popover',
  card_unset_default_failed: 'Nyahtetapkan kad sebagai lalai tidak berjaya!',
  card_unset_default_success: 'Nyahtetapkan kad sebagai lalai berjaya!',
  cart: 'Troli',
  cart_error_title: 'Ralat Kad!',
  carton: 'KARTON',
  carton_deal: 'TAWARAN KADBOD',
  cash_on_delivery: 'Pembayaran Secara Tunai Ketika Penghantaran',
  catch_weight: 'Catchweight',
  categories: 'Kategori',
  category_sub: 'Kategori/Sub Kategori',
  cc_add_card: 'Tambah Kad Baharu',
  cc_card_number: 'Nombor Kad',
  cc_cvv: 'CVV',
  cc_expiry_date: 'Tarikh Tamat Tempoh',
  cc_name_on_card: 'Nama pada Kad',
  change: 'Ubah',
  change_address: 'Ubah Alamat',
  change_order_item_status_confirmation: 'Adakah anda ingin mengemas kini Status Pesanan Item anda?',
  change_password: 'Ubah Kata Laluan',
  change_profile_photo: 'Ubah gambar profil',
  change_the_address: 'Ubah alamat',
  change_time: 'Ubah waktu',
  channel_admin: 'Pentadbir Saluran',
  chat: 'Sembang',
  chat_mute_notification: 'Bisukan Pemberitahuan',
  chat_muted: 'Sembang dibisukan',
  chat_no_description: 'Hos Pusat Pengambilan ini belum menambah penerangan kumpulan. Nantikan!',
  chat_participants: 'Ahli',
  chat_unmuted: 'Sembang dinyahbisukan',
  chats: 'Sembang',
  check_birtday_val: 'updateProfile.birth_date_cannot_be_empty',
  check_email_val: 'updateProfile.email_cannot_be_empty',
  check_fname_length: 'first_name_cannot_be_empty',
  check_fname_length_limit: 'first_name_must_be_at_least_2_characters',
  check_gender_val: 'updateProfile.gender_cannot_be_empty',
  check_phone_number: 'Nombor telefon sudah wujud.',
  check_phone_number_error: 'Nombor Telefon Tidak Sah.',
  check_phone_number_length_val: 'updateProfile.phone_number_must_be_at_least_5_characters',
  check_phone_number_val: 'phone_number_cannot_be_empty',
  check_search: 'Sila semak',
  check_valid_close_time: 'Ruang tidak boleh dikosongkan',
  check_valid_email: 'this_field_must_be_a_valid_email',
  check_valid_open_time: 'Ruang tidak boleh dikosongkan',
  checkout: 'Daftar keluar',
  checkout_loader: {
    back_to_checkout: 'Kembali ke Daftar Keluar',
    cancel_transaction: 'Anda telah membatalkan transaksi anda.',
    change_payment: 'Ubah Kaedah Pembayaran',
    contact_support: 'Hubungi Sokongan',
    continue: 'Teruskan',
    fail: 'Pembayaran anda tidak berjaya.<br>Sila cuba lagi!',
    failure: {
      card_not_supported: 'Kad ini tidak disokong untuk transaksi ini. Sila daftar keluar dengan kad lain.',
      card_velocity_exceeded:
        'Telah melebihi had limit kad. Sila cuba lagi dengan kad lain untuk melengkapkan proses daftar keluar.',
      error: 'Sesuatu telah terjadi...Kami menghadapi masalah untuk memuatkan halaman ini. Sila cuba lagi.',
      expired_card: 'Kad anda telah tamat tempoh. Sila cuba lagi dengan kad yang lain.',
      general:
        'Nampaknya terdapat masalah dengan kad anda. Sila cuba lagi dengan kad yang lain untuk melengkapkan transaksi atau hubungi bank anda untuk bantuan lanjut.',
      insufficient_e_wallet_balance:
        'Baki anda tidak mencukupi untuk membuat pembayaran. Sila pastikan anda mempunyai dana yang mencukupi dalam Treedots Pay dan cuba lagi',
      insufficient_funds:
        'Baki anda tidak mencukupi untuk membuat pembayaran. Sila pastikan anda mempunyai dana yang mencukupi bagi kaedah pembayaran anda atau pilih yang lain.',
      invalid_account: 'Kad anda sudah tidak sah. Sila cuba lagi dengan kad yang lain.',
      processing_error: 'Ralat telah berlaku ketika memproses kad. Sila cuba untuk daftar keluar sekali lagi.'
    },
    insufficient: 'Dana tidak mencukupi.',
    loading: 'Sila tunggu!<br>Kami sedang memproses pembayaran anda..',
    not_you_1: 'Bukan kesilapan anda, terdapat masalah pada pihak kami',
    not_you_2: 'Sila hubungi',
    not_you_3: 'Pasukan Sokongan kami',
    not_you_4: 'bagi mendapatkan bantuan!',
    or_reach_1: 'Atau hubungi',
    or_reach_2: 'Pasukan Sokongan',
    or_reach_3: 'untuk bantuan',
    payment_canceled: 'Pembayaran dibatalkan',
    payment_failed: 'Pembayaran tidak berjaya!',
    review_checkout: 'Semak Item Daftar Keluar',
    review_payment: 'Semak Kaedah Pembayaran',
    success: 'Pembayaran anda berjaya!',
    try_another_method: 'Cuba kaedah pembayaran yang lain?'
  },
  checkout_pre_order: 'Daftar keluar Prapesanan',
  chinese_simplified: 'Bahasa Cina (Ringkas)',
  chinese_traditional: 'Bahasa Cina (Tradisional)',
  choose_card: 'Perlu Memilih Kad',
  choose_hub: 'Cari Pusat Pengambilan',
  choose_hub_btn: 'Pilih Pusat Pengumpulan',
  choose_product: 'Perlu Memilih Produk',
  choose_server: 'Pilih Pelayan',
  choose_sign_in_method: 'Pilih kaedah daftar masuk',
  city: 'Bandar',
  city_cannot_be_empty: 'Bandar tidak boleh kosong',
  clear_cache: 'Kosongkan Cache',
  clear_cache_confirm: 'Adakah anda pasti ingin mengosongkan cache?',
  close: 'TUTUP',
  code: 'Kod',
  collect_at: 'Ambil Di',
  collection_date: 'Jangkaan Tarikh Pengambilan: %{date} ',
  collection_details: 'Butiran Pengambilan',
  collection_note:
    '*Tarikh Pengambilan akan disahkan sebaik sahaja Pusat Pengambilan anda mencecah %{currency_symbol}%{minimum_order}.',
  collection_point_description:
    'Pusat Pengambilan ialah tempat dalam komuniti anda dan lokasi lain yang membolehkan anda melakukan perkara berikut:',
  collection_point_not_supported:
    'Kami mohon maaf. Pembekal ini tidak membuat penghantaran ke Pusat Pengambilan yang anda pilih.',
  collection_point_unsupported: 'Pusat Pengambilan tidak disokong',
  collection_points_near_you: 'Pusat Pengambilan Berdekatan Dengan Anda',
  coming_soon: 'akan datang!',
  community: 'Komuniti',
  community_description_label: 'Penerangan Komuniti',
  companies_with_key_contact: 'Syarikat yang mempunyai Kenalan Utama',
  companies_with_postal_code: 'Syarikat yang mempunyai poskod',
  company: 'Syarikat',
  companyDoesNotExit: 'Syarikat tidak wujud',
  competitor_price_feedback: 'maklumbalas harga pesaing',
  confirm: 'Sahkan',
  confirm_CP_modal: 'Sertai Komuniti Hab kami untuk menerima kemas kini mengenai pesanan dan penghantaran!',
  confirm_CP_title: 'Selamat Datang ke Hab Ang Mo Kio',
  confirm_button: 'butang pengesahan',
  confirm_cancel_order: 'Adakah anda pasti ingin membatalkan pesanan ini?',
  confirm_email: 'Sahkan E-mel Baharu',
  confirm_increased_quantity: 'Sahkan Peningkatan Kuantiti ',
  confirm_order_5_mins:
    'telah dicipta dalam masa 5 minit yang lalu, adakah anda mahu menyerahkan pesanan baharu ini?',
  confirm_password: 'Sahkan Kata Laluan Baharu',
  confirm_password_cannot_be_empty: 'Sahkan kata laluan tidak boleh kosong',
  confirm_password_does_not_match: 'Kata laluan yang disahkan tidak sepadan',
  confirm_password_new: 'Sahkan Kata Laluan',
  confirm_remove_product_form_cart: 'Ya, Alih Keluar Item Ini',
  confirm_send_inv_email: 'Kami akan menghantar invois ke e-mel anda, teruskan?',
  confirm_submission: 'Sahkan Penyerahan',
  confirm_update_order: 'Adakah anda pasti ingin mengemas kini pra-pesanan ini?',
  confirm_update_order_mess: 'Adakah anda pasti ingin mengemas kini pesanan ini?',
  confirmation: 'Pengesahan',
  connect: 'Sambungkan',
  connectSupplier: {
    connected: 'Disambungkan',
    payment: 'Pembayaran',
    supplier: 'pembekal',
    supplier_not_found: 'Pembekal Tidak Ditemui'
  },
  connectToOtherSuppliers: 'Sambung ke pembekal lain',
  connected: 'Bersambung',
  contact: 'Hubungi',
  contact_p1: 'Untuk pelanggan di Indonesia',
  contact_p2: "E-mel Khidmat Pelanggan: hello.id{'@'}thetreedots.id",
  contact_p3: 'WhatsApp: +62 821 10001 778',
  contact_p4: 'Perkhidmatan Aduan Pelanggan',
  contact_p5: ' Perlindungan Pengguna dan Pematuhan Perdagangan',
  contact_p6: 'Kementerian Perdagangan Republik Indonesia',
  contact_p7: 'WhatsApp: 0853-1111-10101',
  contact_us: 'Hubungi Kami',
  continue_order: 'Anda akan membuat pesanan ini, teruskan?',
  cooking: 'Masakan',
  copy: 'Salin',
  country: 'Negara',
  country_code: 'Kod Negara',
  country_of_origin: 'Negara Asal',
  cp_description_point_1: 'Pembelian kumpulan dengan pembeli lain dengan mudah',
  cp_description_point_2: 'Pilih produk daripada Pembekal yang berbeza',
  cp_description_point_3: 'Mengambil barangan runcit anda pada waktu penghantaran',
  credit_term: 'Terma Kredit',
  payment_duedate: 'Bayaran Perlu Dibayar:',
  crop_image: 'Potong Imej',
  customerPage: {
    account_number: 'Nombor Akaun',
    add_address: 'Tambah Alamat',
    add_company: 'Tambah Syarikat',
    address: 'Alamat',
    address_has_been_added: 'Alamat telah ditambah!',
    address_has_been_updated: 'Alamat telah dikemas kini!',
    address_saved: 'Alamat telah disimpan!',
    bank_transfer: 'Pemindahan Bank',
    billing_address: 'Alamat Pengebilan',
    branch: 'Cawangan',
    business_information: 'Maklumat Perniagaan',
    cannot_load_image: 'Tidak dapat memuatkan imej',
    cod: 'COD',
    end_week: 'End of Week',
    days_lower: 'days',
    days: 'Days',
    thirty_days: '(30 Days)',
    end_month: 'End of Month',
    collection: 'Pengambilan',
    company: 'Syarikat',
    company_has_been_updated: 'Syarikat telah dikemas kini!',
    company_information: 'Maklumat Syarikat',
    company_information_saved: 'Maklumat syarikat telah disimpan!',
    company_name: 'Nama Syarikat',
    company_name_cannot_be_empty: 'Nama syarikat tidak boleh kosong',
    company_name_must_be_at_least_4_characters: 'Nama syarikat mestilah sekurang-kurangnya 4 aksara',
    credit_term: 'Terma Kredit',
    credit_term_cannot_be_empty: 'Terma Kredit tidak boleh kosong',
    customer: 'Pelanggan',
    customer_email_optional: 'E-mel Pelanggan (Pilihan)',
    customers: 'Pelanggan',
    delete_person: 'Padamkan Individu',
    delivery_charge: 'Caj Penghantaran',
    delivery_hours: 'Waktu Penghantaran',
    delivery_hours_has_been_updated: 'Waktu Penghantaran telah dikemas kini!',
    delivery_hours_saved: 'Waktu penghantaran telah disimpan!',
    delivery_instruction: 'Arahan Penghantaran',
    details: 'Butiran',
    edit_information: 'Edit Maklumat',
    enter_a_location: 'Sila masukkan lokasi',
    enter_building_name: 'Masukkan nama bangunan',
    enter_city: 'Masukkan nama bandar',
    enter_floor_no: 'Masukkan nombor tingkat',
    enter_new_company_name: 'Masukkan nama syarikat',
    enter_postal_code: 'Masukkan poskod',
    enter_stall_no: 'Masukkan nombor gerai',
    enter_street_address: 'Masukkan nama jalan',
    enter_unit_no: 'Masukkan nombor unit',
    enter_your_place: 'Masukkan tempat anda',
    existing_account: 'Akaun Sedia Ada',
    find_customer: 'Cari Pelanggan',
    image: 'Imej',
    image_has_been_added: 'Imej telah ditambah',
    image_has_been_updated: 'Imej telah dikemas kini',
    image_optional: 'Imej (Pilihan)',
    images: 'Imej',
    minimum_order: 'Pesanan Minimum',
    minimum_order_quantity: 'Kuantiti Pesanan Minimum',
    new_account: 'Akaun Baharu',
    no_branches: 'Tiada Cawangan',
    no_cash_no_delivery: 'Tiada Wang Tunai Tiada Penghantaran',
    person_has_been_added: 'Individu telah ditambah!',
    person_has_been_deleted: 'Individu telah dipadamkan!',
    person_has_been_updated: 'Individu telah dikemas kini!',
    person_information: 'Maklumat Individu',
    person_information_saved: 'Maklumat individu telah disimpan!',
    position_cannot_be_empty: 'Jawatan tidak boleh kosong',
    profile_cannot_be_empty: 'Profil tidak boleh kosong',
    select_a_profile: 'Pilih profil',
    statement: 'Pernyataan',
    sub_buyer: 'Subpembeli',
    sub_buyer_has_been_added: 'Subpembeli telah ditambah!',
    sub_buyers: 'Subpembeli',
    upload_company_logo: 'Muat Naik Logo Syarikat'
  },
  dashboard: 'Papan pemuka',
  date_purchased_new_to_old: 'Tarikh pembelian (Baru kepada Lama)',
  date_purchased_old_to_new: 'Tarikh Pembelian (Lama ke Baru)',
  days: '{day} hari',
  default: 'Asal',
  default_group_description: 'Tuan Rumah Pusat Pengambilan ini belum menambah penerangan kumpulan. Nantikan!',
  delete: 'Padam',
  delete_account: 'Padamkan Akaun',
  delete_account_text1: 'Kami kesal anda terpaksa meninggalkan kami',
  delete_account_text2: 'Adakah anda pasti ingin memadam akaun anda selama-lamanya?',
  delete_account_text3: 'Sila ambil maklum, sebaik sahaja anda padam:',
  delete_account_text4:
    'Kami hanya akan mengekalkan akaun anda selama 30 hari, dan akaun anda akan dipadam selama-lamanya. Sekiranya anda mengubah fikiran anda dalam tempoh 30 hari, anda boleh mengaktifkan kembali akaun anda dengan hanya mengelog masuk.',
  delete_account_text5:
    'Semua data anda, rekod transaksi masa lalu, baki baucar dan dana e-dompet akan dipadamkan secara kekal dan anda tidak akan dapat mengakses akaun anda lagi.',
  delete_card: 'Padamkan Kad?',
  delete_chat_confirm: 'Adakah anda pasti ingin meninggalkan ruang sembang ini?',
  delete_items: 'Padamkan item',
  price_changes: 'Oh, terdapat perubahan harga',
  delete_message: 'Mesej ini telah dipadamkan',
  delete_message_confirm: 'Adakah anda pasti ingin memadamkan mesej ini?',
  delivery: 'Penghantaran',
  deliveryTimeOptional: 'Waktu Penghantaran (Pilihan)',
  delivery_address: 'Alamat Penghantaran',
  delivery_date_time: 'Tarikh dan Waktu Penghantaran',
  delivery_time: 'Waktu Penghantaran',
  desc_unavailable_product: 'Produk ini tiada di Pusat Pengambilan yang telah anda pilih.',
  description: 'Penerangan',
  desired_solution: 'Penyelesaian yang diingini',
  developer_menu: 'Menu Pengaturcara',
  diary: 'Diari',
  did_not_receive_code: 'Tidak menerima kod?',
  direct_price: 'Harga Langsung',
  discount: 'Diskaun',
  discount_voucher: 'Baucar Diskaun',
  dispute_select_reason: 'Pilih sebab masalah anda',
  dispute_select_remedy: 'Pilih tindakan pembetulan',
  dispute_selected_reason: 'Punca masalah anda yang dipilih',
  dispute_selected_remedy: 'Pembetulan yang dipilih',
  dispute_status_declined: 'Ditolak',
  dispute_status_processing: 'Memproses',
  dispute_status_resolved: 'Diselesaikan',
  dispute_status_unknown: 'Tidak Diketahui',
  distance: 'Jarak',
  document: 'Dokumen',
  done: 'Selesai',
  dont_have_account: 'Tidak mempunyai akaun',
  dont_have_an_account: 'Tidak mempunyai Akaun?',
  downloading: 'Memuat turun',
  drinks: 'Minuman',
  drn_delayed_delivery: 'Kelewatan dalam Penghantaran',
  drn_goods_spoild: 'Barangan Yang Rosak',
  drn_missing_item: 'Item Hilang',
  dry_no_remedy: 'Menerima produk tanpa sebarang pembetulan',
  dry_request_credit: 'Meminta Nota Kredit',
  dry_request_exchange: 'Meminta pertukaran 1:1',
  dry_request_pick_item: 'Meminta untuk mengambil item yang salah',
  e_wallet: 'E-Dompet',
  edit: 'Edit',
  edit_card: 'Edit Kad',
  edit_profile: 'Edit Profil',
  email: 'E-mel',
  email_address: 'Alamat E-mel',
  email_already_registered: 'E-mel ini telah didaftarkan',
  email_alt_img: 'e-mel',
  email_cannot_be_empty: 'E-mel tidak boleh kosong',
  email_failed: 'Kemas kini e-mel tidak berjaya',
  email_link_to_share:
    'mailto:?subject=TreeDots%20App%20Download%20Link&amp;body=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%0Ahttps%3A%2F%2Fthetreedots.com%2Fdownload',
  email_logo_url: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/mail-256.png',
  email_share_link: 'mailto:hello@thetreedots.com',
  email_updated: 'E-mel berjaya dikemas kini!',
  email_username: 'E-mel/Nama Pengguna',
  empty_cart_1: 'Troli anda kosong!',
  empty_cart_2: 'Layari',
  empty_cart_3: 'makanan kami dan mula membuat pesanan dengan kami!',
  encouraging: {
    content_1:
      'Pada tahun 2021, platform belian kumpulan telah diperluaskan, menyediakan penyelesaian kepada perintis komuniti untuk memberikan perkhidmatan kepada pengguna akhir.',
    content_2: 'Kami telah melancarkan',
    content_2a:
      'tempat hos Pusat Pengambilan boleh menguruskan pesanan daripada kejiranan mereka, menerima barangan pukal daripada pembekal dan mendapat komisen. Anda juga boleh menjadi hos dengan mendaftar bagi aplikasi Pusat Penjual kami!',
    seller_app: 'Aplikasi Pusat Penjual,',
    title: 'Menggalakkan Pemilikan Perniagaan'
  },
  english: 'Bahasa Inggeris',
  enter_address: 'Masukkan alamat',
  enter_card_number: 'Masukkan Nombor Kad',
  enter_code: 'Masukkan Kod',
  enter_country: 'Masukkan negara',
  enter_description: 'Masukkan deskripsi (Pilihan)',
  enter_state: 'Masukkan negeri',
  enter_voucher_code: 'Masukkan kod baucar',
  enter_your_mobile_number:
    'Masukkan nombor telefon mudah alih anda dan kami akan menghantar kod pengesahan untuk anda kembali ke akaun anda.',
  err_msg_color: 'bahaya',
  err_query: 'Opps! Ralat telah berlaku, cuba lagi kemudian.',
  err_query_payment: 'Opps! Ada sesuatu yang tidak kena dengan dengan pembayaran anda, sila cuba lagi.',
  err_searh: 'Tiada hasil carian!',
  err_voucher_invalid: 'Kod baucar tidak sah!',
  errorContent: 'Maaf, kami tidak dapat memprosesnya, sila cuba sekali lagi.',
  errorDefaultTime: 'Waktu tutup hendaklah lebih besar daripada atau sama dengan waktu Buka',
  errorTitle: 'Oops! Ralat berlaku',
  estimated_collection_date: 'Anggaran Tarikh Pengambilan',
  expire_soon: 'Tamat Tempoh Tidak Lama Lagi',
  expired_date: 'Tarikh Tamat Tempoh',
  expires: 'Tamat tempoh',
  facebook: 'Facebook',
  facebook_alt_img: 'facebook',
  facebook_link_to_share: 'https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fthetreedots.com%2Fdownload',
  facebook_logo_url: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/facebook-256.png',
  facing_an_issue_chat_with_us_for_real_time_support:
    'Anda menghadapi sebarang isu? Sembang dengan kami untuk mendapatkan sokongan masa nyata!',
  failed: 'Tidak Berjaya',
  failed_cancel: 'Tidak berjaya membatalkan item.',
  faq: 'Soalan Lazim',
  faq_page: {
    collections: 'Pengambilan',
    more: 'Lagi',
    orders: 'Pesanan',
    p1_1:
      'Untuk membeli dengan kami, anda perlu muat turun aplikasi Group Buy kami terlebih dahulu untuk telefon mudah alih dan daftar untuk akaun peribadi. Aplikasi kami kini tersedia untuk IOS dan Android. Selepas itu mula melayari tawaran platform kami!',
    p1_2:
      'Anda melihat sesuatu yang anda suka? Pilih Pusat Pengambilan berhampiran anda dari tempat anda ingin membeli dan mengambil item anda. Tambah item ke troli anda, pastikan anda memilih kaedah pembayaran anda, dan buat pesanan anda - itu sahaja! Pesanan anda akan dipaparkan di bawah bahagian Pesanan, tempat anda boleh memantau status pembelian anda dan melihat ia sedia untuk diambil. Apabila Group Buy anda dan pembeli yang lain membuat pesanan sama telah mencapai had minimum bagi pembekal untuk mengatur penghantaran - anda akan mendapat pengesahan e-mel mengenai masa pengambilan di Pusat Pengambilan pilihan anda!',
    p1_3:
      'Jangan lupa untuk mengakses ruang sembang dalam aplikasi kami dan katakan "hai" kepada hos Pusat Pengambilan anda - ia mungkin jiran sebelah anda, seseorang dari kawasan yang anda kenali! Hos akan menerima barang anda daripada pembekal dan akan mempunyai maklumat terkini mengenai lokasi pesanan anda. Pastikan anda menyertai ruang sembang komuniti Pusat Pengambilan! Terdapat pembeli seperti anda, rakan dan jiran yang berkongsi idea serta resipi!',
    p2_1:
      'Platform kami menawarkan pengalaman membeli Group Buy yang rakan-rakan dan jiran dapat membeli belah bersama, menjimatkan wang dan makanan bersama! Apabila anda membeli produk dengan kami, anda akan menjadi sebahagian daripada Group Buy secara automatik dan menyumbang kepada penyelesaian masalah lebihan makanan dengan setiap pembelian anda!  ',
    p2_2:
      'Bagaimana ia berfungsi? Semua produk yang disenaraikan oleh pembekal kami dijual secara pukal. Tetapi ada berita baik, anda tidak perlu membeli secara bersendirian. Kami membenarkan pembeli individu untuk membeli dalam kuantiti yang mereka ingini dan kami akan kumpulkan dengan pembeli yang lain di Aplikasi Group Buy. Apabila kesemua barang yang dibeli oleh pembeli tadi telah mencapai had minimum, pembekal akan menguruskan penghantaran kesemua pesanan ke Pusat Pengambilan sekaligus.',
    p2_3:
      'Melalui cara ini, kami dapat mengekalkan harga kami dengan rendah, untuk membolehkan anda menikmati hingga 90% daripada harga runcit untuk barangan runcit anda! Pembekal tidak perlu memecahkan pukal atau membazirkan inventori mereka yang membolehkan cara pengagihan semula secara efektif dan pengurangan pelepasan karbon ke atmosfera yang lebih berkesan. Apa lagi? Kami membawa komuniti bersama-sama! Sertai ruang sembang komuniti dalam aplikasi kami dan katakan “hai” kepada Hos Pusat Pengambilan dan rakan-rakan pembeli anda!',
    p3_1:
      'Untuk menyertai Pusat Pengambilan, mulakan dengan mencari pusat pengambilan yang berhampiran dengan anda di dalam peta. Anda boleh mengakses peta dengan hanya menekan ikon lokasi. Setelah anda menjumpai Pusat Pengumpulan yang anda ingin beli dan ambil item daripadanya, klik sahkan dan anda dapat menikmati produk yang tersedia!',
    p3_2:
      'Apabila anda menyertai Pusat Pengumpulan, anda menjadi sebahagian daripada komuniti sembang Pusat Pengumpulan secara automatik, tempat anda boleh bercakap dengan Hos mengenai pesanan dan pengambilan anda atau berbual dengan rakan-rakan pembeli! Anda mahu bercakap dengan Hos anda secara peribadi? Sembang 1:1 juga tersedia! Bagi mengakses semua sembang, pilih ikon sembang.',
    p4_1:
      'Anda boleh memantau status pesanan anda melalui aplikasi di bawah bahagian Pesanan. Item yang sedia untuk diambil akan dipaparkan di bawah status “Untuk Diambil” dengan tarikh pengambilan yang disahkan. Anda fikir anda akan terlepas pesanan? Jangan bimbang, kami akan menghantar e-mel dan pemberitahuan sebaik sahaja tarikh pengambilan bagi item anda telah disahkan. Apabila hari pengambilan telah tiba, kami akan mengingatkan anda lagi!',
    p4_2:
      'Sila ambil maklum, item daripada pembekal yang berbeza di dalam pesanan anda akan tiba pada masa yang berbeza mengikut kemajuan Group Buy. Jika anda tidak dapat mengambil item anda pada tarikh yang telah ditentukan, sila hubungi hos pusat pengambilan melalui aplikasi sembang jika anda ingin membuat perubahan kepada pesanan anda. ',
    p5_1:
      'Anda boleh membatalkan item pesanan anda jika kuantiti minimum Group Buy bagi pembekal tidak dapat dicapai. Anda boleh memeriksa kemajuan Group Buy produk anda di dalam bahagian Pesanan. Item yang mempunyai status "Untuk Diambil" bersama tarikh pengambilan yang telah ditetapkan tidak boleh dibatalkan kerana mengubah fikiran. Kami akan memproses bayaran balik bagi pembatalan dengan segera. Sila ambil maklum, ia akan mengambil masa 7-10 hari bekerja untuk pembayaran balik ke dalam akaun anda. Bagi mendapatkan maklumat lanjut berkaitan dasar pembatalan dan pembayaran balik, sila semak bahagian bayaran balik.',
    p6_1:
      'Jika anda mempunyai item di dalam pesanan yang Group Buy tidak dapat dicapai, kami akan mengalihkan item ke tarikh Group Buy yang tersedia secara automatik, agar mereka dapat cuba untuk mencapai had minimum dengan kumpulan pembeli yang lain. Anda akan dimaklumkan melalui e-mel yang mengandungi maklumat jangkaan tarikh pengambilan bagi item anda.',
    p6_2:
      'Sekiranya anda tidak mahu menunggu item anda lagi, anda boleh membatalkan item jika Group Buy minimum tidak tercapai dan mendapat bayaran balik. Jika anda ingin membuat sebarang pindaan kepada pesanan anda, sila hubungi Hos Pusat Pengambilan anda melalui ruang sembang di dalam aplikasi. Hos anda dapat menguruskan sepenuhnya pesanan anda, membuat pembatalan, memindahkan pesanan anda ke tarikh pengambilan lain atau ke tempat pengambilan yang lain bagi pihak anda.',
    p7_1:
      'Sekiranya produk yang anda beli sudah kehabisan stok pada masa pembelian anda, Hos Pusat Pengambilan anda akan memproses bayaran balik untuk anda. Jika anda telah menerima produk daripada pembekal yang rosak (berkualiti rendah, tamat tempoh, dan lain-lain), sila hubungi Hos Pusat Pengambilan anda melalui ruang sembang dalam aplikasi untuk memohon bayaran balik untuk anda. Pembayaran balik mungkin akan mengambil masa dalam 7-10 hari bekerja untuk dipaparkan dalam akaun bank anda. Untuk mendapatkan maklumat penuh mengenai dasar pembatalan dan pembayaran balik, semak bahagian Bayaran Balik kami.',
    p7_2: 'bahagian.',
    p8_1:
      'Sesetengah produk dalam Troli anda mungkin ditandakan sebagai "tidak tersedia" apabila pembekal produk ini tidak dapat membuat penghantaran ke Pusat Pengambilan pilihan anda. Anda boleh memilih untuk memilih Pusat Pengambilan yang lain yang pembekal menghantar produk, dan mendaftar keluar di Pusat Pengambilan yang lain. Anda mahukan pembekal tertentu membuat penghantaran ke Pusat Pengambilan kegemaran anda? Bercakap dengan Hos Pusat Pengambilan anda melalui ruang sembang dalam aplikasi kami! Hos anda merupakan individu yang akan berhubung dan berkomunikasi dengan pembekal, dan boleh meminta mereka membuat penghantaran!',
    p9_1:
      'Platform kami hanya menawarkan pembeli pesanan yang diambil sendiri dari Hos Pusat Pengambilan kami. Kami sangat bersemangat tentang kelestarian dan pembinaan komuniti. Hos Pusat Pengambilan anda menerima semua pesanan komuniti bagi kejiranan sekaligus, membolehkan pembekal mengagihkan semula inventori mereka dengan lebih cekap dan efisien bagi mengurangkan pelepasan karbon ke atmosfera melalui penghantaran pukal. Sertai kami bagi perjalanan membeli-belah barangan runcit yang lestari!',
    privacy: 'Privasi',
    q1: 'Bagaimana saya ingin membuat pesanan?',
    q2: 'Apa itu Group Buy dan bagaimana saya membuat pembelian kumpulan?',
    q3: 'Bagaimana saya boleh menyertai Pusat Pengambilan?',
    q4: 'Bagaimana saya boleh mengetahui bila untuk mengambil pesanan saya?',
    q5: 'Bolehkah saya membatalkan pesanan saya?',
    q6: 'Bagaimana jika pesanan Group Buy saya tidak dapat dicapai?',
    q7: 'Bagaimana jika saya menerima produk yang tidak lengkap atau rosak?',
    q8: 'Mengapa beberapa produk di dalam Troli saya "tidak tersedia" untuk daftar keluar?',
    q9: 'Adakah anda menyediakan perkhidmatan penghantaran ke rumah? ',
    refunds: 'Bayaran Balik',
    related: 'Soalan Berkaitan',
    title: 'Soalan Lazim (FAQ)',
    top_qustions: 'Soalan Teratas',
    treedots: 'TreeDots'
  },
  favorite: 'Kegemaran',
  feedback: 'Maklumbalas telah dihantar!',
  female: 'Perempuan',
  file_saved: 'Fail Disimpan',
  finance_label: 'Kewangan',
  find_company: 'Cari Syarikat',
  find_out: 'Ketahui dengan lebih lanjut di sini',
  find_products: 'Cari Produk',
  first_name: 'Nama Pertama',
  first_name_cannot_be_empty: 'Nama pertama tidak boleh kosong',
  first_name_failed: 'Kemas kini nama pertama tidak berjaya!',
  first_name_must_be_at_least_2_characters: 'Nama pertama mestilah mempunyai sekurang-kurangnya 2 aksara',
  first_name_updated: 'Nama pertama berjaya dikemas kini!',
  floor: 'Tingkat',
  floorNo: 'Nombor Tingkat (Pilihan)',
  follow_us: 'Ikuti Kami',
  for_next_delivery: 'untuk penghantaran pada hari seterusnya.',
  forgot_password: 'Terlupa Kata Laluan?',
  forgot_password_1: 'Terlupa Kata Laluan?',
  forgot_your_password: 'Terlupa kata laluan anda?',
  forward: 'Ke Depan',
  fpx: 'FPX',
  friends_join: 'Sertai Rakan-Rakan',
  frozen_batang_steak: 'Stik Batang Sejuk Beku',
  fruits: 'Buah-buahan',
  gender: 'Jantina',
  gender_failed: 'Kemas kini jantina tidak berjaya!',
  gender_updated: 'Kemas kini jantina berjaya!',
  generic: 'generik',
  get_code: 'Tidak mendapat kod?',
  get_help: 'Hubungi kami untuk bantuan!',
  go_to_login: 'Pergi ke log masuk',
  got_a_voucher: 'Ada baucar?',
  grabpay: 'GrabPay',
  greeting: 'Bagus',
  group_buy: {
    how_group_buy_works: 'Bagaimana Group Buy berfungsi',
    slide_1: 'Sertai pusat pengambilan yang berdekatan!',
    slide_2: 'Membeli belah di pusat pengambilan bersama rakan-rakan pembeli!',
    slide_3: 'Capai Group Buy minimum untuk mengambil pesanan bersama-sama!',
    slide_4:
      'Sembang dengan Hos dan komuniti pusat pengambilan anda untuk berita terkini, perkongsian resipi dan banyak lagi!'
  },
  group_buy_available: 'Group Buy Tersedia',
  group_by_item: 'KUMPULKAN MENGIKUT ITEM',
  group_description: 'Penerangan Kumpulan',
  group_spend_total:
    'Pembekal memerlukan pembelian kumpulan anda untuk membuat perbelanjaan  berjumlah <strong>{currencyCode}%{minimum_order}</strong> bagi menghantar pesanan anda ke Pusat Pengambilan.',
  gst_included: 'Termasuk GST, jika berkenaan.',
  halal: 'Halal',
  halal_off:
    'Dayakan jika syarikat ini merupakan syarikat penubuhan halal dan tidak ingin memaparkan produk tidak halal.',
  halal_on:
    'Nyahdayakan jika syarikat ini merupakan syarikat penubuhan yang tidak halal. Produk tidak halal akan dipaparkan.',
  halal_product: 'Produk Halal',
  halal_products: 'Produk Halal',
  halal_type: 'Jenis Halal',
  handle_not_connect_mess:
    ' bukan cawangan utama dan tidak boleh disambungkan. Sila hubungi pejabat anda untuk meminta sambungan kepada syarikat utama.',
  health: 'Kesihatan',
  hello: 'Helo',
  hello_new_string: 'Helo',
  help: 'Bantuan',
  hi: 'Hai',
  history: 'Sejarah',
  hmm: 'Hmm...',
  home: 'Laman Utama',
  host: 'Hos',
  host_account: 'Akaun Hos',
  hot_deals: 'Tawaran Hebat',
  hot_discounts: 'Diskaun Hebat',
  how_help: 'Bagaimana kami boleh membantu anda?',
  image: 'Imej',
  image_lager_than_5mb: 'Saiz imej ini lebih besar daripada had 5 MB!',
  in: 'dalam',
  inactive_supplier:
    'Pesanan daripada pembekal ini belum bermula lagi. Anda boleh memilih Pusat Pengambilan yang lain untuk membuat pembelian.',
  incorrect_card_number: 'Tambah kad baharu tidak berjaya! Nombor kad anda salah.',
  incorrect_email_or_password_entered: 'E-mel atau kata laluan dimasukkan adalah salah',
  incorrect_phone_number: 'Nombor telefon yang dimasukkan salah',
  incorrect_role: 'Anda bukanlah pelanggan atau jurujual',
  incorrect_verification_code: 'Kod pengesahan yang dimasukkan salah',
  inquiries: 'Pertanyaan',
  instagram: 'Instagram',
  invalid_card_year: 'Tambah kad baharu tidak berjaya! Tahun tamat tempoh kad anda tidak sah.',
  invalid_phone_number: 'Nombor Telefon Tidak Sah',
  invalid_price: 'Harga tidak sah',
  invite_friends: 'Jemput Rakan-Rakan',
  invite_friends_description:
    'Jemput rakan untuk mendaftar dan dapatkan diskaun $5 bagi pembelian seterusnya untuk anda dan rakan anda',
  invite_friends_get_5: 'Jemput Rakan-Rakan dan dapatkan $5',
  ios: 'ios',
  is_the_company_you_want: 'Adakah syarikat yang anda mahu tambah disenaraikan di atas?',
  item_unavailable_after: 'Item ini tidak tersedia selepas',
  items_note: 'Nota item',
  johor: 'Johor',
  join_now: 'SERTAI SEKARANG!',
  join_to_ordering: 'Sertai penyewa ini untuk mula membuat pesanan',
  key_contact: {
    company_with_key_contact: 'Syarikat yang mempunyai kenalan utama',
    does_not_exits: 'tidak wujud',
    enter_first_name: 'Masukkan nama pertama',
    enter_last_name: 'Masukkan nama terakhir',
    key_contact_information: 'Maklumat Kenalan Utama',
    select_position: 'Pilih jawatan'
  },
  key_contact_label: 'Orang yang untuk dihubungi',
  kg: 'KG',
  kitchen: 'Dapur',
  klang_valley: 'Lembah Klang',
  language: 'Bahasa',
  language_preference: 'Pilihan Bahasa',
  last_name: 'Nama Terakhir',
  last_name_cannot_be_empty: 'Nama terakhir tidak boleh kosong',
  last_name_failed: 'Kemas kini nama terakhir tidak berjaya!',
  last_name_must_be_at_least_2_characters: 'Nama terakhir mestilah sekurang-kurangnya 2 aksara',
  last_name_updated: 'Kemas kini nama terakhir berjaya!',
  last_order: 'Pesanan terakhir',
  last_quoted_price: 'Harga Sebut Terakhir',
  later: 'Kemudian',
  learn_more: 'Ketahui Lebih Lanjut',
  leave: 'Tinggalkan',
  leave_channel_confirm_header: 'Tinggalkan ruang sembang ini?',
  leave_channel_confirm_message:
    'Anda tidak akan dapat melihat perbualan di dalam ruang sembang ini. Anda boleh datang kembali dengan menyertai pusat pengambilan ini.',
  leave_channel_toast_message: 'Anda telah meninggalkan saluran ini',
  leave_community_channel_confirm_header: 'Tinggalkan Ruang Sembang Komuniti?',
  leave_community_channel_confirm_message: 'Adakah anda pasti ingin meninggalkan ruang sembang komuniti ini?',
  leave_private_channel_confirm_header: 'Tinggalkan Ruang Sembang Peribadi?',
  leave_private_channel_confirm_message: 'Adakah anda pasti ingin meninggalkan ruang sembang peribadi ini?\n',
  limit_price: 'Had Harga',
  linkedin: 'Linkedin',
  list_of_referrees_signed_up: 'Senarai rujukan yang telah mendaftar dengan TreeDots',
  live_chat: 'Sembang Siaran Langsung',
  live_chat_support_only_available_in_android_and_ios_app:
    'Sokongan sembang secara langsung hanya tersedia bagi aplikasi Android dan iOS',
  loading: 'Memuatkan…',
  loading_infinity_scroll: 'Sedang memuatkan lebih banyak data...',
  location_permission_text1: 'Helo! Masukkan poskod anda atau benarkan lokasi anda diakses untuk meneruskan',
  location_permission_text2:
    'Untuk menikmati perkhidmatan geolokasi kami, sila benarkan Group Buy TreeDots mengakses lokasi anda.',
  location_permission_text3:
    'Anda juga boleh membenarkan lokasi diakses pada telefon mudah alih anda dengan pergi ke:',
  location_permission_text4: 'Tetapan > TreeDots Group Buy > Lokasi > Benarkan Akses Lokasi',
  location_permission_text5: 'Tetapan > Lokasi > Kebenaran Lokasi > TreeDots GroupBuy > Benarkan',
  log_in: 'Log Masuk',
  login: 'Log Masuk',
  login_header_tagline: 'Selamat datang ke TreeDots, tempat produk makanan yang murah dan berkualiti wujud.',
  login_now: 'Log Masuk Sekarang',
  login_sticky: 'Log masuk untuk meneruskan beli-belah!',
  login_with_app_instead:
    'Nombor telefon mudah alih ini telah didaftarkan dengan Aplikasi Group Buy. Sila cuba log masuk ke Aplikasi Group Buy.',
  login_with_email: 'Log Masuk menggunakan E-mel',
  login_with_mobile: 'Log Masuk menggunakan Nombor Telefon Mudah Alih',
  logout: 'Log Keluar',
  logout_confirm: 'Adakah anda pasti ingin log keluar?',
  looking_to_browse1: 'Ingin melayari',
  looking_to_browse2: 'Pembekal kami',
  looking_to_browse3: '? Ketik di sini!',
  main_company: 'Syarikat Utama',
  make_as_default_address: 'Jadikan sebagai alamat lalai',
  malaysia: 'Malaysia',
  male: 'Lelaki',
  manage_account: 'Uruskan Akaun',
  map: {
    active_only: 'Aktif Sahaja',
    cancel: 'Batalkan',
    collection_point_is_inactive: 'Pusat Pengambilan tidak aktif',
    collection_point_near_me: 'Tempat Pengambilan Berhampiran Saya',
    confirm: 'Sahkan',
    continue: 'Teruskan',
    delelect: 'Nyahpilih',
    dismiss: 'Abaikan',
    enter_an_address_to_view_collection_points: 'Masukkan alamat untuk melihat pusat pengambilan',
    enter_password: 'Masukkan kata laluan',
    enter_your_address: 'Masukkan alamat anda',
    find_a_collection_point: 'Cari Pusat Pengambilan',
    friends_only: 'Rakan Sahaja',
    get_your_pass: 'Dapatkan kata laluan anda daripada hos Pusat Pengambilan',
    halal: 'Halal',
    halal_only: 'Halal sahaja',
    km: 'km',
    no_collection_point_nearby: 'Tiada Pusat Pengambilan yang berdekatan',
    not_connected_to_cp: 'Anda tidak disambungkan ke mana-mana Pusat Pengambilan!',
    not_open_for_orders_right_now: 'Tidak dibuka untuk pesanan sekarang',
    pass_incorrect: 'Kata laluan salah. Sila cuba lagi.',
    popup_message: 'Pusat Pengambilan ini tidak menerima pesanan buat masa ini. Ingin teruskan?',
    prefer_buyers_know:
      'Hos Pusat Pengambilan ini ingin mengenali pembeli secara lebih peribadi. Jika anda kenal Hos, tambah kata laluan di sini atau pilih Pusat Pengambilan awam.',
    search_results: 'Hasil Carian',
    search_this_area: 'Cari di kawasan ini',
    show_inactive: 'Papar tidak aktif',
    welcome_close: 'Tutup',
    welcome_join_now: 'Sertai sekarang',
    welcome_message:
      'Sertai Pusat Pengambilan kami untuk menerima berita terbaru mengenai pesanan dan penghantaran!',
    welcome_title: 'Selamat datang ke Pusat Pengambilan %{cp_name}'
  },
  markAsReadAll: 'Tandakan semua sebagai telah dibaca',
  mark_all: 'Tandakan semua sebagai telah dibaca',
  mark_all_as_read: 'tandakan semua sebagai dibaca',
  mark_as_read: 'Tandakan sebagai Telah dibaca',
  mark_as_received: 'Tandakan Sebagai Telah Diterima',
  mark_as_unread: 'Tandakan sebagai Belum dibaca',
  market_price: 'Harga Pesaing',
  maximum_quantity_exceeded: 'Melebihi kuantiti maksimum.',
  meals: 'Hidangan',
  meat: 'Daging',
  medium_color: 'sederhana',
  melaka: 'Melaka',
  member: 'Ahli',
  members: 'Ahli',
  menu: 'Menu',
  menu_items: {
    category: 'Kategori',
    home: 'Laman Utama',
    notifications: 'Pemberitahuan'
  },
  merchandise_subtotal: 'Jumlah Kecil Barangan',
  merchant: 'Peniaga',
  message_cart_empty: 'Troli anda kosong.',
  message_forwaded: 'Mesej telah dipanjangkan',
  message_join_to_tenant: 'Anda telah meminta untuk menyertai penyewa ini',
  method_send_invoice: 'Sila pilih kaedah untuk menghantar invois ini.',
  minimum_order_malaysia: 'Nilai Pesanan Minimum ialah RM3.00',
  minimum_order_singapore: 'Bagi pembayaran bukan e-dompet, nilai pesanan minimum ialah S$0.50',
  mission:
    'Visi dunia tanpa pembaziran makanan, kami menyasarkan untuk menjadi pemangkin kepada rantaian bekalan makanan moden dan saluran pengedaran makanan yang cekap di rantau ini untuk menjadikan pencapaian kelestarian dan mudah untuk diakses oleh semua. Kami mempunyai visi bahawa makanan, yang telah diternak dan disemai dengan susah payah, tidak akan dibazirkan.',
  mobile_already_registered: 'Nombor telefon mudah alih ini telah didaftarkan',
  mobile_not_registered:
    'Anda masih belum mendaftar dengan kami lagi, untuk bermula sila membuat pendaftaran akaun!',
  mobile_number: 'Nombor Telefon Mudah Alih',
  mobile_phone: 'Telefon Mudah Alih',
  more_credit_term: 'Lebih banyak pilihan terma kredit boleh tersedia pada papan pemuka pembekal anda',
  most_popular: 'Paling Popular',
  most_purchased: 'Paling banyak dibeli',
  msg_delete: 'Mesej Dipadamkan',
  mute: 'Bisukan',
  my_account: 'Akaun Saya',
  my_cards: 'Kad Saya',
  my_company: 'Syarikat Saya',
  my_custom_class: 'my-custom-class',
  my_orders: 'Pesanan Saya',
  name: 'Nama',
  name_az: 'Nama A-Z',
  name_za: 'Nama Z-A',
  nevermind_keep_my_account: 'Abaikan, simpan akaun saya',
  new_email: 'E-mel Baharu',
  new_password: 'Kata Laluan Baharu',
  new_phone: 'Telefon Baharu',
  newcustomer: {
    active: 'Aktif',
    add_brand: 'Tambah Cawangan',
    add_debit: 'Tambah Kad Debit/Kredit',
    add_nets: 'Tambah Kad Bank NETS',
    add_person_in_charge: 'Tambah Individu Bertanggungjawab',
    address: 'alamat',
    close_time: 'Waktu Tutup',
    company_name: 'Nama syarikat',
    days: 'Hari',
    default_delivery_time: 'Waktu Penghantaran Lalai',
    delivery_details: 'Butiran Penghantaran',
    edit_business_information: 'Edit Maklumat Perniagaan',
    edit_delivery_time: 'Edit Waktu Penghantaran',
    edit_image: 'Edit Imej',
    edit_payment_card: 'Edit Kad Pembayaran',
    edit_person_in_charge: 'Edit Individu Bertanggungjawab',
    enter_delivery_interval: 'Arahan Penghantaran',
    language: 'Bahasa',
    main_brand: 'Cawangan Utama',
    notifications: 'Pemberitahuan akan dihantar dalam bahasa ini.',
    open_time: 'Waktu Buka',
    payment_card_details: 'Butiran Kad Pembayaran',
    person_in_charge: 'Individu Bertanggungjawab',
    profile: 'profil',
    same_as_parent_company: 'Sama seperti Syarikat Induk',
    set_as_default: 'Tetapkan sebagai Lalai',
    set_default_time: 'Tetapkan Masa Lalai',
    set_delivery_days: 'Tetapkan Hari Penghantaran',
    update_address: 'Kemas Kini Alamat',
    update_person_in_charge: 'Kemas Kini Maklumat Individu Bertanggungjawab',
    upload_company_logo: 'Muat Naik Logo Syarikat',
    upload_image: 'Muat Naik Imej'
  },
  newest: 'Terbaru',
  next: 'Seterusnya',
  next_day: 'Penghantaran Hari Seterusnya tidak tersedia',
  no: 'Tidak',
  noData: 'Tiada Data',
  no_alert_confirm: 'Tidak, cipta pesanan baharu.',
  yes_proceed_order: 'Ya, buat pesanan ini',
  no_cash_off:
    'Dayakan jika anda ingin syarikat ini membuat pembayaran sama ada sebelum atau pada masa penghantaran untuk menerima item mereka. Pembekal mempunyai hak untuk mengambil balik item jika syarat penghantaran tidak dipenuhi.',
  no_cash_on:
    'Nyahdayakan jika anda tidak ingin syarikat ini membuat pembayaran sebelum atau pada masa penghantaran untuk memenuhi pesanan.',
  no_cc: 'Belum ada kad kredit ditambah',
  no_chat_text1: 'Maaf, tiada hasil ditemui.',
  no_chat_text2: 'Kami tidak dapat mencari kata kunci yang anda cari.',
  no_chat_text3: 'Sila cuba kata kunci yang lain.',
  no_continue_adding_new_company: 'Tidak, teruskan menambah syarikat baharu',
  no_credit_card_selected: 'Sila pilih kaedah pembayaran',
  no_expiry_date: 'Tiada tarikh tamat tempoh',
  no_name: 'Tiada nama',
  no_notification: 'Tiada Pemberitahuan',
  no_notification_text1: 'Pemberitahuan anda akan ditunjukkan di sini!',
  no_notification_text2: 'Kami akan menggunakan ruang ini untuk memaklumkan anda tentang',
  no_notification_text3: 'pesanan dan promosi! :)',
  no_product_found: 'Tiada Produk Ditemui',
  no_product_in_category: 'Belum ada produk di sini,',
  no_quantity: 'Pembekal ini tidak mempunyai kuantiti yang cukup bagi item ini.',
  no_referred_text: 'Belum ada rakan yang disyorkan',
  no_results_found: 'Tiada hasil carian ditemui',
  no_search_result: 'Kami tidak dapat menjumpai produk yang sepadan dengan carian anda.',
  no_search_text1: 'Kami tidak menjumpai produk yang',
  no_search_text2: 'sepadan dengan carian anda!',
  no_subcategory1: 'Kategori ini tidak mempunyai item buat masa ini.',
  no_subcategory2: 'Sila datang kembali lagi!',
  no_supcategory: 'Pembekal ini tidak mempunyai item buat masa ini.',
  no_supplier_text1:
    'Kami sangat komited untuk membawa pembekal ke bandar anda menyertai kami. Kami berharap anda akan dapat menikmati membeli belah produk di platform kami tidak lama lagi!',
  no_supplier_text2: 'Tidak pasti apa yang perlu dilakukan seterusnya?',
  no_supplier_text3: 'Sila',
  no_supplier_text4: 'hubungi kami',
  no_transaction_made: 'Tiada transaksi dibuat.',
  no_update_available: 'Tiada maklumat terkini tersedia',
  no_vouchers_text: 'Tiada baucar ditemui',
  non_halal_product: 'Tidak Halal',
  non_pooling: 'Bukan Dikumpulkan',
  notif_no_spree_open:
    'Pusat Pengambilan ini tidak dibuka untuk pesanan sekarang. Kembali kemudian atau pilih yang lain!',
  notification: 'Pemberitahuan',
  notifications: 'Pemberitahuan',
  off: 'MATI',
  new_onboarding_1: 'Bahan makanan berkualiti tinggi dengan kos rendah untuk perniagaan anda',
  new_onboarding_2: 'Urus berbilang entiti dalam satu aplikasi mudah',
  onboarding1_text:
    'Platform Group Buy Nombor 1 tempat rakan dan jiran dapat menikmati diskaun hebat bersama-sama!',
  onboarding1_title: 'Selamat Datang ke Treedots',
  onboarding2_text:
    'Kami bekerjasama dengan pembekal yang boleh dipercayai yang membekalkan makanan ke pasar dan restoran yang anda nikmati.',
  onboarding2_title_1: 'Makanan Berkualiti Tinggi.',
  onboarding2_title_2: 'Harga Rendah.',
  onboarding3_text: 'Beli belah barangan runcit yang mampan dan ambil di Pusat Pengambilan pilihan anda!',
  onboarding3_title: 'Terokai',
  onboarding4_text: 'Berbual dengan hos Pusat Pengambilan anda dan rakan-rakan pembeli yang lain!',
  onboarding4_title: 'Sertai komuniti yang semakin berkembang!',
  onboarding_button: 'Beli Belah Barangan Runcit',
  one_or_more_company:
    'Satu atau lebih syarikat dalam pangkalan data kami mempunyai poskod yang sama seperti yang anda telah masukkan. Jika syarikat yang anda cuba tambahkan sudah wujud, sila sambung kepadanya',
  one_or_more_company1:
    'Satu atau lebih syarikat dalam pangkalan data kami mempunyai nombor telefon yang sama seperti yang anda telah masukkan. Jika syarikat yang anda cuba tambahkan sudah wujud, sila sambung kepadanya',
  open_alert_reject: 'Adakah anda ingin menolak Pra-pesanan',
  open_document: 'Buka Dokumen',
  operation_label: 'Operasi',
  optional: 'Pilihan',
  Optional: '(Pilihan)',
  or_sign_in_with: 'atau daftar masuk dengan',
  or_use: 'atau gunakan yang lebih',
  order: {
    accept_rejection: 'Terima Penolakan',
    accept_rejection_confirm_description:
      'Menerima penolakan ini bermakna anda telah bersetuju untuk menamatkan pertikaian ini. Anda tidak akan menerima bayaran balik.',
    accept_rejection_confirm_title: 'Adakah anda pasti ingin menerima penolakan itu?',
    action_not_be_reversed: 'Tindakan ini tidak boleh dibuat semula',
    allow_refund_default: 'Sentiasa membuat bayaran balik kepada TreeDots Pay',
    amount_refunded: 'Jumlah Dibayar Balik:',
    appeal_description:
      'Dengan menolak tawaran ini, isu ini akan dilaporkan kepada Pusat Resolusi TreeDots. Proses semakan akan mengambil masa dalam 12-24 jam. Anda akan menerima maklum balas sebaik sahaja proses semakan ini selesai.',
    appeal_reason_input_placeholder:
      'Sila maklumkan sebab kepada masalah ini secara terperinci agar kami dapat membuat siasatan dengan segera.',
    appeal_submitted: 'Rayuan Telah Diserahkan',
    appeal_submitted_description:
      'Isu ini telah dilaporkan kepada Pusat Resolusi TreeDots. Proses semakan akan mengambil masa dalam satu hari. Anda akan menerima maklum balas sebaik sahaja proses semakan ini selesai.',
    appeal_to_treedots: 'Rayuan kepada TreeDots',
    attachment: 'Lampiran',
    back: 'KEMBALI',
    browse: 'Layari',
    buy_again: 'Beli Lagi',
    cancel: 'Batalkan',
    cancel_Item_modal: 'BATALKAN ITEM',
    cancel_item: 'Batalkan Item?',
    cancel_modal_text: 'Adakah anda pasti ingin membatalkan item ini?',
    cancel_order_confirm: 'Adakah anda pasti ingin membatalkan pesanan ini?',
    cancel_order_succeed: 'Berjaya dibatalkan',
    cancel_refund_default: 'Bukan sekarang',
    cancelled: 'Dibatalkan',
    cashback: 'pulangan tunai',
    change_company: 'Ubah Syarikat',
    chart_with_hub: 'Sembang dengan Hos',
    chat_not_ready: 'Ruang sembang komuniti ini masih belum sedia!',
    collected: 'Telah Diambil',
    collection_date: 'Tarikh Pengambilan:',
    collection_point: 'Pusat Pengambilan',
    completed: 'Selesai',
    date_of_request: 'Tarikh permintaan',
    date_resolved: 'Tarikh diselesaikan',
    dispute: 'Pertikaian',
    dispute_attach_description:
      'Lampirkan bukti sokongan bagi isu anda. Anda boleh melampirkan foto (JPG, JPEG dan PNG) atau video (MP4) hingga 5 fail. Maksimum 10MB',
    dispute_attach_photo_video: 'Lampirkan foto atau video',
    dispute_detail: 'Butiran Pertikaian',
    dispute_history: 'Sejarah Pertikaian',
    dispute_id: 'ID Pertikaian',
    dispute_review_description:
      'Isu ini telah dilaporkan kepada Pusat Resolusi TreeDots. Proses semakan akan mengambil masa kira-kira satu hari. Anda akan menerima maklum balas sebaik sahaja proses semakan ini selesai.',
    draft: 'Deraf',
    estimated: 'Anggaran',
    estimated_collection_date: 'Anggaran Tarikh Pengambilan',
    group_buy_progress: 'Kemajuan Group Buy',
    have_been_rewarded: 'Anda telah diberikan ganjaran Pulangan Tunai!',
    have_no_cancelled: 'Anda tidak mempunyai item “Dibatalkan”.',
    have_no_collected: 'Anda tidak mempunyai item “Telah Diambil”.',
    have_no_overview: 'Anda tidak mempunyai item “Gambaran Keseluruhan”.',
    have_no_preparing: 'Anda tidak mempunyai item yang “Sedang Disediakan”.',
    have_no_to_collect: 'Anda tidak mempunyai item "Untuk Diambil".',
    manage_account: 'Urus Akaun',
    my_account: 'Akaun Saya',
    my_orders: 'Pesanan Saya',
    need_help: 'Perlukan bantuan?',
    ongoing: 'Sedang berlangsung',
    opening_chat: 'Membuka ruang sembang...',
    order_No: 'Nombor Pesanan',
    order_amount: 'Amaun Pesanan:',
    order_id: 'ID Pesanan',
    order_title: 'Pesanan',
    order_total: 'Jumlah Pesanan:',
    our_food: 'makanan kami untuk mula memesan dengan kami!',
    overview: 'Gambaran Keseluruhan',
    package_no: 'Nombor Pakej',
    polling_item: 'Item yang dikumpulkan',
    preparing: 'Sedang Disediakan',
    qty: 'Kuantiti:',
    reason_for_appeal: 'Sebab rayuan',
    refund: 'Bayaran balik:',
    refund_modal_bold_text: 'Akaun > TreeDots Pay',
    refund_modal_header:
      'Adakah anda mahu menerima bayaran balik dengan serta merta pada masa yang akan datang?',
    refund_modal_text:
      'Pilih e-dompet TreeDots Pay sebagai pilihan utama bagi semua bayaran balik anda. Anda sentiasa boleh mengubahnya',
    refund_total: 'Amaun yang dibayar balik:',
    refunded: 'Telah dibayar balik',
    rejoin_chat_message:
      'Anda telah meninggalkan ruang sembang ini sebelum ini. Anda ingin menyertai kembali?',
    rejoin_chat_no: 'TIDAK, TERIMA KASIH',
    rejoin_chat_title: 'Sertai semula ruang sembang ini?',
    rejoin_chat_yes: 'SERTAI RUANG SEMBANG',
    resolution: 'Resolusi',
    resolve_dispute: 'Selesaikan Isu',
    resolve_dispute_description: 'Isu ini akan diselesaikan secara automatik dalam masa 24 jam.',
    submit_appeal: 'Serahkan Rayuan',
    supplier_rejection_description1: 'Pembekal menolak permintaan untuk pembayaran balik',
    supplier_rejection_description2: ' untuk produk. Sila semak Sejarah Pertikaian untuk melihat butiran.',
    supplier_rejection_reason: 'Alasan Penolakan Pembekal',
    text_item_required:
      'Item ini memerlukan kuantiti minimum untuk disahkan bagi pengambilan. Buat masa ini sadang menunggu pembeli lain dari kawasan kejiranan anda untuk mengumpul item ini! Anda boleh membatalkan item ini jika anda tidak ingin menunggu (anda tidak akan dicaj bagi item ini).',
    text_missing_items: 'Kenapa saya mempunyai item yang tiada?',
    text_pooling_items:
      'Item pengumpulan memerlukan kuantiti minimum untuk disahkan bagi pengambilan. Anda telah membuat pesanan melebihi had minimum bagi kumpulan ini. Tapi jangan bimbang! Item yang masih belum disahkan akan disahkan dalam kumpulan pembeli pada bila-bila masa!',
    to_collect: 'Untuk Diambil',
    total_paid: 'Jumlah Dibayar:',
    track_issue: 'Jejaki Isu',
    view_detail: 'Lihat Butiran',
    view_history: 'Lihat Sejarah',
    volume_deal: 'TAWARAN KUANTITI',
    voucher_savings: 'Penjimatan baucar:',
    want_to_cancel_issue: 'Adakah anda pasti ingin membatalkan isu ini?',
    you_will_receive: 'Anda akan menerima bayaran balik dalam masa 7-10 hari bekerja.'
  },
  orderB2b: {
    acceptingRemedy: 'Menerima produk tanpa sebarang pembetulan',
    accountNumber: 'Nombor Akaun',
    addPICMessage: 'Sila tambah individu bertanggungjawab ke akaun peniaga sebelum menyerahkan pesanan',
    attention: 'Perhatian',
    billTo: 'Bil Kepada',
    companies: 'Syarikat',
    created: 'Dicipta',
    deliverTo: 'Hantar Kepada',
    delivery: 'Penghantaran',
    deliveryInstruction: 'Arahan Penghantaran',
    delivery_address: 'Alamat Penghantaran',
    deplayDelivery: 'Kelewatan Penghantaran',
    detail: 'Butiran',
    discount: 'Diskaun',
    downloadInvoice: 'Muat turun invois',
    dueDate: 'Tarikh Akhir',
    filter: 'Penapis',
    from: 'Daripada',
    goodsSpoild: 'Barangan Rosak',
    grandTotal: 'Jumlah Keseluruhan',
    gst: 'GST',
    invoice: 'Invois',
    invoiceDate: 'Tarikh Invois',
    invoiceNumber: 'Nombor Invois',
    item: 'Item',
    items: 'Item',
    missingItem: 'Barangan Hilang',
    order: 'Pesanan',
    orderItemDetail: 'Butiran Item Pesanan',
    orderNow: 'Pesan Sekarang',
    orders: 'Pesanan',
    placeOrder: 'Buat Pesanan',
    poNumber: 'Nombor PO',
    preOrder: 'Pra-pesanan',
    price: 'Harga',
    purchase: 'Pembelian',
    qty: 'Kuantiti',
    remark: 'Catatan',
    repeat: 'Ulang',
    repeatOrder: 'Ulang Pesanan',
    reportProblem: 'Laporkan Masalah',
    requestingCreditNote: 'Memohon Nota Kredit',
    requestingExchange: 'Meminta pertukaran 1:1',
    requestingItem: 'Memohon untuk mengambil barangan yang salah',
    reset: 'Tetapkan semula',
    selectDate: 'Pilihan tarikh tamat mestilah lebih besar daripada tarikh mula',
    selectDeliveryDate: 'Pilih Tarikh Penghantaran',
    selectProblem: 'Pilih punca masalah anda',
    selectRemedy: 'Tindakan pembetulan yang dipilih',
    sendInvoice: 'Hantar Invois',
    separateInvoice: 'Invois Berasingan',
    showInvoice: 'Tunjukkan Invois',
    sku: 'SKU',
    status: 'Status',
    subTotal: 'Jumlah Kecil',
    submitPreOrder: 'Serahkan Prapesanan',
    tax: 'Cukai',
    taxInvoice: 'Cukai Invois',
    taxRegistrationNo: 'Nombor Pendaftaran Cukai',
    to: 'Kepada',
    total: 'Jumlah',
    totalPrice: 'Jumlah Harga',
    unitPrice: 'Harga Seunit',
    updateOrderSuccess: 'Berjaya dikemaskini',
    viewDispute: 'Lihat Pertikaian',
    viewDisputeStatus: 'Lihat Status Pertikaian',
    weight: 'Berat',
    paymentFail: 'Pembayaran Gagal',
    paymentFailMessage:
      'Maaf! Pesanan anda telah kami batalkan kerana kami tidak dapat menerima pembayaran untuknya',
    paymentPending: 'Pembayaran Belum Selesai',
    paymentPendingMessage:
      'Kami sedang menunggu pengesahan daripada rakan pembayaran untuk mengesahkan pesanan anda!',
    paymentPendingHeadline: 'Selesaikan pembayaran anda sebelum:',
    paymentExpireIn: 'Tamat tempoh dalam masa:',
    backToCart: 'Kembali Ke Troli',
    pendingPayment: 'PEMBAYARAN BELUM SELESAI',
    paymentInstruction: 'Arahan Pembayaran',
    viewQRCode: 'Lihat Kod QR',
    cancelPaymentTransaction: 'Batalkan Pembayaran & Pesanan',
    paymentTransactionExpired: 'Tempoh pembayaran telah tamat!',
    paynowInstruction: 'Your PayNow QR code will be valid for 1 hour, kindly make payment before it expires!',
    cancelPaymentTitle: 'Cancel Payment and Order?',
    cancelPaymentHint: 'Your payment and order will be cancelled and you will not be charged.',
    cancelStayButton: 'Stay',
    cancelConfirmButton: 'Confirm',
    paymentStatusUpdated: 'Payment status has been updated!',
    paymentStatusUpdatedHint: 'Please close this page to continue'
  },
  order_added: 'Pesanan telah ditambah!',
  order_amount: 'Amaun Pesanan',
  order_created: 'Kami telah menerima pesanan anda!',
  order_failed: 'Pesanan Tidak Berjaya',
  order_now: 'Pesan Sekarang',
  order_of_measure: 'Susunan Ukuran',
  order_received_date: 'Tarikh Terima',
  note_to_driver: 'Note to Driver',
  order_submitted: 'Pesanan Telah Diserahkan',
  order_total: 'Jumlah Pesanan',
  ordering: 'Pesanan dan Pengambilan',
  ordering_collection: {
    find_out_more: 'Ketahui dengan lebih lanjut mengenai Pesanan dan Pengambilan di sini!',
    p1: 'Jika anda membeli dengan kami, anda akan menjadi sebahagian daripada Group Buy.',
    p2: 'Layari pelbagai pilihan produk yang berkualiti dan nikmati tawaran hebat bersama!',
    p3: 'Daftar keluar barangan runcit anda di Pusat Pengambilan pilihan anda!',
    p4: 'Capai Group Buy minimum bersama rakan-rakan pembeli dan ambil pesanan anda!',
    p5: 'Berbual dengan Hos Pusat Pengambilan anda jika anda mempunyai soalan berkaitan dengan pesanan anda!',
    take_a_tour: 'Buat Lawatan',
    take_step: 'Lihat langkah-langkah Cara Menggunakan Group Buy!'
  },
  orders: 'Pesanan',
  orders_temporarily_closed: 'Pesanan Ditutup Buat Sementara',
  originalPrice: 'Harga Asal',
  other: 'Lain-Lain',
  other_information: 'Maklumat Lain (Pilihan)',
  other_methods: 'kaedah lain',
  our_mission: 'Visi dan Misi Kami',
  our_research: {
    content_1:
      'Kami mendapati lebih daripada 810,000 Tan makanan telah dibazirkan pada tahun 2017. Ini adalah di SINGAPURA SAHAJA.',
    content_2:
      'Perkara yang memburukkan lagi keadaan adalah membuang makanan dengan kuantiti yang banyak ke tapak pelupusan akan menghasilkan kuantiti gas metana yang sangat tinggi dan memberi impak yang menjejaskan kesihatan Planet kita.',
    title: 'Kami telah melengkapkan penyelidikan kami'
  },
  our_solution: {
    content_1:
      'Pada pertengahan tahun 2019, kami telah mencipta konsep versi pertama aplikasi kami. Kami telah menghasilkan platform atas talian yang membolehkan pembekal untuk mengedarkan stok lebihan makanan melalui perkhidmatan pengurusan pesanan dan logistik.',
    content_2:
      'Dengan mengekalkan yuran yang rendah bagi platform kami, kami telah menunjukkan kepada pembekal kaedah alternatif bagi kos efektif dalam menangani inventori tidak terjual dan berhubung dengan pelanggan mereka.',
    title: 'Penyelesaian Kami'
  },
  our_team_will_reply_to_your_inquires_in_2_3_working_days:
    'Pasukan kami akan membalas pertanyaan anda dalam tempoh 2-3 hari bekerja.',
  out_of_stock: 'Kehabisan Stok',
  select_other_variant: 'Pilih Variasi Lain',
  overview: 'Gambaran Keseluruhan',
  pack_of: 'PEK',
  packaging_size: 'Saiz Pembungkusan',
  paid: 'TELAH DIBAYAR',
  parent_address: 'Alamat induk',
  password: 'Kata Laluan',
  password_cannot_be_empty: 'Kata laluan tidak boleh kosong',
  password_failed: 'Kemas kini kata laluan tidak berjaya!',
  password_must_be_at_least_6_characters: 'Kata laluan mestilah sekurang-kurangnya 6 aksara',
  password_updated: 'Kata laluan telah berjaya dikemas kini!',
  pay_now: 'Bayar Sekarang',
  payment: 'Bayaran',
  paymentStatus: 'Status Pembayaran',
  paymentSuccess: 'Pembayaran Berjaya',
  paymentSuccessMessage: 'Order has been successfully placed',
  paymentMethod: 'Kaedah Pembayaran',
  paymentTime: 'Masa Membuat Pembayaran',
  orderNumber: 'Nombor pesanan',
  totalAmount: 'JUMLAH',
  viewOrderDetails: 'Lihat Butiran Pesanan',
  backToHome: 'Kembali ke Halaman Utama',
  paymentFail: 'Pembayaran Gagal',
  paymentFailMessage:
    'Maaf! Pesanan anda telah kami batalkan kerana kami tidak dapat menerima pembayaran untuknya.',
  backToCart: 'Kembali Ke Troli',
  payment_method: 'Kaedah Pembayaran',
  payment_option: 'Pilihan Pembayaran',
  payment_option_page: {
    other_payment_method: 'Kaedah Pembayaran Yang Lain',
    treedots_balance_modal_page: {
      amount: 'AMAUN',
      change_payment_method: 'Ubah Kaedah Pembayaran',
      oops: 'Oops, anda tidak mempunyai baki yang mencukupi',
      to_complete:
        'Bagi melengkapkan transaksi anda, sila tambah nilai dompet TreeDots anda atau pilih pilihan pembayaran yang lain',
      top_up_treedots: 'Tambah Nilai TreeDots Wallet'
    },
    treedots_pay: 'TreeDots Pay'
  },
  penang: 'Pulau Pinang',
  per: 'Setiap',
  per_unit_price: 'Harga Per Unit',
  perak: 'Perak',
  person: 'Individu',
  person_type: 'Jenis Individu',
  pet: 'Haiwan Peliharaan',
  phone: 'Telefon',
  phone_alt_img: 'telefon',
  phone_failed: 'Kemas kini Telefon tidak berjaya!',
  phone_logo_url: 'https://cdn2.iconfinder.com/data/icons/circle-icons-1/64/phone-256.png',
  phone_number: 'Nombor Telefon',
  phone_number_cannot_be_empty: 'Nombor telefon tidak boleh kosong',
  phone_share_link: 'tel:+65 3138 5464',
  phone_updated: 'Telefon berjaya dikemas kini!',
  place_order: 'Buat pesanan',
  please_check_your_email_password: 'Sila semak e-mel dan kata laluan anda',
  please_enter_valid_mobile_number: 'Sila masukkan nombor telefon yang sah.',
  please_enter_valid_username_password: 'Sila masukkan nama pengguna dan kata laluan yang sah',
  please_enter_verification_code:
    'Sila masukkan kod pengesahan yang telah dihantar ke nombor telefon mudah alih anda<br><strong>{countryCode} {number}</strong>',
  please_provide_your_password: 'Sila berikan kata laluan anda',
  please_provide_your_username: 'Sila berikan nama pengguna anda',
  please_select_language: 'Sila pilih bahasa.',
  please_try_again: 'Sila cuba lagi',
  please_wait: 'Sila tunggu',
  po_number: 'Nombor PO',
  po_number_optional: 'Nombor PO (Pilihan)',
  pool_item: 'Item Kumpulan',
  pool_together: 'Kumpul Bersama',
  pooling: 'Mengumpul',
  pooling_item: 'ITEM PENGUMPULAN',
  position: 'Jawatan',
  postal_code: 'Poskod',
  postal_code_cannot_be_empty: 'Poskod tidak boleh kosong',
  postal_code_not_found: 'Syarikat dengan poskod tidak dijumpai.',
  postal_code_placeholder: 'cth. 456123',
  price: 'Harga',
  priceUpdatedSuccessfully: 'Harga berjaya dikemas kini',
  price_cannot_be_empty: 'Harga tidak boleh kosong',
  price_change_log: 'Log perubahan harga',
  price_is_quoted: 'Sebut harga',
  price_normal: 'harga',
  price_per: 'Harga /',
  price_update: 'Harga Dikemas kini',
  price_updated: 'Harga Dikemas kini',
  privacy: 'Dasar Privasi',
  privacy_policy: {
    change_privacy: '7. Perubahan kepada Dasar Privasi',
    children: '3. Privasi Kanak-Kanak',
    collection: '2. Pengumpulan Data Peribadi',
    cookies: '6. Kuki dan Teknologi Mudah Alih',
    effective: 'Berkuat kuasa pada 14 Mei 2020.',
    intro: '1. Pengenalan',
    p1:
      'Dasar Privasi ini terpakai kepada produk, perkhidmatan, laman web yang disediakan, aplikasi mudah alih yang disediakan dan/atau perniagaan yang dijalankan, oleh TreeDots Enterprise (Private Limited), TreeDots International (Private Limited) (secara kolektif dan individu dirujuk sebagai "TreeDots”, "kami") dan menerangkan cara kami mengendalikan Data Peribadi dan mematuhi keperluan Akta Perlindungan Data Peribadi ("PDPA") Singapura. TreeDots sangat mengambil tentang keselamatan serta privasi pelanggan dan pengguna laman web (seperti www.thetreedots.com) serta aplikasi mudah alih TreeDots (seperti laman web dan aplikasi telefon mudah alih secara kolektif atau individu yang dirujuk sebagai "Laman"; aplikasi telefon mudah alih yang dirujuk sebagai "Aplikasi").',
    p1_1:
      '1.1 Dengan menggunakan Perkhidmatan, Aplikasi atau Laman kami, anda telah mengesahkan dan bersetuju dengan pengambilan dan penggunaan Data Peribadi anda yang berkaitan dengan dasar ini. Kami tidak akan menggunakan atau berkongsi Data Peribadi anda untuk kegunaan lain melainkan perkara yang telah dinyatakan dalam Dasar Privasi ini.',
    p1_2:
      '2.1 Dasar Privasi ini akan membantu anda memahami cara kami mengumpul, menggunakan, mendedahkan dan/atau memproses Data Peribadi yang anda berikan kepada kami atau yang kami miliki tentang anda, serta membantu anda membuat keputusan sebelum memberi kami Data Peribadi anda. Terma "Data Peribadi" merujuk kepada maklumat yang disambungkan kepada anda sebagai individu yang boleh dikenal pasti, yang ditakrifkan di bawah PDPA bermaksud data, sama ada benar atau tidak, tentang individu yang boleh dikenal pasti daripada data tersebut, atau daripada data itu dan maklumat lain yang organisasi mempunyai atau mungkin mempunyai akses kepadanya ("Data Peribadi"). Ini mungkin termasuk nama, alamat, nombor telefon, alamat e-mel, tarikh lahir, jantina, butiran kad kredit, ID peranti, sistem atau versi pengendalian anda, maklumat tentang pembelian atau pilihan anda dan sebarang maklumat lain yang kami kumpulkan yang anda dikenal pasti atau boleh dikenal pasti.',
    p1_3: 'Dasar Privasi ini tertakluk kepada undang-undang Singapura.',
    p2_1:
      '2.1 Kami mungkin akan mengumpul Data Peribadi anda melalui pelbagai cara, termasuk tetapi tidak terhad kepada keadaan apabila anda:',
    p2_1a:
      '• memberikan Data Peribadi anda melalui Laman atau Aplikasi kami bagi tujuan pendaftaran dan mencipta akaun;',
    p2_1b: '• memuat turun atau mengakses Laman atau Aplikasi;',
    p2_1c: '• bersetuju dengan Laman atau Aplikasi yang akan mengakses Negara/Rantau anda;',
    p2_1d: '• menyertai promosi atau ciri-ciri Aplikasi yang lain;',
    p2_1e: '• memproses pembayaran atau transaksi kredit;',
    p2_1f:
      '• permintaan bagi maklumat produk atau maklumat atau ingin menerima komunikasi berkaitan pemasaran, promosi atau yang lain-lain;',
    p2_1g:
      '• meminta peruntukan barangan dan/atau perkhidmatan daripada kami dan/atau kami menyediakan tujuan perniagaan sampingan lain yang berkaitan dengan atau berkaitan dengan Laman atau Aplikasi kami • membuat pembelian melalui Aplikasi kami;',
    p2_1h: '• membuat pertanyaan melalui jabatan Kejayaan Pelanggan kami dan/atau;',
    p2_1i: '• berinteraksi dengan kakitangan jualan kami atau dengan kami.',
    p2_2:
      '2.2 Semasa menggunakan Perkhidmatan, Laman atau Aplikasi kami, atau meminta bantuan kami, kami mungkin akan mengumpul secara automatik atau menghendaki anda untuk memberikan maklumat peribadi anda, yang mungkin termasuk tetapi tidak terhad kepada:',
    p2_2a: '• nama;',
    p2_2b: '• alamat e-mel;',
    p2_2c: '• nombor telefon;',
    p2_2d: '• maklumat pembayaran;',
    p2_2e: '• data lokasi;',
    p2_2f: '• fail media;',
    p2_2g: '• data sejarah komunikasi;',
    p2_2h:
      '• data penggunaan dan transaksi, termasuk butiran mengenai carian, pesanan, pengiklanan dan kandungan yang anda berinteraksi di Laman atau Aplikasi, serta produk dan perkhidmatan lain yang berkaitan dengan anda.',
    p2_2i:
      'Anda tidak mempunyai kewajipan untuk menyediakan Data Peribadi yang diminta oleh kami. Walau bagaimanapun, bergantung pada situasi, kami mungkin tidak dapat menyediakan anda dengan produk dan perkhidmatan, atau berurus niaga dengan anda, bagi situasi yang bergantung pada pengambilan, pengunaan atau pendedahan Data Peribadi anda.',
    p3_1:
      '3.1 Kami tidak dan tidak berniat untuk mengumpul, menggunakan, berkongsi mana-mana Data Peribadi atau berurus niaga melalui platform kami secara langsung dengan sesiapa sahaja yang kami tahu berumur di bawah 18 tahun. Jika anda berumur di bawah 18 tahun, anda harus menggunakan Laman kami, Aplikasi atau Perkhidmatan hanya dengan penglibatan ibu bapa atau penjaga dan tidak sepatutnya menyerahkan sebarang Data Peribadi kepada kami. Dengan memberikan Data Peribadi kepada kami, anda mengisytiharkan bahawa anda berumur 18 tahun ke atas.',
    p4_1:
      '4.1 Dalam menjalankan perniagaan kami, TreeDots mungkin/perlu mendedahkan atau berkongsi Data Peribadi anda kepada syarikat perkhidmatan pihak ketiga kami, ejen dan/atau sekutu kami atau syarikat berkaitan, dan/atau pihak ketiga lain untuk pelbagai tujuan, kerana pihak ketiga tersebut akan memproses Data Peribadi anda untuk tujuan ini.',
    p4_2:
      '4.2 Penyedia perkhidmatan pihak ketiga kami boleh mengumpul dan memproses Data Peribadi anda bagi pihak kami atau sebaliknya bagi satu atau lebih untuk Tujuan berikut, termasuk tetapi tidak terhad kepada mereka yang menyediakan perkhidmatan analisis, pentadbiran, pengiklanan, logistik dan teknologi maklumat.',
    p5_1:
      '5.1 Kami akan berusaha untuk mengambil semua langkah yang munasabah dalam memastikan Data Peribadi anda dirahsiakan dan selamat, dan mengambil langkah-langkah teknikal dan organisasi yang sesuai untuk mengelakkan tindakan yang menyalahi undang-undang atau tidak sengaja, kehilangan tidak sengaja, pendedahan atau akses yang tidak dibenarkan atau lain-lain bentuk pemprosesan yang menyalahi undang-undang. Penggunaan Data peribadi pengguna dilindungi oleh rangkaian yang selamat dan hanya boleh diakses oleh bilangan pekerja yang terhad yang mempunyai hak akses khas kepada sistem tersebut.',
    p5_2:
      '5.2 Kami melaksanakan pengaturan keselamatan yang munasabah dalam memastikan Data Peribadi anda dilindungi dan terjamin. Walau bagaimanapun, kami tidak akan bertanggungjawab untuk sebarang penggunaan Data Peribadi anda tanpa kebenaran oleh pihak ketiga sepenuhnya yang berpunca daripada faktor di luar kawalan kami.',
    p6_1:
      '6.1 Sebagai tambahan kepada perkara di atas, kami mungkin akan menggunakan teknologi berikut (dihuraikan di bawah) untuk mengumpul maklumat mengenai aktiviti anda secara automatik dalam Aplikasi atau Laman, seperti yang mungkin:',
    p6_1a: '• Kuki • Suar web • Tag analitik • Log pelayan web • Teknologi geolokasi',
    p6_2:
      '6.2 Kuki. Bagi pengguna Laman, sila ambil perhatian bahawa TreeDots boleh mendepositkan “cookies” dalam komputer atau peranti mudah alih anda untuk mengenal pasti anda. Kuki ialah fail teks data kecil yang dihantar dari komputer pelayan semasa sesi pelayaran. Kuki biasanya disimpan pada cakera keras komputer dan digunakan oleh laman web untuk mensimulasikan sambungan berterusan ke laman ini. Langkah-langkah keselamatan telah digunakan untuk mengelakkan akses yang tidak dibenarkan kepada data pelawat. Walau bagaimanapun, pelawat mengakui bahawa TreeDots tidak mengawal pemindahan data melalui kemudahan telekomunikasi termasuk Internet. Oleh itu, setakat yang dibenarkan oleh undang-undang, TreeDots tidak akan bertanggungjawab terhadap sebarang pelanggaran keselamatan atau pendedahan yang tidak dibenarkan atau penggunaan sebarang data sedemikian di Internet, tanpa kesalahan TreeDots. Tidak semua kuki mengumpul Data Peribadi dan anda boleh mengkonfigurasi pelayar anda untuk menolak kuki. Walau bagaimanapun, ini mungkin bermakna anda mungkin tidak dapat memanfaatkan sepenuhnya perkhidmatan atau ciri di Laman ini.',
    p6_3:
      '6.3 Suar Web. Suar web ialah imej grafik kecil pada halaman web atau dalam e-mel yang boleh digunakan untuk merekod halaman dan iklan yang diklik oleh pengguna, atau menjejaki prestasi kempen pemasaran e-mel.',
    p6_4:
      '6.4 Tag Analitik. Kami menggunakan tag analitik untuk menganalisis apa yang pelanggan kami suka lakukan serta keberkesanan fungsi dan pengiklanan kami. Kami mungkin akan menggunakan maklumat yang dikumpulkan melalui tag analitik atau pautan yang dijejaki digabungkan dengan Data Peribadi anda.',
    p6_5:
      '6.5 Log Pelayan Web. Log pelayan web ialah rekod aktiviti yang dicipta oleh peranti mudah alih atau komputer yang menghantar halaman web yang anda minta ke laman pelayar. Sebagai contoh, log pelayan web mungkin merekodkan istilah carian yang anda masukkan atau pautan yang anda klik untuk membawa anda ke halaman web, maklumat tentang laman browser anda, seperti alamat IP anda dan kuki yang ditetapkan di browser anda oleh data pelayan.',
    p6_6:
      '6.6 Teknologi Geolokasi. Teknologi geolokasi merujuk kepada teknologi yang membenarkan kami menentukan Negara/Rantau anda. Kami mungkin meminta anda memberikan maklumat Negara/Rantau secara manual (seperti poskod anda), atau untuk membolehkan peranti mudah alih anda menghantar maklumat Negara/Rantau anda kepada kami.',
    p7_1:
      '7.1 TreeDots berhak untuk menukar Dasar Privasi pada bila-bila masa dan memaklumkan kepada anda tentang kemas kini dasar di Platform kami. Setakat yang dibenarkan di bawah undang-undang yang berkenaan, penggunaan berterusan Perkhidmatan, Laman atau Aplikasi kami, termasuk membuat sebarang pesanan, akan menjadi pengakuan dan penerimaan anda terhadap perubahan yang dibuat kepada Dasar Privasi ini.',
    p7_2:
      '7.2 Dasar ini terpakai kepada kedua-dua pembeli dan penjual yang menggunakan Perkhidmatan, Laman atau Aplikasi kami kecuali jika dinyatakan sebaliknya.',
    protecting: '5. Melindungi Data Peribadi anda',
    sharing: '4. Perkongsian dan Pendedahan Maklumat Peribadi',
    title: 'Dasar Privasi TreeDots'
  },
  private: 'Peribadi',
  processing: 'Memproses',
  product: 'Produk',
  productInfo: 'Maklumat Produk',
  product_availability: 'Ketersediaan Produk',
  product_detail: {
    achieve_min: 'Capai pembelian minimum bersama-sama',
    add_to_cart: 'Tambah ke Troli',
    add_to_cart_failed: 'Tidak berjaya Tambah ke troli',
    add_to_cart_success: 'Ditambahkan ke troli berjaya',
    almost_stock: 'Hampir kehabisan Stok!',
    buy_together: 'Beli bersama',
    buy_with_others: 'Beli bersama orang lain',
    country_of_origin: 'NEGARA ASAL',
    discounted_price: 'Harga Diskaun',
    halal: 'HALAL',
    how_group_buy_works: 'Cara Group Buy berfungsi',
    item_weight: 'BERAT ITEM',
    join_collection_point: 'Sertai Pusat Pengambilan',
    packaging: 'PEMBUNGKUSAN',
    per_unit_price: 'HARGA PER UNIT',
    pooling: 'pembeli diperlukan',
    product_description: 'PENERANGAN PRODUK',
    quantity: 'KUANTITI',
    see_less: 'Lihat Kurang',
    see_more: 'Lihat Lagi',
    total_price: 'JUMLAH HARGA',
    uom_default: 'Unit',
    variation: 'VARIASI',
    view_supplier: 'Lihat Pembekal',
    volume_deal: 'TAWARAN BILANGAN',
    volume_deal_description:
      'Nikmati harga diskaun dan terima pulangan tunai bagi setiap item yang dibeli apabila Pusat Pengambilan mencecah jumlah kuantiti!',
    volume_deal_details: 'Butiran Tawaran Kuantiti',
    similar_product: 'Produk Serupa',
    explore_other_product: 'Lihat-lihat Produk lain',
    sharing_title: 'Anda mungkin menggemari %{product_name} dari TreeDots for Merchants!',
    sharing_dialog_title: 'Kongsikan Produk',
    sharing_text: 'Lihat-lihat %{product_name} di TreeDots for Merchants sekarang!\n',
    sold_out: 'Sold Out',
    buy_again: 'Buy Again',
    stock: 'Stock',
    out_of_stock: 'Out of Stock'
  },
  product_found: 'Produk Ditemui',
  product_in_other_collection_point: 'Produk tersedia di <b>%{collection_point_name}</b>',
  product_info: 'Maklumat Produk',
  product_information: 'MAKLUMAT PRODUK',
  product_name: 'Nama Produk',
  product_name_a_z: 'Nama Produk A-Z',
  product_name_z_a: 'Produk Nama Z-A',
  product_price_update: 'Harga Produk Telah Dikemaskini',
  product_variation: 'VARIASI PRODUK',
  product_weight: 'Berat Produk',
  products: 'Produk',
  products_found: 'Produk Ditemui',
  profile: 'Profil',
  profile_updated: 'Profil Dikemas Kini',
  promos: 'Promosi',
  prompt_select_collection_point: 'Sila pilih Pusat Pengambilan anda!',
  providing: {
    content_1:
      'Pada tahun 2020, dalam keadaan pandemik Covid-19, kami telah menambah baik platform kami dengan menghubungkan pembekal secara terus kepada pengguna akhir. Kami memperkenalkan',
    content_1a:
      'Kini isi rumah dan pembeli individu boleh menikmati produk daripada pembekal kami pada harga yang lebih rendah dan mengambil bahagian secara aktif dalam menyelesaikan isu pembaziran makanan bersama kami.',
    content_2:
      'Pengguna akhir membeli produk dengan jiran dan rakan mereka bersama-sama sebagai pembelian kolektif. Pembekal tidak perlu memecahkan pukal mereka, boleh menghantar pesanan ke kawasan komuniti sekaligus mengurangkan pelepasan karbon dan membolehkan cara pengagihan semula yang lebih efektif.',
    groupbuy_app: 'Aplikasi Group Buy.',
    title: 'Menyediakan bagi Isi Rumah'
  },
  quantity: 'Kuantiti',
  quotation: 'Sebut Harga',
  quotationApproved: 'Sebut harga telah diluluskan',
  quotationPendingContent:
    'Oleh kerana harga sebut harga anda berada di bawah had yang ditetapkan, permohonan anda telah dikemukakan kepada pembekal anda untuk kelulusan selanjutnya.',
  quotationPendingTitle: 'Sebut harga anda sedang menunggu kelulusan',
  quotation_has_been_sent: 'Sebut Harga telah dihantar!',
  quotation_price: 'Harga Sebut Harga',
  quote_price: 'Harga Sebut Harga',
  quote_price_great_direct_price_title: 'Sebut harga melebihi harga langsung?',
  quote_price_great_direct_price_body:
    'Pelanggan akan menerima harga yang disebut yang lebih tinggi daripada harga langsung produk. Adakah anda pasti?',
  quote_price_great_direct_price_agree: 'Ya, Teruskan',
  quoted: 'Sebutan Harga',
  quotedBy: 'Sebut Harga Daripada',
  re_enter_password: 'Masukkan semula kata laluan',
  read_more: 'Baca lebih lanjut',
  received_your_problem:
    'Kami telah menerima permintaan anda. Sila tunggu sehingga kakitangan kami memberi respon kepada permintaan anda.',
  recent_searches: 'Carian Terkini',
  referral: 'Rujukan',
  referral_code_label: 'Rujukan (Pilihan)',
  referral_code_placeholder: 'Kod Rujukan',
  refund_breakdown: 'Butiran Bayaran Balik',
  refund_description1:
    'Bagi item yang dibayar dengan baucar, jumlah bayaran balik tidak termasuk jumlah baucar.',
  refund_description2: 'Anda boleh menyemak butiran bayaran balik di sini.',
  refund_tooltip1: 'Nilai baucar yang digunakan apabila pengguna mendaftar keluar',
  refund_tooltip2:
    'Bilangan item yang telah dibatalkan pada pesanan yang telah kami membuat pembayaran balik',
  refund_tooltip3: 'Jumlah amaun yang telah dibayar balik',
  refunded_amount: 'Amaun Dibayar Balik',
  refunded_qty: 'Kuantiti Dibayar Balik',
  refunds: 'Bayaran Balik',
  refunds_policy: {
    or: 'atau',
    p1:
      'TreeDots membenarkan pembatalan dan bayaran balik jika anda mengubah fikiran setelah membuat pesanan produk. Ini hanya terpakai kepada produk bagi Group Buy yang masih tidak mencapai had minimum.',
    p2:
      'Produk yang layak untuk pembatalan mesti mempunyai status “Sedang Disediakan” di dalam pesanan anda. Produk yang mempunyai status “Untuk Diambil” tidak boleh dibatalkan disebabkan oleh perubahan fikiran.',
    p3:
      'Semua bayaran balik disebabkan oleh perubahan fikiran adalah tertakluk kepada terma dan syarat berikut:',
    p3_1:
      'Item haruslah mempunyai status “Sedang Disediakan” di dalam pesanan anda bagi memenuhi syarat pembatalan dan pembayaran balik',
    p3_2: 'Item mestilah di bawah Group Buy yang masih belum mencapai had minimum yang diperlukan',
    p3_3:
      'Dasar pembayaran balik ini tidak terpakai kepada item, yang telah dinyatakan sebagai “Untuk Diambil” dan mencapai had Group Buy minimum',
    p3_4: 'Bagi item yang mempunyai baucar, jumlah bayaran balik tidak termasuk amaun baucar',
    p4a:
      'Bayaran balik bagi pembatalan item akan diproses selepas pembatalan kepada kaedah pembayaran yang sama yang anda gunakan untuk daftar keluar.',
    p4b:
      'Anda boleh mendapatkan maklumat bayaran balik mengenai item anda di bawah seksyen “Pembatalan” di dalam pesanan anda.',
    p5:
      'Anda boleh meminta Hos Pusat Pengambilan anda untuk membuat pembatalan bagi pihak anda di dalam ruang sembang jika anda ingin Hos untuk menguruskan pembatalan anda. Jika anda menerima produk yang tidak lengkap atau rosak, sila hubungi Hos Pusat Pengambilan anda untuk membuat permintaan bayaran balik untuk anda.',
    p6:
      'Sekiranya anda menghadapi masalah untuk membatalkan pesanan anda akibat masalah teknikal, sila hubungi',
    refunds_take:
      'Pembayaran balik akan mengambil masa 7-10 hari bekerja untuk dipaparkan di akaun bank anda.',
    tech_call: 'Sokongan Teknikal melalui panggilan',
    title: 'Dasar Pembayaran Balik TreeDots',
    whatsapp: 'WhatsApp ke +65 31384846.',
    whatsapp_my: 'WhatsApp ke +65 89145600',
    whatsapp_sg: 'WhatsApp di +65 31384846.'
  },
  register_today: 'Daftar Hari Ini!',
  registered_mobile_go_to_login:
    'Nombor telefon anda telah didaftarkan dengan platform kami. Adakah anda ingin log masuk dengan nombor telefon mudah alih ini?',
  reject: 'Tolak',
  reject_pre_success: 'Tolak Pra-pesanan Berjaya',
  rejectedBy: 'Ditolak Oleh',
  reminder_spree_close_date_and_collection_date:
    'Beli sebelum <b>%{close_date}</b> untuk mengambil pada <b>%{collection_date}!</b>',
  remove_card: 'Alih keluar Kad',
  remove_confirm: 'Adakah anda pasti ingin memadamkan kad ini?',
  remove_mess: 'Alih keluar barangan ini?',
  remove_product_from_cart: 'Alih Keluar Item Dari Troli?',
  removedSuccessfully: 'Berjaya Dialih keluar.',
  reply: 'Balas',
  report_your_problem: 'Laporkan Masalah anda',
  requestAt: 'Minta Pada',
  requestQuotationDetails: 'Minta Butiran Sebut Harga',
  request_again: 'Minta sekali lagi',
  request_approval: 'Minta Kelulusan',
  request_approve: 'Minta Kelulusan',
  request_at: 'Minta Pada',
  request_quotation: 'Minta Sebut Harga',
  request_quotation_details: 'Minta butiran sebut harga',
  request_quotation_failed: 'Permintaan Sebut Harga Tidak Berjaya',
  request_reject: 'Permohonan Ditolak',
  request_transaction: 'Minta Butiran Transaksi',
  requested: 'Diminta',
  resend_code: 'Hantar Semula Kod',
  resend_code_in: 'Hantar Semula Kod dalam {duration}',
  reset_filter: 'Set Semula Penapis',
  reset_password: 'Tetapkan Semula Kata Laluan',
  salesperson: 'Jurujual',
  sample: 'Sampel',
  sampleProduct: 'Sampel Produk',
  save: 'Simpan',
  save_address: 'Simpan Alamat',
  save_changes: 'Simpan perubahan',
  saved_successfully: 'Berjaya Disimpan',
  seafood: 'Makanan Laut',
  search: 'Cari…',
  search_collection_point_via_map: 'Cari Pusat Pengambilan melalui peta',
  search_for: 'Cari',
  search_for_food: 'Cari makanan',
  search_in: 'Cari di',
  search_in_ang_mo_kio: 'Cari di Ang Mo Kio',
  search_items: 'Cari Item...',
  search_location: 'Masukkan Lokasi Di Sini',
  search_results_for: 'Hasil Carian bagi',
  search_suppliers: 'Cari pengedar...',
  search_term: 'istilah carian.',
  see_all: 'Lihat semua',
  see_more: 'Lihat Lagi',
  select: 'Pilih',
  selectCompany: {
    branches: 'Cawangan',
    change: 'Ubah',
    change_company: 'Ubah Syarikat',
    main_company: 'Syarikat Utama',
    products: 'Produk',
    select_company: 'Pilih Syarikat'
  },
  select_all: 'Pilih Semua',
  select_another_option: 'Pilih pilihan lain',
  select_collection_point: 'Pilih Pusat Pengambilan',
  select_date: 'Sila Pilih Tarikh',
  select_delivery_date: 'Tetapkan Tarikh Penghantaran',
  select_delivery_time: 'Pilih Waktu Penghantaran',
  select_invoice: 'Pilih Invois',
  select_payment_method: 'Pilih Kaedah Pembayaran',
  select_person: 'Pilih orang',
  select_preferred_language: 'Sila Buat Pilihan Harga',
  select_state: 'Pilih kawasan beli belah pilihan anda sekarang untuk menikmati pemilihan produk di sana.',
  select_valid_action: 'Sila pilih sebab yang sah kepada masalah anda',
  select_valid_remedy: 'Sila pilih tindakan pembetulan yang sah bagi masalah anda',
  selected_collection_point: 'Pusat Pengambilan Yang Dipilih',
  seller: 'Penjual',
  selling_price: 'Harga Jualan',
  send: 'Hantar',
  send_document: 'Hantar dokumen',
  send_feedback: 'Hantar Maklumbalas',
  send_photo: 'Hantar foto',
  send_verification_code: 'Hantar Kod Pengesahan',
  send_via_email: 'Hantar melalui E-mel',
  send_via_peppol: 'Hantar melalui Rangkaian Peppol',
  separate_invoice: 'Invois Berasingan',
  set_birth_date: 'Tetapkan Tarikh Lahir',
  set_default: 'Tetapkan Kad sebagai Lalai',
  set_main_card: 'Tetapkan Kad Utama',
  set_new_price: 'Tetapkan Harga Baharu',
  set_password: 'Tetapkan Kata Laluan',
  set_password_for_sub_buyer: 'Tetapkan kata laluan bagi subpembeli',
  set_time: 'Tetapkan Tarikh dan Waktu Penghantaran',
  settings: 'Tetapan',
  share: 'Kongsikan',
  share_via: 'Kongsikan melalui',
  show_less: 'Tutup pilihan',
  show_more: 'Buka Pilihan',
  sign_in: 'Log masuk',
  sign_in_failed:
    'Nampaknya ia bukan kata laluan atau e-mel yang betul. Sila cuba lagi atau gunakan Terlupa Kata Laluan.',
  sign_in_with_mobile_phone: 'Daftar Masuk Menggunakan Telefon Mudah Alih',
  sign_in_with_password: 'Daftar Masuk Menggunakan Kata Laluan',
  sign_out: 'Daftar Keluar',
  sign_out_confirmation: 'Pengesahan Daftar Keluar',
  delete_account_button: 'Padamkan akaun saya',
  delete_account_title: 'Apakah anda pasti?',
  delete_account_confirmation:
    'Akaun anda akan dipadamkan dan anda akan kehilangan semua akses kepadanya. Tindakan ini tidak boleh diterbalikkan.',
  delete_account_agree: 'Setuju dan padamkan akaun saya',
  delete_account_go_back: 'Kembali',
  sign_up: 'Daftar',
  singapore: 'Singapura',
  skip: 'Langkau',
  sku: 'SKU',
  snacks: 'Makanan ringan',
  social_shared_mess:
    'Hai, TreeDots menyediakan inventori berkualiti tinggi dan murah.\n\nMuat turun sekarang di https://thetreedots.com/download',
  soldInUnits: 'Jualan dalam unit {number}',
  out_of_max_quantity: 'Kuantiti maksimum ialah {quantity}!',
  out_of_max_weight: 'Maximum weight is {weight} KG!',
  last_remaming_item: 'Berat maksimum ialah {weight} KG!',
  last_remaming_weight_item: 'Baki {remainingQuantity} KG terakhir yang tinggal',
  some_items_not_favorite: 'Sesetengah item tiada di dalam Kegemaran.',
  something_went_wrong_please_try_again: 'Sesuatu telah berlaku. Sila cuba lagi.',
  sorry: 'Maaf!',
  sort: 'Susun',
  spelling: 'ejaan',
  stall: 'Gerai',
  stallNo: 'Nombor Gerai (Pilihan)',
  staples: 'Makanan Asas',
  start_buying_choose_cp: 'Untuk mula membeli dalam kumpulan, pilih pusat pengambilan anda.',
  start_purchasing_today: 'Mulakan Pembelian Hari Ini',
  started_with_avocados: {
    content_1:
      'Salah seorang pengasas yang telah bekerja di sebuah syarikat perdagangan dan perkapalan komoditi sebelum ini, dan pada satu hari, disebabkan oleh keadaan yang tidak dijangka, 24 Tan Avocado telah ditakdirkan untuk rosak di pelabuhan perkapalan di China.',
    content_2:
      'Pengasas itu tidak dapat menerima perkara tersebut. Ini mencetuskan idea TreeDots. Kami mahu menjadi sebuah syarikat yang menyelamatkan makanan hasil segar berkualiti tinggi daripada dibuang kerana amalan kawal selia yang lama.',
    title: 'Ia bermula dengan buah Avokado'
  },
  state: 'Negeri',
  stay_tuned: 'Nantikan!',
  stock_locations: 'lokasi stok',
  street: 'Jalan',
  street_number_cannot_be_empty: 'Nombor jalan tidak boleh kosong',
  sub_total: 'Jumlah Kecil',
  submit: 'Serah',
  submit_information_about_other_supplier: 'Hantar maklumat berkaitan pembekal yang lain',
  submit_report_mess:
    'Kami telah menerima permohonan anda. Sila tunggu sehingga kakitangan kami memberi respon kepada permohonan anda.',
  subtotal: 'Jumlah Kecil',
  success: 'Berjaya',
  successfully_added_to_cart: 'Berjaya ditambah dalam troli.',
  successfully_connected_to_company: 'Berjaya disambungkan ke syarikat',
  sucess_msg_color: 'utama',
  supplier: 'Pembekal',
  support: 'Sokongan',
  sweets: 'Gula-gula',
  switch_collection_point: 'Ubah Pusat Pengambilan',
  sync_state: {
    halal: 'Halal',
    non_halal: 'Tidak Halal',
    not_applicable: 'Tidak diperakui Halal'
  },
  system_outage_head_text: 'Kami tutup buat sementara waktu 😫',
  system_outage_sub_text:
    'Pasukan kami sedang berusaha untuk memastikan platform kembali berfungsi, sila beri kami sedikit masa untuk membaikinya',
  tab_1: 'Tab 1',
  tabs: {
    account: 'Akaun',
    cart: 'Troli',
    categories: 'Kategori',
    home: 'Laman Utama',
    orders: 'Pesanan'
  },
  takeAction: 'Ambil Tindakan',
  take_photo: 'Ambil foto',
  tap_here_to_view_description: 'Klik di sini untuk melihat penerangan',
  tax: 'Cukai',
  tax_amount: 'Semua harga termasuk GST',
  tech: 'Sokongan Teknikal',
  telegr: 'Telegram',
  telegr_alt_img: 'telegram',
  telegr_link_to_share:
    'https://telegram.me/share/url?text=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%0Ahttps%3A%2F%2Fthetreedots.com%2Fdownload&amp;url=',
  telegr_logo_url: 'https://cdn3.iconfinder.com/data/icons/social-icons-33/512/Telegram-256.png',
  telegr_share_link: 'https://t.me/TreeDotsBot',
  tell_a_friend: 'Maklumkan Kepada Kawan',
  terms: 'Terma Penggunaan',
  terms_of_use: {
    collection: '5. Pengambilan',
    effective: 'Berkuat kuasa mulai 14 Mei 2020.',
    eligibility: '3. Kelayakan',
    governing: '10. Undang-Undang dan Bidang Kuasa Pentadbiran',
    inability: '6. Ketidakupayaan untuk Mengambil',
    intelectual: '2. Harta Intelek',
    intro: '1. Pengenalan',
    intro_1:
      'Ini merupakan terma penggunaan dan penjualan (“Terma”) bagi penggunaan laman yang dimiliki, dikawal dan diuruskan oleh TreeDots Enterprise (Private Limited) dan TreeDots International (Private Limited) (secara kolektif “TreeDots”), termasuk www.thetreedots.com dan mana-mana aplikasi mudah alih TreeDots (“Laman”). Kami mungkin menambah laman tambahan dari semasa ke semasa ketika kami memperkembangkan tawaran kami dan Terma ini akan mengawal selia laman baharu tersebut apabila ditambah.',
    intro_2:
      'Terma ini adalah antara anda dan TreeDots termasuk tanpa had pengguna seperti penjual, pembeli, peniaga atau penyumbang kandungan, maklumat dan bahan-bahan lain. Jika anda membuat perjanjian ini untuk dan bagi pihak entiti perniagaan, istilah “anda” dalam perjanjian ini bermaksud entiti perniagaan bagi pihak yang anda gunakan Laman ini, melainkan konteks yang tidak dibenarkan.',
    limitations: '9. Had Liabiliti',
    miscell: '11. Hal-Hal Lain',
    p10_1:
      'Anda dengan ini bersetuju bahawa undang-undang Singapura akan mengawal Terma ini dan mahkamah Singapura akan mempunyai bidang kuasa bukan eksklusif terhadap sebarang pertikaian yang timbul daripada atau berkaitan dengan Terma ini.',
    p11_1:
      'Anda dan TreeDots merupakan kontraktor bebas, dan tiada agensi, perkongsian, usaha sama, majikan-pekerja atau hubungan francais yang dimaksudkan atau dicipta oleh Terma ini.',
    p11_2:
      'Jika mana-mana peruntukan Terma ini tidak sah atau tidak boleh dikuatkuasakan, kesahihan dan penguatkuasaan peruntukan yang tinggal tidak akan terkesan atau terjejas.',
    p11_3:
      'Sebarang kegagalan TreeDots untuk melaksanakan hak atau memerlukan pelaksanaan kewajipan dalam Terma ini tidak akan menjejaskan keupayaan TreeDots dalam melaksanakan hak tersebut atau memerlukan tindakan pada bila-bila masa selepas itu dan tidak akan mengenepikan pelanggaran Terma ini.',
    p11_4:
      'Anda tidak boleh menyerahkan sebarang hak atau mewakilkan apa-apa kewajipan di sini tanpa kebenaran bertulis kami terlebih dahulu dan sebarang percubaan penyerahan hak atau perwakilan yang melanggar peruntukan ini adalah terbatal dan tidak sah serta tidak mempunyai kesan.',
    p11_5: 'Terma ini tidak memberi hak kepada mana-mana pihak ketiga yang bukan pihak kepada Terma ini.',
    p1_1:
      '1.1 Anda mungkin mengakses Laman kami dari komputer atau peranti mudah alih (contohnya melalui sebarang aplikasi mudah alih TreeDots). Terma ini mengawal selia penggunaan Laman kami dan kelakuan anda, tanpa mengira cara akses.',
    p1_2:
      '1.2 Kami mungkin akan melibatkan anda dengan laman pihak ketiga termasuk halaman web media sosial tempat kami menyiarkan kandungan, seperti http://www.facebook.com/thetreedots/ dan lain-lain. TreeDots tidak mengawal Laman Sosial tersebut dan Terma ini tidak terpakai kepada syarikat yang tidak dimiliki atau dikawal oleh TreeDots, atau tindakan orang yang tidak diambil bekerja atau diuruskan oleh TreeDots. Anda hendaklah sentiasa menyemak terma penggunaan yang disiarkan di Laman Sosial.',
    p1_3:
      '1.3 Kami meminta anda untuk membaca Terma dan Dasar Privasi kami dengan teliti sebelum mengakses atau menggunakan Laman kami, atau membeli sebarang produk melalui Laman kami. Jika anda mempunyai sebarang pertanyaan yang berkaitan dengan melayari dan pembelian atas talian bersama kami, sila rujuk kepada Soalan Lazim kami (“FAQ”), bahagian Pesanan dan Pengambilan atau hubungi kami.',
    p1_4:
      '1.4 Dengan menggunakan Laman ini, anda bersetuju dengan Terma dan peruntukan Dasar Privasi, Soalan Lazim, Pesanan dan Pengambilan serta Dasar Bayaran Balik kami. TreeDots berhak untuk menukar atau mengubah suai mana-mana terma dan syarat yang terkandung dalam Terma pada bila-bila masa sahaja, atau pada notis seperti yang dikehendaki oleh undang-undang yang berkaitan. Sebarang perubahan atau pengubahsuaian kepada Terma Penggunaan TreeDots akan berkuat kuasa apabila perubahan ini disiarkan. Terma Penggunaan TreeDots akan terpakai kepada mana-mana pesanan yang dibuat melalui Laman kami adalah Terma Penggunaan TreeDots pada masa anda membuat pesanan. Penggunaan berterusan anda terhadap Laman ini berikutan penyiaran sebarang perubahan atau pengubahsuaian merupakan pengesahan dan penerimaan anda terhadap perubahan atau pengubahsuaian tersebut. Jika anda tidak bersetuju dengan Terma Penggunaan TreeDots atau perubahan serta pengubahsuaian, anda mesti segera berhenti menggunakan Laman ini.',
    p2_1:
      '2.1 Anda boleh menggunakan Laman ini mengikut kebenaran yang telah dibenarkan oleh Terma Penggunaan TreeDots.',
    p2_2: '2.2. Anda tidak boleh:',
    p2_2a:
      '2.2.1. Menyalin, menyahkompil, kejuruteraan terbalik, membongkar, cuba mendapatkan kod sumber, menyahsulit, mengganggu, atau mengugat integriti atau prestasi Laman;',
    p2_2b:
      '2.2.2. Membuat atau cuba membuat sebarang pengubahsuaian, penyesuaian, penambahbaikan, terjemahan atau kerja terbitan daripada Laman;',
    p2_2c:
      '2.2.3. Melanggar mana-mana undang-undang terpakai, peraturan atau pengawalan yang berkaitan dengan akses anda atau penggunaan Laman;',
    p2_2d: '2.2.4. Menggunakan Laman bagi tujuan yang ia tidak direka atau dimaksudkan;',
    p2_2e:
      '2.2.5. Menggunakan Laman untuk menghantar, menyiarkan, atau menyampaikan apa-apa kandungan yang menyinggung perasaan, perlakuan tidak senonoh, mengancam, menyiksa, menghina, mengganggu, memfitnah, menipu, menyiksa, lucah, tidak senonoh, invasif terhadap privasi orang lain, perkauman, etnik atau sebaliknya yang tidak menyenangkan;',
    p2_2f:
      '2.2.6. Menggunakan Laman untuk menghantar mesej yang tidak diminta atau mesej yang tidak dibenarkan, bahan pengiklanan atau promosi atau sebarang spam atau surat berantai.',
    p2_2g:
      '2.2.7. Menggunakan atau cuba menggunakan sebarang robot, bot, lelabah carian atau mana-mana peranti automatik lain atau cara untuk mengakses, memperoleh, menyalin atau memantau mana-mana bahagian Laman, atau sebarang data atau kandungan yang ditemui atau diakses melalui Laman;',
    p2_2h: '2.2.8. Mengumpul sebarang maklumat berkenaan dengan pengguna lain tanpa persetujuan mereka;',
    p2_2i:
      '2.2.9. Melakukan apa-apa perbuatan untuk mengelakkan daripada membayar apa-apa yuran dan/atau caj yang dikenakan;',
    p2_2j:
      '2.2.10. Mencuba dan/atau melibatkan diri dalam sebarang aktiviti atau tindakan yang bertujuan untuk menyalahgunakan, atau memanipulasi sebarang promosi, kempen dan/atau kod diskaun yang ditawarkan melalui Laman. Akta dan aktiviti sedemikian termasuk tetapi tidak terhad kepada: mencipta akaun palsu atau pendua;',
    p2_2k: 'menjana pesanan palsu;',
    p2_2l:
      '2.2.11. Mengizinkan atau menggalakkan sesiapa sahaja untuk melakukan mana-mana yang dinyatakan di atas;',
    p2_3:
      '2.3. Semua hak harta intelek (termasuk, tanpa had, hak cipta, tanda dagangan, paten, hak reka bentuk, dan semua bentuk hak milik intelek lain) dalam Laman ini dimiliki oleh TreeDots. Selain itu, anda mengakui dan bersetuju bahawa kod sumber dan objek Laman dan format, direktori, pertanyaan, algoritma, struktur dan organisasi Laman ini merupakan harta intelek dan maklumat sulit TreeDots.',
    p2_4:
      '2.4. Laman ini boleh menggunakan atau memasukkan perisian pihak ketiga yang tertakluk kepada terma lesen sumber terbuka dan pihak ketiga (“Perisian Pihak Ketiga”). Anda mengakui dan bersetuju bahawa hak anda untuk menggunakan Perisian Pihak Ketiga tersebut sebagai sebahagian daripada Laman ini adalah tertakluk kepada dan ditadbir oleh terma dan syarat lesen sumber terbuka dan pihak ketiga yang terpakai kepada Perisian Pihak Ketiga tersebut, termasuk, tanpa had, apa-apa pengakuan, lesen yang berkenaan terma dan penafian yang terkandung di dalamnya. Sekiranya berlaku konflik di antara Terma ini dan terma lesen sumber terbuka atau pihak ketiga tersebut, terma sumber terbuka atau lesen pihak ketiga akan diguna pakai berkenaan dengan penggunaan Perisian Pihak Ketiga yang berkaitan dengan anda. Dalam keadaan apa pun, Laman atau komponen laman ini dianggap sebagai sumber terbuka atau perisian yang tersedia secara umum.',
    p3_1:
      '3.1 Untuk membuat pembelian melalui Laman kami, anda akan diminta untuk memberikan maklumat peribadi anda kepada kami. Khususnya, anda akan diminta untuk memberi kami nama anda, alamat e-mel, nombor telefon dan/atau butiran hubungan lain seperti yang dimaklumkan.',
    p3_2:
      '3.2 Maklumat atau butiran peribadi yang anda berikan kepada kami akan diproses mengikut Notis Privasi kami dalam konteks pesanan anda. Apabila anda menggunakan Laman kami, anda bersetuju dengan pemprosesan maklumat dan butiran anda, dan anda mengesahkan bahawa semua maklumat dan butiran yang diberikan oleh anda adalah benar dan tepat.',
    p3_3:
      '3.3 Apabila anda membuat pesanan melalui Laman kami, anda mengesahkan bahawa anda berumur lebih daripada 18 tahun dan layak untuk memasuki kontrak. Sekiranya anda berumur di bawah 18 tahun, anda hanya boleh membuat pesanan dengan mendapat persetujuan ibu bapa atau penjaga anda. Jika anda tidak mempunyai persetujuan daripada ibu bapa atau penjaga anda, anda mesti berhenti menggunakan Laman ini.',
    p3_4:
      '3.4 Sila ambil maklum bahawa jika anda tidak memberi kami semua maklumat yang kami perlukan, anda tidak boleh membuat atau memproses pesanan anda.',
    p4_1:
      '4.1 Maklumat produk dan penerangan di Laman ini telah diberikan kepada kami oleh Pembekal yang berkaitan yang menjual di platform kami. Dalam keadaan sedemikian TreeDots tidak secara bebas mengesahkan maklumat produk yang diberikan oleh Pembekal dan tidak boleh bertanggungjawab terhadap tuntutan tersebut.',
    p4_2:
      '4.2 Walaupun kami cuba memberikan penerangan yang tepat mengenai produk, kami tidak menjamin bahawa penerangan, maklumat, harga, promosi atau kandungan lain yang terdapat di Laman adalah tepat, terkini atau bebas daripada kesilapan. TreeDots boleh membetulkan sebarang kesilapan, ketidaktepatan atau ketinggalan dan untuk menukar atau mengemas kini maklumat atau membatalkan pesanan jika sebarang maklumat di Laman tidak tepat pada bila-bila masa tanpa notis terlebih dahulu, termasuk selepas anda menyerahkan pesanan anda.',
    p4_3:
      '4.3 Sekiranya produk yang anda terima salah, tidak lengkap atau rosak, Dasar Bayaran Balik TreeDots akan terpakai.',
    p4_4:
      '4.4 Harga yang ditunjukkan dalam Laman ini adalah dalam mata wang tempatan (Dolar Singapura (SGD) bagi rantau Singapura dan Ringgit Malaysia (RM) bagi rantau Malaysia), adalah termasuk GST serta yuran penghantaran Pembekal ke Pusat Pengambilan.',
    p4_5:
      '4.5 Harga produk akan ditetapkan pada setiap masa di Laman kami, kecuali jika terdapat kes kesilapan yang jelas. Walaupun kami berusaha untuk memastikan bahawa harga yang dipaparkan di Laman adalah betul, kesilapan mungkin berlaku. Jika kami menemui ralat dalam harga mana-mana produk yang telah anda pesan, kami akan memberitahu anda secepat mungkin. Sekiranya TreeDots tidak dapat menghubungi anda, pesanan anda mungkin dibatalkan dan jumlah yang dibayar oleh anda akan dibayar balik sepenuhnya melalui kaedah pembayaran asal yang anda gunakan.',
    p4_6:
      '4.6 Bagi maklumat berkaitan cara pembayaran yang terdapat di TreeDots, sila rujuk ke bahagian Soalan Lazim serta Pesanan dan Pengambilan.',
    p5_1:
      '5.1 Produk yang dipaparkan di dalam Laman ini adalah berdasarkan kepada kod negara yang anda telah daftarkan. Produk ini boleh dipesan di antara Singapura dan Malaysia sahaja dan boleh didapati untuk pengambilan sendiri sahaja. Sila rujuk ke bahagian Soalan Lazim atau Pesanan dan Pengambilan kami jika anda memerlukan maklumat lanjut mengenai terma pengambilan TreeDots.',
    p6_1:
      '6.1 Sila rujuk bahagian Soalan Lazim dan Bayaran Balik kami untuk pilihan yang disediakan jika anda tidak menerima pakej anda.',
    p7_1:
      '7.1 Semua pulangan mesti dilakukan mengikut arahan yang dinyatakan dalam bahagian Soalan Lazim (FAQ) dan Bayaran Balik kami.',
    p8_1: '8.1 Anda mesti berumur sekurang-kurangnya 18 tahun untuk menjadi pengguna berdaftar.',
    p8_2:
      '8.2 Anda bersetuju bahawa anda hanya akan menyerahkan atau memberikan maklumat yang tepat dan benar, dan anda akan menyimpan maklumat yang diberikan adalah yang terkini.',
    p8_3:
      '8.3 Bagi pendaftaran akaun TreeDots di Laman kami, anda memerlukan kata laluan untuk Akaun anda. Anda bertanggungjawab untuk memastikan butiran Akaun anda termasuk kata laluan anda kekal sulit, terkini, lengkap dan tepat. Anda bertanggungjawab untuk semua aktiviti yang berlaku di bawah Akaun dan/atau kata laluan anda (dibenarkan atau tidak) seolah-olah aktiviti tersebut dijalankan oleh anda. Anda akan memaklumkan TreeDots dengan segera jika anda menyedari atau mempunyai sebab untuk mengesyaki bahawa kerahsiaan butiran Akaun anda termasuk kata laluan anda telah dikompromi atau jika terdapat sebarang penggunaan Akaun anda yang tidak dibenarkan atau jika Data Peribadi anda memerlukan kemas kini.',
    p8_4:
      '8.4 Anda boleh menyimpan maklumat kad kredit anda sebagai sebahagian daripada Akaun anda bagi membolehkan proses daftar keluar yang lebih cepat. Pengumpulan dan pengendalian maklumat ini ditadbir oleh Dasar Privasi kami.',
    p8_5:
      '8.5 Jika anda mengakses dan menggunakan Laman ini bagi pihak orang lain, syarikat, organisasi atau entiti undang-undang (“Entiti”), anda menyatakan bahawa anda mempunyai kuasa untuk mengikat Entiti tersebut sebagai bahagian utama kepada Terma TreeDots. Setakat mana anda tidak mempunyai kuasa sedemikian, anda bersetuju untuk terikat kepada Terma ini dan anda menerima liabiliti bagi kemudaratan yang disebabkan oleh sebarang penggunaan Laman ini.',
    p8_6:
      '8.6 TreeDots berhak untuk menggantung atau menamatkan Akaun anda tanpa notis terlebih dahulu atas sebarang sebab termasuk jika Terma TreeDots dilanggar.',
    p8_7: '8.7 Anda boleh menyahaktifkan Akaun anda pada bila-bila masa melalui Laman.',
    p8_8:
      '8.8 Anda mengakui dan bersetuju bahawa jika anda menyahaktifkan Akaun anda atau jika TreeDots menggantung atau menamatkan Akaun anda, anda akan kehilangan sebarang maklumat yang berkaitan dengan Akaun anda. Selepas penamatan, semua hak yang diberikan kepada anda dalam Terma ini akan segera terhenti.',
    p9_1:
      'Anda mengakui dan bersetuju bahawa satu-satunya hak anda berkenaan dengan sebarang masalah atau ketidakpuasan dengan Laman ini adalah dengan meminta penamatan akaun anda dan/atau menghentikan sebarang penggunaan perkhidmatan.',
    p9_2:
      'Dalam keadaan apa pun TreeDots atau anak syarikat, sekutu, rakan kongsi, pembekal atau pemberi lesen bertanggungjawab terhadap sebarang kerosakan tidak langsung, sampingan, berbangkit, yang timbul daripada atau berkaitan dengan akses atau penggunaan atau ketidakupayaan anda untuk mengakses atau menggunakan perkhidmatan atau mana-mana kandungan pihak ketiga dan perkhidmatan, atau yang timbul daripada atau berkaitan dengan kelakuan anda atau kelakuan pengguna lain yang berkaitan dengan penggunaan Laman ini, sama ada atau tidak kerosakan sedemikian boleh dijangka dan sama ada TreeDots dinasihatkan tentang kemungkinan kerosakan tersebut atau tidak. Tanpa mengehadkan perkara di atas, liabiliti agregat TreeDots kepada anda (sama ada di bawah kontrak, tort, undang-undang atau sebaliknya) tidak akan melebihi jumlah yuran yang diterima oleh TreeDots berkenaan dengan perkhidmatan yang menimbulkan tuntutan tersebut. Had di atas akan dikenakan walaupun pembetulan yang dinyatakan di atas gagal dengan tujuan pembetulan.',
    p9_3:
      'TreeDots tidak menjamin atau mewakili bahawa ia menilai atau memantau kesesuaian, kesahihan, keupayaan, pergerakan atau lokasi mana-mana pengguna atau pembekal pihak ketiga dan anda dengan jelas mengenepikan dan melepaskan TreeDots daripada mana-mana semua liabiliti, tuntutan atau ganti rugi yang timbul daripada atau dalam apa-apa cara yang berkaitan dengan pengguna atau perkhidmatan pihak ketiga.',
    p9_4:
      'Tiada apa-apa dalam Terma ini akan terpakai kepada atau dalam apa-apa cara menghadkan atau mengecualikan liabiliti TreeDots untuk: (a) kematian atau kecederaan peribadi yang disebabkan oleh kecuaian; (b) ketidakjujuran, penipuan; atau (c) liabiliti yang mungkin tidak terhad atau dikecualikan di bawah mana-mana undang-undang dan peraturan yang berkenaan.',
    product: '4. Maklumat Produk dan Harga',
    refunds: '7. Bayaran Balik',
    title: 'Terma Pengunaan TreeDots',
    your_account: '8. Akaun Anda'
  },
  test_enterprise_branch: 'TEST-TreeDots Enterprise-Branch',
  text_copied_to_clipboard: 'Teks telah disalin ke papan klip',
  text_remove_item_in_cart: 'Item ini akan dialih keluar daripada troli anda?',
  this_field_must_be_a_valid_email: 'Medan ini mestilah e-mel yang sah',
  title_check_duplicate: 'Gabungkan pesanan?',
  duplicate_order: 'Pesanan Pendua',
  order_merged: 'Pesanan digabungkan - Item anda telah ditambah kepada pesanan',
  new_order_placed: 'Pesanan baharu dibuat!',
  already_has: 'sudah ada',
  an_order_with: 'pesanan dengan',
  for_delivery_on: 'untuk penghantaran pada:',
  do_you_want_merge: 'Adakah anda ingin menggabungkan item ini dengan pesanan sedia ada?',
  do_you_want_proceed: 'Adakah anda masih mahu meneruskan?',
  toast_color: 'utama',
  toast_color_error: 'bahaya',
  tomorrow: 'Esok',
  tooltip_message_end: 'daripada apa yang telah disenaraikan.',
  tooltip_message_four: 'produk ini mungkin berbeza',
  tooltip_message_second: 'berbeza mengikut saiz. Akhir',
  tooltip_message_start: 'Produk semulajadi ini',
  tooltip_message_third: 'daripada',
  top: 'Atas',
  top_picks: 'Pilihan Teratas',
  topup: 'Tambah Nilai',
  total: 'Jumlah',
  total_amount: 'Jumlah Amaun',
  total_bill: 'Jumlah Bil',
  total_new_price: 'Jumlah Harga Baharu',
  total_order: 'Jumlah Pesanan',
  total_payment: 'Jumlah Bayaran',
  total_price: 'Jumlah Harga',
  total_weight: 'Jumlah Berat',
  transaction: 'Transaksi',
  treedots_groupbuy: 'TreeDots Group Buy',
  treedots_story: 'Cerita TreeDots',
  treedotspay: 'TreeDots Pay',
  try_again: 'Cuba Lagi',
  twitter: 'Twitter',
  twitter_alt_img: 'twitter',
  twitter_link_to_share:
    'https://twitter.com/intent/tweet/?text=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%0Ahttps%3A%2F%2Fthetreedots.com%2Fdownload&amp;url=',
  twitter_logo_url: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/twitter-256.png',
  type_message: 'Taip mesej di sini',
  un_paid: 'TIDAK DIBAYAR',
  pending_payment: 'PENDING PAYMENT',
  paid_payment: 'FAILED',
  unable_to_connect: 'Tidak dapat disambungkan',
  unable_to_place_order: 'Tidak dapat membuat pesanan',
  unavailable: 'Tidak tersedia',
  unavailable_product: 'Produk Tidak Tersedia',
  understood_delete_account: 'Faham, padam akaun saya',
  undo: 'BUAT ASAL',
  unit: 'Unit',
  per_unit: '[Unit]',
  unitNo: 'Nombor Unit (Pilihan)',
  unitPriceCannotBeEmpty: 'Harga seunit tidak boleh kosong',
  unmute: 'Nyahbisukan',
  update: 'Kemas Kini',
  updateOrder: 'Kemas Kini Pesanan',
  confirm_order_update: 'Sahkan Kemaskini Pesanan',
  confirm_order_cancel: 'Sahkan Pembatalan Pesanan',
  updateProfile: {
    birth_date_cannot_be_empty: 'Tarikh lahir tidak boleh kosong',
    email_cannot_be_empty: 'E-mel tidak boleh kosong',
    gender_cannot_be_empty: 'Jantina tidak boleh kosong',
    phone_number_must_be_at_least_5_characters: 'Nombor telefon mestilah sekurang-kurangnya 5 aksara'
  },
  update_address: 'Kemas Kini Alamat',
  update_information: 'Kemas Kini Maklumat',
  update_item_success_mess: 'Kemas kini status barangan Berjaya!',
  update_modal_header: 'Kemas kini baharu sudah tersedia!',
  update_modal_message: 'Kami telah menambah baik aplikasi Group Buy. Sila kemas kini untuk meneruskan.',
  update_pre_order: 'Kemas kini Pra-pesanan',
  update_price: 'Kemas kini Harga',
  update_profile: 'Kemas Kini Profil',
  use_this_postal_code: 'Gunakan Poskod ini',
  user_not_found_for_mobile:
    'Anda tidak mempunyai akaun bersama kami. Sila daftar untuk mengakses platform kami.',
  username: 'Nama pengguna',
  valid_till: 'Sah hingga',
  validation: {
    confirm_password: 'Kata laluan yang dimasukkan tidak sepadan',
    country_code: 'Pilih kod negara',
    email: 'Sila masukkan alamat e-mel yang sah',
    first_name: 'Nama pertama mestilah lebih daripada 2 aksara',
    password: 'Kata laluan mesti lebih daripada enam aksara',
    phone: 'Sila masukkan nombor telefon yang sah'
  },
  variations: 'VARIASI',
  vegetables: 'Sayur-sayuran',
  veggies: 'Sayur-sayuran',
  vendor_connect: 'Sambung Pembekal!',
  verify_phone_number: 'Sahkan Nombor Telefon',
  verify_your_number: 'Sahkan nombor telefon anda',
  view: 'Lihat',
  view_all_collection_points: 'Lihat Semua Pusat Pengambilan',
  view_dispute_status: 'Lihat Status Pertikaian',
  view_order: 'Lihat Pesanan',
  vision:
    'Menjelang tahun 2025, TreeDots berharap dapat menyelamatkan 2 juta tan makanan daripada pembaziran, dan ia dapat mengurangkan pelepasan karbon sebanyak 18 juta tan.',
  volume_deal: 'TAWARAN KUANTITI',
  volume_deal_s: 'Tawaran Kuantiti',
  voucher: 'Baucar',
  voucher_amount: 'Jumlah Baucar',
  voucher_applied: 'Baucar Digunakan',
  voucher_deduction: 'Potongan Baucar',
  vouchers: 'Baucar',
  vouchers_expiring_soon: 'baucar akan tamat tempoh tidak lama lagi',
  wa: 'Whatsapp',
  wa_alt_img: 'whatsapp',
  wa_link_to_share:
    'whatsapp://send?text=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%0Ahttps%3A%2F%2Fthetreedots.com%2Fdownload',
  wa_logo_url: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/whatsapp-256.png',
  wa_share_link: 'https://wa.me/6531384846',
  waiting_for_approval: 'Sedang menunggu kelulusan daripada penyewa',
  wallet: {
    add_bank_account: 'Tambah Akaun Bank',
    add_bank_fail: 'Tambah akaun bank baharu tidak berjaya!',
    add_bank_success: 'Tambah akaun bank baharu berjaya!',
    add_new_bank_account: 'Tambah Akaun Bank Baharu',
    amount_exceeds: 'Amaun ini telah melebihi maksud amaun boleh dipindahkan. Amaun boleh dipindahkan:',
    available_withdraw: 'Baki Pengeluaran Tersedia',
    balance: 'Baki',
    completed_withdraw: 'Anda telah melengkapkan pengeluaran!',
    info_withdraw: 'Apakah maksud dana tersedia?',
    is_required: 'ini diperlukan!',
    item_refund: 'Bayaran Balik Item',
    link_bank: 'Pautkan akaun bank anda',
    load_withdraw: 'Pengeluaran anda sedang diproses',
    main_info_withdraw:
      'Sebarang baki yang diterima daripada derma tidak dibenarkan untuk pengeluaran. Anda masih boleh menggunakannya sebagai bayaran kepada pembelian mana-mana dalam aplikasi.',
    minimum_withdraw: 'Nilai pengeluaran minimum ialah $0.11',
    no_transaction: 'Anda belum mempunyai senarai transaksi lagi.',
    order_id: 'ID Pesanan',
    order_payment: 'Pembayaran Pesanan',
    order_refund: 'Bayaran Balik Pesanan',
    primary_refunds: 'Tetapkan sebagai destinasi bayaran balik lalai',
    savings: 'SIMPANAN:',
    select_bank: 'Pilih Bank',
    something_wrong:
      'Sesuatu telah terjadi...Nampaknya terdapat isu dengan transaksi ini. Sila cuba sebentar lagi.',
    to: 'kepada',
    top_fail_info:
      'Tambah Nilai anda tidak berjaya, nampaknya terdapat isu berkaitan dengan kaedah pembayaran yang anda gunakan. Sila cuba sebentar lagi atau pilih yang lain.',
    top_up: 'Tambah Nilai',
    top_up_page: {
      add_new_bank_account: 'Tambah Akaun Bank Baharu',
      bank_transfer: 'Pemindahan Bank',
      bank_transfer_page: {
        add_bank_page: {
          account_name: 'Nama Akaun',
          account_number: 'Nombor Akaun',
          account_type: 'Jenis Akaun',
          add_dbs_ocbc: 'Tambah Akaun DBS/OCBC',
          bank_code: 'Kod Bank',
          branch_code: 'Kod Cawangan',
          current_account: 'Akaun Semasa',
          instruction_1: 'Log masuk ke aplikasi perbankan anda',
          instruction_2:
            'Pilih Pemindahan ke pilihan <strong>akaun bank tempatan</strong>dan kemudian pilih <strong>Tambah Penerima Bayaran</strong>',
          instruction_3:
            'Pilih pilihan untuk menambah penerima bayaran menggunakan butiran <strong>Akaun Bank</strong>',
          instruction_4: 'Di bawah Nama Bank, pilih <strong>TREEDOTS ENTERPRISE PTE LTD</strong>',
          instruction_5: 'Masukkan nombor akaun anda',
          instruction_5_1: '6591118888',
          instruction_5_2: '<strong>Salin</strong>',
          instruction_6: 'Pastikan nombor akaun adalah betul',
          instruction_7:
            'Masukkan <strong>Nama Penerima Bayaran</strong> anda dan <strong>Sahkan</strong>, kemudian pilih <strong>Hantar kepada Penerima Bayaran</strong>',
          instruction_8: 'Masukkan amaun tambah nilai anda, tambah sebarang komen kepada penerima dan Sahkan',
          instruction_9: 'TreeDots akan menerima tambah nilai anda dalam tempoh 30 saat',
          saving_account: 'Akaun Simpanan',
          success: 'Anda kini boleh menggunakan Akaun Bank anda dengan TreeDots',
          top_up_via: 'Tambah Nilai Melalui Pemindahan Bank',
          verified: 'Disahkan!',
          we_have_transferred:
            'Kami telah memindahkan wang ke akaun di atas. Amaun yang diterima adalah sebanyak:'
        },
        amount: 'Amaun',
        amount_tip: 'Pastikan anda menghantar amaun seperti yang telah dinyatakan.',
        copy: 'Salin',
        instruction_1:
          'Log masuk ke halaman iperbankan anda, dan ikut maklumat di bawah untuk membuat pemindahan.',
        instruction_2: 'Tambah penerima baharu dengan mengikut maklumat di bawah.',
        instruction_3:
          'Apabila bayaran berjaya diterima, invois ini akan dikemas kini secara automatik dalam masa 5 minit. (Anda mungkin perlu menyegar semula halaman ini).',
        instruction_title: 'Arahan Pemindahan:',
        recipient_account_number: 'Nombor Akaun Penerima',
        recipient_account_number_placeholder: '6591118888',
        recipient_account_number_tip:
          'Petua: Semak jika nombor akaun yang anda masukkan dalam halaman iperbankan anda adalah betul.',
        recipient_bank: 'Bank Penerima',
        recipient_bank_placeholder: 'CIMB',
        recipient_name: 'Nama Penerima',
        recipient_name_placeholder: 'TreeDots',
        top_up_via: 'Tambah Nilai melalui Pemindahan Bank'
      },
      card_page: {
        maximum_top_up: 'Nilai tambah nilai maksimum ialah',
        minimum_top_up: 'Nilai tambah nilai minimum ialah',
        top_up_amount: 'Amaun tambah nilai:',
        top_up_via: 'Tambah Nilai melalui Kad Kredit/Kad Debit'
      },
      complete_page: {
        paid_total: 'Jumlah yang telah dibayar',
        the_top_up_amount:
          'Amaun tambah nilai akan dikemas kini di dalam dompet anda yang akan mengambil masa beberapa minit',
        title: 'Anda telah selesai menambah nilai!'
      },
      credit_card_debit_card: 'Kad Kredit/Kad Debit',
      fpx: 'FPX',
      grabpay: 'GrabPay',
      grabpay_page: {
        top_up_via: 'Tambah Nilai GrabPay'
      },
      modal_page: {
        bank_transfer: 'Pemindahan Bank',
        fee: 'Caj',
        grabpay: 'GrabPay',
        netslink: 'NETSLink',
        no_fee: 'Tiada Caj',
        ok: 'OK',
        payment_method: 'Kaedah Pembayaran',
        paynow: 'PayNow',
        please_make_sure:
          'Sila pastikan anda mempunyai baki yang mencukupi di dalam akaun anda atau pilih kaedah pembayaran yang lain.',
        review_and_confirm: 'Semak dan Sahkan',
        top_up_amount: 'Amaun Tambah Nilai',
        top_up_failed: 'Tambah Nilai tidak berjaya!',
        top_up_successful: 'Tambah nilai berjaya!',
        total: 'Jumlah',
        your_balance: 'Jumlah baki anda akan dikemas kini dalam masa beberapa minit lagi'
      },
      nets: 'NETSLink',
      netslink_page: {
        top_up_via: 'Tambah Nilai NETSLink'
      },
      paynow: 'PayNow',
      paynow_old_page: {
        add_your: 'Tambah Nombor PayNow Anda',
        instruction_1: 'Log masuk ke aplikasi perbankan dan pilih PayNow',
        instruction_2: 'Pilih <strong>Bayar Sekarang ke VPA</strong>',
        instruction_3: 'Masukkan VPA penuh anda seperti yang ditunjukkan di bawah:',
        instruction_3_1: '+6591118888#TREEDOTS',
        instruction_3_2: '<strong>Salin</strong>',
        instruction_4: 'Pastikan <strong>Nama Panggilan Penerima</strong> adalah betul.',
        instruction_5: 'Masukkan amaun yang anda ingin membuat tambah nilai',
        instruction_6: 'Tambah nilai anda akan dipaparkan dalam aplikasi ini dalam tempoh 30 saat.',
        top_up_via: 'Tambah Nilai melalui PayNow'
      },
      paynow_page: {
        account_name: 'Nama Akaun',
        account_name_placeholder: 'TreeDots',
        click_to_download: 'Klik Untuk Muat Turun Kod QR',
        copy: 'Salin',
        instruction_1_1: 'Pastikan menggunakan aplikasi',
        instruction_1_2: 'PayNow',
        instruction_1_3: 'yang betul untuk membuat pembayaran.',
        instruction_2_1: 'Pastikan anda membuat pembayaran ke XFERS PTE. LTD. - AKAUN KLIEN',
        instruction_title: 'ARAHAN PEMINDAHAN',
        the_qr_code:
          'Kod QR ini hanya sah bagi transaksi ini. Amaun dan nombor rujukan akan diisi secara automatik apabila kod QR diimbas.',
        top_up_via: 'Tambah Nilai melalui PayNow',
        uen: 'UEN (Nombor Entiti Unik)',
        uen_placeholder: '4318123452R13'
      }
    },
    total_treedotspay: 'Jumlah Baki Total TreeDots Pay:',
    transaction_history: 'Sejarah Transaksi',
    transfer_bank: 'Pindahkan ke Bank',
    transfer_to: 'Pindahkan ke:',
    tree_wallet: 'Tree-Wallet',
    turn_off_refunds_bottom:
      'Bayaran balik akan mengambil masa dalam 7-10 hari bekerja untuk dipaparkan ke bank anda. Untuk menerima bayaran balik dalam masa nyata, tetapkan TreeDots Pay sebagai pilihan lalai.',
    turn_off_refunds_title: 'Matikan bayaran balik ke TreeDots Pay?',
    turn_off_refunds_top:
      'Jika dimatikan, anda akan menerima semua bayaran balik bagi pesanan yang telah dibatalkan ke akaun bank atau kaedah pembayaran yang anda pilih semasa daftar keluar.',
    turn_on_refunds:
      'Menetapkan TreeDots Pay sebagai destinasi utama untuk bayaran balik akan membolehkan anda untuk menerima bayaran balik bagi pesanan yang telah dibatalkan dalam masa nyata. Anda boleh mengubah tetapan ini pada bila-bila masa.',
    turn_on_refunds_title: 'Hidupkan bayaran balik ke TreeDots Pay?',
    understood: 'Faham',
    view_all: 'Lihat Semua',
    volume_deal_refund: 'Bayaran Balik Tawaran Kuantiti',
    withdraw: 'Pengeluaran',
    withdraw_amount: 'Amaun pengeluaran:',
    withdraw_appear:
      'Amaun pengeluaran akan dipaparkan dalam bank akaun anda yang akan mengambil masa beberapa minit',
    withdraw_fail_info:
      'Transaksi Pengeluaran anda tidak berjaya, nampaknya terdapat isu dengan bank akaun yang anda cuba gunakan. Sila cuba lagi atau pilih bank yang lain.',
    withdrawal: 'Pengeluaran',
    withdrawal_failed: 'Pengeluaran tidak berjaya',
    your_wallet_is_protected: 'Dompet anda dilindungi.'
  },
  we_are_sorry: 'Kami mohon maaf!',
  we_will_proceed_to_clear_your_cart: 'Kami akan meneruskan untuk mengosongkan troli anda',
  weight: 'Berat',
  weight_size: 'berat, saiz',
  welcome_again: 'Selamat Datang Kembali!',
  what_is_collection_point: 'Apa itu Pusat Pengambilan?',
  whatis_next: {
    content:
      'Tahun 2021 adalah tahun yang sangat menarik buat kami! Kami akan membawakan pembekal dan mengembangkan perkhidmatan kami untuk menjadi penyelesaian kepada Pengurusan Simpanan, Pengurusan Inventori, Logistik Pihak Ketiga dan Perkhidmatan Kewangan.',
    title: 'Apakah yang Seterusnya?'
  },
  which_state: 'Di negeri manakah anda berada sekarang?',
  yes: 'Ya',
  yes_confirm: 'Ya, Sahkan',
  yes_connect_to_existing_company: 'Ya, sambungkan saya ke syarikat sedia ada',
  yes_leave_community_chat: 'YA, TINGGALKAN',
  yes_reject: 'Ya, Tolak',
  yesterday: 'Semalam',
  your_information_is_protected: 'Maklumat anda dilindungi.',
  your_order: 'Pesanan anda',
  your_referral: 'Rujukan anda',
  your_referral_code: 'Kod rujukan anda',
  your_referrees: 'Rujukan anda',
  your_reward: 'Ganjaran anda',
  please_select_cuisine:
    'Sila pilih masakan yang berkaitan dengan perniagaan anda. Anda boleh memilih lebih daripada satu!',
  what_type_of_your_business: 'Apakah jenis masakan perniagaan anda?',
  selection: 'Pilihan',
  cuisine_preference: 'PILIHAN MASAKAN',
  edit_cusine: 'Edit Masakan',
  add_cuisine: 'Tambah Masakan',
  cuisine_preference_optional: 'Pilihan Masakan (Pilihan)',
  tell_us_about_your_business: 'Beritahu kami tentang perniagaan anda',
  cuisine: 'Masakan',
  do_you_have_any_feedback: 'Adakah anda mempunyai sebarang maklum balas? (Pilihan)',
  input_feedback: 'Masukkan Maklum Balas',
  have_not_set_pwd: 'Belum menetapkan kata laluan?',
  click_here: 'Klik Disini',
  dont_have_pwd: 'Belum ada kata laluan?',
  enter_your_mobile_number_to_set_pwd:
    'Masukkan nombor telefon bimbit anda dan kami akan menghantar kod pengesahan untuk menetapkan kata laluan baharu.',
  set_pwd: 'Tetapkan Kata Laluan',
  phone_verified: 'Nombor telefon berjaya disahkan!',
  password_successfully_set: 'Kata laluan berjaya ditetapkan!',
  all_companies: 'Semua Syarikat',
  company_request: 'Permintaan Syarikat',
  company_in_review: 'Syarikat sedang Disemak',
  you_have_company: 'Ada syarikat yang sedang menunggu tindakan anda',
  we_are_still_process: 'Kami masih dalam proses menyemak syarikat anda',
  select_company_pic_name: 'Pilih Syarikat atau Nama PIC',
  let_us_get_started: 'Mari kita mulakan!',
  we_are_reviewing_company: 'Kami sedang menyemak syarikat anda',
  step1_add_company: 'Langkah 1: Tambah syarikat',
  step2_we_will_review: 'Langkah 2: Kami akan menyemak dan meluluskan syarikat anda',
  step3_start_ordering: 'Langkah 3: Mula Memesan!',
  add_new_company: 'Tambah Syarikat Baharu',
  search_company: 'Cari Syarikat atau Nombor Akaun',
  search_company_admin: 'Cari Syarikat, PIC atau Nombor Akaun',
  thank_you_first_company: 'Terima kasih kerana menambah syarikat pertama anda!',
  we_received_registration:
    'Kami telah menerima pendaftaran anda dan sedang dalam proses menyemak syarikat anda.',
  get_started: 'Mari kita mulakan!',
  other_info_error: 'Melebihi bilangan maksimum aksara iaitu 255',
  file_upload_error: 'Melebihi saiz maksimum fail iaitu 50 MB',
  select_variation: 'Pilih Variasi',
  you_have_overdue: 'Anda ada ({totalInvoices}) invois yang belum dibayar',
  kindly_make_payment: 'Sila buat pembayaran sekarang sebanyak',
  my_invoices: 'Invois Saya',
  customer_balance: 'Baki Pelanggan',
  unpaid: 'Belum Dibayar ({unpaidAmount})',
  unpaid_invoices: 'Invois yang belum dibayar sebanyak ({unpaidInvoiceAmount})',
  overdue: 'Lampau tempoh',
  overdue_copy: 'Lampau tempoh',
  all_invoices: 'Semua Invois',
  pay_invoices: 'Bayar Invois',
  total_invoice_amount: 'Jumlah Keseluruhan Invois',
  download_invoice: 'Muat turun Invois',
  invoice_date: 'Tarikh Invois: {invoiceDate}',
  due: 'Tarikh matang: {dueDate}',
  awaiting_payment: 'Menunggu Pembayaran',
  pending_payment_approval: 'Kelulusan Pembayaran Belum Diterima',
  paid_copy: 'Telah dibayar',
  select_payment: 'Pilih Kaedah Pembayaran',
  use_customer_balance: 'Gunakan Baki Pelanggan',
  digital_payment_method: 'Kaedah Pembayaran Digital',
  other_payment_method: 'Lain-lain Kaedah Pembayaran',
  summary: 'Ringkasan',
  pay: 'Bayar',
  credit_note: 'Nota Kredit ({creditCode})',
  overpayment: 'Pembayaran berlebihan',
  bank_transfer_detail: 'Butiran Pindahan Bank',
  transaction_date: 'Tarikh Transaksi',
  payment_value_date: 'Tarikh Nilai Pembayaran',
  the_date_that_effective: 'Tarikh transaksi berkuat kuasa.',
  payment_proof: 'Bukti Pembayaran',
  reference_code: 'Kod Rujukan (Pilihan)',
  input_reference_provided:
    'Masukkan nombor rujukan yang diberikan oleh bank anda untuk transaksi pembayaran ini.',
  notes: 'Catatan (Pilihan)',
  payment_overdue_invoice: 'Pembayaran untuk invois tertunggak bagi {userName}',
  enter_desc_transaction: 'Masukkan penerangan tentang transaksi ini.',
  upload_file: 'Muat naik Fail',
  max_file_size: 'Maksimum saiz fail ialah 50 mb',
  payment_summary: 'Ringkasan Pembayaran',
  payment_pending: 'Bayaran Belum selesai',
  payment_success: 'Payment Success',
  payment_failed: 'Payment Failed',
  back_to_invoice: 'Kembali ke Senarai Invois',
  pending: 'Belum selesai',
  invoice_list: 'Invois',
  reference_number: 'Nombor Rujukan',
  payment_time: 'Masa Membuat Pembayaran',
  recipient: 'Penerima',
  payment_details: 'Butiran Pembayaran',
  image_success_transaction: 'Imej bagi transaksi anda yang berjaya',
  use_available_credits: 'Gunakan Kredit yang Ada',
  remaining_credits: 'Baki Kredit yang Tinggal:',
  request_detail_placeholder:
    'Cth: Saya mahu menggunakan semua kredit pelanggan saya yang saya ada pada Invois A dan tiada satu pun untuk Invois B',
  request_detail_let_us_know:
    'Maklumkan kepada kami di sini jika anda ada apa-apa permintaan tentang peruntukan kredit pelanggan.',
  request_detail_optional: 'Minta Butiran (Pilihan)',
  apply_available_credit: 'Gunakan Kredit Yang Ada',
  input_amount: 'Masukkan Amaun',
  note_only: 'Nota',
  request_details_only: 'Minta Perincian',
  payment_pending_info:
    'Pada masa ini kami sedang memproses pembayaran anda dan pengemaskinian status pembayaran akan mengambil sedikit masa untuk dipaparkan pada sistem',
  grand_total: 'Jumlah Keseluruhan',
  end_of_week: 'Hujung Minggu',
  available_credits: 'Kredit Yang Ada',
  payment_creation_failed: 'Gagal Mencipta Pembayaran',
  missing_payment_proof: 'Sila muat naik bukti pembayaran',
  applied_credits: 'Kredit yang Digunakan',
  bank_transfer: 'Pindahan Bank',
  type_your_company_cuisine: 'Taip masakan syarikat anda disini',
  what_type_cuisine_company_sell: 'Apa jenis masakan yang dijual oleh syarikat Anda?',
  bargain_price: 'Harga Murah',
  special_price: 'Harga Istimewa',
  support_information: 'Maklumat sokongan / alasan',
  input_proposed: 'Masukkan harga yang dicadangkan atau kuantiti pesanan mingguan',
  pending_approve: 'Menunggu Kelulusan',
  request_sent: 'Permintaan Dihantar!',
  refresh: 'Segar Semula',
  all_rights_reserved: 'Hak Cipta Terpelihara',
  only_allowed_symbol: 'Simbol yang dibenarkan hanyalah',
  cannot_find_product: 'Maaf, produk ini tidak dapat kami temui.',
  select_another_one: 'Sila pilih yang lain!',
  back_to_home: 'Kembali ke Halaman Utama',
  cheque: 'Cek',
  cash: 'Tunai',
  edit_payment_amount: 'Edit Amaun Pembayaran',
  amount_due: 'Amaun yang perlu dibayar',
  update_payment: 'Kemas kini Pembayaran',
  update_amount: 'Kemas kini Amaun',
  deposit_date: 'Tarikh Deposit Tunai',
  cheque_date: 'Tarikh Deposit Cek',
  transaction_reference_number: 'No. Rujukan Transaksi',
  cash_detail: 'Butiran Tunai',
  cheque_detail: 'Butiran Cek',
  payment_amount_warning: 'Jumlah Pembayaran mestilah melebihi',
  not_sure_avail_credit: 'Tidak pasti jumlah Kredit Yang Ada?',
  not_sure_credit_desc:
    'Jika anda ada soalan lanjut tentang jumlah kredit anda yang ada dan cara menggunakannya, sila hubungi kami di bawah',
  verify_mobile_num: 'Sahkan nombor telefon bimbit',
  sorry_went_wrong: 'Maaf, terdapat kesilapan',
  we_detected_unusual_activity:
    'Terdapat aktiviti luar biasa yang kami kesan pada alamat IP anda dan ciri ini digantung buat sementara waktu. Sila cuba lagi dalam:',
  manage_companies: 'Uruskan Syarikat',
  invalid_amount_negative: 'Amaun tidak sah. Amaun yang dimasukkan tidak boleh negatif',
  to: 'Ke',
  future_date_error: 'Sila pilih satu tarikh yang pada atau sebelum',
  past_date_error: 'Sila pilih satu tarikh yang pada atau selepas',
  make_payment: 'Buat Pembayaran',
  cash_payment_collection: 'Pembayaran Tunai (Kutipan)',
  cash_payment_collection_detail: 'Butiran Pembayaran Tunai (Kutipan).',
  cash_collection_date: 'Tarikh Kutipan Tunai',
  cash_deposit: 'Deposit Tunai',
  cash_deposit_detail: 'Butiran Deposit Tunai',
  input_amount_error: 'Amaun yang dimasukkan tidak boleh melebihi baki kredit yang ada',
  input_amount_error_2: 'Amaun yang dimasukkan tidak boleh melebihi amaun yang perlu dibayar',
  image_error_size: 'Saiz imej terlalu besar. Sila muat naik imej yang mempunyai saiz kurang daripada 5 MB',
  image_error_type: 'Hanya fail imej yang dibenarkan',
  amount_due_error: 'Amaun yang perlu dibayar tidak boleh melebihi',
  invoice_cn: {
    invoice: 'Invois',
    credit_note: 'Nota Kredit',
    invoice_cn: 'Invois dan Nota Kredit',
    bill_to: 'BILKAN KEPADA',
    grand_total: 'Jumlah Keseluruhan',
    sub_total: 'Jumlah Kecil',
    tax: 'Cukai',
    download: 'Muat turun untuk melihat invois asal',
    download_pdf: 'Muat turun Invois (pdf)',
    view_invoice_cn: 'Lihat Invois dan Nota Kredit',
    invoices: 'INVOIS',
    cn: 'NOTA KREDIT',
    all_file: 'Muat turun Semua Fail',
    cn_amount: 'AMAUN NOTA KREDIT',
    download_cn: 'Muat turun untuk melihat Nota Kredit asal',
    download_cn_pdf: 'Muat turun Nota Kredit (pdf)',
    download_all: 'Muat turun Semua Fail',
    view: 'Lihat',
    download_file: 'Fail yang berjaya dimuat turun',
    download_files: 'Fail yang berjaya dimuat turun',
    download_file_fail: 'Fail gagal dimuat turun',
    download_files_fail: 'Fail gagal dimuat turun',
    view_document: 'View Document',
    preview: 'Preview',
    share_invoice: 'Share Invoice',
    share_credit_note: 'Share Credit Note'
  },
  search_company_not_found: 'Maaf, syarikat tidak ditemui',
  try_different_name: 'Cuba Nama Syarikat atau Nombor Akaun yang lain',
  try_different_name_admin: 'Cuba Nama Syarikat, PIC atau Nombor Akaun yang lain',
  sorry_could_not_find: 'Maaf, tidak dijumpai',
  sorry_not_any_products: 'Sorry, we couldn’t find any products!',
  sorry_not_found: 'Maaf, kami tidak dapat mencari "{search_keyword}"',
  try_different_keyword: 'Sila cuba kata kunci yang lain',
  today: 'Hari ini',
  companies: 'Syarikat',
  select_other_company: 'Pilih Syarikat Lain',
  selected_count: '{selected_count} dipilih',
  customer_instruction: 'Arahan Pelanggan',
  delivery_instruction_hint: 'Cth: Masukkan arahan tetap untuk semua pesanan daripada pelanggan ini',
  ex_delivery_instruction: 'Cth: "Masukkan arahan tetap untuk semua pesanan daripada pelanggan"',
  no_invoices: 'Belum lagi ada invois!',
  browse_your_business: 'Mula melayari perniagaan anda',
  browse_product: 'Semak Imbas Produk',
  update_app: 'Mengemas Kini Versi Aplikasi',
  better_experience: 'Kami sedang mengemas kini aplikasi ini untuk memberi anda pengalaman yang lebih baik.',
  wait_update: 'Sila tunggu sehingga kemas kini ini lengkap.',
  default_billing_address: 'Alamat Pengebilan Lalai',
  set_as_default_address: 'Tetapkan sebagai alamat pengebilan lalai',
  select_address: 'Pilih Alamat',
  you_have_no_company: 'Anda tidak ada syarikat.',
  register_your_company: 'Daftarkan syarikat anda sekarang untuk membeli produk',
  previous: 'Sebelum',
  updated: 'Dikemas kini',
  removed: 'DIALIH KELUAR',
  new_request: 'Permintaan Baharu',
  dismiss: 'Abaikan',
  view_details: 'Lihat Butiran',
  master_card_debit: 'Master Card (debit)',
  visa_card_debit: 'Visa Card (debit)',
  nets_payment_error: 'Ralat pembayaran NETS',
  on_registration: 'Semasa Pendaftaran',
  alert_error_header: 'Ralat',
  select_business_profile: 'Pilih Profil Perniagaan',
  connection_issue_message:
    'Tidak dapat memuat - Sila pastikan anda bersambung dengan Internet dan cuba lagi',
  unable_load: 'Tidak dapat memuat',
  please_make_sure_connected: 'Sila pastikan anda bersambung dengan Internet dan cuba semula',
  page_taking_longer:
    'Halaman ini mengambil masa lebih lama daripada yang sepatutnya untuk memuat. Anda mungkin mahu memeriksa sambungan Internet anda.',
  pull_to_refresh: 'Tarik ke bawah untuk segarkan semula',
  black_listed: 'Disenarai hitam',
  search_all_products: 'Cari Semua Produk',
  product_category: 'Kategori Produk',
  relevant: 'Relevan',
  filter: 'Penapis',
  favorites: 'Kegemaran',
  result_for: 'Hasil untuk <strong>"<i>{search_key}</i>"</strong>',
  result_from: 'Hasil daripada <strong>Kategori Produk: {category_name}</strong>',
  product_have_purchased: 'Produk yang anda beli',
  more_products: 'Lebih Banyak Produk',
  with_price: 'Dengan harga',
  in_stock: 'Ada dalam stok',
  apply_search_filter: 'Gunakan Penapis',
  reset_search_filter: 'Tetapkan Semula Penapis',
  no_preview_docs: 'Tiada Pratonton Tersedia',
  cancel_payment: 'Batalkan Pembayaran',
  cancel_payment_desc: 'Pembayaran Anda akan dibatalkan',
  pending_verification: 'Menunggu Pengesahan',
  view_invoice: 'Lihat Invois',
  view_payment_history: 'Lihat Sejarah Bayaran',
  payment_history: 'Sejarah Pembayaran',
  total_paid_amount: 'Jumlah Amaun Dibayar',
  partially_paid: 'Dibayar Separuh',
  order_processed: 'Pesanan Diproses',
  order_processing: 'Pesanan Diletakkan',
  order_cancel_by_supplier: 'Pesanan Dibatalkan oleh Pembekal',
  order_cancelled: 'Pesanan Dibatalkan',
  delivery_started: 'Penghantaran Dimulakan',
  delivered: 'Dihantar',
  failed_delivery: 'Penghantaran Gagal',
  order_completed: 'Pesanan Selesai',
  receive_your_order: 'Kami telah menerima pesanan Anda',
  is_preparing_order: 'sedang menyediakan pesanan Anda',
  cancelled_by_supplier: 'Pesanan Anda telah dibatalkan oleh {supplierName}',
  has_been_cancelled: 'Pesanan Anda telah dibatalkan.',
  has_pickup_order: 'Rakan logistik kami telah mengambil item pesanan Anda',
  delivered_success: 'Item pesanan Anda berjaya dihantar!',
  delivery_failed: 'Maaf, percubaan penghantaran kami tidak berjaya',
  order_has_been_completed: 'Pesanan Anda telah selesai!',
  track_order: 'Menjejaki Pesanan',
  catch_weight_cap: 'BERAT ANGGARAN',
  cap_grand_total: 'JUMLAH KESELURUHAN',
  delivery_information: 'MAKLUMAT PENGHANTARAN',
  billing_information: 'MAKLUMAT TAGIHAN',
  bill_name_and_address: 'Nama dan Alamat Pengebilan',
  order_delivery_instruction: 'Arahan Penghantaran',
  view_driver_location: 'Lihat Lokasi Pemandu pada Peta',
  not_found_order: 'Maaf, kami tidak menemui pesanan yang Anda cari!',
  view_all_order: 'Lihat Semua Pesanan',
  cancellation_reason: 'Sebab Pembatalan',
  failure_reason: 'Sebab Kegagalan',
  note: 'Nota',
  track_driver_location: 'Lihat Lokasi Pemandu',
  view_image: 'Lihat Imej',
  payment_date: 'Tanggal Pembayaran',
  invoice_total: 'Invoice Total',
  items: 'Items'
};
export default en;
