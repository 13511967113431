import { apolloClient } from '@/main';
import {
  getProducts,
  requestQuotation,
  saleAddFavouriteSku,
  saleCheckFavouriteSkus,
  saleProductRecommend,
  saleRemoveFavouriteSku,
  saleUpdatePrice
} from '@/modules/sale/services/graphql';
import { getProductsOosStatus } from '@/modules/shared/services/graphql/product';
import debounce from 'lodash.debounce';
import { MUTATIONS } from './mutations';

export const ACTIONS = {
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_MORE_PRODUCTS: 'GET_MORE_PRODUCTS',
  UPDATE_SEARCH: 'UPDATE_SEARCH',
  CHOOSE_PRODUCT: 'CHOOSE_PRODUCT',
  ADD_FAVORITE: 'ADD_FAVORITE',
  REMOVE_FAVORITE: 'REMOVE_FAVORITE',
  REQUEST_QUOTATION: 'REQUEST_QUOTATION',
  CHECK_FAVORITE_SKUS: 'CHECK_FAVORITE_SKUS',
  SALE_UPDATE_PRICE: 'SALE_UPDATE_PRICE',
  GET_PRODUCTS_PRICES: 'GET_PRODUCTS_PRICES',
  GET_SALE_PRODUCTS_RECOMMEND: 'GET_SALE_PRODUCTS_RECOMMEND',
  RESET_PRODUCT_RECOMMEND: 'RESET_PRODUCT_RECOMMEND'
};

const fetchProductData = async (productIds, commit, state) => {
  // Fetch more info to display OOS status, newest prices and more
  const { data } = await apolloClient.query({
    query: getProductsOosStatus,
    variables: {
      productIds: productIds,
      tenantId: state.search.tenantId,
      buyerId: state.search.buyerId
    }
  });

  // binding new data into old list
  commit(MUTATIONS.GET_PRODUCT_DETAILS_SUCCESS, data.getProductsOosStatus);
};

const fetchMoreProductData = async (productIds, commit, state) => {
  // Fetch more info to display OOS status, newest prices and more for more products section in new search page
  const { data } = await apolloClient.query({
    query: getProductsOosStatus,
    variables: {
      productIds: productIds,
      tenantId: state.search.tenantId,
      buyerId: state.search.buyerId
    }
  });

  // binding new data into old list
  commit(MUTATIONS.GET_MORE_PRODUCT_DETAILS_SUCCESS, data.getProductsOosStatus);
};

// load product list for general

const performSearch = async (commit, state) => {
  try {
    const { data } = await apolloClient.query({
      query: getProducts,
      variables: state.search
    });

    const newObj = {
      ...data.getProductsSales
    };
    commit(MUTATIONS.GET_PRODUCTS_SUCCESS, { list: newObj, getProductStatus: 'Success' });

    // Fetch more info to display OOS status, newest prices and more in background
    const productIds = newObj.items.map((obj) => obj.id);
    fetchProductData(productIds, commit, state);
  } catch (error) {
    commit(MUTATIONS.GET_PRODUCTS_FAILURE, { getProductStatus: error?.message });
  }
};
const performSearchDebounce = debounce(performSearch, 600);

// load product list only for new search page more product list

const performMoreProductSearch = async (commit, state) => {
  try {
    const { data } = await apolloClient.query({
      query: getProducts,
      variables: state.searchMore
    });

    const newObj = {
      ...data.getProductsSales
    };
    commit(MUTATIONS.GET_MORE_PRODUCTS_SUCCESS, { moreProductList: newObj, getProductStatus: 'Success' });

    // Fetch more info to display OOS status, newest prices and more in background
    const productIds = newObj.items.map((obj) => obj.id);
    fetchMoreProductData(productIds, commit, state);
  } catch (error) {
    commit(MUTATIONS.GET_MORE_PRODUCTS_FAILURE, { getProductStatus: error?.message });
  }
};
const performMoreProductSearchDebounce = debounce(performMoreProductSearch, 600);

export default {
  // check favorite skus
  async [ACTIONS.CHECK_FAVORITE_SKUS]({ commit }, params) {
    commit(MUTATIONS.CHECK_FAVORITE_SKUS_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: saleCheckFavouriteSkus,
        variables: params
      });
      commit(MUTATIONS.CHECK_FAVORITE_SKUS_SUCCESS);
      return data.saleCheckFavouriteSkus;
    } catch (error) {
      commit(MUTATIONS.CHECK_FAVORITE_SKUS_FAILURE, error);
    }
    return [];
  },

  // request quotation
  async [ACTIONS.REQUEST_QUOTATION]({ commit }, params) {
    commit(MUTATIONS.REQUEST_QUOTATION_REQUEST);
    try {
      await apolloClient.mutate({
        mutation: requestQuotation,
        variables: params
      });
      commit(MUTATIONS.REQUEST_QUOTATION_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.REQUEST_QUOTATION_FAILURE, error);
    }
  },

  // add favorite
  async [ACTIONS.ADD_FAVORITE]({ commit }, params) {
    commit(MUTATIONS.ADD_FAVORITE_REQUEST);
    try {
      await apolloClient.mutate({
        mutation: saleAddFavouriteSku,
        variables: params
      });
      commit(MUTATIONS.ADD_FAVORITE_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.ADD_FAVORITE_FAILURE, error);
    }
  },

  // remove favorite
  async [ACTIONS.REMOVE_FAVORITE]({ commit }, params) {
    commit(MUTATIONS.REMOVE_FAVORITE_REQUEST);
    try {
      await apolloClient.mutate({
        mutation: saleRemoveFavouriteSku,
        variables: params
      });
      commit(MUTATIONS.REMOVE_FAVORITE_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.REMOVE_FAVORITE_FAILURE, error);
    }
  },

  // get products
  async [ACTIONS.GET_PRODUCTS]({ commit, state }, { isLoadMore }) {
    commit(MUTATIONS.GET_PRODUCTS_REQUEST, isLoadMore);
    if (!isLoadMore) {
      performSearchDebounce(commit, state);
    } else {
      performSearch(commit, state);
    }
  },

  // get products for more product items in search page
  async [ACTIONS.GET_MORE_PRODUCTS]({ commit, state }, { isLoadMore }) {
    commit(MUTATIONS.GET_MORE_PRODUCTS_REQUEST, isLoadMore);
    if (!isLoadMore) {
      performMoreProductSearchDebounce(commit, state);
    } else {
      performMoreProductSearch(commit, state);
    }
  },

  // get sale products recommend
  async [ACTIONS.GET_SALE_PRODUCTS_RECOMMEND]({ commit }, params) {
    try {
      const { data } = await apolloClient.query({
        query: saleProductRecommend,
        variables: params
      });
      commit(MUTATIONS.GET_SALE_PRODUCTS_SUCCESS, { oosList: data.saleGetProductsRecommend });
    } catch (error) {
      commit(MUTATIONS.GET_SALE_PRODUCTS_FAILURE, error);
    }
  },

  // reset products recommend
  async [ACTIONS.RESET_PRODUCT_RECOMMEND]({ commit }) {
    commit(MUTATIONS.RESET_PRODUCT_RECOMMEND);
  },

  // update search
  async [ACTIONS.UPDATE_SEARCH]({ commit }, { search = {}, isLoadMore = false, type }) {
    commit(MUTATIONS.UPDATE_SEARCH, { search, isLoadMore, type });
  },

  // choose product
  [ACTIONS.CHOOSE_PRODUCT]({ commit }, { item }) {
    commit(MUTATIONS.CHOOSE_PRODUCT, item);
  },
  async [ACTIONS.SALE_UPDATE_PRICE]({ commit }, params) {
    try {
      const { data } = await apolloClient.mutate({
        mutation: saleUpdatePrice,
        variables: params
      });
      commit(MUTATIONS.UPDATE_PRICE_SUCCESS, data.saleUpdatePrice);
    } catch (error) {
      commit(MUTATIONS.UPDATE_PRICE_FAILURE, error);
    }
  }
};
