<template>
  <div class="w-90-center">
    <div v-if="screen === 1">
      <ion-label v-if="locale === 'zh-CN'" class="onboardingTitle mx-auto">
        <span style="color: #000"> 为您的企业提供 </span>
        <span style="color: #04565a"> 优质 </span>
        <span style="color: #000"> 、</span>
        <span style="color: #04565a"> 优惠 </span>
        <span style="color: #000"> 的食品配料 </span>
      </ion-label>
      <ion-label v-else-if="locale === 'id-ID'" class="onboardingTitle mx-auto">
        <span style="color: #000">
          {{ $t('new_onboarding_1').slice(0, 13) }}
        </span>
        <span style="color: #04565a">
          {{ $t('new_onboarding_1').slice(13, 32) }}
        </span>
        <span style="color: #000">
          {{ $t('new_onboarding_1').slice(32, 36) }}
        </span>
        <span style="color: #04565a">
          {{ $t('new_onboarding_1').slice(36, 42) }}
        </span>
        <span style="color: #000">
          {{ $t('new_onboarding_1').slice(42, 80) }}
        </span>
      </ion-label>
      <ion-label v-else-if="locale === 'ms-MY'" class="onboardingTitle mx-auto">
        <span style="color: #000">
          {{ $t('new_onboarding_1').slice(0, 13) }}
        </span>
        <span style="color: #04565a">
          {{ $t('new_onboarding_1').slice(13, 32) }}
        </span>
        <span style="color: #000">
          {{ $t('new_onboarding_1').slice(32, 39) }}
        </span>
        <span style="color: #04565a">
          {{ $t('new_onboarding_1').slice(39, 49) }}
        </span>
        <span style="color: #000">
          {{ $t('new_onboarding_1').slice(49, 80) }}
        </span>
      </ion-label>
      <ion-label v-else class="onboardingTitle mx-auto">
        <span style="color: #04565a">
          {{ $t('new_onboarding_1').slice(0, 12) }}
        </span>
        <span style="color: #000">
          {{ $t('new_onboarding_1').slice(12, 16) }}
        </span>
        <span style="color: #04565a">
          {{ $t('new_onboarding_1').slice(16, 43) }}
        </span>
        <span style="color: #000">
          {{ $t('new_onboarding_1').slice(43, 65) }}
        </span>
      </ion-label>
    </div>
    <div v-else>
      <ion-label v-if="locale === 'ms-MY'" class="onboardingTitle mx-auto">
        <span style="color: #04565a">
          {{ $t('new_onboarding_2').slice(0, 21) }}
        </span>
        <span style="color: #000">
          {{ $t('new_onboarding_2').slice(21, 50) }}
        </span>
      </ion-label>
      <ion-label v-else-if="locale === 'zh-CN'" class="onboardingTitle mx-auto">
        <span style="color: #000"> 在一个简单的应用程序中管理 </span>
        <span style="color: #04565a"> 多个实体 </span>
      </ion-label>
      <ion-label v-else class="onboardingTitle mx-auto">
        <span style="color: #000">
          {{ $t('new_onboarding_2').slice(0, 6) }}
        </span>
        <span style="color: #04565a">
          {{ $t('new_onboarding_2').slice(6, 24) }}
        </span>
        <span style="color: #000">
          {{ $t('new_onboarding_2').slice(24, 43) }}
        </span>
      </ion-label>
    </div>
  </div>
</template>
<script>
export default {
  name: 'wordingParse',
  props: ['locale', 'screen']
};
</script>
<style lang="scss">
@import 'onboarding';
.w-90-center {
  width: 90%;
  margin: 0 auto;
}
</style>
