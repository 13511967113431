import filter from './modules/filter';

export const state = {
  isLoggedIn: false,
  user: null,
  isLoading: false
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  getResetPassUser(state) {
    return state.setResetPassUser;
  }
};

const actions = {
  setUser({ commit }, payload) {
    commit('setUser', payload.user);
  },
  clearStore({ commit }) {
    commit('clearStore');
  },
  setResetPassUser({ commit }, payload) {
    commit('setResetPassUser', payload.user);
  },
  clearResetPassUser({ commit }) {
    commit('clearResetPassUser');
  }
};

const mutations = {
  setUser(state, payload) {
    state.user = payload;
    localStorage.setItem('user', JSON.stringify(payload));
    localStorage.setItem('token_jwt', payload.tokenJWT);
  },
  clearStore(state) {
    state.user = null;
    localStorage.setItem('user', null);
    localStorage.setItem('token_jwt', null);
  },
  setResetPassUser(state, payload) {
    state.resetPassUser = payload;
    localStorage.setItem('resetPassUser', JSON.stringify(payload));
  },
  clearResetPassUser(state) {
    state.resetPassUser = null;
    localStorage.setItem('resetPassUser', JSON.stringify(null));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    filter
  }
};
