import gql from 'graphql-tag';

export const createPreOrderB2b = gql`
  mutation createPreOrderB2b(
    $customerId: Int!
    $standalone: Boolean!
    $deliveryAddressId: Int!
    $billingAddressId: Int
    $cartItems: [CreatePreOrderB2bInput]!
  ) {
    createPreOrderB2b(
      customerId: $customerId
      standalone: $standalone
      deliveryAddressId: $deliveryAddressId
      billingAddressId: $billingAddressId
      cartItems: $cartItems
    ) {
      id
      token
    }
  }
`;

export const getPurchaseB2bOrders = gql`
  query getPurchaseB2bOrders(
    $statusIds: [Int]
    $customerBuyerIds: [Int]
    $startDeliveryDate: String
    $endDeliveryDate: String
    $limit: Int
    $offset: Int
  ) {
    getPurchaseB2bOrders(
      statusIds: $statusIds
      customerBuyerIds: $customerBuyerIds
      startDeliveryDate: $startDeliveryDate
      endDeliveryDate: $endDeliveryDate
      limit: $limit
      offset: $offset
    ) {
      data {
        id
        version
        total_price
        total_tax
        is_paid
        stripe_transaction_id
        delivery_date
        delivery_time
        updated_at
        created_at
        payment_type
        stripe_card_id
        reason_cancel
        reason_type_cancel
        reason
        description
        supplier_name
        credit_note
        app_name
        tenant_id
        user {
          first_name
          last_name
        }
        orderStatus {
          name
        }
        orderLogs {
          description
          user {
            user_type_id
          }
        }
        orderItems {
          id
          sku_id
          invoice_id
          customer {
            account_number
            id
            name
            minimum_order
          }
          sku {
            id
            product {
              id
              name
            }
          }
        }
        paymentTransactionItems {
          id
          order_id
          invoice_id
          payment_transaction_id
          amount
          created_at
          paymentTransaction {
            id
            checkout_session_id
            payment_intent_id
            amount
            payment_link
            payment_type_id
            status
            expires_at
            created_at
            created_by
          }
        }
      }
    }
  }
`;

export const getPreOrdersB2b = gql`
  query getPreOrdersB2b(
    $statusIds: [Int]
    $customerBuyerIds: [Int]
    $startDeliveryDate: String
    $endDeliveryDate: String
    $isIncludeTotalCount: Boolean
    $limit: Int
    $offset: Int
  ) {
    getPreOrdersB2b(
      statusIds: $statusIds
      customerBuyerIds: $customerBuyerIds
      startDeliveryDate: $startDeliveryDate
      endDeliveryDate: $endDeliveryDate
      isIncludeTotalCount: $isIncludeTotalCount
      limit: $limit
      offset: $offset
    ) {
      totalCount
      data {
        id
        actual_order_id
        total_price
        total_tax
        delivery_date
        delivery_time
        po_number
        standalone
        active
        stripe_transaction_id
        created_at
        updated_at
        user {
          id
          first_name
          last_name
        }
        orderStatus {
          id
          name
        }
        preOrderItemsB2b {
          id
          sku_id
          supplier {
            name
            tenant_id
          }
          customer {
            id
            name
            minimum_order
          }
          sku {
            id
            product {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export const getOrderItemDetailB2b = gql`
  query getOrderItemDetailB2b($id: Int, $orderId: Int) {
    getOrderItemDetailB2b(id: $id, orderId: $orderId) {
      id
      invoice_id
      order_id
      tenant_name
      tenant_id
      sku_id
      user_id
      customer_buyer_id
      order_item_status_id
      order_item_status
      customer_seller_id
      amount_qty
      total_qty
      origin_unit_price
      sale_unit_price
      total_price
      tax
      catch_weight
      is_catch_weight
      is_order_by_weight
      active
      cancelled
      sku_name
      product
      oom
      image
      user_name
      mobile
      stripe_card_id
      delivery_status
      delivery_address
      dispute_status
      dispute_reason
      dispute_remedy
      customer_name
      alias_name
      minimum_order
      description
    }
  }
`;
export const getCustomersOrders = gql`
  query getCustomersOrders($searchQueries: String, $isIncludeTotalCount: Boolean, $limit: Int, $offset: Int) {
    getCustomersOrders(
      searchQueries: $searchQueries
      isIncludeTotalCount: $isIncludeTotalCount
      limit: $limit
      offset: $offset
    ) {
      totalCount
      customers {
        id
        account_number
        name
      }
    }
  }
`;
export const getCustomerAndOrderStatus = gql`
  query getCustomerAndOrderStatus {
    getCustomerAndOrderStatus {
      orderStatues {
        id
        name
      }
    }
  }
`;

export const orderItemDisputes = gql`
  mutation Mutation($orderItemDisputesPayload: OrderItemDisputesInput!) {
    orderItemDisputes(payload: $orderItemDisputesPayload) {
      id
      order_id
      invoice_id
      sku_id
      product
      oom
      image
      user_id
      customer_buyer_id
      order_item_status_id
      catch_weight
      alias_name
      customer_seller_id
      amount_qty
      origin_unit_price
      sale_unit_price
      total_qty
      total_price
      tax
      active
      minimum_order
      hub
      user_name
      mobile
      stripe_card_id
      tenant_id
      tenant_name
      delivery_status
      cancelled
      dispute_status
      dispute_reason
      dispute_remedy
      delivery_address
    }
  }
`;

export const getInvoiceList = gql`
  query getInvoiceList($id: Int!) {
    getInvoiceList(id: $id) {
      invoice_id
      order_id
      invoice_number
      currency_code
      currency_symbol
    }
  }
`;

export const getInvoiceById = gql`
  query getInvoiceById($invoiceId: Int!) {
    getInvoiceById(invoiceId: $invoiceId) {
      id
      currency_symbol
      created_at
      invoice_number
      customer_name
      billing_name
      billing_address
      grand_total
      supplier_name
      actual_delivery_date
      OrderItem {
        id
        sku_name
        total_qty
        uom_name
        sale_unit_price
        total_price
        tax
        is_order_by_weight
        total_weight
      }
      credit_note {
        id
        credit_note_number
        amount
        created_at
        credit_note_date
      }
    }
  }
`;

export const getPreOrderItemDetailB2b = gql`
  query getPreOrderItemDetailB2b($id: Int, $orderId: Int) {
    getPreOrderItemDetailB2b(id: $id, orderId: $orderId) {
      id
      order_id
      sku_id
      product_type_id
      product
      sku_name
      oom
      image
      increment_qty
      user_id
      customer_buyer_id
      order_item_status_id
      is_order_by_weight
      is_catch_weight
      weight
      total_weight
      catch_weight
      customer_name
      alias_name
      customer_seller_id
      amount_qty
      origin_unit_price
      sale_unit_price
      total_qty
      total_price
      tax
      tax_rate
      status_note
      active
      minimum_order
      hub
      user_name
      mobile
      stripe_card_id
      delivery_status
      cancelled
      tenant_id
      tenant_name
      tenant_class_id
      tenant_email
      tenant_email_notification
      tenant_phone
      max_quantity
    }
  }
`;

export const sendInvoiceToPeppolByOrderId = gql`
  mutation sendInvoiceToPeppolByOrderId(
    $orderId: Int!
    $tenantLegalEntityId: String
    $customerPeppolId: String
    $customerPeppolScheme: String
    $sendOnly: Boolean
  ) {
    sendInvoiceToPeppolByOrderId(
      orderId: $orderId
      tenantLegalEntityId: $tenantLegalEntityId
      customerPeppolId: $customerPeppolId
      customerPeppolScheme: $customerPeppolScheme
      sendOnly: $sendOnly
    )
  }
`;

export const getCustomerPeppol = gql`
  query getCustomerPeppol($id: Int!) {
    getCustomerPeppol(id: $id) {
      id
      peppol_id
      peppol_scheme_id
      peppol_scheme_code
      peppol_scheme
    }
  }
`;

export const getTenantIntegrationStorecove = gql`
  query getTenantIntegrationStorecove($tenantId: Int!) {
    getTenantIntegrationStorecove(tenantId: $tenantId) {
      id
      legal_entity_id
      peppol_id
      peppol_scheme_id
      send_only
      tenant_id
      created_at
      created_by
      updated_at
      updated_by
      active
    }
  }
`;

export const repeatOrderB2b = gql`
  mutation repeatOrderB2b($orderId: Int!, $customerId: Int!) {
    repeatOrderB2b(orderId: $orderId, customerId: $customerId)
  }
`;

export const updatePreOrdersB2b = gql`
  mutation updatePreOrdersB2b($orderStatusId: Int, $id: Int!, $lastUserId: Int) {
    updatePreOrdersB2b(orderStatusId: $orderStatusId, id: $id, lastUserId: $lastUserId)
  }
`;

export const addPreOrderB2b = gql`
  mutation addPreOrderB2b(
    $customerId: Int!
    $deliveryAddressId: Int!
    $billingAddressId: Int
    $preOrderItems: AddPreOrderItemB2bInput!
  ) {
    addPreOrderB2b(
      customerId: $customerId
      deliveryAddressId: $deliveryAddressId
      billingAddressId: $billingAddressId
      preOrderItems: $preOrderItems
    ) {
      id
      token
    }
  }
`;

export const addOrderB2b = gql`
  mutation addOrderB2b(
    $customerId: Int!
    $deliveryAddressId: Int!
    $billingAddressId: Int
    $cartItems: [AddOrderItemB2bInput]!
  ) {
    addOrderB2b(
      customerId: $customerId
      deliveryAddressId: $deliveryAddressId
      billingAddressId: $billingAddressId
      cartItems: $cartItems
    ) {
      id
      token
      redirect_url
      paynowRes {
        id
        token
        url
        payment_intent
        expires_at
        status
        payment_status
        cancel_url
      }
    }
  }
`;

export const getDeliveryTimes = gql`
  query getDeliveryTimes($tenant_id: Int!) {
    getDeliveryTimes(tenant_id: $tenant_id) {
      deliveryTimes
    }
  }
`;

export const updateOrderB2b = gql`
  mutation updateOrderB2b(
    $orderId: Int!
    $orderVersion: Int!
    $deliveryDate: String
    $deliveryTime: String
    $description: String
    $poNumber: String
    $orderItems: [UpdateOrderItemB2bInput]!
  ) {
    updateOrderB2b(
      orderId: $orderId
      orderVersion: $orderVersion
      deliveryDate: $deliveryDate
      deliveryTime: $deliveryTime
      description: $description
      poNumber: $poNumber
      orderItems: $orderItems
    ) {
      id
      token
    }
  }
`;

export const cancelOrderB2b = gql`
  mutation cancelOrderB2b($orderId: Int!, $orderVersion: Int!) {
    cancelOrderB2b(orderId: $orderId, orderVersion: $orderVersion) {
      id
      order_status_id
      active
    }
  }
`;

export const getViewsPrices = gql`
  query getViewsPrices($orderId: Int!) {
    getViewsPrices(orderId: $orderId) {
      id
      active
      amount_qty
      sku_id
      customer_buyer_id
      total_qty
      total_weight
      product_type_id
      is_order_by_weight
      sale_unit_price
      total_price
      tax
      tax_rate
      order_id
      catch_weight
      weight
      order_item_status_id
      status_note
      orderItemStatus {
        name
      }
      orderItemDispute {
        id
        status
      }
      sku {
        id
        product_id
        is_order_by_weight
        is_catch_weight
        name
        active
        increment_qty
        inventories {
          sku_id
          id
          quantity
          price
          logistic_type
          product_type_id
        }
        product {
          name
          image
          tenant_id
        }
        oom {
          name
        }
      }
    }
  }
`;

export const checkAllowDoubleOrder = gql`
  mutation checkAllowDoubleOrder {
    checkAllowDoubleOrder
  }
`;

export const PRICE_ADD = gql`
  mutation PRICE_ADD($skuId: Int!, $buyerId: String, $price: Float, $lastUserId: Int!) {
    priceAdd(skuId: $skuId, buyerId: $buyerId, price: $price, lastUserId: $lastUserId) {
      id
    }
  }
`;

export const getOrderItemAmendments = gql`
  query getOrderItemAmendments($orderItemId: Int!, $isApproved: Int) {
    getOrderItemAmendments(orderItemId: $orderItemId, isApproved: $isApproved) {
      id
      order_item_id
      old_qty
      new_qty
      is_approved
      created_by
      created_at
      active
      user_type
      reason
    }
  }
`;

export const updateOrderItemAmendmentsApprovalById = gql`
  mutation updateOrderItemAmendmentsApprovalById($amendmentId: Int!, $isApproved: Int!) {
    updateOrderItemAmendmentsApprovalById(amendmentId: $amendmentId, isApproved: $isApproved) {
      id
      order_item_id
      old_qty
      new_qty
      is_approved
      created_by
      created_at
      updated_by
      updated_at
      active
    }
  }
`;

export const updateItemReceived = gql`
  mutation updateItemReceived($id: Int!) {
    updateItemReceived(id: $id) {
      success
    }
  }
`;

export const updatePreOrderB2b = gql`
  mutation updatePreOrderB2b($customerId: Int!, $preOrderId: Int!, $preOrderItems: AddPreOrderItemB2bInput!) {
    updatePreOrderB2b(customerId: $customerId, preOrderId: $preOrderId, preOrderItems: $preOrderItems) {
      id
      token
    }
  }
`;

export const cancelPreOrderB2b = gql`
  mutation cancelPreOrderB2b($preOrderId: Int!) {
    cancelPreOrderB2b(preOrderId: $preOrderId)
  }
`;
export const getTotalCountPurchaseB2bOrder = gql`
  query getTotalCountPurchaseB2bOrder(
    $statusIds: [Int]
    $customerBuyerIds: [Int]
    $startDeliveryDate: String
    $endDeliveryDate: String
  ) {
    getTotalCountPurchaseB2bOrder(
      statusIds: $statusIds
      customerBuyerIds: $customerBuyerIds
      startDeliveryDate: $startDeliveryDate
      endDeliveryDate: $endDeliveryDate
    )
  }
`;
export const processNetsPaymentPinPad = gql`
  mutation processNetsPaymentPinPad(
    $headers: String
    $requestBody: String
    $newOrderId: Int
    $newOrderItemIds: [Int]
    $netsCardId: Int
    $netsT0205: String
    $amount: Float
    $needCancelOrder: Boolean!
  ) {
    processNetsPaymentPinPad(
      headers: $headers
      requestBody: $requestBody
      newOrderId: $newOrderId
      newOrderItemIds: $newOrderItemIds
      netsCardId: $netsCardId
      netsT0205: $netsT0205
      amount: $amount
      needCancelOrder: $needCancelOrder
    )
  }
`;
export const downloadInvoiceCNPdf = gql`
  mutation downloadInvoiceCNPdf($orderId: Int, $creditNoteIds: [Int]) {
    downloadInvoiceCNPdf(orderId: $orderId, creditNoteIds: $creditNoteIds)
  }
`;
export const downloadInvoice = gql`
  mutation downloadInvoice($orderId: Int!) {
    downloadInvoice(orderId: $orderId)
  }
`;

export const downloadInvoiceByInvoiceId = gql`
  mutation downloadInvoiceByInvoiceId($invoiceId: Int!) {
    downloadInvoiceByInvoiceId(invoiceId: $invoiceId)
  }
`;

export const paymentTransactionDetailsForOrder = gql`
  mutation paymentTransactionDetailsForOrder($orderId: Int!) {
    paymentTransactionDetailsForOrder(orderId: $orderId) {
      id
      checkout_session_id
      payment_intent_id
      amount
      payment_link
      payment_type_id
      status
      expires_at
      created_by
      created_at
      updated_at
      paymentType {
        id
        name
        is_manual
      }
      paymentTransactionItems {
        id
        order_id
        invoice_id
        payment_transaction_id
        amount
        created_at
        order {
          id
          order_status_id
          delivery_date
        }
      }
    }
  }
`;

export const cancelPrepayment = gql`
  mutation cancelPrepayment($stripeCheckoutSessionId: String!) {
    cancelPrepayment(stripeCheckoutSessionId: $stripeCheckoutSessionId)
  }
`;

export const getFluidCustomerInfo = gql`
  query getFluidCustomerInfo($tenantId: Int!, $customerId: Int!) {
    getFluidCustomerInfo(tenantId: $tenantId, customerId: $customerId)
  }
`;
