import { apolloClient } from '@/main';
import {
  addOrderB2b,
  checkAllowDoubleOrder,
  createPreOrderB2b,
  getDeliveryTimes,
  processNetsPaymentPinPad
} from '@/modules/b2b/services/graphql';
import { getMessage } from '@/services/shared/helper/services.js';
import { MUTATIONS } from './mutations';

export const ACTIONS = {
  SET_FAILURE: 'SET_FAILURE',
  ADD_ORDER: 'ADD_ORDER',
  ADD_PRE_ORDER: 'ADD_PRE_ORDER',
  GET_DELIVERY_TIMES: 'GET_DELIVERY_TIMES',
  CHECK_DOUBLE_ORDER: 'CHECK_DOUBLE_ORDER',
  NETS_PAYMENT_PIN: 'NETS_PAYMENT_PIN'
};

export default {
  // force set failure
  async [ACTIONS.SET_FAILURE]({ commit }, params) {
    commit(MUTATIONS.SET_FAILURE, new Error(params.msg));
  },

  // check duplicate ORDER
  async [ACTIONS.CHECK_DOUBLE_ORDER]({ commit }) {
    commit(MUTATIONS.CHECK_DOUBLE_ORDER_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: checkAllowDoubleOrder
      });
      commit(MUTATIONS.CHECK_DOUBLE_ORDER_SUCCESS);
      return data.checkAllowDoubleOrder;
    } catch (error) {
      commit(MUTATIONS.CHECK_DOUBLE_ORDER_FAILURE, error);
    }
    return null;
  },

  // add pre-ORDER
  async [ACTIONS.ADD_PRE_ORDER]({ commit }, params) {
    commit(MUTATIONS.ADD_PRE_ORDER_REQUEST);
    try {
      await apolloClient.mutate({
        mutation: createPreOrderB2b,
        variables: params
      });
      commit(MUTATIONS.ADD_PRE_ORDER_SUCCESS);
    } catch (error) {
      commit(MUTATIONS.ADD_PRE_ORDER_FAILURE, getMessage(error));
    }
  },
  // add ORDER
  async [ACTIONS.ADD_ORDER]({ commit }, params) {
    commit(MUTATIONS.ADD_ORDER_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: addOrderB2b,
        variables: params
      });
      if (!errors || errors.length === 0) {
        commit(MUTATIONS.ADD_ORDER_SUCCESS);
      } else {
        commit(MUTATIONS.ADD_ORDER_FAILURE, errors[0]);
      }
      return data;
    } catch (error) {
      commit(MUTATIONS.ADD_ORDER_FAILURE, getMessage(error));
    }
  },

  async [ACTIONS.GET_DELIVERY_TIMES](_, params) {
    try {
      const { data } = await apolloClient.query({
        query: getDeliveryTimes,
        variables: {
          active: true,
          tenant_id: params
        }
      });
      return data.getDeliveryTimes;
    } catch (error) {
      console.log(error);
    }
  },

  // add Proccess NETs payment pin pad
  async [ACTIONS.NETS_PAYMENT_PIN]({ commit }, params) {
    commit(MUTATIONS.NETS_PAYMENT_PIN_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: processNetsPaymentPinPad,
        variables: params
      });
      commit(MUTATIONS.NETS_PAYMENT_PIN_SUCCESS);
      return data;
    } catch (error) {
      console.log(`echo: NETS_PAYMENT_PIN response data ERRRO: ==== ${JSON.stringify(error)}`);
      commit(MUTATIONS.NETS_PAYMENT_PIN_FAILURE, error);
    }
  }
};
