import { getUserType } from '@/modules/shared/utils/';
import { Capacitor } from '@capacitor/core';
import { BranchDeepLinks } from 'capacitor-branch-deep-links';

const PATH_WITH_ROLE = ['/product-detail'];

/**
 * Event Documentation:
 * https://help.branch.io/developers-hub/docs/tracking-commerce-content-lifecycle-and-custom-events
 *
 * Standar event name:
 * https://github.com/BranchMetrics/android-branch-deep-linking-attribution/blob/master/Branch-SDK/src/main/java/io/branch/referral/util/BRANCH_STANDARD_EVENT.java
 */
const isNative = Capacitor.isNativePlatform();
const TAG = 'VUE_BRANCH';

/**
 * @param storage
 * @param router
 */
const init = (storage, router) => {
  BranchDeepLinks.addListener('init', async (event) => {
    // Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
    // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic
    const referringParams = event?.referringParams || {};
    const clickedBranchLink = referringParams['+clicked_branch_link'] || false;
    if (clickedBranchLink) {
      // get url from params
      let url = referringParams['url'];
      // check URL path
      if (url && PATH_WITH_ROLE.map((path) => url.indexOf(path) > -1)) {
        const { user_type_id } = await storage.getUser();
        const userType = getUserType(user_type_id);
        url = `/${userType}/${url}`;
      }

      // fetch editional query params
      const paramKeys = Object.keys(referringParams);
      const query = {};
      paramKeys.forEach((key) => {
        if (key.indexOf('__') > -1) {
          query[key.replace('__', '')] = referringParams[key];
        }
      });

      // storage.setBranchOpenUrl(storage.setBranchOpenUrl(referringParams['url']));
      if (url)
        router.push({
          path: url,
          query
        });
    }
  });
  BranchDeepLinks.addListener('initError', (error) => {
    console.log(`echo: registerBranchioListener initError ${JSON.stringify(error)}`);
  });
};

/**
 * @param {Number} newIdentity
 */
const setIdentity = (newIdentity) => {
  if (isNative && newIdentity) {
    BranchDeepLinks.setIdentity({ newIdentity: `${newIdentity}` })
      .then((res) => {
        console.log(`${TAG} [setIdentity]`, JSON.stringify(res.referringParams));
      })
      .catch((err) => {
        console.error(`${TAG} [setIdentity: Error]`, err.message);
      });
  }
};

const logout = () => {
  if (!isNative) return;
  BranchDeepLinks.logout()
    .then((res) => {
      console.log(`${TAG} [logout]`, JSON.stringify(res.logged_out));
    })
    .catch((err) => {
      console.error(`${TAG} [logout: Error]`, err.message);
    });
};

const Branch = {
  setIdentity,
  logout,
  init
};

export default Branch;
