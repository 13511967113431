import { DEFAULT_GETTERS } from '@/modules/sale/constants';

export default {
  ...DEFAULT_GETTERS,
  cardList: (state) => state.listCustomerCard?.data || [],
  defaultCardId: (state) => state.defaultCardId,
  removeStripeCard: (state) => state.removeStripeCard,
  saleListStripeCard: (state) => state.saleListStripeCard,
  listInvoices: (state) => state.listInvoices || [],
  totalCountInvoices: (state) => state.totalCountInvoices,
  xeroCustomer: (state) => state.xeroCustomer,
  totalUnpaidInvoices: (state) => state.totalUnpaidInvoices,
  paymentSummary: (state) => state.paymentSummary,
  selectedPaymentInvoices: (state) => state.selectedPaymentInvoices,
  selectedPaymentMethod: (state) => state.selectedPaymentMethod,
  isFinanceModuleEnabled: (state) => state.isFinanceModuleEnabled,
  paymentHistory: (state) => state.paymentHistory
};
