import b2bModule from '../modules/b2b/store';
import saleModule from '../modules/sale/store';
import sharedModule from '../modules/shared/store';

import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import actions from './actions';
import mutations from './mutations';
// import createPlugin from 'logrocket-vuex';
// import LogRocket from 'logrocket';

// const logrocketPlugin = createPlugin(LogRocket);

const store = new Vuex.Store({
  modules: {
    shared: sharedModule,
    b2b: b2bModule,
    sale: saleModule
  },
  // plugins: [createPersistedState(), logrocketPlugin], // temporary remove log rocket for out of memory issue
  plugins: [
    createPersistedState({
      paths: [
        'shared',
        'sale.account',
        'sale.address',
        'sale.cart',
        'sale.payment',
        'sale.purchase',
        'sale.product',
        'sale.productSearch',
        'b2b.account',
        'b2b.address',
        'b2b.cart',
        'b2b.payment',
        'b2b.purchase',
        'b2b.product',
        'b2b.productSearch'
      ]
    })
  ],
  actions,
  mutations
});

export default store;
