<template #default>
  <ion-page>
    <ion-content :fullscreen="true" class="onboardingContainer">
      <div style="position: absolute; top: 10%; left: 12%; width: 20%">
        <ion-img src="/assets/images/treedots_logo.svg" />
      </div>
      <ion-slides pager="true" :options="slideOpts" class="onboardingSlider text-center" ref="slides">
        <ion-slide>
          <ion-grid>
            <ion-row>
              <div class="img">
                <ion-img class="onboardingImg mx-auto" src="/assets/images/boarding_cart.png" />
              </div>
              <wording-parse :locale="locale" :screen="1" />
            </ion-row>
            <ion-row class="bottom">
              <ion-col size="12">
                <ion-button class="onboardingButton" @click="slideNext(2)"> {{ $t('next') }}</ion-button>
              </ion-col>
              <ion-col size="12">
                <ion-button fill="clear" @click="goHome()"> {{ $t('skip') }}</ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-slide>
        <ion-slide>
          <ion-grid>
            <ion-row>
              <div class="img">
                <ion-img class="onboardingImg mx-auto" src="/assets/images/boarding_entity.png" />
              </div>
              <wording-parse :locale="locale" />
            </ion-row>
            <ion-row class="bottom">
              <ion-col size="12">
                <ion-button class="onboardingButton" @click="goHome()"> {{ $t('get_started') }}</ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-slide>
      </ion-slides>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonImg, IonButton, IonGrid, IonRow, IonSlides, IonSlide } from '@ionic/vue';
import { useRouter } from 'vue-router';
import { defineComponent, inject, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import wordingParse from './wordingParse.vue';
export default defineComponent({
  name: 'Onboarding',
  components: {
    IonContent,
    IonPage,
    IonImg,
    IonButton,
    IonGrid,
    IonRow,
    IonSlides,
    IonSlide,
    wordingParse
  },
  setup() {
    const { locale } = useI18n();
    const storage = inject('$storage');
    onMounted(async () => {
      locale.value = await storage.get('language');
    });
    const router = useRouter();

    const slideOpts = {
      initialSlide: 0,
      slidesPerView: 1
    };
    return {
      router,
      slideOpts,
      storage,
      locale
    };
  },
  methods: {
    async goHome() {
      // set skipped on boarding = true
      const currentUser = await this.storage.getUser();
      await this.storage.setSkippedOnBoarding(currentUser.id.toString());

      // check user type to redirect user to correct app
      if (
        (currentUser.user_type_id === 6 || currentUser.user_type_id === 12) &&
        currentUser.buyer_type === 1
      ) {
        this.router.push('/b2b');
      } else if (this.userType === 7 || this.userType === 2) {
        this.router.push('/sale');
      } else {
        this.router.push('/b2c');
      }
    },
    slideNext(index) {
      this.$refs.slides.$el.slideTo(index);
    }
  }
});
</script>
<style scoped lang="scss">
@import 'onboarding';
ion-button {
  text-transform: none;
  --border-radius: 10px;
  font-size: 1.97vh;
  width: 95%;
}

.bottom {
  position: fixed;
  bottom: 70px;
  width: 100%;
}
.img {
  width: 70%;
  margin: 0 auto;
  margin-top: -50px;
}
</style>
