import gql from 'graphql-tag';

export const getInvoicePaymentHistory = gql`
  query getInvoicePaymentHistory($invoiceId: Int!, $tenantId: Int!) {
    getInvoicePaymentHistory(invoiceId: $invoiceId, tenantId: $tenantId) {
      details {
        paymentNumber
        paymentDate
        paymentType
        type
        stripeCardId
        amount
      }
      paymentStatus
      paidAmount
      amount
      amountDue
      customerCreditUsed
      creditTerm
    }
  }
`;

export const getDefaultCard = gql`
  query getDefaultCardByCustomer($customerId: Int!) {
    getDefaultCardByCustomer(customerId: $customerId) {
      ... on DefaultCardByCustomerResponse {
        cardId
        stripeCustomerId
      }
      ... on CardErrorResponse {
        type
        message
      }
    }
  }
`;
